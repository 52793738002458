import React from "react";
import { Button, Loader, Tag } from "rsuite";

import { displayStatusColor } from "../utils/displayStatusColor";

const ApplicantStatusTable = ({ applications, loading, redirectURL }) => {
  if (loading) return <Loader className="tw-my-8" content="Loading..." />;

  return (
    <div className="tw-my-8">
      {applications.length > 0 ? (
        <div className="tw-w-full tw-flex tw-flex-col sm:tw-px-2">
          {applications.map((application) => (
            <div
              className="tw-w-full tw-flex tw-flex-row tw-my-2 tw-py-4 tw-items-start sm:tw-rounded-lg sm:tw-flex-col sm:tw-border sm:tw-border-solid sm:tw-border-[#E5E5EA] sm:tw-px-4"
              style={{ borderBottom: "1px solid #E5E5EA" }}
            >
              <div className="tw-w-1/3 tw-text-left sm:tw-w-full">
                <span className="tw-font-medium">
                  {application.application_name}
                </span>
              </div>
              <div className="tw-flex tw-w-1/2 sm:tw-w-full sm:tw-pt-2 sm:tw-justify-start">
                <div className="tw-w-1/2 tw-flex tw-flex-col">
                  <span className="tw-text-xs">Submitted on</span>
                  <span>{application.submitted_on}</span>
                </div>
                <div className="tw-w-1/2 tw-flex tw-flex-col">
                  <span className="tw-text-xs">Status</span>
                  <div>
                    <Tag
                      style={{
                        backgroundColor: displayStatusColor(application.status),
                      }}
                      color={displayStatusColor(application.status)}
                    >
                      {application.status.toUpperCase()}
                    </Tag>
                  </div>
                </div>
              </div>
              <div className="tw-w-1/3 sm:tw-w-full sm:tw-flex sm:tw-pt-4 sm:tw-mt-2 sm:tw-border-t-2 sm:tw-border-[#E5E5EA] ">
                {application.status === "approved" && (
                  <Button
                    className="sm:tw-w-full"
                    appearance="primary"
                    href={redirectURL}
                  >
                    Continue
                  </Button>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="tw-text-center tw-my-8">
          You haven't submitted any applications yet.
        </div>
      )}
    </div>
  );
};

export default ApplicantStatusTable;
