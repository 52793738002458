// React
import React from "react";

// UI
import { Button } from "rsuite";
import { FaRegCalendarAlt, FaRegCalendarTimes } from "react-icons/fa";

export function CalendarButtons(props) {
  const { category, booking } = props;

  if (category !== "completed") {
    return (
      <div>
        <Button
          startIcon={<FaRegCalendarAlt size={18} />}
          appearance="link"
          href={booking?.reschedule}
          target="_blank"
        >
          Reschedule
        </Button>
        <Button
          className="tw-text-red-500"
          startIcon={<FaRegCalendarTimes size={18} />}
          appearance="link"
          href={booking?.cancel_path}
          target="_blank"
        >
          Cancel
        </Button>
      </div>
    );
  }

  return null;
}

export function ConfirmationButtons(props) {
  const { category, booking, handleAccept } = props;

  if (category === "pending") {
    return (
      <>
        <Button
          appearance="link"
          className="tw-text-red-500"
          href={booking?.cancel_path}
          target="_blank"
        >
          Cancel
        </Button>
        <Button appearance="ghost" href={booking?.reschedule} target="_blank">
          Reschedule
        </Button>
        {booking?.status === "Pending your approval" && (
          <>
            <Button
              appearance="primary"
              onClick={() => handleAccept(booking.id)}
            >
              Accept
            </Button>
          </>
        )}
      </>
    );
  }

  if (category === "upcoming") {
    return (
      <>
        <Button
          appearance="link"
          className="tw-text-red-500 tw-border-red-500"
          href={booking?.cancel_path}
          target="_blank"
        >
          Cancel
        </Button>
        <Button appearance="ghost" href={booking?.reschedule} target="_blank">
          Reschedule
        </Button>
        <Button
          appearance="primary"
          href={booking?.contact_info}
          target="_blank"
        >
          Join Room
        </Button>
      </>
    );
  }

  if (category === "completed") {
    return (
      <Button appearance="primary" href={booking?.rate_now} target="_blank">
        Rate Now
      </Button>
    );
  }
}
