import React, { createContext, useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "rsuite";
import { FutureLabApi } from "../util/api";
import { PortalApi } from "../util/portal_api";
import { usePortal } from "./portal";

export enum PlatformType {
  Portal = "portal",
  FutureLab = "futurelab",
}

export enum UserType {
  Mentor = "mentor",
  Mentee = "mentee",
}

export interface IMentorContext {
  mentor: object;
  setMentor: (mentor: object) => void;
  refreshMentor: () => void;
  type: PlatformType;
  program?: object | null;
}

const MentorProfileContext = createContext<IMentorContext>(undefined);

const useMentorProvider: () => IMentorContext = () => {
  const [mentor, setMentor] = useState();
  const { hexId } = useParams();
  const type = PlatformType.FutureLab;

  useEffect(() => {
    setMentor(undefined);
    getMentor();
  }, [hexId]);

  const getMentor = () => {
    FutureLabApi({
      method: "get",
      path: `/mentor_profiles/${hexId}`,
      success: ({ data }) => {
        setMentor(data);
      },
    });
  };

  const refreshMentor = () => {
    getMentor();
  };

  return { mentor, setMentor, refreshMentor, type };
};

const usePortalMentorProvider: () => IMentorContext = () => {
  const [mentor, setMentor] = useState();
  const { mentorId } = useParams();
  const { program } = usePortal();
  const type = PlatformType.Portal;

  const refreshMentor = () => {
    PortalApi({
      method: "get",
      path: `/mentor_profiles/${mentorId}`,
      options: { program_id: program?.id },
      success: ({ data }) => {
        setMentor(data);
      },
    });
  };

  useEffect(() => {
    if (program && mentorId) {
      refreshMentor();
    }
  }, [mentorId, program]);

  return { mentor, setMentor, refreshMentor, type };
};

export const PortalMentorProfileProvider = ({ children }) => {
  const { mentor, setMentor, refreshMentor, type } = usePortalMentorProvider();
  const { program } = usePortal();

  return (
    <MentorProfileContext.Provider
      value={{
        mentor,
        setMentor,
        refreshMentor,
        type,
        program,
      }}
    >
      {children}
    </MentorProfileContext.Provider>
  );
};

export const usePortalMentorProfile = () => useContext(MentorProfileContext);

export const MentorProfileProvider = ({ children }) => {
  const { mentor, setMentor, refreshMentor, type } = useMentorProvider();

  return (
    <MentorProfileContext.Provider
      value={{
        mentor,
        setMentor,
        refreshMentor,
        type,
        program: null,
      }}
    >
      {children}
    </MentorProfileContext.Provider>
  );
};

export const useMentorProfile = () => useContext(MentorProfileContext);

export function withMentorProfile(WrappedComponent) {
  const Component = (props) => {
    const { mentor, setMentor, refreshMentor } = useMentorProfile();

    if (!mentor) return <Loader center content="loading" />;

    return (
      <WrappedComponent
        mentor={mentor}
        setMentor={setMentor}
        refreshMentor={refreshMentor}
        {...props}
      />
    );
  };

  return Component;
}
