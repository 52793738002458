// React
import React from "react";

// 3rd Party
import moment from "moment";

export function ArrayCell({ value }) {
  return (
    <div className="tw-flex tw-flex-col tw-gap-1">
      {value?.map((item, i) => (
        <span key={i} className="tw-text-md">
          {`${i + 1}. ${item}`}
        </span>
      ))}
    </div>
  );
}

export function TimeCell({ value }) {
  if (!value) return <span />;
  return <span>{moment(value).format("DD MMM YYYY")}</span>;
}

export function NumberCell({ value }) {
  return <div className="tw-text-center tw-font-semibold">{value || "-"}</div>;
}

export function BooleanCell({ value }) {
  return <span>{value ? 'True' : 'False'}</span>;
}
