// React
import React from "react";
import { useNavigate } from "react-router-dom";

// UI
import { Accordion, Divider } from "rsuite";
import { FaRegCircleCheck } from "react-icons/fa6";

export const StartingGuide = ({ setupStatus }) => {
  const { has_invited_user, has_setup_pairing, has_created_agenda } =
    setupStatus;
  const navigate = useNavigate();
  return (
    <div className="tw-max-w-lg">
      <Accordion>
        <Accordion.Panel
          defaultExpanded
          header="How to get started"
          className="tw-mb-2"
        >
          {!has_invited_user && (
            <>
              <div className="tw-flex tw-items-center tw-gap-4 py-1">
                <FaRegCircleCheck color="#696969" size={24} />
                <a onClick={() => navigate("./user-invites")}>
                  Invite Participants
                </a>
              </div>
              <Divider className="tw-my-4" />
            </>
          )}
          {!has_setup_pairing && (
            <>
              <div className="tw-flex tw-items-center tw-gap-4 py-1">
                <FaRegCircleCheck color="#696969" size={24} />
                <a onClick={() => navigate("./group_and_pairings_management")}>
                  Setup Pairing
                </a>
              </div>
              <Divider className="tw-my-4" />
            </>
          )}

          {!has_created_agenda && (
            <div className="tw-flex tw-items-center tw-gap-4 py-1">
              <FaRegCircleCheck color="#696969" size={24} />
              <a onClick={() => navigate("./program_overview_settings")}>
                Create Agenda
              </a>
            </div>
          )}
          {/* <Divider className="tw-my-4" />

          <div className="tw-flex tw-items-center tw-gap-4 py-1">
            <FaRegCircleCheck color="#696969" size={24} />
            <a onClick={() => navigate("./bookings/bookings_list")}>
              Track a booking
            </a>
          </div> */}
        </Accordion.Panel>
      </Accordion>
    </div>
  );
};
