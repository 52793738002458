// React
import React from "react";

// UI
import { Divider, Modal } from "rsuite";

export default function StarterPackModal({ show, close }) {
  return (
    <Modal size="xs" open={show} onClose={close}>
      <Modal.Header>
        <Modal.Title className="tw-font-medium tw-text-lg">
          Mentorship starter pack
        </Modal.Title>
      </Modal.Header>
      <Divider className="tw-mb-0 tw-mt-2" />
      <Modal.Body className="tw-text-center tw-font-medium tw-text-[#222222]">
        Too many mentors, can't land on one?
        <br />
        Get Mentorship Starter Pack!
        <br />
        For only <span className="tw-font-bold">RM60</span>,
        <br />
        unlock a shortcut to learn from the
        <br />
        success of 3 different mentors!
      </Modal.Body>
    </Modal>
  );
}
