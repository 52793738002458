// React
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useOutletContext, useNavigate } from "react-router-dom";

// FutureLab
import avatar from "../../images/avatar_placeholder.png";
import check from "../../images/blue-check.png";
import { NavLink } from "./ChatManagement";

// UI
import { Grid, Row, Col, Button, Dropdown, Modal } from "rsuite";
import { BsThreeDots } from "react-icons/bs";

declare module "rsuite" {
  interface ButtonProps {
    state?: object;
  }
}

const GroupList = () => {
  const {
    mentors,
    mentees,
    selectedGroup,
    groupChats,
    setSelectedGroup,
    addUsers,
    deleteUsers,
    admins,
  } = useOutletContext() as any;

  const users = useMemo(() => {
    const join = [...admins, ...mentors, ...mentees];
    const unique = join.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id);
      if (!x) {
        acc.push(current);
      }
      return acc;
    }, []);
    return unique;
  }, [mentors, mentees, admins]);

  const handleDelete = async (id) => {
    await deleteUsers(selectedGroup.id, [id]);
  };

  const selectedParticipants = useMemo(() => {
    if (!selectedGroup) return [];
    const ids = selectedGroup?.participants?.map(
      (participant) => participant.userId
    );
    return users.filter((user) => ids.includes(user.id));
  }, [selectedGroup, users]);

  useEffect(() => {
    if (groupChats.length > 0 && !selectedGroup) {
      setSelectedGroup(groupChats[0]);
    }
  }, [groupChats, selectedGroup]);

  return (
    <Grid fluid>
      <Row className="tw-flex tw-justify-between">
        <Col sm={14}>
          <ProgramHeader groupLength={groupChats.length}/>
          <div className="tw-mt-5 tw-flex tw-flex-col tw-gap-5 tw-overflow-y-auto tw-h-[600px]">
            {groupChats.map((group) => (
              <GroupCard
                onClick={() => setSelectedGroup(group)}
                key={group.id}
                group={group}
                active={selectedGroup?.id === group.id}
              />
            ))}
          </div>
        </Col>
        <Col sm={8}>
          {groupChats.length > 0 && (
            <MemberList
              selectedParticipants={selectedParticipants}
              selectedGroup={selectedGroup}
              deleteUsers={handleDelete}
              addUsers={addUsers}
            />
          )}
        </Col>
      </Row>
    </Grid>
  );
};

export const MemberList = ({
  selectedGroup,
  selectedParticipants,
  deleteUsers,
  addUsers,
  from = "/",
}) => {
  const memberLength = selectedParticipants.length || 0;
  const containerRef = useRef(null);

  return (
    <>
      <div className="tw-flex tw-items-center tw-justify-between tw-font-bold">
        <span>Members ({memberLength})</span>
        <Button
          appearance="link"
          size="sm"
          as={NavLink}
          href="/user-list"
          state={{ selectedGroup, selectedParticipants, from, addUsers }}
        >
          + Add Member
        </Button>
      </div>
      <div
        ref={containerRef}
        className="tw-mt-5 tw-flex tw-flex-col tw-gap-4 tw-overflow-y-auto tw-h-[520px]"
      >
        {selectedParticipants.map((member) => (
          <MemberCard
            deleteUsers={deleteUsers}
            key={member.id}
            member={member}
          />
        ))}
      </div>
    </>
  );
};

const DeleteModal = ({ open, onClose, handleDelete }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Remove Member</Modal.Title>
      </Modal.Header>
      <Modal.Body className="tw-flex tw-justify-center tw-items-center">
        <span className="tw-bold">
          Are you sure you want to remove this member?
        </span>
      </Modal.Body>
      <Modal.Footer className="tw-flex tw-justify-evenly tw-items-center">
        <Button onClick={onClose} appearance="ghost">
          Cancel
        </Button>
        <Button
          onClick={async () => {
            await handleDelete();
            onClose();
          }}
          appearance="primary"
        >
          Remove
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const MemberCard = ({ member, deleteUsers }) => {
  const [open, setOpen] = useState(false);

  const onClose = () => setOpen(false);
  const handleDelete = async () => {
    try {
      await deleteUsers(member.id);
    } catch (error) {
      console.log(error);
    } finally {
      onClose();
    }
  };

  return (
    <>
      <div className="tw-flex tw-rounded-md tw-items-center tw-p-2.5 tw-h-36 tw-border tw-border-solid tw-border-[#EAEAEA] tw-gap-2 tw-relative">
        <img
          className="tw-h-24 tw-w-24 tw-rounded-md"
          src={member.avatar || avatar}
          alt="avatar"
          loading="lazy"
        />
        <div className="tw-flex tw-flex-col tw-h-24 tw-justify-between">
          <div className="tw-justify-self-start tw-flex tw-flex-col">
            <span className="tw-text-md tw-font-bold tw-m-0">{`${member.firstname} ${member.lastname}`}</span>
            <span className="tw-text-sm tw-text-gray-500 tw-m-0">
              {member.job?.name}
            </span>
          </div>
          <div className="tw-flex tw-items-center tw-gap-2 tw-justify-self-end">
            <Button
              appearance="ghost"
              as={"a"}
              href={`/mentor_profile/${member.slug}`}
              size="xs"
              target="_blank"
            >
              View Profile
            </Button>
            <Button
              appearance="primary"
              as="a"
              href={member.chat_link}
              target="_self"
              size="xs"
            >
              Message
            </Button>
          </div>
        </div>
        <div className="tw-absolute tw-right-0 tw-top-0 tw-flex tw-items-center tw-gap-2 tw-p-3 tw-cursor-pointer">
          <img onClick={() => setOpen(true)} src={check} alt="check" />
        </div>
      </div>
      <DeleteModal open={open} onClose={onClose} handleDelete={handleDelete} />
    </>
  );
};

const GroupCard = ({ group, onClick, active = false }) => {
  const ref = useRef(null);
  const nav = useNavigate();
  useLayoutEffect(() => {
    if (active) {
      ref.current.classList.add("tw-bg-[#EDF7FF]");
      ref.current.classList.add("tw-border-[#8DBFEB]");
    } else {
      ref.current.classList.remove("tw-bg-[#EDF7FF]");
      ref.current.classList.remove("tw-border-[#8DBFEB]");
    }
  }, [active]);

  return (
    <div
      ref={ref}
      onClick={onClick}
      className="tw-flex tw-rounded-xl tw-cursor-pointer tw-items-center tw-gap-2 tw-relative tw-p-3 hover:tw-bg-[#EDF7FF] tw-border tw-border-solid tw-border-[#EAEAEA] hover:tw-border-[#8DBFEB]"
    >
      <img
        className="tw-h-32 tw-w-32 tw-rounded-md"
        src="https://placehold.co/120x120"
        alt="image-logo"
      />
      <div className="tw-flex tw-flex-col tw-gap-1 tw-self-start">
        <h3 className="tw-text-lg tw-font-bold tw-m-0">{group.name}</h3>
        <span className="tw-text-sm tw-text-gray-500 tw-m-0">
          {group.description}
        </span>
      </div>
      <div className="tw-absolute tw-right-0 tw-top-0 tw-flex tw-items-center tw-gap-2 tw-p-3">
        <Dropdown
          placement="bottomEnd"
          renderToggle={(props, ref) => (
            <span className="tw-font-bold tw-text-3xl" ref={ref} {...props}>
              <BsThreeDots />
            </span>
          )}
        >
          <Dropdown.Item onClick={() => nav("/edit-group")}>
            Group Settings
          </Dropdown.Item>
        </Dropdown>
      </div>
    </div>
  );
};

const ProgramHeader = ({ groupLength = 0 }) => {
  return (
    <div>
      <div className="tw-flex tw-items-center tw-gap-2">
        <h1 className="tw-text-xl">
          Chats
        </h1>
      </div>
      <div className="tw-flex tw-items-center tw-gap-5 tw-font-bold">
        <span>Groups ({groupLength})</span>
        <Button appearance="primary" size="sm" as={NavLink} href="/add-group">
          + Add Group
        </Button>
      </div>
    </div>
  );
};

export default GroupList;
