import React, { useState } from "react";
import { PortalApi } from "../../../../../util/portal_api";
import { usePortal } from "../../../../../contexts/portal";
import { useFormContext } from "react-hook-form";
import Toaster from "../../../../shared/Toaster";
import { Button } from "rsuite";

const FormSubmitButton = () => {
  const { program, setProgram } = usePortal();
  const { handleSubmit } = useFormContext();
  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    if (program) {
      data.program_id = program.id;
    }

    const form = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (typeof value === "object") {
        Object.entries(value).forEach(([subKey, subValue]) => {
          if (subValue instanceof File) {
            form.append(`${key}[${subKey}]`, subValue);
          }

          if (typeof subValue !== "undefined")
            form.append(`${key}[${subKey}]`, subValue);
        });
      } else {
        form.append(key, value as any);
      }
    });

    setLoading(true);
    PortalApi({
      method: "post",
      path: "/programs",
      options: form,
      config: {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      success: ({ data }) => {
        setLoading(false);
        setProgram(data);
        Toaster({
          type: "success",
          msg: "Successfully saved.",
        });
      },
      error: ({ data }) => {
        console.log(data);
        setLoading(false);

        Toaster({
          type: "error",
          msg: "Oops, something went wrong.",
        });
      },
    });
  };

  return (
    <>
      <Button
        appearance="primary"
        onClick={handleSubmit(onSubmit)}
        loading={loading}
        className="tw-mb-6"
      >
        Save
      </Button>
    </>
  );
};

export default FormSubmitButton;
