/**
 * Need to be use under usercontext and navbarcontext
 */

import React from "react";
import { useUser } from "../contexts/user";
import flLogo from "../images/fl-circle-logo.png";
import { useNavbarContext } from "../contexts/navbarContext";

export type Program = {
  id: number;
  name: string;
  photo_url: string;
  program_url: string;
  is_active: boolean;
};

interface useProgramsOutput {
  programs: Program[];
  activeProgram: Program;
  otherPrograms?: Program[];
  search: string | null;
  setSearch: React.Dispatch<React.SetStateAction<string | null>>;
}

type UsesPrograms = () => useProgramsOutput;

const usePrograms: UsesPrograms = () => {
  const [search, setSearch] = React.useState(null);
  const { onPortal, portalId, baseUrl } = useNavbarContext();
  const { user } = useUser();
  const programs = (user?.programs as Program[]) || [];

  const defaultProgram: Program = {
    id: 0,
    name: "FutureLab",
    photo_url: flLogo,
    program_url: baseUrl.fl,
    is_active: true,
  };

  const joinPrograms = React.useMemo(
    () => [defaultProgram, ...programs],
    [programs]
  );

  const activeProgram = React.useMemo(() => {
    if (!!!programs || !onPortal) return defaultProgram;
    return programs.find((program) => program.id === portalId);
  }, [programs, onPortal, portalId]);

  const otherPrograms = React.useMemo(
    //sort by active program if futurelab always on top
    () => {
      const data = joinPrograms
        .filter((p) => p.id !== activeProgram?.id)
        .sort((a, b) => {
          if (a.id === 0) return -1;
          if (b.id === 0) return 1;
          if (a.is_active) return -1;
          if (b.is_active) return 1;
          return 0;
        });

      if (search) {
        return data.filter((p) =>
          p.name.toLowerCase().includes(search.toLowerCase())
        );
      }
      return data;
    },
    [programs, activeProgram, search]
  );

  return {
    programs: joinPrograms || [defaultProgram],
    activeProgram,
    otherPrograms,
    search,
    setSearch,
  };
};

export default usePrograms;
