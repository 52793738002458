import React from "react";
import { createPortal } from "react-dom";

const portal = (Wrapped) => {
  const Component = (props) => {
    return createPortal(<Wrapped {...props} />, document.body);
  };

  return Component;
};

export default portal;
