import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

const schema = yup.object().shape({
  groupChatName: yup.string().required("Group name is required"),
  recipientUserIds: yup.array().min(1, "Please select at least one user"),
  groupChatDescription: yup.string(),
  programId: yup.string().required("Program is required"),
  groupChatAdmins: yup.array(),
  programName: yup.string(),
});

export const useCreateForm = (program: any, api: any, reset: any) => {
  const formInstance = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      programName: program?.name,
      programId: program?.id,
      recipientUserIds: [],
    },
  });
  const nav = useNavigate();

  const onSubmit = async (data) => {
    try {
      const res = await api.post("/chat/group-chat", {
        ...data,
        programId: _.toNumber(data.programId),
      });
      formInstance.reset();
      reset();
      nav("/");
    } catch (error) {
      console.log(error);
    }
  };

  const onEdit = async (data) => {
    try {
      const res = await api.put("/chat/group-chat", {
        ...data,
        programId: _.toNumber(data.programId),
      });
      formInstance.reset();
      reset();
      nav("/");
    } catch (error) {
      console.log(error);
    }
  };

  const onError = (errors) => {
    console.log(errors);
  };

  const submitHandler = formInstance.handleSubmit(onSubmit, onError);
  const editHandler = formInstance.handleSubmit(onEdit, onError);

  const insertUsers = (_, users) => {
    const current = formInstance.getValues("recipientUserIds");
    formInstance.setValue("recipientUserIds", [...current, ...users]);
  };

  const deleteUser = (user) => {
    const current = formInstance.getValues("recipientUserIds");
    formInstance.setValue(
      "recipientUserIds",
      current.filter((u) => u !== user)
    );
  };

  return {
    ...formInstance,
    submitHandler,
    insertUsers,
    deleteUser,
    editHandler,
  };
};

export default useCreateForm;
