// React
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// FutureLab
import ApplicationDataTable from "./ui/ApplicationDataTable";
import { usePortal } from "../../../contexts/portal";
import { PortalApi } from "../../../util/portal_api";
import DeletionModal from "./ui/DeletionModal";

// UI
import { Button, Message, toaster } from "rsuite";

const handleDataUpdate = {
  togglePublished: (data, applicationId) => {
    const updatedData = data.map((item) => {
      if (item.id === applicationId) {
        return {
          ...item,
          published: !item.published, // Toggle the published value
        };
      }
      return item;
    });
    return updatedData;
  },

  deleteApplication: (data, applicationId) => {
    const filteredData = data.filter((item) => item.id !== applicationId);

    return filteredData;
  },
};

const ApplicationList = () => {
  const [dataList, setDataList] = useState([]);
  const [applicationId, setApplicationId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { program } = usePortal();

  useEffect(() => {
    if (program) {

        setLoading(true);
        PortalApi({
          method: "get",
          path: "/applications",
          options: { program_id: program.id },
          success: ({ data }) => {
            setDataList(data);
            setLoading(false);
          },
        });
  
    }

  }, [program?.id]);

  const handleToggle = (applicationId) => {
    PortalApi({
      method: "post",
      path: `/applications/${applicationId}/toggle_publish`,
      success: ({ data }) => {
        setDataList(handleDataUpdate.togglePublished(dataList, applicationId));
        toaster.push(
          <Message showIcon type="success">
            {data.message}
          </Message>
        );
      },
    });
  };

  const handleDelete = () => {
    PortalApi({
      method: "delete",
      path: `/applications/${applicationId}`,
      success: ({ data }) => {
        setOpenModal(false);
        setDataList(
          handleDataUpdate.deleteApplication(dataList, applicationId)
        );
        toaster.push(
          <Message showIcon type="success">
            {data.message}
          </Message>
        );
      },
    });
  };

  return (
    <>
      <div className="tw-flex tw-mb-10 tw-justify-between">
        <h1 className="tw-text-xl">Applications</h1>
        <div>
          <Button
            appearance="primary"
            onClick={() => navigate("./new")}
            className=""
          >
            Create New
          </Button>
        </div>
      </div>
      <div>
        <ApplicationDataTable
          data={dataList}
          loading={loading}
          setOpenModal={setOpenModal}
          setApplicationId={setApplicationId}
          handleToggle={handleToggle}
        />
      </div>
      {openModal && (
        <DeletionModal
          open={openModal}
          handleModalClose={() => setOpenModal(false)}
          clickHandler={handleDelete}
          title={"Delete Application"}
          bodyMessage={
            "Are you sure you would like to delete this application?"
          }
        />
      )}
    </>
  );
};

export default ApplicationList;
