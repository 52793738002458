import React from 'react';
import { Button, Progress, Stack } from 'rsuite';
import { useUser } from '../../contexts/user';
import ProfileCompletionBar from './ProfileCompletionBar';

const PreviewSaveButton = ({
  handleSubmit = undefined,
  onSubmit = undefined,
  isSubmitting = false,
  noSave = false,
}) => {
  const { user } = useUser();

  return (
    <>
      <Stack spacing={4} justifyContent="space-between">
        <div className="tw-text-xl tw-font-medium tw-hidden sm:tw-block">
          Edit Profile
        </div>
        <Stack spacing={4}>
          <div className="sm:tw-hidden">
            <ProfileCompletionBar />
          </div>
          <Button
            appearance="ghost"
            href={`/mentor_profile/${user.slug}`}
            target="_blank"
          >
            Preview
          </Button>
          <Button
            appearance="primary"
            onClick={noSave ? undefined : handleSubmit(onSubmit)}
            loading={isSubmitting}
          >
            Save
          </Button>
        </Stack>
      </Stack>
      {/* <div className="tw-hidden sm:tw-block">
        <ProfileCompletionBar />
      </div> */}
    </>
  );
};

export default PreviewSaveButton;
