// React
import React from "react";

// FutureLab
import {
  ArrayCell,
  NumberCell,
  TimeCell,
} from "./data_table_component/DataTableCellType";
import DataTable from "./DataTable";

const menteeColumns = [
  {
    Header: "User ID",
    accessor: "userId",
    Cell: NumberCell,
  },
  {
    Header: "How did you find out about FutureLab?",
    accessor: "howDidYouFindOutAboutFutureLab",
    Cell: ArrayCell,
  },
  {
    Header: "First Name",
    accessor: "firstName",
  },
  {
    Header: "Last Name",
    accessor: "lastName",
  },
  {
    Header: "Email Address",
    accessor: "email",
  },
  {
    Header: "Signup Date",
    accessor: "signupDate",
    Cell: TimeCell,
  },
  {
    Header: "Last Login Date",
    accessor: "lastLoginDate",
    Cell: TimeCell,
  },
  {
    Header: "Date of birth",
    accessor: "dateOfBirth",
    Cell: TimeCell,
  },
  {
    Header: "Country",
    accessor: "country",
  },
  {
    Header: "Spoken Languages",
    accessor: "spokenLanguages",
    Cell: ArrayCell,
  },
  {
    Header: "I am a",
    accessor: "occupation",
  },
  {
    Header: "Which company are you from?",
    accessor: "company",
  },
  {
    Header: "Are you looking for a job?",
    accessor: "lookingForJob",
  },
  {
    Header: "When are you looking to start working?",
    accessor: "lookingForJobStartDate",
  },
  {
    Header: "Are you looking for..",
    accessor: "lookingForJobType",
  },
  {
    Header: "What's your most urgent priority?",
    accessor: "lookingForJobPriority",
    Cell: ArrayCell,
  },
  {
    Header: "What job role are you interested in?",
    accessor: "lookingForJobRole",
    Cell: ArrayCell,
  },
  {
    Header: "Interested Company",
    accessor: "lookingForJobCompany",
    Cell: ArrayCell,
  },
  {
    Header: "No. of FutureLab Bookings Made",
    accessor: "noOfFutureLabBookingsMade",
    Cell: NumberCell,
  },
  {
    Header: "No. of FutureLab Events Joined",
    accessor: "noOfFutureLabEventsJoined",
    Cell: NumberCell,
  },
  {
    Header: "No. of Programme Bookings Made",
    accessor: "noOfProgrammeBookingsMade",
    Cell: NumberCell,
  },
  {
    Header: "No. of Programme Events Joined",
    accessor: "noOfProgrammeEventsJoined",
    Cell: NumberCell,
  },
  {
    Header: "No. of Programmes Joined",
    accessor: "noOfProgrammesJoined",
    Cell: NumberCell,
  },
  {
    Header: "Which Programmes?",
    accessor: "programmesJoined",
    Cell: ArrayCell,
  },
];

const MenteeSignupData = ({ initialMentees = [], maxPages, fields }) => {
  return (
    <DataTable
      initialData={initialMentees}
      maxPages={maxPages}
      title="Mentee Signup Data"
      column={menteeColumns as any}
      fields={fields}
    />
  );
};
export default MenteeSignupData;
