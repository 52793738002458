// React
import React from "react";
import { Button } from "rsuite";

export default function RecommendationButtons({
  mentor,
  FLDashboard,
  handleCloseModal,
}) {
  return (
    <>
      {/* {FLDashboard && (
        <Button
          appearance="ghost"
          // onClick={() => handleFollow(mentor)}
          className="tw-w-full"
        >
          {mentor?.is_following ? "Unfollow" : "Follow"}
        </Button>
      )} */}
      <Button
        onClick={() => handleCloseModal("view_profile", mentor)}
        appearance={FLDashboard ? "primary" : "ghost"}
        className="tw-w-full tw-mr-1"
      >
        See Profile
      </Button>

      {mentor?.chat_link && (
        <Button
          onClick={() => handleCloseModal("chat", mentor)}
          appearance="primary"
          className="tw-w-full"
        >
          Chat
        </Button>
      )}
    </>
  );
}
