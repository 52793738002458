import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { usePortal } from "../../../../../../contexts/portal";

const schema = yup.object().shape({
  white_label: yup.object().shape({
    dashboard_primary_colour: yup.string(),
    dashboard_secondary_colour: yup.string(),
    sign_in_logo_link: yup.string(),
    sign_up_logo_link: yup.string(),
  }),
});

const defaultValues = {
  white_label: {
    dashboard_primary_colour: "#428AC9",
    dashboard_secondary_colour: "#FFFFFF",
    sign_in_logo_link: "",
    sign_up_logo_link: "",
  },
};

const AppearanceFormProvider = ({ children }) => {
  const { program } = usePortal();

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    const extractDefaultValues = (program) => {
      if (!program) return defaultValues;

      return {
        white_label: {
          dashboard_primary_colour:
            program?.white_label.dashboard_primary_colour || "#428AC9",
          dashboard_secondary_colour:
            program?.white_label.dashboard_secondary_colour || "#FFFFFF",
          sign_in_logo_link: program?.white_label.sign_in_logo_link || "",
          sign_up_logo_link: program?.white_label.sign_up_logo_link || "",
        },
      };
    };

    // Reset form with extracted values
    methods.reset(extractDefaultValues(program));
  }, [program]);

  return <FormProvider {...methods}>{children}</FormProvider>;
};

export default AppearanceFormProvider;
