import React, { useEffect, useMemo } from "react";
import AppContainer from "./AppContainer";
import { UserProvider } from "../contexts/user";
import axios from "axios";
import mixpanel from "mixpanel-browser";
import { Button } from "rsuite";

const formatToRm = (num: number) => {
  return new Intl.NumberFormat("en-MY", {
    style: "currency",
    currency: "MYR",
  }).format(num);
};

const usePurchase = (packagePlan = {} as any, token, userId, actionPath) => {
  const [promo, setPromo] = React.useState({
    promo: false,
    discount: 0,
  });
  const [promoId, setPromoId] = React.useState(null);
  const [promoDesc, setPromoDesc] = React.useState(null);
  const [quantity, setQuantity] = React.useState(1);
  const discountPercentage = promo?.promo ? promo?.discount : 0;
  const urlParams = new URLSearchParams(window.location.search);
  const [promoCode, setPromoCode] = React.useState(null);
  const [checkLoading, setCheckLoading] = React.useState(false);
  const [promoCodeError, setPromoCodeError] = React.useState(null);

  let isFixedCreditPackage = useMemo(
    () => packagePlan.id === 12,
    [packagePlan.id]
  );

  const price = React.useMemo(() => {
    const num = Number(packagePlan?.cost) * quantity;
    return num;
  }, [packagePlan, quantity]);

  const discountedAmount = React.useMemo(() => {
    const amount = price * (discountPercentage / 100);
    return amount;
  }, [price, discountPercentage]);

  const transactionFee = React.useMemo(() => {
    //price * 0.03 + 1
    const currPrice = price - discountedAmount;
    const grossFee = currPrice * 0.03;
    const baseFee = currPrice ? 1 : 0;
    const fee = baseFee + grossFee;
    return fee;
  }, [price, discountedAmount]);

  const subTotal = price + transactionFee - discountedAmount;

  const checkPromoCode = async () => {
    const packageId = packagePlan?.id;
    if (!promoCode) {
      return;
    }
    try {
      setCheckLoading(true);
      const res = await axios.get("/promo_code_check", {
        params: {
          code: promoCode,
          package_id: packageId,
        },
        headers: {
          "X-CSRF-Token": token,
          "X-Requested-With": "XMLHttpRequest",
        },
        withCredentials: true,
      });

      const data = res.data[0];

      if (!data?.response?.promo) {
        throw new Error("Invalid or expired promo code");
      }

      setPromo(data.response);
      setPromoId(data.attributes?.id);
      setPromoDesc(data.attributes?.name);
    } catch (err) {
      console.log(err);
      setPromoCodeError(err.message);
    } finally {
      setCheckLoading(false);
    }
  };

  const resetPromo = () => {
    setPromo({
      promo: false,
      discount: 0,
    });
  };

  const trackCheckoutButton = () => {
    mixpanel.track("Clicked Checkout Button", {
      "Credit Quantity": quantity,
    });
  };

  useEffect(() => {
    const params = urlParams.get("promo_code");
    params && setPromoCode(params);
  }, []);

  useEffect(() => {
    if (!promoCode) {
      setPromoCodeError(null);
    }
  }, [promoCode]);

  return {
    promo,
    promoId,
    promoDesc,
    quantity,
    setQuantity,
    price,
    discountedAmount,
    transactionFee,
    subTotal,
    checkPromoCode,
    resetPromo,
    trackCheckoutButton,
    isFixedCreditPackage,
    promoCode,
    setPromoCode,
    checkLoading,
    promoCodeError,
  };
};

const PurchasePage = ({
  subscriptionPath,
  packagePlan = {} as any,
  token,
  userId,
  actionPath,
}) => {
  const {
    promo,
    promoId,
    promoDesc,
    quantity,
    setQuantity,
    price,
    discountedAmount,
    transactionFee,
    subTotal,
    checkPromoCode,
    resetPromo,
    trackCheckoutButton,
    isFixedCreditPackage,
    promoCode,
    setPromoCode,
    checkLoading,
    promoCodeError,
  } = usePurchase(packagePlan, token, userId, actionPath);

  return (
    <UserProvider>
      <AppContainer>
        <div className="small-10 small-offset-1 large-6 large-offset-3 columns py-10 tw-px-0 xs:tw-py-8">
          <div
            className="large-4 small-6 columns"
            style={{ display: "inline-block" }}
          >
            <div className="tw-flex tw-items-center tw-gap-2 xs:tw-mb-4">
              <a
                // className="back-btn"
                style={{ color: "#000" }}
                href={subscriptionPath}
              >
                <i className="fas fa-arrow-left font-size-6 tw-mr-3" />
                <span>Back</span>
              </a>
            </div>
          </div>
          <div className="large-12 small-12 columns">
            <h6
              style={{
                fontSize: 18,
                textAlign: "center",
              }}
            >
              Checkout
            </h6>
          </div>
          <div
            className="large-12 small-12 columns pb-2 mb-6"
            style={{
              textAlign: "center",
              fontSize: 18,
              borderBottom: "1px solid #EAEAEA",
              borderTop: "none",
            }}
          >
            Order Summary
          </div>
          <div
            className="small-12 columns py-4 lg-px-10"
            style={{
              borderBottom: "1px solid #EAEAEA",
              marginBottom: 10,
            }}
          >
            <div className="small-12 columns mt-2 mb-5">
              <div className="small-9 large-8 columns">
                <h6>{packagePlan?.name}</h6>
              </div>
              <div className="small-3 large-4 columns">
                <QuantitySelector
                  quantity={quantity}
                  setQuantity={setQuantity}
                  isFixedCreditPackage={isFixedCreditPackage}
                />
              </div>
            </div>
            <div className="small-12 columns m-0">
              <div className="small-8 columns">
                <p className="text-left montserrat">Price</p>
              </div>
              <div className="small-4 columns">
                <p id="start_amount" className="text-right montserrat">
                  {formatToRm(price)}
                </p>
              </div>
            </div>
            <div className="small-12 columns m-0">
              <div className="small-8 columns">
                <p className="text-left montserrat">Tax</p>
              </div>
              <div className="small-4 columns">
                <p className="text-right montserrat">{formatToRm(0)}</p>
              </div>
            </div>
            <div className="small-12 columns m-0">
              <div className="small-8 columns">
                <p className="text-left montserrat">Discount</p>
              </div>
              <div className="small-4 columns">
                <p id="discount-amount" className="text-right montserrat">
                  {formatToRm(discountedAmount)}
                </p>
              </div>
            </div>
            <div className="small-12 columns m-0">
              <div className="small-8 columns">
                <p className="text-left montserrat">Transaction Fee</p>
              </div>
              <div className="small-4 columns">
                <p id="discount-amount" className="text-right montserrat">
                  {formatToRm(transactionFee)}
                </p>
              </div>
            </div>
          </div>
          <div className="large-12 small-12 checkout_address">
            <div
              className={
                isFixedCreditPackage
                  ? "large-12 columns tw-hidden"
                  : "large-12 columns"
              }
              style={{
                borderBottom: "1px solid #EAEAEA",
              }}
            >
              <h6 className="uppercase tw-pb-0">Promo Code</h6>
              {!promo?.promo && (
                <div id="checking" className="promo_code_section large-6">
                  <p className="montserrat tw-text-[0.8rem]">
                    Use <span className="tw-font-semibold">MENTOR15</span> to
                    get 15% off
                  </p>
                  <CheckPromo
                    promoCode={promoCode}
                    setPromoCode={setPromoCode}
                    checkPromoCode={checkPromoCode}
                    isFixedCreditPackage={isFixedCreditPackage}
                    checkLoading={checkLoading}
                    promoCodeError={promoCodeError}
                  />
                </div>
              )}
              {promo?.promo && (
                <div id="approved" className="promo_code_section">
                  <p>
                    Success! The code you have used is
                    <br />
                    <span
                      id="confirmed_promo_code"
                      className="tw-text-green-400"
                    >
                      {promoDesc}
                    </span>
                  </p>
                  <div
                    onClick={resetPromo}
                    id="another_promo_code"
                    className="btn btn-blue"
                  >
                    Choose Another Code
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className="small-12 large-12 columns subtotal"
            style={{
              textAlign: "right",
              borderBottom: "1px solid #EAEAEA",
            }}
          >
            <h5
              className="text-right m-0 mr-2"
              style={{
                //"display: inline-block; font-size: 18px;"
                display: "inline-block",
                fontSize: 18,
              }}
            >
              Subtotal
            </h5>
            <span
              className="text-center"
              style={{
                display: "inline-block",
                fontSize: 18,
              }}
            >
              <span
                id="subtotal_amount"
                style={{
                  //"font-size: 23px;"
                  fontSize: 23,
                }}
              >
                <strong>{formatToRm(subTotal)}</strong>
              </span>
            </span>
          </div>
          <div
            className="small-12 large-8 columns"
            style={{
              color: "#979797",
              marginBottom: 20,
              marginTop: 20,
            }}
          >
            <p className="montserrat">
              By clicking checkout, you agree that:
              <br />
              This subscription will be active from the date of payment and will
              be valid for a total of{" "}
              {isFixedCreditPackage ? "6 months" : "3 months"}. By subscribing
              you also agree to out{" "}
              <a href="https://www.futurelab.my/terms-of-service">
                "Terms of Service
              </a>{" "}
              and <a href="https://www.futurelab.my/privacy">Privacy Policy</a>.
            </p>
          </div>
          <form method="POST" action={actionPath}>
            <input name="utf8" type="hidden" value="✓" />
            <input type="hidden" name="authenticity_token" value={token} />
            <input
              value={packagePlan?.id}
              type="hidden"
              name="user_subscription[package_id]"
              id="user_subscription_package_id"
            />
            <input
              value={userId}
              type="hidden"
              name="user_subscription[user_id]"
              id="user_subscription_user_id"
            />
            <input
              type="hidden"
              name="user_subscription[promo_code_id]"
              id="user_subscription_promo_code_id"
              value={promoId}
            />
            <input
              type="hidden"
              name="user_subscription[quantity]"
              id="user_subscription_quantity"
              value={quantity}
            />
            <input
              type="submit"
              name="commit"
              value="Checkout"
              id="payment_portal_link"
              className="btn btn-blue uppercase btn_services my-4 small-12 large-3 columns"
              data-disable-with="Checkout"
              onClick={trackCheckoutButton}
            />
          </form>
        </div>
      </AppContainer>
    </UserProvider>
  );
};

function CheckPromo({
  promoCode,
  setPromoCode,
  checkPromoCode,
  isFixedCreditPackage,
  checkLoading,
  promoCodeError,
}) {
  const inputRef = React.useRef(null);

  useEffect(() => {
    if (promoCodeError) {
      inputRef.current.focus();
      //add red border
      inputRef.current.classList.add("tw-border-red-500");
    } else {
      inputRef.current.classList.remove("tw-border-red-500");
    }
  }, [promoCodeError]);

  return (
    <>
      <div className="input-group">
        <div className="input-group-append">
          <input
            type="text"
            id="promo_code"
            disabled={isFixedCreditPackage || checkLoading}
            className="form-control"
            value={promoCode}
            placeholder="Enter Promo Code"
            onChange={(e) => setPromoCode(e.target.value)}
            ref={inputRef}
          />
          <Button
            id="check_promo_code"
            disabled={isFixedCreditPackage || !promoCode}
            className="btn btn-outline-blue btn-sm montserrat tw-align-middle tw-mb-4"
            onClick={checkPromoCode as any}
            loading={checkLoading}
            appearance="primary"
          >
            Check
          </Button>
        </div>
      </div>
      {promoCodeError && (
        <div className="tw-text-red-500 tw-text-sm -tw-translate-y-7">
          {promoCodeError}
        </div>
      )}
    </>
  );
}

const QuantitySelector = ({
  quantity = 1,
  setQuantity,
  isFixedCreditPackage,
}) => {
  const minimum = quantity === 1;

  return (
    <div className="tw-flex tw-items-center tw-self-end tw-justify-between sm:tw-gap-1">
      <button
        disabled={minimum}
        className="tw-px-3 tw-py-2 tw-rounded-lg tw-border tw-border-solid sm:tw-px-2 sm:tw-py-1 tw-text-[#428ac9]"
        onClick={() => setQuantity((q) => q - 1)}
      >
        <i className="fas fa-minus tw-align-middle"></i>
      </button>
      <span>{quantity}</span>
      <button
        disabled={isFixedCreditPackage}
        onClick={() => setQuantity((q) => q + 1)}
        className="tw-px-3 tw-py-2 tw-rounded-lg tw-border tw-border-solid sm:tw-px-2 sm:tw-py-1 tw-text-[#428ac9]"
      >
        <i className="fas fa-plus tw-align-middle"></i>
      </button>
    </div>
  );
};

export default PurchasePage;
