// React
import React, { useEffect, useState } from "react";

// FutureLab
import NetPromoterScoreContent from "./nps/NetPromoterScoreContent";
import { FutureLabApi } from "../util/api";

// UI
import { Message, toaster } from "rsuite";

// 3rd Party
import Cookies from "js-cookie";
import axios from "axios";
import { useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export interface UserNps {
  type: string;
  text_type: string;
}

interface NetPromoterScoreProps {
  portalId: string | null;
  onPortal: { enterpriseSlug: string | null; programSlug: string | null };
  userNpsDetails: UserNps;
}

const npsSchema = yup.object({
  number_of_stars: yup.number(),
  feedback_text: yup.array().when("number_of_stars", {
    is: (numberOfStars) => numberOfStars && numberOfStars < 6,
    then: yup.array().min(1, "At least one tag is required"),
    otherwise: yup.array().default([]),
  }),
  comment: yup.string().when("number_of_stars", {
    is: (numberOfStars) => numberOfStars && numberOfStars < 6,
    then: yup.string().trim().required(),
    otherwise: yup.string().nullable().default(null),
  }),
});

export default function NetPromoterScore(
  netPromoterScoreProps: NetPromoterScoreProps
) {
  const { portalId, onPortal, userNpsDetails } =
    netPromoterScoreProps;
  const [openNpsModel, setOpenNpsModel] = useState<boolean>(true);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(npsSchema),
  });
  const npsScore = useWatch({ control, name: "number_of_stars" });

  useEffect(() => {
    if (npsScore >= 6) {
      setValue("feedback_text", []);
    }
    setValue(
      "nps_category",
      npsScore > 5 ? "General Feedback" : "Platform Issue"
    );
  }, [npsScore]);

  const onSubmit = async (data) => {
    try {
      await FutureLabApi({
        method: "post",
        path: "/users/current/nps_submission",
        options: data,
        success: () => {
          Cookies.remove("modal_to_show");
          toaster.push(
            <Message showIcon type="success">
              Feedback was successfully submitted
            </Message>
          );
        },
      })
    } catch (err) {
      console.log(err);
      toaster.push(
        <Message showIcon type="error">
          An error occurred while submitting the form
        </Message>
      );
    }
    setOpenNpsModel(false);
  };

  return (
    <NetPromoterScoreContent
      portalId={portalId}
      userNpsDetails={userNpsDetails}
      openNpsModel={openNpsModel}
      setOpenNpsModel={setOpenNpsModel}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      control={control}
      errors={errors}
      npsScore={npsScore}
      onPortal={onPortal}
    />
  );
}
