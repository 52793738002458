import React, { useEffect, useState } from "react";
import { Button, Divider, Modal } from "rsuite";
import EventNotificationForm from "./EventNotificationForm";
import { FutureLabApi } from "../../../util/api";
import { useFormContext } from "react-hook-form";

const EventSegmentModal = ({
  openModal,
  setOpenModal,
  event,
  handleSubmit,
  loading,
}) => {
  // handleSubmit, openModal, setOpenModal

  const [notifyUsers, setNotifyUsers] = useState(false);
  const [skills, setSkills] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [countries, setCountries] = useState([]);
  const [companies, setCompanies] = useState([]);

  const { setValue } = useFormContext();

  useEffect(() => {
    FutureLabApi({
      method: "get",
      path: `/skills`,
      success: ({ data }) => {
        setSkills(data);
      },
    });

    FutureLabApi({
      method: "get",
      path: `/industries`,
      success: ({ data }) => {
        setIndustries(data);
      },
    });

    FutureLabApi({
      method: "get",
      path: `/companies`,
      success: ({ data }) => {
        setCompanies(data);
      },
    });

    FutureLabApi({
      method: "get",
      path: `/countries`,
      success: ({ data }) => {
        setCountries(data);
      },
    });
  }, []);

  // TODO:
  // setValue for notify_users
  // Create func for handling events

  return (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(false)}
      className="tw-mt-16"
      size="xs"
      backdrop={"static"}
    >
      <Modal.Header>
        <Modal.Title className="tw-font-medium">
          Notify users via email
        </Modal.Title>
        <Divider className="tw-absolute tw-w-full tw-left-0 tw-mt-4 tw-bg-gray-300" />
      </Modal.Header>
      <Modal.Body className="tw-py-4">
        {notifyUsers ? (
          <EventNotificationForm
            skills={skills}
            industries={industries}
            companies={companies}
            countries={countries}
          />
        ) : (
          <div className="tw-text-center tw-py-4">
            <span>
              {`Would you like to ${
                event ? "re-select" : "select"
              } relevant event tags, and notify users who
            share similar interests and preferences?`}
            </span>
          </div>
        )}
      </Modal.Body>
      <Divider className="tw-absolute tw-w-full tw-left-0 tw-m-0 tw-bg-gray-200" />
      <Modal.Footer className="tw-flex tw-pt-4">
        {notifyUsers ? (
          <>
            <Button
              onClick={() => {
                setNotifyUsers(false);
                setValue("notify_users", false);
              }}
              loading={loading}
              appearance="ghost"
              className="tw-w-full"
            >
              Back
            </Button>
            <Button
              onClick={() => handleSubmit()}
              loading={loading}
              appearance="primary"
              className="tw-w-full"
            >
              Done
            </Button>
          </>
        ) : (
          <>
            <Button
              onClick={() => handleSubmit()}
              loading={loading}
              appearance="ghost"
              className="tw-w-full"
            >
              No thanks
            </Button>
            <Button
              onClick={() => {
                setNotifyUsers(true);
                setValue("notify_users", true);
              }}
              loading={loading}
              appearance="primary"
              className="tw-w-full"
            >
              Yes
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default EventSegmentModal;
