export const displayStatusColor = (status) => {
  switch (status) {
    case "applied":
    case "pending":
    case "shortlisted":
      return "#FEEBCF";
    case "approved":
      return "#BEE1DA";
    case "rejected":
    case "closed":
      return "#FFD1D1";
    default:
      return status;
  }
};
