// React
import React, { useState } from "react";

// FutureLab
import { UserInfo } from "../../PairingTable";
import RatingDetailsModal from "../RatingDetailsModal";

// UI
import { Button, Drawer, Table } from "rsuite";
import { FaStar } from "react-icons/fa";

const { Column, HeaderCell, Cell } = Table;

export function OneOnOneRatingDrawer(props: any) {
  const {
    data,
    loading,
    mentor,
    mentee,
    setOpenDrawer,
    openDrawer,
    bookingId,
    setBookingId,
    type
  } = props;

  const [openModal, setOpenModal] = useState(false);

  // Event functions
  const handleRatingModal = (rowBookingId) => {
    setOpenModal(true);
    setOpenDrawer(false);
    setBookingId(rowBookingId);
  };

  return (
    <>
      <Drawer
        placement="right"
        size="sm"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <Drawer.Header>
          <Drawer.Title className="tw-font-medium">Rating Details</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body style={{ padding: "30px 30px" }}>
          <div className="tw-w-full tw-flex">
            <div className="tw-w-1/2">
              <span className="tw-text-xs tw-mb-2 tw-font-medium">Mentee</span>
              <UserInfo user={mentee} />
            </div>
            <div className="tw-w-1/2">
              <span className="tw-text-xs tw-mb-2 tw-font-medium">Mentor</span>
              <UserInfo user={mentor} />
            </div>
          </div>

          <Table
            data={data}
            autoHeight
            rowHeight={66}
            headerHeight={60}
            wordWrap="break-word"
            loading={loading}
            className="tw-w-full tw-mt-6"
            renderEmpty={() => (
              <p className="tw-text-center montserrat tw-mt-10">
                No completed sessions.
              </p>
            )}
            rowClassName="tw-text-sm"
          >
            <Column verticalAlign="middle" flexGrow={1} align="center">
              <HeaderCell className="tw-text-xs">Date Completed</HeaderCell>
              <Cell dataKey="completed_on" />
            </Column>

            <Column verticalAlign="middle" flexGrow={1} align="center">
              <HeaderCell className="tw-text-xs">Avg Mentee Rating</HeaderCell>
              <Cell dataKey="mentee_score">
                {(rowData) =>
                  rowData.mentee_score > 0 ? (
                    <div className="tw-flex tw-items-center tw-justify-center	">
                      <span className="tw-mr-1">{rowData.mentee_score}</span>
                      <FaStar color="428ac9" size={20} className="tw-mb-1" />
                    </div>
                  ) : (
                    "N/A"
                  )
                }
              </Cell>
            </Column>

            <Column verticalAlign="middle" flexGrow={1} align="center">
              <HeaderCell className="tw-text-xs">Avg. Mentor Rating</HeaderCell>
              <Cell dataKey="mentor_score">
                {(rowData) =>
                  rowData.mentor_score > 0 ? (
                    <div className="tw-flex tw-items-center tw-justify-center	">
                      <span className="tw-mr-1">{rowData.mentor_score}</span>
                      <FaStar color="428ac9" size={20} className="tw-mb-1" />
                    </div>
                  ) : (
                    "N/A"
                  )
                }
              </Cell>
            </Column>

            <Column verticalAlign="middle" flexGrow={1} align="center">
              <HeaderCell className="tw-text-xs">Action</HeaderCell>
              <Cell>
                {(rowData) => (
                  <Button
                    appearance="link"
                    onClick={() => handleRatingModal(rowData.booking_id)}
                  >
                    See Details
                  </Button>
                )}
              </Cell>
            </Column>
          </Table>
        </Drawer.Body>
      </Drawer>

      <RatingDetailsModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        setOpenDrawer={setOpenDrawer}
        bookingId={bookingId}
        mentee={mentee}
        mentor={mentor}
        type={type}
      />
    </>
  );
}
