// React
import React from "react";

// FutureLab
import avatar from "../../images/avatar_placeholder.png";

// UI
import { Pagination } from "rsuite";

// 3rd Party
import { useTable, useRowSelect, usePagination } from "react-table";

interface TableProps {
  columns: any[];
  data: any[];
  setSelected: (selected: any[]) => void;
}

const columns = [
  {
    Header: ({ getToggleAllPageRowsSelectedProps }) => (
      <div className="tw-flex tw-items-center tw-justify-center">
        <input
          className="tw-m-0"
          type="checkbox"
          {...getToggleAllPageRowsSelectedProps()}
        />
      </div>
    ),
    Cell: ({ row }) => (
      <div className="tw-flex tw-items-center tw-justify-center">
        <input
          className="tw-m-0"
          type="checkbox"
          {...row.getToggleRowSelectedProps()}
        />
      </div>
    ),
    id: "selection",
  },
  {
    Header: "Profile Picture",
    accessor: "avatar",
    Cell: ({ value }) => (
      <img
        src={value || avatar}
        alt="profile"
        className="tw-w-12 tw-h-12 tw-rounded-md"
        loading="lazy"
      />
    ),
  },
  {
    Header: "Account Type",
    accessor: "role",
  },
  {
    Header: "First Name",
    accessor: "firstname",
  },
  {
    Header: "Last Name",
    accessor: "lastname",
  },
  {
    Header: "Organisation / University",
    accessor: "organisation",
  },
  {
    Header: "Action",
    id: "action",
    Cell: ({ row: { original } }) => (
      <a
        href={`/mentor_profile/${original.slug}`}
        className="tw-text-blue-500 hover:tw-underline"
        target="_blank"
      >
        View Profile
      </a>
    ),
  },
];

const Table = (props: TableProps) => {
  const { data, setSelected } = props;

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 8,
        pageIndex: 0,
      },
    },
    usePagination,
    useRowSelect
  );

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    selectedFlatRows,
    state: { pageIndex, pageSize },
  } = tableInstance;

  React.useEffect(
    () => setSelected(selectedFlatRows.map((v) => v.original)),
    [selectedFlatRows]
  );

  return (
    <div>
      <table {...getTableProps()} className="tw-w-full tw-border-collapse">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className="tw-p-2 tw-border tw-border-gray-300 tw-bg-gray-100 tw-text-center"
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className="tw-p-2 tw-border tw-border-gray-300 tw-capitalize tw-text-center"
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="tw-flex tw-justify-center tw-items-center tw-mt-4">
        <Pagination
          prev
          next
          size="sm"
          total={data.length}
          limit={pageSize}
          activePage={pageIndex + 1}
          onChangePage={(page) => gotoPage(page - 1)}
        />
      </div>
    </div>
  );
};

export default Table;
