// React
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

// FutureLab
import {
  IMentorContext,
  PlatformType,
} from "../../../../../contexts/mentor_profile";
import FieldError from "../../../../ui/FieldError";
import { withUser } from "../../../../../contexts/user";
import { FutureLabApi } from "../../../../../util/api";
import translateText from "../../../../../util/translateText";
import creditLogo from "../../../../../images/credit_logo.png";
import { useVariables } from "../../../../../contexts/variables";

// UI
import {
  Button,
  Col,
  Divider,
  FlexboxGrid,
  Form,
  Input,
  Message,
  Modal,
  Steps,
  TagPicker,
  toaster,
} from "rsuite";

// 3rd Party
import { DayPickerSingleDateController } from "react-dates";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import Cookies from "js-cookie";

type BookingInput = {
  topic: string;
  learning_objectives: string[];
};

const schema = yup.object().shape({
  learning_objectives: yup
    .array()
    .max(3, "You can only select up to 3 topics")
    .required()
    .label("Services")
    .nullable(),
  topic: yup.string().required().label("Topic"),
});

const BookingModal = ({
  user,
  open,
  handleClose,
  initialDate = undefined,
  initialTime = undefined,
  daySize = 50,
  convertText,
}) => {
  const { mentor, type, program } = useOutletContext<IMentorContext>();
  const { available_dates, timeslots } = mentor as any;
  const userCredit = user.balance / 10;
  const [currentStep, setCurrentStep] = useState(
    initialDate && initialTime && userCredit > 1 ? 3 : 0
  );
  const [date, setDate] = useState(initialDate);
  const [time, setTime] = useState(initialTime);
  const [focused, setFocused] = useState(true);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<BookingInput>({
    resolver: yupResolver(schema),
    mode: "onBlur",
    // criteriaMode: "firstError",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [goals, setGoals] = useState([]);

  const submitUrl =
    type === PlatformType.FutureLab
      ? "/main/bookings"
      : "/bookings/booking_submission";

  const getBookingParams = (data) => {
    return {
      date: date.format("DD/MM/YYYY"),
      time: time,
      booking: {
        //@ts-ignore
        program_id: program?.id || null,
        //@ts-ignore
        mentor_id: mentor?.id,
        mentee_id: user.id,
        status: "pending",
        session_method: "appear_in",
        topic: data.topic,
        service_id: "2 3 27 28 29",
        booking_links_attributes: {
          "0": { internal_link: "", _destroy: "1" },
        },
        booking_attachments_attributes: { "0": { _destroy: "1" } },
      },
      objective: data.learning_objectives,
      react_form: true,
    };
  };

  useEffect(() => {
    FutureLabApi({
      method: "get",
      path: "/goals",
      success: ({ data }) => {
        setGoals(data);
      },
    });
  }, []);

  const onSubmit = async (data) => {
    const bookingParams = getBookingParams(data);

    setIsSubmitting(true);

    const token = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    try {
      await axios.post(submitUrl, bookingParams, {
        headers: {
          "Content-Type": "application/json",
          Accept: "text/javascript",
        },
        withCredentials: true,
      });
      toaster.push(
        <Message showIcon type="success">
          {translateText(convertText, "Booking submitted")}
        </Message>
      );
      handleClose();
      window.location.href =
        type === PlatformType.FutureLab ? "/booking-complete" : "/bookings";
    } catch (err) {
      const { error } = err.response.data;
      toaster.push(
        <Message showIcon type="error">
          {error}
        </Message>
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (type === PlatformType.Portal && currentStep === 0) {
      setCurrentStep(1);
    }
  }, [type, currentStep]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className="tw-w-[500px]"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title className="tw-text-xl tw-font-medium">
          {translateText(convertText, "Book Session")}
        </Modal.Title>
        <Divider className="tw-absolute tw-w-full tw-left-0 tw-mt-4 tw-bg-gray-300" />
      </Modal.Header>
      {currentStep === 0 && (
        <TopUpStep
          user={user}
          convertText={convertText}
          currentStep={currentStep}
          handleSubmit={() => setCurrentStep(1)}
        />
      )}
      {currentStep === 1 && (
        <DateStep
          convertText={convertText}
          currentStep={currentStep}
          date={date}
          setDate={setDate}
          focused={focused}
          setFocused={setFocused}
          available_dates={available_dates}
          daySize={daySize}
          setCurrentStep={setCurrentStep}
        />
      )}
      {currentStep === 2 && (
        <TimeStep
          convertText={convertText}
          currentStep={currentStep}
          date={date}
          setTime={setTime}
          timeslots={timeslots}
          setCurrentStep={setCurrentStep}
        />
      )}
      {currentStep === 3 && (
        <DetailsStep
          convertText={convertText}
          currentStep={currentStep}
          date={date}
          time={time}
          handleSubmit={handleSubmit(onSubmit)}
          control={control}
          errors={errors}
          goals={goals}
          setCurrentStep={setCurrentStep}
          isSubmitting={isSubmitting}
        />
      )}
    </Modal>
  );
};

const StepsList = ({ convertText, currentStep }) => {
  const { type } = useOutletContext<IMentorContext>();

  return (
    <Steps current={currentStep} small>
      {type === PlatformType.FutureLab && (
        <Steps.Item title={translateText(convertText, "Top up")} />
      )}
      <Steps.Item title={translateText(convertText, "Date")} />
      <Steps.Item title={translateText(convertText, "Time")} />
      <Steps.Item title={translateText(convertText, "Details")} />
    </Steps>
  );
};

const BasicFooter = ({
  setCurrentStep,
  isFinal = false,
  requestHandle = () => {},
  disable = false,
}) => (
  <Modal.Footer className="tw-pt-5">
    <Button
      onClick={() => setCurrentStep((step) => step - 1)}
      appearance="ghost"
      disabled={disable}
    >
      Back
    </Button>
    {!isFinal ? (
      <Button
        onClick={() => setCurrentStep((step) => step + 1)}
        appearance="primary"
        disabled={disable}
      >
        Next
      </Button>
    ) : (
      <Button onClick={requestHandle} appearance="primary" disabled={disable}>
        Request
      </Button>
    )}
  </Modal.Footer>
);

const BodyWrapper = ({ children, convertText, currentStep }) => (
  <Modal.Body className="tw-pt-5">
    <StepsList convertText={convertText} currentStep={currentStep} />
    <div className="tw-my-2">
      <div className="tw-text-center">{children}</div>
    </div>
  </Modal.Body>
);

const DetailsStep = ({
  convertText,
  currentStep,
  date,
  time,
  handleSubmit,
  control,
  errors,
  goals,
  setCurrentStep,
  isSubmitting,
}) => {
  return (
    <>
      <BodyWrapper convertText={convertText} currentStep={currentStep}>
        <div className="tw-text-left">
          <h6 className="tw-text-xl">
            {date.format("dddd, DD MMMM YYYY")} at {time}
          </h6>
          <Form fluid className="tw-pt-5">
            <Form.Group>
              <Form.ControlLabel className="tw-text-base">
                {translateText(convertText, "Tell me about yourself")}{" "}
                <span className="tw-text-red-500">*</span>
              </Form.ControlLabel>
              <Controller
                name="topic"
                control={control}
                render={({ field }) => (
                  <Input rows={4} as="textarea" size="lg" {...field} />
                )}
              />
              <ErrorMessage errors={errors} name="topic" as={FieldError} />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel className="tw-text-base">
                {translateText(convertText, "Topics to discuss")}
                <span className="tw-text-red-500">*</span>
              </Form.ControlLabel>
              {errors["learning_objectives"] ? (
                <ErrorMessage
                  errors={errors}
                  name="learning_objectives"
                  as={FieldError}
                />
              ) : (
                <Form.ControlLabel className="tw-text-sm tw-text-[#979797]">
                  {translateText(
                    convertText,
                    "You can select more than one, maximum 3"
                  )}
                </Form.ControlLabel>
              )}
              <Controller
                name="learning_objectives"
                control={control}
                render={({ field }) => (
                  <TagPicker
                    cleanable={false}
                    virtualized
                    data={goals}
                    valueKey={"mentor_label"}
                    labelKey={"mentor_label"}
                    block
                    placeholder={translateText(convertText, "Please select")}
                    size="lg"
                    {...field}
                  />
                )}
              />
            </Form.Group>
          </Form>
        </div>
      </BodyWrapper>
      <Divider className="tw-absolute tw-w-full tw-left-0 tw-m-0 tw-bg-gray-300" />
      <BasicFooter
        setCurrentStep={setCurrentStep}
        isFinal
        requestHandle={handleSubmit}
        disable={isSubmitting}
      />
    </>
  );
};

const TimeStep = ({
  convertText,
  currentStep,
  date,
  setTime,
  timeslots,
  setCurrentStep,
}) => {
  const [active, setActive] = useState(null);
  const activeClass = "tw-border-[#1675E0] tw-font-bold";

  useEffect(() => {
    if (!active && timeslots[date.format("DD/MM/YYYY")].length > 0) {
      setTime(timeslots[date.format("DD/MM/YYYY")][0]);
      setActive(0);
    }
  }, [timeslots, date, active, setTime]);

  return (
    <>
      <BodyWrapper convertText={convertText} currentStep={currentStep}>
        <div>
          <h6 className="tw-mb-9 tw-text-xl">
            {date.format("dddd, DD MMMM YYYY")}
          </h6>
          <FlexboxGrid>
            {timeslots[date.format("DD/MM/YYYY")].map((time, index) => (
              <FlexboxGrid.Item as={Col} colspan={24} xs={6} key={time}>
                <a
                  onClick={() => {
                    setTime(time);
                    setActive(index);
                  }}
                >
                  <div
                    className={
                      "tw-border tw-border-solid tw-border-[#979797] tw-mb-2 tw-rounded-md tw-py-2 tw-text-sm" +
                      (active === index ? activeClass : "")
                    }
                  >
                    {time}
                  </div>
                </a>
              </FlexboxGrid.Item>
            ))}
          </FlexboxGrid>
        </div>
      </BodyWrapper>
      <Divider className="tw-absolute tw-w-full tw-left-0 tw-m-0 tw-bg-gray-300" />
      <BasicFooter setCurrentStep={setCurrentStep} />
    </>
  );
};

const DateStep = ({
  convertText,
  currentStep,
  date,
  setDate,
  focused,
  setFocused,
  available_dates,
  daySize,
  setCurrentStep,
}) => {
  return (
    <>
      <BodyWrapper convertText={convertText} currentStep={currentStep}>
        <DayPickerSingleDateController
          initialVisibleMonth={() => moment(date)}
          date={date}
          onDateChange={(date) => {
            setDate(date);
          }}
          focused={focused}
          onFocusChange={({ focused }) => setFocused(focused)}
          numberOfMonths={1}
          noBorder
          hideKeyboardShortcutsPanel
          daySize={daySize}
          isOutsideRange={(day) =>
            !available_dates.includes(day.format("DD/MM/YYYY"))
          }
        />
      </BodyWrapper>
      <Divider className="tw-absolute tw-w-full tw-left-0 tw-m-0 tw-bg-gray-300" />
      <BasicFooter setCurrentStep={setCurrentStep} />
    </>
  );
};

const TopUpStep = ({ user, convertText, currentStep, handleSubmit }) => {
  const userCredit = user.balance / 10;
  const isMentor = user.is_fl_mentor || false;
  const { variables } = useVariables();

  useEffect(() => {
    if (userCredit >= 1 || isMentor) {
      handleSubmit();
    }
  }, [userCredit, handleSubmit]);

  return (
    <>
      <Modal.Body className="tw-pt-5">
        <StepsList convertText={convertText} currentStep={currentStep} />
        <div className="tw-my-2">
          <div className="tw-text-center">
            <FlexboxGrid className="tw-justify-center tw-items-center tw-py-8 tw-flex-col tw-gap-2">
              <FlexboxGrid.Item as={Col} colspan={24} xs={6}>
                <img src={creditLogo} alt="credit" />
              </FlexboxGrid.Item>
              <FlexboxGrid.Item as={Col} colspan={24} xs={18}>
                <span className="tw-font-semibold tw-text-lg">
                  Top up your mentorship credits to begin!
                </span>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item as={Col} colspan={24} xs={18}>
                <span>
                  You Have{" "}
                  <span className="tw-font-semibold">{user.balance / 10}</span>{" "}
                  Credits
                </span>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </div>
        </div>
      </Modal.Body>
      <Divider className="tw-absolute tw-w-full tw-left-0 tw-m-0 tw-bg-gray-300" />
      <Modal.Footer className="tw-pt-5">
        {userCredit < 1 ? (
          <Button
            onClick={() => {
              Cookies.set("origin", window.location.href);
            }}
            appearance="primary"
            className="tw-px-14 tw-font-medium"
            href="/package/10/purchase_page"
          >
            Buy a credit
          </Button>
        ) : (
          <Button onClick={handleSubmit} appearance="primary">
            Next
          </Button>
        )}
      </Modal.Footer>
    </>
  );
};

export default withUser(BookingModal);
