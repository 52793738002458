import React from "react";
import InvitationModal from "./InvitationModal";
import useCard from "./useCard";

export interface Role {
  id: number;
  name: string;
  description: string;
}

const RoleCard = (role: Role): JSX.Element => {
  const { open, setOpen, instance } = useCard(role);
  return (
    <section>
      <div
        className="tw-flex tw-cursor-pointer hover:tw-bg-gray-100 tw-p-4 tw-rounded-md tw-border tw-border-gray-200 tw-justify-between"
        data-id={role.id}
        data-name={role.name}
        onClick={() => setOpen(true)}
      >
        <div className="large-11 small-11 columns">
          <p
            className="m-0 montserrat"
            style={{ color: "#4A4A4A", fontWeight: 500 }}
          >
            {role.name}
          </p>
          <p className="m-0 montserrat text-muted">
            <span id={`user_role_${role.name.toLowerCase()}`} className="m-0">
              {role.description}
            </span>
          </p>
        </div>

        <i
          className="fas fa-arrow-right align-self-center"
          style={{ color: "#A4A4A4" }}
        ></i>
      </div>
      <InvitationModal
        open={open}
        setOpen={setOpen}
        role={role}
        instance={instance}
      />
    </section>
  );
};

export default RoleCard;
