import React, { createContext, useState, useContext, useEffect } from "react";
import { usePortalSetup } from "../setup";
import { useNavigate } from "react-router-dom";
import { Message, toaster } from "rsuite";

const SetupVariablesContext = createContext(undefined);

export const SetupVariablesProvider = ({ children, editMode = false }) => {
  const { program, setupStatus } = usePortalSetup();
  const [formStep, setFormStep] = useState(
    setupStatus?.current_setup_step || "design"
  );
  const [formSetupArray, setFormSetupArray] = useState([
    "design",
    "structure",
    "mentorship_setting",
    "impact_tracking",
  ]);
  const navigate = useNavigate();

  useEffect(() => {
    if (setupStatus) {
      setFormSetupArray(setupStatus.setup_form_list);
    }
    return () => {
      setFormSetupArray([]);
    };
  }, [setupStatus]);

  useEffect(() => {
    if (setupStatus?.portal_setup_completed === false) {
      setFormStep(setupStatus?.current_setup_step);
    }
  }, [setupStatus]);

  // Variables
  const isEditing = editMode;
  const isSetupComplete = setupStatus?.portal_setup_completed;

  // Functions
  const handleStep = (action = null) => {
    if (action === "complete" && program) {
      window.location.replace(`/enter-program/${program.id}`);
      return;
    }

    if (action === "save_draft" && program) {
      navigate("/dashboard");
      toaster.push(<Message type="success">Program saved as draft.</Message>);
      return;
    }

    setFormStep((formStep) => {
      const currentIndex = formSetupArray.indexOf(formStep);
      const isPreviousAction = action === "previous" && currentIndex > 0;
      const isNextAction = currentIndex < formSetupArray.length - 1;

      return isPreviousAction
        ? formSetupArray[currentIndex - 1]
        : isNextAction
        ? formSetupArray[currentIndex + 1]
        : formStep;
    });
  };

  return (
    <SetupVariablesContext.Provider
      value={{ formStep, setFormStep, handleStep, isEditing, isSetupComplete }}
    >
      {children}
    </SetupVariablesContext.Provider>
  );
};

export const useSetupVariable = () => useContext(SetupVariablesContext);
