import React, { useEffect, useState } from "react";
import { useUser } from "../../contexts/user";
import LinkButton from "../ui/LinkButton";
import TooltipHelper from "../ui/TooltipHelper";
import ImpactCards from "./impact/ImpactCards";
import translateText from "../../util/translateText";
import { FutureLabApi } from "../../util/api";
import { FaStar, FaRegCalendarCheck, FaRegCalendarTimes } from "react-icons/fa";

const DashboardImpact = ({ convertText }) => {
  const { user } = useUser();
  const {
    impact_stats: {
      average_rating: avgRating,
      mentees_helped: menteesHelped,
      mentoring_sessions: totalSessions,
      profile_viewed: profileViews,
      total_reviews: totalReviews,
    },
  } = user;
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    FutureLabApi({
      method: "get",
      path: "/users/current/dnd_status",
      success: ({ data }) => {
        setToggle(data.active);
      },
    });
  }, [toggle]);

  const userAvailability = !toggle
    ? { status: "Available", icon: <FaRegCalendarCheck color="#428AC9" /> }
    : { status: "Not Available", icon: <FaRegCalendarTimes color="#DB6057" /> };

  return (
    <>
      <div className="tw-w-full sm:tw-block">
        <div className="tw-flex tw-w-full tw-mb-3 tw-items-center">
          <p className="section-header montserrat">
            {translateText(convertText, "My Impact")}{" "}
          </p>
          <TooltipHelper
            tip={translateText(
              convertText,
              "What positive impact do you want to make through mentorship?"
            )}
          />
        </div>

        <div className="tw-flex tw-gap-1">
          <ImpactCards
            type="combination"
            stat={Number(avgRating)}
            image={<FaStar color="#428AC9" />}
            subheader={translateText(convertText, "Rating")}
            // showLink
            // link={""}
          />
          <ImpactCards
            type="text"
            stat={menteesHelped}
            subheader={translateText(convertText, "Mentees helped")}
            showTooltip
            tipText={translateText(
              convertText,
              "These are the mentees that you have helped via mentorship on this platform"
            )}
          />
          <ImpactCards
            destination='/main/reviews'
            type="text"
            stat={totalReviews}
            subheader={translateText(convertText, "Total reviews")}
            // showLink
            // link={""}
          />
          <ImpactCards
            destination="/main/availability"
            type="image"
            image={userAvailability.icon}
            subheader={translateText(convertText, userAvailability.status)}
            // showLink
            // link={""}
          />
        </div>
      </div>
    </>
  );
};

export default DashboardImpact;
