import React from "react";

const MenuItem = ({ icon, label, to }) => {
  const handleNavigate = () => {
    if (label == "Logout") {
      //window location href with DELETE method
      return fetch(to, {
        method: "DELETE",
        headers: {
          "X-CSRF-Token": document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
        },
      }).then(() => {
        window.location.href = "/";
      });
    }
    window.location.href = to;
  };
  return (
    <div
      className="tw-flex tw-w-full tw-py-2 tw-px-3 tw-items-center tw-text-[#222222] hover:tw-bg-[#D9E8F4] hover:tw-text-[#428AC9] tw-cursor-pointer"
      style={{ borderBottom: "0.83px solid #EAEAEA" }}
      onClick={handleNavigate}
    >
      <span className="tw-mr-3" style={{ fontSize: 26 }}>
        {icon}
      </span>
      <span className="tw-text-sm">{label}</span>
    </div>
  );
};

export default MenuItem;
