import React from 'react';

const ReviewTag = (props) => {
  const { text, size = 'base', className } = props;
  const sizeClass = {
    xs: 'tw-px-2 tw-py-1',
    base: 'tw-text-base tw-px-3 tw-py-2',
  };

  return (
    <span
      className={`tw-rounded-3xl tw-inline-block tw-mr-2 tw-whitespace-nowrap ${sizeClass[size]} ${className}`}
      style={{
        background: '#E5E5EA',
        fontSize: size === 'xs' ? '10px' : '',
      }}
    >
      {text}
    </span>
  );
};

export default ReviewTag;
