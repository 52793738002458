import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  FormDatePicker,
  FormInputText,
} from "../../../../form/default/FormInputs";
import { Divider, Form, Input, InputGroup, Loader } from "rsuite";
import FormSubmitButton from "../appearance/FormSubmitButton";
import { useDebouncedValue } from "@mantine/hooks";
import { usePortal } from "contexts/portal";
import { checkProgramName } from "api/portal/programApi";
import {
  MdOutlineCheckCircleOutline,
  MdOutlineHighlightOff,
} from "react-icons/md";
import { ErrorMessage } from "@hookform/error-message";
import FieldError from "components/form/FieldError";

const BasicInfoForm = () => {
  const {
    control,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useFormContext();

  const { enterprise } = usePortal();
  const [newProgramName, setNewProgramName] = useState("");
  const [debounceProgramName] = useDebouncedValue(newProgramName, 2000);
  const [loading, setLoading] = useState(false);
  const [isTaken, setIsTaken] = useState(null);

  useEffect(() => {
    if (enterprise && debounceProgramName.length > 0) {
      const checkData = async () => {
        setLoading(true);
        try {
          const checkResponse = await checkProgramName(
            newProgramName,
            enterprise?.id
          );
          setIsTaken(checkResponse.data);
          if (checkResponse.data === true) {
            setError("program.name", {
              type: "manual",
              message: "Program name is already in use",
            });
          } else {
            setValue("program.name", newProgramName);
          }
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      };
      checkData();
    }
  }, [debounceProgramName]);

  return (
    <div>
      <Form>
        <Form.Group>
          {/* <FormInputText
            name={"name"}
            label={"Portal Name"}
            placeholder={"Portal Name"}
            control={control}
            errors={errors}
          /> */}
          <Form.ControlLabel>Program Name</Form.ControlLabel>
          <Controller
            name="program.name"
            control={control}
            render={({ field }) => (
              <>
                <InputGroup inside className="tw-w-full">
                  <Input
                    name={field.name}
                    value={newProgramName || field.value}
                    placeholder={"Portal Name"}
                    onChange={(value) => {
                      setIsTaken(null);
                      clearErrors("program.name");
                      if (newProgramName !== value) {
                        setNewProgramName(value);
                      }
                    }}
                  />
                  <InputGroup.Addon>
                    {loading && <Loader />}
                    {!loading &&
                      isTaken !== null &&
                      (isTaken ? (
                        <MdOutlineHighlightOff
                          className="tw-text-base"
                          style={{ color: "red" }}
                        />
                      ) : (
                        <MdOutlineCheckCircleOutline
                          className="tw-text-base"
                          style={{ color: "green" }}
                        />
                      ))}
                  </InputGroup.Addon>
                </InputGroup>
                <ErrorMessage
                  className="flex-1	"
                  errors={errors}
                  name={"program.name"}
                  as={FieldError}
                />
              </>
            )}
          />
        </Form.Group>
        <Form.Group>
          <FormInputText
            name={"program.description"}
            label={"Program Description"}
            as="textarea"
            rows={5}
            placeholder={"Program Description"}
            control={control}
            errors={errors}
          />
        </Form.Group>
        <Form.Group>
          <FormInputText
            name={"program.support_email"}
            label={"Program Support"}
            helplabel={
              "Indicate an email where users can seek help if they experience issues."
            }
            placeholder={"admin@example.com"}
            control={control}
            errors={errors}
          />
        </Form.Group>

        <div className="tw-flex tw-flex-row tw-gap-6">
          <Form.Group>
            <FormDatePicker
              name={"program.start_date"}
              label={"Program Start Date"}
              format={"dd MMM yyyy"}
              placeholder={"Start Date"}
              placement="autoVertical"
              control={control}
              errors={errors}
              block
            />
          </Form.Group>
          <Form.Group>
            <FormDatePicker
              name={"program.end_date"}
              label={"Program End Date"}
              format={"dd MMM yyyy"}
              placeholder={"End Date"}
              placement="autoVertical"
              control={control}
              errors={errors}
              block
            />
          </Form.Group>
        </div>
      </Form>
      <Divider />
      <div className="tw-flex tw-flex-row-reverse">
        <FormSubmitButton />
      </div>
    </div>
  );
};

export default BasicInfoForm;
