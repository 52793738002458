// React
import React from "react";

// UI
import {
  MdCalendarMonth,
  MdLaptopMac,
  MdOutlineAccessTime,
} from "react-icons/md";
import { Divider } from "rsuite";

export function SessionDetails({ bookingData, mentor, mentee }) {
  return (
    <div className="tw-my-6">
      <div className="tw-w-full tw-px-6">
        <div className="tw-w-full tw-flex tw-justify-around">
          <div className="tw-w-1/3">
            <MdCalendarMonth className="tw-text-lg tw-mr-2" />
            {bookingData.booking_date}
          </div>
          <div className="tw-w-1/3">
            <MdOutlineAccessTime className="tw-text-lg tw-mr-2" />
            {bookingData.booking_start_time} - {bookingData.booking_end_time}
          </div>
          <div className="tw-w-1/3">
            <MdLaptopMac className="tw-text-lg tw-mr-2" />
            {bookingData.session_method}
          </div>
        </div>
        <div className="tw-w-full tw-mt-6">
          <MdLaptopMac className="tw-text-lg tw-mr-2" />
          {bookingData.program?.name}
        </div>
      </div>

      <Divider className="tw-w-full tw-mt-4 tw-bg-gray-300" />

      <div className="tw-w-full tw-flex">
        <div className="tw-w-1/2 tw-px-6">
          <div className="tw-w-full">
            <h6>Topic</h6>
            {bookingData.topic ? bookingData.topic : "No topic given."}
          </div>
          <div className="tw-w-full tw-mt-6">
            <h6>Learning objectives</h6>
            <ol type="1">
              {bookingData?.learning_objectives?.length > 0
                ? bookingData.learning_objectives.map((learning, index) => (
                    <li key={index}>{learning.objective}</li>
                  ))
                : "No learning objectives available."}
            </ol>
          </div>
        </div>
        <div className="tw-w-1/2 tw-px-6">
          <div className="tw-w-full">
            <h6>Reschedule history</h6>
            {bookingData?.booking_reschedules?.length > 0
              ? bookingData.booking_reschedules.map((reschedules, index) => (
                  <div key={index}>
                    <div>
                      <div className="tw-w-full">
                        <span className="montserrat tw-text-sm">
                          Reschedule submitted on
                        </span>
                        <p>{reschedules.created_at}</p>
                      </div>
                      <div className="tw-w-full">
                        <span className="montserrat tw-text-sm">
                          Requested by
                        </span>
                        <p>
                          {reschedules.who_rescheduled == "Mentor"
                            ? mentor?.full_name
                            : mentee?.full_name}
                        </p>
                      </div>
                    </div>
                    <Divider className="tw-w-full tw-mt-4 tw-bg-gray-300" />
                  </div>
                ))
              : "No reschedule history for this session."}
          </div>
        </div>
      </div>
    </div>
  );
}
