import React from "react";
import LinkButton from "../../ui/LinkButton";
import TooltipHelper from "../../ui/TooltipHelper";
import { Col, FlexboxGrid } from "rsuite";

const ImpactCards = (props) => {
  const {
    stat,
    subheader,
    showTooltip,
    tipText,
    showLink,
    type,
    image,
    destination = null,
  } = props;

  const navigateTo = () => {
    if (destination) {
      window.open(destination, "_self");
    }
  };

  return {
    text: (
      <div
        className={
          destination ? "tw-cursor-pointer impact-cards" : "impact-cards"
        }
        onClick={navigateTo}
      >
        <div className="tw-flex tw-w-full tw-items-center tw-justify-center tw-mt-1">
          <h2 className="tw-text-2xl tw-m-0 d-inline">
            {stat !== "" ? stat : "-"}
          </h2>
          {showTooltip && <TooltipHelper tip={tipText} />}
        </div>
        <p className="montserrat tw-text-center font-size-4 tw-mb-0">
          {subheader}
        </p>

        {showLink && <LinkButton>See details</LinkButton>}
      </div>
    ),
    image: (
      <div
        className={
          destination ? "tw-cursor-pointer impact-cards" : "impact-cards"
        }
        onClick={navigateTo}
      >
        <div className="tw-flex tw-w-full tw-items-center tw-justify-center tw-mt-1">
          <h2 className="tw-text-2xl tw-m-0 d-inline">{image}</h2>
          {showTooltip && <TooltipHelper tip={tipText} />}
        </div>
        <p className="montserrat tw-text-center font-size-4 tw-mb-0">
          {subheader}
        </p>

        {showLink && <LinkButton>See details</LinkButton>}
      </div>
    ),
    combination: (
      <div
        className={
          destination ? "tw-cursor-pointer impact-cards" : "impact-cards"
        }
        onClick={navigateTo}
      >
        <div className="tw-flex tw-w-full tw-items-center tw-justify-center tw-mt-1">
          <h2 className="tw-text-2xl tw-m-0 d-inline">
            {stat !== "" ? (
              <FlexboxGrid.Item colspan={24}>
                <FlexboxGrid.Item as={Col} className="tw-pl-0">
                  {stat}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item as={Col} className="tw-p-0 tw-flex tw-pt-1">
                  {image}
                </FlexboxGrid.Item>
              </FlexboxGrid.Item>
            ) : (
              "-"
            )}
          </h2>
          {showTooltip && <TooltipHelper tip={tipText} />}
        </div>
        <p className="montserrat tw-text-center font-size-4 tw-mb-0">
          {subheader}
        </p>

        {showLink && <LinkButton>See details</LinkButton>}
      </div>
    ),
  }[type];
};

export default ImpactCards;
