// React
import React from "react";

// UI
import {
  Button,
  Form,
  Input,
  Message,
  Modal,
  Radio,
  RadioGroup,
  toaster,
} from "rsuite";

// 3rd Party
import { Controller, useForm, useWatch } from "react-hook-form";
import axios from "axios";
import Cookies from "js-cookie";

const failedBookingReasons = (bookingParticipant) => {
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return [
    `${capitalizeFirstLetter(bookingParticipant.user_type)} did not show up`,
    "Session had to be rescheduled",
    "Session had to be cancelled",
    "Others",
  ];
};

export default function FailedBookingModal({
  open,
  close,
  setBookingModalType,
  token,
  bookingId,
  bookingParticipant,
}) {
  const { handleSubmit, control } = useForm();

  const otherReason = useWatch({ control, name: "failed_booking_reason" });

  const onSubmit = async (data) => {
    const payload = {
      ...data,
      booking_id: bookingId,
    };
    try {
      await axios.post("/bookings/failed_booking_report", payload, {
        headers: {
          "X-CSRF-Token": token,
        },
      });
    } catch (err) {
      console.log(err);
      toaster.push(
        <Message showIcon type="error">
          An error occurred while submitting the form
        </Message>
      );
    } finally {
      setBookingModalType("clear");
      Cookies.set(`no_show_modal`, bookingId, { expires: 7 });
      toaster.push(
        <Message showIcon type="success">
          Thanks for letting us know! The details have been sent to our support
          team.
        </Message>,
        { duration: 10000 }
      );
    }
  };

  return (
    <Modal open={open} className="mt-16 sm:w-full" size="xs" onClose={close}>
      <Modal.Header>
        <Modal.Title className="tw-font-semibold">
          Report a session not carried out
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.ControlLabel className="tw-text-[#222222] tw-text-sm">
            Can you tell us why this session was not carried out?
          </Form.ControlLabel>
          <Controller
            control={control}
            defaultValue={null}
            name="failed_booking_reason"
            render={({ field }) => (
              <RadioGroup {...field} className="tw-pl-2">
                {failedBookingReasons(bookingParticipant).map(
                  (reason, index) => (
                    <Radio key={index} value={reason}>
                      {reason}
                    </Radio>
                  )
                )}
              </RadioGroup>
            )}
          />
          {otherReason === "Others" && (
            <Controller
              name="other_failed_booking_reason"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  as="textarea"
                  rows={3}
                  placeholder="Type here"
                />
              )}
            />
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button appearance="ghost" onClick={close}>
          Cancel
        </Button>
        <Button
          appearance="primary"
          disabled={otherReason === undefined}
          onClick={handleSubmit(onSubmit)}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
