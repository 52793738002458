import React from "react";
import { Col, Form, TagPicker } from "rsuite";
import { useUser } from "../../contexts/user";
import FieldError from "../ui/FieldError";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { map } from "lodash";
import translateText from "../../util/translateText";

const HiringExperienceLevel = ({ convertText, control, func, errors }) => {
  const { user } = useUser();
  const setValue = func;
  
  return (
    <Form fluid className="tw-mt-6">
      <Form.Group className="tw-mb-6">
        <Form.ControlLabel className="tw-text-base">
          {translateText(convertText, "What level of experience are you hiring?")}
          <span className="tw-text-red-500">*</span>
        </Form.ControlLabel>
        <Controller
          name="info.hiring_experience_level"
          control={control}
          render={({ field }) => (
            <TagPicker
              data={map(
                user.info.hiring_experience_level_list,
                (value, key) => ({
                  label: value,
                  value,
                })
              )}
              style={{ width: "100%" }}
              onChange={(value) => {
                setValue(field.name, value);
              }}
              placeholder={translateText(convertText, "Please select")}
              placement={"topStart"}
              size="lg"
              {...field}
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name="info.hiring_experience_level"
          as={FieldError}
        />
      </Form.Group>
    </Form>
  );
};

export default HiringExperienceLevel;
