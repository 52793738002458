// React
import React, { useState, useRef } from "react";

// UI
import {
  Button,
  Drawer,
  InputGroup,
  Input,
  DatePicker,
  SelectPicker,
} from "rsuite";
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai";

// 3rd Party
import debounce from "lodash.debounce";

const styles = {
  width: 300,
  marginBottom: 10,
};

export const DataTableFilter = ({
  query,
  setQuery,
  loading,
  date,
  setDate,
  options,
  onFieldChange,
}) => {
  const [open, setOpen] = useState(false);
  const openFilter = () => setOpen(true);
  const closeFilter = () => setOpen(false);
  const inputRef = useRef<any>(null);

  const clearQuery = () => {
    setQuery("");
    //clear value of input
    inputRef.current.value = "";
  };

  const handleChangeDate = (value, name) => {
    console.log(value, name);
    setDate((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <Drawer open={open}>
        <div className="tw-p-5">
          <div
            style={{
              fontWeight: "bold",
              fontSize: "18px",
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <span>Filter</span>
            <AiOutlineClose onClick={closeFilter} />
          </div>
          <InputGroup inside style={styles}>
            <Input
              disabled={loading}
              placeholder="search"
              defaultValue={query}
              onChange={debounce((e) => setQuery(e), 2000)}
              ref={inputRef}
            />
            <InputGroup.Button
              disabled={loading}
              loading={loading}
              onClick={clearQuery}
            >
              {query ? <AiOutlineClose /> : <AiOutlineSearch />}
            </InputGroup.Button>
          </InputGroup>
          <SelectPicker
            onChange={onFieldChange}
            loading={loading}
            data={options}
          />
          <div>
            <label>Date From</label>
            <DatePicker
              oneTap
              format="yyyy-MM"
              style={{ width: 200 }}
              cleanable
              onChange={(value) => handleChangeDate(value, "date_from")}
              value={date.date_from}
              disabled={loading}
            />
          </div>
          <div>
            <label>Date To</label>
            <DatePicker
              oneTap
              format="yyyy-MM"
              style={{ width: 200 }}
              cleanable
              onChange={(value) => handleChangeDate(value, "date_to")}
              value={date.date_to}
              disabled={loading}
            />
          </div>
        </div>
      </Drawer>
      <Button appearance="primary" onClick={openFilter}>
        Filter
      </Button>
    </>
  );
};
