// React
import React from "react";

// FutureLab
import {
  ArrayCell,
  BooleanCell,
  NumberCell,
  TimeCell,
} from "./data_table_component/DataTableCellType";
import DataTable from "./DataTable";

const creditPurchaseColumns = [
  {
    Header: "Credit Transaction ID",
    accessor: "paymentId",
    Cell: NumberCell,
  },
  {
    Header: "Transaction Type",
    accessor: "transactionType",
  },
  {
    Header: "Amount Paid (MYR)",
    accessor: "amountPaid",
    Cell: NumberCell,
  },
  {
    Header: "Service Tax (MYR)",
    accessor: "serviceTax",
    Cell: NumberCell,
  },
  {
    Header: "Date of Purchase",
    accessor: "dateOfPurchased",
    Cell: TimeCell,
  },
  {
    Header: "Quantity of Purchased",
    accessor: "purchasedQuantity",
    Cell: NumberCell,
  },
  {
    Header: "Promo Code Used?",
    accessor: "promoCodeUsed",
  },
  {
    Header: "Discount Applied %",
    accessor: "discountRate",
    Cell: NumberCell,

  },
  {
    Header: "Total Discount (MYR)",
    accessor: "totalDiscount",
    Cell: NumberCell,

  },
  {
    Header: "First Name",
    accessor: "firstName",
  },
  {
    Header: "Last Name",
    accessor: "lastName",
  },
  {
    Header: "Email Address",
    accessor: "emailAddress",
  },
  {
    Header: "First Time Buyer?",
    accessor: "firstTimeBuyer",
    Cell: BooleanCell
  },
  {
    Header: "Part of a Programme?",
    accessor: "isProgramme",
  },
  {
    Header: "Programme Name",
    accessor: "programmeName",
  },
];

const CreditPurchaseData = ({ creditPurchase = [], maxPages, fields }) => {
  
  return (
    <DataTable
      initialData={creditPurchase}
      maxPages={maxPages}
      title="Credit Purchase Data"
      column={creditPurchaseColumns as any}
      fields={fields}
    />
  );
};
export default CreditPurchaseData;