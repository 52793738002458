import React, { useEffect, useState } from "react";

// UI
import { Avatar, Col, FlexboxGrid } from "rsuite";

// FutureLab
import { PortalApi } from "../../../../util/portal_api";
import ApplicantStatusTable from "../components/ApplicantStatusTable";

const ApplicationStatus = ({ program, userEmail, redirectURL }) => {
  const [loading, setLoading] = useState(false);
  const [applications, setApplications] = useState([]);

  useEffect(() => {
    setLoading(true);

    PortalApi({
      method: "get",
      path: `/applicants/status`,
      options: { program_id: program.id },
      success: ({ data }) => {
        setLoading(false);
        setApplications(data);
      },
      error: (err) => {
        setLoading(false);
      },
    });
  }, []);

  return (
    <FlexboxGrid
      justify="center"
      className="tw-flex tw-justify-center tw-w-full tw-my-4 tw-py-4"
      style={{ minHeight: "100dvh" }}
    >
      <FlexboxGrid.Item
        as={Col}
        colspan={24}
        sm={24}
        lg={14}
        className="tw-flex tw-flex-col tw-justify-center tw-text-center tw-items-center"
      >
        <div>
          {program.photo?.url ? (
            <img
              src={program.photo.url}
              alt={program.photo}
              className="tw-w-60 tw-h-60 tw-mb-2 tw-rounded-md sm:tw-w-28 sm:tw-h-28"
            />
          ) : (
            <Avatar>{program.name.charAt(0)}</Avatar>
          )}
          <h1 className="tw-my-2 tw-text-lg">{program.name}</h1>
        </div>

        <div className="tw-mt-10">
          <p className="tw-m-0 tw-text-center">Your application status as</p>
          <b>{userEmail}</b>
        </div>

        <div className="tw-w-full">
          <ApplicantStatusTable
            applications={applications}
            loading={loading}
            redirectURL={redirectURL}
          />
        </div>

        <div
          className="tw-py-2 tw-w-full"
          style={{ position: "fixed", bottom: 10, backgroundColor: "#ffffff" }}
        >
          {program.support_email && (
            <p className="tw-text-center">
              Need futher assistance? Contact{" "}
              <a
                href={`mailto:${program.support_email}?subject=Application%20enquiry%20for%20${program.name}`}
              >
                {program.support_email}
              </a>
            </p>
          )}

          <span className="tw-text-sm tw-text-gray-500">
            Powered by FutureLab
          </span>
        </div>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

export default ApplicationStatus;
