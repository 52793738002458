// (START): Template defaults for: Employee Development Programme
// WYSIWIG learning_modules.description
export const EmployeeEngagementProgramme = {
  learning_modules: [
    {
      name: "Module 1: Personal Effectiveness",
      description:
        "<p>In this module, mentees will explore their personal strengths and weaknesses and understand how these factors influence their professional and personal lives. They will learn to harness strengths and mitigate weaknesses for improved performance.</p><p><br></p><p>Learning Objectives:</p><ul><li>Identify personal strengths and weaknesses through self-assessment tools.</li><li>Analyze the impact of personal strengths and weaknesses on their work and life.</li><li>Develop strategies to leverage strengths and address weaknesses effectively.</li></ul>",
      learning_module_tasks_attributes: [
        {
          name: "Complete personal reflection template",
          category: "session",
        },
        {
          name: "1-on-1 session with Mentors",
          category: "session",
        },
        {
          name: "Learning reflection feedback",
          category: "session",
        },
      ],
      duration_range: [],
      order: 0,
    },
    {
      name: "Module 2: Personal and Work Value Alignment",
      description:
        "<p>This module delves into aligning personal values with the values of the organization. Mentees will gain insight into the importance of values alignment for job satisfaction, motivation, and overall success in the workplace.</p><p><br></p><p>Learning Objectives:</p><ul><li>Define personal values and their significance in career decisions.</li><li>Assess the organizational values and culture and identify alignment with personal values.</li><li>Develop a plan to align personal and work values for increased job satisfaction.</li></ul>",
      learning_module_tasks_attributes: [
        {
          name: "Complete value alignment framework",
          category: "session",
        },
        {
          name: "1-on-1 session with Mentors",
          category: "session",
        },
        {
          name: "Learning reflection feedback",
          category: "session",
        },
      ],
      duration_range: [],
      order: 1,
    },
    {
      name: "Module 3: Career Success at your company",
      description:
        "<p>This final module focuses on equipping mentees with the skills and knowledge necessary to thrive their company. They will explore career development opportunities within the company and learn strategies for continuous growth.</p><p><br></p><p>Learning Objectives:</p><ul><li>Understand the organizational structure, culture, and career paths within their organization.</li><li>Develop a personalized career plan with short-term and long-term goals.</li><li>Acquire tips and advice to advance their careers within the organization.</li></ul>",
      learning_module_tasks_attributes: [
        {
          name: "Identifying career goals framework",
          category: "session",
        },
        {
          name: "1-on-1 session with Mentors",
          category: "session",
        },
        {
          name: "Learning reflection feedback",
          category: "session",
        },
      ],
      duration_range: [],
      order: 2,
    },
  ],
  goals: [
    "Identifying personal strengths and weaknesses",
    "Company and personal values alignment",
    "Career Goal Setting",
    "Career Guidance",
  ],
  impact_questions: [{}],
};
// (END): Template defaults for: Employee Development Programme

// (START): Template defaults for: Entrepeneursip Programme
// WYSIWIG learning_modules.description
export const EntrepeneurshipProgramme = {
  learning_modules: [
    {
      name: "Idea Validation & Market Research",
      description: "",
      duration_range: [],
      order: 0,
    },
    {
      name: "Business Development | Build solid business model ",
      description: "",
      duration_range: [],
      order: 1,
    },
    {
      name: "Marketing & Business Strategies",
      description: "",
      duration_range: [],
      order: 2,
    },
  ],
  goals: [
    "Idea validation",
    "Business Model Canvas",
    "Marketing Strategies",
    "Business Administration",
    "Identifying Market Needs and Customer Research",
    "Pitch",
    "Commercialization",
  ],
  impact_questions: [{}],
};
// (END): Template defaults for: Entrepeneursip Programme

// (START): Template defaults for: Youth Employability Programme
// WYSIWIG learning_modules.description
export const YouthEmployabilityProgramme = {
  learning_modules: [
    {
      name: "Self-Exploration | Identifying Strengths and Weaknesses through DISC test ",
      description: "",
      duration_range: [],
      order: 0,
    },
    {
      name: "Communication skill",
      description: "",
      duration_range: [],
      order: 1,
    },
    {
      name: "Resume Building & Interview Preparation",
      description: "",
      duration_range: [],
      order: 2,
    },
  ],
  goals: [
    "Self-Awareneness",
    "Identifying Strengths and Weaknesses",
    "Communication Skills",
    "Network Development",
    "CV and Cover Letter Preparation",
    "Interview Preparation",
  ],
  impact_questions: [{}],
};
// (END): Template defaults for: Youth Employability Programme
