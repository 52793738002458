// React
import React, { useLayoutEffect, useState } from "react";

// FutureLab
import { withUser } from "../../contexts/user";
import { FutureLabApi } from "../../util/api";
import DiscoverSearchBox from "../DiscoverSearchBox";
import DashboardEvents from "./DashboardEvents";
import DashboardBookings from "./DashboardBookings";
import DashboardAvailability from "./DashboardAvailability";
import DashboardMentors from "./DashboardMentors";
import DashboardMentees from "./DashboardMentees";
import DashboardProfile from "./DashboardProfile";
import DashboardImpact from "./DashboardImpact";
import DashboardMentorClasses from "./DashboardMentorClasses";
import DashboardFeatureModal from "./DashboardFeatureModal";
import DashboardBannerModal from "./DashboardBannerModal";
import { DashboardPromo } from "./DashboardPromo";

// UI
import { Carousel, Col, FlexboxGrid } from "rsuite";

// 3rd Party
import Joyride, { EVENTS } from "react-joyride";
import mixpanel from "mixpanel-browser";
import _ from "lodash";
import { useSetState } from "react-use";

const TooltipContent = () => (
  <div className="tw-flex tw-flex-col tw-gap-2">
    <span className="tw-font-bold tw-m-0 tw-p-0 tw-text-left">
      Top up credits
    </span>
    <p className="tw-m-0 tw-p-0">
      You can easily add credits to your account by clicking on this button
    </p>
  </div>
);

const DashboardContent = ({ user, showSurvey, convertText }) => {
  const {
    id: userId,
    user_type: userType,
    application_pending: pendingApproval,
  } = user;
  const isMentee = userType === "mentee";

  const [openFeatureModal, setOpenFeatureModal] = useState(true);
  const [openBannerModal, setOpenBannerModal] = useState(false);
  const [run, setRun] = useSetState<boolean | any>(false);
  const [steps, setSteps] = useSetState<any[]>([
    {
      target: ".credit_button_intro",
      content: <TooltipContent />,
      disableBeacon: true,
      placement: "bottom-end",
    },
  ]);

  useLayoutEffect(() => {
    //get localstorage
    const dashboardCreditIntro =
      localStorage.getItem("dashboardCreditIntro") || null;
    const parsed = dashboardCreditIntro
      ? JSON.parse(dashboardCreditIntro)
      : null;
    if (!parsed?.dashboardCreditIntro || parsed?.userId !== user.id) {
      setRun((prev) => !prev);
    }
  }, []);

  const handleTourEnd = (data) => {
    const tourEnd = EVENTS.TOUR_END;
    if (tourEnd.includes(data.type)) {
      //save local storage
      localStorage.setItem(
        "dashboardCreditIntro",
        JSON.stringify({
          dashboardCreditIntro: true,
          userId: user.id,
        })
      );
    }
  };

  const components = {
    impact: <DashboardImpact convertText={convertText} />,
    availability: <DashboardAvailability convertText={convertText} />,
    bookings: (
      <DashboardBookings convertText={convertText} currentUser={userId} />
    ),
    events: <DashboardEvents convertText={convertText} />,
    mentees: (
      <DashboardMentees convertText={convertText} currentUser={userId} />
    ),
    mentors: <DashboardMentors convertText={convertText} currentUser={user} />,
    classes: <DashboardMentorClasses convertText={convertText} />,
  };

  const dashDisplay = (user) => {
    const componentKey =
      user === "mentee" || pendingApproval === true
        ? ["mentors", "bookings", "events"]
        : ["mentors", "bookings", "events"];

    return componentKey.map((e, index) => (
      <div key={index} className="tw-my-8 sm:tw-w-full sm:tw-mb-4">
        {components[e]}
      </div>
    ));
  };

  // Show Survey Feature
  const {
    survey: { show_modal: showFeature, feature: feature },
  } = showSurvey;

  const postData = (submitted = false) => {
    const featureData = {
      user_id: userId,
      feature: feature,
      submitted: submitted,
    };

    FutureLabApi({
      method: "post",
      path: "/surveys/beta",
      options: featureData,
    });
  };

  const handleFeatureModalClick = () => {
    postData(true);
    setOpenFeatureModal(false);
  };

  const handleFeatureModalClose = (event) => {
    if (event) {
      postData();
      setOpenFeatureModal(false);
    }
  };

  const handleBannerClick = () => {
    window.open("https://forms.gle/NWzV5aDUAjTPp3oK6", "_blank");
    mixpanel.track("Clicked Mentee Dashboard Banner", {
      Name: user.full_name,
      "User Created At": user.created_at,
      "User Id": userId,
    });
    // e.preventDefault();
    // setOpenBannerModal(true);
  };

  return (
    <>
      {/* <Joyride
        steps={steps}
        disableScrolling={true}
        run={run}
        callback={handleTourEnd}
        disableOverlayClose={true}
        locale={{
          close: "Got it", // Change the close button text
        }}
        styles={{
          options: {
            arrowColor: "#428AC9",
            primaryColor: "#428AC9",
            textColor: "#222222",
          },
          buttonNext: {
            backgroundColor: "transparent",
            color: "#428AC9",
            border: "solid 1px #428AC9",
          },
        }}
      /> */}
      <FlexboxGrid justify="center">
        <FlexboxGrid.Item as={Col} colspan={22} xs={24} md={24} lg={24}>
          {/* In Product Marketing Banner - Route changes based on campaign */}
          {isMentee && (
            <>
              {!_.isEmpty(user.promo_trackers) && (
                <DashboardPromo user={user} />
              )}
            </>
          )}
          <FlexboxGrid className="profile-wrapper">
            <FlexboxGrid.Item colspan={24}>
              <DashboardProfile convertText={convertText} />
            </FlexboxGrid.Item>
          </FlexboxGrid>
          {dashDisplay(userType)}
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default withUser(DashboardContent);
