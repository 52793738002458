// React
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// FutureLab
import { usePortal} from "../../../../contexts/portal";
import getQuestionField from "../getQuestionField";
import {
  FormInputPicker,
  FormInputText,
  FormSelectPicker,
} from "../../../form/default/FormInputs";
import { FormRichTextInput } from "../../../ui/RichTextEditor";
import { PortalApi } from "../../../../util/portal_api";
import generateRandomHex from "../../../../util/randomHexGenerator";

// UI
import { Button, ButtonToolbar, Form, Input, Toggle } from "rsuite";
import CopyIcon from "@rsuite/icons/Copy";
import TrashIcon from "@rsuite/icons/Trash";

// 3rd Party
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import _ from "lodash";

const schema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string(),
  user_type: yup.string().required().label("role"),
  questions: yup.array().of(
    yup.object({
      id: yup.mixed().nullable(),
      title: yup.string().required().nullable().label("Question"),
      description: yup.string().nullable(),
      required: yup.bool(),
      scale_start: yup.number().nullable(),
      scale_end: yup.number().nullable(),
      options: yup.array().of(yup.string()),
      question_type: yup.string().required().label("type"),
      _destroy: yup.bool(),
    })
  ),
});

const ApplicationForm = () => {
  const { applicationId } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
    watch,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      description: "",
      user_type: "",
      questions: [
        {
          id: null,
          title: "",
          description: "",
          required: false,
          options: [],
          question_type: "text",
          _destroy: false,
        },
      ],
    },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { program } = usePortal();

  const getRoleId = (string) => {
    const idArray = program.available_roles.find((role) => role.name == string);
    return idArray.id;
  };

  useEffect(() => {
    if (applicationId) {
      PortalApi({
        method: "get",
        path: `/applications/${applicationId}`,
        success: ({ data }) => {
          reset({
            name: data.name,
            user_type: data.user_type,
            description: data.description,
            questions: data.questions,
          });
        },
      });
    }
  }, [applicationId]);

  const onSubmit = (data) => {
    setIsSubmitting(true);
    data.program_id = program.id;
    data.link_ref = generateRandomHex(10);
    data.role_id = getRoleId(data.user_type);

    PortalApi({
      method: applicationId ? "patch" : "post",
      path: applicationId ? `/applications/${applicationId}` : `/applications`,
      options: data,
      success: () => {
        setIsSubmitting(false);
        navigate("..");
      },
      error: () => {
        setIsSubmitting(false);
      },
    });
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "questions",
    keyName: "idx",
  });

  const watchQuestions = watch("questions");

  return (
    <Form className="mb-4">
      <Form.Group>
        <FormInputText
          name={"name"}
          control={control}
          errors={errors}
          placeholder={"Application Name"}
          label={"Application Name"}
        />
      </Form.Group>

      <Form.Group>
        <FormRichTextInput
          name="description"
          control={control}
          errors={errors}
          label="Description"
        />
      </Form.Group>

      <Form.Group>
        <FormInputPicker
          name={"user_type"}
          control={control}
          errors={errors}
          placeholder={"Select role"}
          label={"Select a role"}
          data={_.map(program.available_roles, (role) => {
            return { label: role.name, value: role.name };
          })}
          style={{ width: "100%" }}
          menuStyle={{ width: 300 }}
        />
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>{"Questions"}</Form.ControlLabel>
        {fields.map((field, index) => (
          <div
            className={`tw-bg-gray-100 tw-p-4 tw-rounded-md tw-my-4 ${
              getValues(`questions.${index}._destroy`) && "hidden"
            }`}
            key={field.idx}
          >
            <div>
              <Controller
                name={`questions.${index}.id`}
                control={control}
                defaultValue={field.id}
                render={({ field }) => (
                  <Input {...field} className="tw-hidden" />
                )}
              />

              <Controller
                name={`questions.${index}._destroy`}
                control={control}
                defaultValue={field._destroy || false}
                render={({ field: { name, value } }) => (
                  <Toggle
                    defaultChecked={value}
                    onChange={(value) => setValue(name, value)}
                    className="tw-hidden"
                  />
                )}
              />

              <div className="tw-inline-flex tw-w-full tw-justify-between">
                <Form.Group className="tw-w-full tw-mr-2">
                  <FormInputText
                    name={`questions.${index}.title`}
                    errors={errors}
                    control={control}
                    placeholder={"Question"}
                  />
                </Form.Group>

                <Form.Group>
                  <FormSelectPicker
                    name={`questions.${index}.question_type`}
                    control={control}
                    errors={errors}
                    placeholder={"Question Type"}
                    data={[
                      {
                        label: "Scale",
                        value: "scale",
                      },
                      {
                        label: "Text",
                        value: "text",
                      },
                      {
                        label: "Options",
                        value: "options",
                      },
                    ]}
                    searchable={false}
                  />
                </Form.Group>
              </div>

              <Form.Group>
                <FormInputText
                  name={`questions.${index}.description`}
                  control={control}
                  errors={errors}
                  placeholder={"Description"}
                  label={"Description (Optional)"}
                  as="textarea"
                  rows={2}
                />
              </Form.Group>

              <Form.Group>
                {getQuestionField({
                  question: watchQuestions[index],
                  name: "questions",
                  control,
                  register,
                  index,
                  field,
                  errors,
                })}
              </Form.Group>

              <div className="tw-inline-flex tw-w-full tw-align-middle">
                <div className="tw-pr-2">
                  <Form.ControlLabel>{"Required"}</Form.ControlLabel>
                </div>
                <div className="tw-pr-4">
                  <Controller
                    name={`questions.${index}.required`}
                    control={control}
                    defaultValue={false}
                    render={({ field: { name, value } }) => (
                      <Toggle
                        checked={value}
                        onChange={(value) => setValue(name, value)}
                      />
                    )}
                  />
                </div>

                <div>
                  <a
                    onClick={() => {
                      let values = getValues();
                      append(_.omit(values.questions[index], ["idx", "id"]));
                    }}
                    className="tw-px-2 tw-pr-4 tw-cursor-pointer"
                  >
                    <CopyIcon />
                  </a>
                </div>
                <div>
                  <a
                    onClick={() => {
                      if (field.id !== undefined) {
                        setValue(`questions.${index}._destroy`, true);
                      } else {
                        remove(index);
                      }
                    }}
                    className="tw-px-2 tw-cursor-pointer"
                  >
                    <TrashIcon />
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
        <Button
          appearance="link"
          className="tw-p-0"
          onClick={() => append({ question_type: "text" })}
        >
          + {"Add more questions"}
        </Button>
      </Form.Group>

      <ButtonToolbar>
        <Button
          appearance="primary"
          type="submit"
          onClick={handleSubmit(onSubmit)}
          loading={isSubmitting}
        >
          {applicationId ? "Save" : "Create"}
        </Button>
        <Button
          appearance="ghost"
          onClick={() => navigate("..")}
        >
          Cancel
        </Button>
      </ButtonToolbar>
    </Form>
  );
};

export default ApplicationForm;
