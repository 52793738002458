// React
import React, { useEffect, useState } from "react";

// FutureLab
import avatarPlaceholder from "../../../images/avatar_placeholder.png";
import { FutureLabApi } from "../../../util/api";
import {
  CalendarButtons,
  ConfirmationButtons,
} from "./component/SessionDetailsButton";

// UI
import { Button, Divider, Form, Input, Message, Modal, toaster } from "rsuite";
import { MdChatBubbleOutline, MdEdit } from "react-icons/md";
import { FaRegCalendarPlus } from "react-icons/fa";
import { BsSend } from "react-icons/bs";

// 3rd Party
import { Controller, useForm } from "react-hook-form";

export default function BookingSessionDetails(props) {
  const {
    openSessionDetails,
    setOpenSessionDetails,
    booking,
    category,
    handleAccept,
  } = props;
  const { control, handleSubmit, reset } = useForm();
  const [edit, setEdit] = useState(true);
  const [bookingNote, setBookingNote] = useState();

  useEffect(() => {
    reset({
      note: bookingNote,
    });
  }, [bookingNote]);

  useEffect(() => {
    FutureLabApi({
      method: "get",
      path: "/dashboard/bookings/booking_note",
      options: { booking_id: booking?.id },
      success: ({ data }) => {
        setBookingNote(data);
      },
    });
  }, [booking]);

  const onSubmit = (data) => {
    // Handle form submission here
    FutureLabApi({
      method: "post",
      path: `/dashboard/bookings/booking_note`,
      options: { booking_id: booking?.id, note: data?.note },
      success: () => {
        toaster.push(
          <Message showIcon type="success">
            Booking note updated.
          </Message>
        );
      },
    });
    setEdit(true);
  };

  const handleClose = () => {
    setOpenSessionDetails(false);
    setEdit(true);
  };

  return (
    <Modal
      backdrop
      open={openSessionDetails}
      onClose={handleClose}
      size="sm"
      className="sm:tw-mt-3"
    >
      <Modal.Header>
        <Modal.Title className="tw-font-semibold">Session Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tw-flex tw-pb-0">
          <div className="tw-mr-4">
            <img
              src={booking?.photo || avatarPlaceholder}
              className="tw-w-20 tw-rounded-xl"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = avatarPlaceholder;
              }}
            />
          </div>
          <div>
            <div className="tw-font-semibold">{booking?.name}</div>
            <Button
              className="tw-text-sm tw-pl-0"
              startIcon={<MdChatBubbleOutline size={18} />}
              appearance="link"
              href={booking?.chat_link}
            >
              Chat
            </Button>
          </div>
        </div>
        <Divider className="tw-mb-4" />
        <div className="tw-flex tw-px-2 tw-pb-3 tw-text-sm xs:tw-flex-wrap xs:tw-pb-0">
          <div className="tw-basis-1/3 xs:tw-basis-1/2">
            <div className="tw-font-medium">Session date</div>
            <div>{booking?.date}</div>
          </div>
          <div className="tw-basis-1/3 xs:tw-basis-1/2">
            <div className="tw-font-medium">Session time</div>
            <div>{`${booking?.time} - ${booking?.ending_time}`}</div>
          </div>
          <div className="tw-basis-1/3 xs:tw-basis-1/2 xs:tw-mt-4 xs:tw-mb-1">
            <Button
              className="xs:tw-px-1"
              startIcon={<FaRegCalendarPlus size={18} />}
              appearance="link"
              href={booking?.add_to_calendar}
            >
              Add to calendar
            </Button>
          </div>
        </div>
        {/* <CalendarButtons booking={booking} category={category} /> */}
        <Divider className="tw-mb-4 tw-mt-2" />
        {booking?.topic && (
          <>
            <div className="tw-text-sm tw-px-2">
              <div className="tw-font-medium tw-mb-1">Topic</div>
              <div className="tw-max-h-16 tw-overflow-y-auto tw-border tw-border-solid tw-border-slate-300 tw-rounded-lg tw-p-2 tw-bg-slate-50">
                {booking?.topic}
              </div>
            </div>
            <Divider className="tw-my-4" />
          </>
        )}
        <div className="tw-flex tw-px-2 tw-justify-between tw-text-sm xs:tw-flex-wrap">
          <div className="tw-basis-1/3 sm:tw-basis-1/2">
            <div className="tw-font-medium">Status</div>
            <div>{booking?.status}</div>
          </div>
          <div className="tw-basis-1/3 sm:tw-basis-1/2">
            <div className="tw-font-medium">Method of contact</div>
            <div>{booking?.session_type}</div>
          </div>
          <div className="tw-basis-1/3 sm:tw-basis-1/2 xs:tw-mt-4 xs:tw-mb-1">
            <div className="tw-font-medium">Service</div>
            <div>{booking?.learning_objectives}</div>
          </div>
        </div>
        <Divider className="tw-mb-4" />
        <Form className="tw-mt-3 tw-px-2 tw-text-sm">
          <div className="tw-flex tw-justify-between">
            <span className="tw-self-center tw-font-medium">Notes</span>
            <div>
              {!edit && (
                <Button
                  onClick={handleSubmit(onSubmit)}
                  startIcon={<BsSend size={18} />}
                  className="tw-text-sm tw-mr-4"
                  appearance="link"
                >
                  Submit
                </Button>
              )}
              <Button
                className="tw-text-sm tw-pl-0"
                startIcon={<MdEdit size={18} />}
                appearance="link"
                onClick={() => setEdit(!edit)}
              >
                Edit
              </Button>
            </div>
          </div>
          <div>
            <Controller
              name="note"
              control={control}
              defaultValue={bookingNote || ""}
              render={({ field }) => (
                <Input {...field} plaintext={edit} as="textarea" rows={3} />
              )}
            />
          </div>
        </Form>
      </Modal.Body>
      <Divider className="tw-mt-1 tw-mb-4" />
      <Modal.Footer className="tw-flex tw-justify-end">
        <ConfirmationButtons
          booking={booking}
          category={category}
          handleAccept={handleAccept}
        />
      </Modal.Footer>
    </Modal>
  );
}
