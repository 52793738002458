import React, { useState } from "react";
import { Nav, Stack } from "rsuite";
import TooltipHelper from "../ui/TooltipHelper";
import translateText from "../../util/translateText";
import useDrag from "../../util/useDrag";
import { ScrollMenu } from "react-horizontal-scrolling-menu";

const DashboardMentorClasses = ({ convertText }) => {
  const [activeKey, setActiveKey] = useState("1");
  const { dragStart, dragStop, dragMove } = useDrag();
  const handleDrag =
    ({ scrollContainer }) =>
    (ev: React.MouseEvent) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  return (
    <>
      <div>
        <div className="sm:tw-w-full sm:tw-text-center sm:tw-mb-5 tw-mb-5 tw-flex tw-justify-between">
          <div className="tw-inline-flex tw-items-center">
            <p className="section-header montserrat">
              {translateText(convertText, "MentorClass")}
            </p>
            <TooltipHelper
              tip={translateText(
                convertText,
                "Start watching our previous MentorClass content here!"
              )}
            />
          </div>
        </div>
        <Nav
          appearance="subtle"
          activeKey={activeKey}
          onSelect={setActiveKey}
          className="tw-mb-5"
        >
          <Nav.Item eventKey="1" className="tw-text-center">
            {translateText(convertText, "Videos")} ({3})
          </Nav.Item>
        </Nav>
      </div>
      <div onMouseLeave={dragStop}>
        <ScrollMenu
          onMouseDown={() => dragStart}
          onMouseUp={() => dragStop}
          onMouseMove={handleDrag}
        >
          {/* First video */}
          <Stack
            className="tw-overflow-x-auto no-scrollbar"
            alignItems="stretch"
          >
            <div className="tw-mb-2 tw-overflow-hidden tw-rounded-lg tw-mr-5 tw-relative">
              <iframe
                width="300"
                height="130"
                src="https://www.youtube.com/embed/8hAcZCDHOeo"
                title="Get Started to FutureLab | Tutorial Video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <div className="tw-px-3 tw-py-4">
                <h6>Get Started to FutureLab | Tutorial Video</h6>
                <p className="montserrat tw-mb-2">
                  Video | 03: 16
                </p>
              </div>
            </div>
          </Stack>

          {/* Second video */}
          <Stack
            className="tw-overflow-x-auto no-scrollbar"
            alignItems="stretch"
          >
            <div className="tw-mb-2 tw-overflow-hidden tw-rounded-lg tw-mr-5 tw-relative">
              <iframe
                width="300"
                height="130"
                src="https://www.youtube.com/embed/Brjx8ekBWAU"
                title="The transformative powers of Big Data and IoT"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <div className="tw-px-3 tw-py-4">
                <h6>The transformative powers of Big Data and IoT</h6>
                <p className="montserrat tw-mb-2">
                  Video | 52: 20
                </p>
              </div>
            </div>
          </Stack>

          {/* Third video */}
          <Stack
            className="tw-overflow-x-auto no-scrollbar"
            alignItems="stretch"
          >
            <div className="tw-mb-2 tw-overflow-hidden tw-rounded-lg tw-mr-5 tw-relative">
              <iframe
                width="300"
                height="130"
                src="https://www.youtube.com/embed/BvCnX0RaFaA"
                title="Dressing and Grooming For Success"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <div className="tw-px-3 tw-py-4">
                <h6>Dressing and Grooming For Success</h6>
                <p className="montserrat tw-mb-2">
                  Video | 55: 18
                </p>
              </div>
            </div>
          </Stack>
        </ScrollMenu>
      </div>
    </>
  );
};

export default DashboardMentorClasses;
