import React from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { Button, Form, Input, Toggle } from "rsuite";
import {
  FormInputText,
  FormSelectPicker,
} from "../../../form/default/FormInputs";
import getQuestionField from "../../application/getQuestionField";
import { MdContentCopy, MdOutlineDelete } from "react-icons/md";

const MentorImpactForm = (props) => {
  const { formProps } = props;
  const {
    control,
    getValues,
    watch,
    setValue,
    register,
    formState: { errors },
  } = formProps;

  const {
    fields: mentorFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "mentor_questions",
    keyName: "idx",
  });

  const watchQuestions = watch("mentor_questions");

  return (
    <>
      <Form.Group>
        {mentorFields.map((field, index) => (
          <div
            className={`tw-bg-gray-100 tw-p-4 tw-rounded-md tw-my-4 ${
              getValues(`mentor_questions.${index}._destroy`) && "hidden"
            }`}
            key={field.idx}
          >
            <div>
              <Controller
                name={`mentor_questions.${index}.id`}
                control={control}
                defaultValue={field.id || undefined}
                render={({ field }) => (
                  <Input {...field} className="tw-hidden" />
                )}
              />

              <Controller
                name={`mentor_questions.${index}._destroy`}
                control={control}
                defaultValue={field._destroy || false}
                render={({ field: { name, value } }) => (
                  <Toggle
                    defaultChecked={value}
                    onChange={(value) => setValue(name, value)}
                    className="tw-hidden"
                  />
                )}
              />

              <Controller
                name={`mentor_questions.${index}.user_type`}
                control={control}
                defaultValue={field.user_type || "mentee"}
                render={({ field }) => (
                  <Input {...field} className="tw-hidden" />
                )}
              />

              <div className="tw-inline-flex tw-w-full tw-justify-between">
                <Form.Group className="tw-w-full tw-mr-2">
                  <FormInputText
                    name={`mentor_questions.${index}.title`}
                    errors={errors}
                    control={control}
                    placeholder={`Question ${index + 1}`}
                    className="tw-font-medium"
                  />
                </Form.Group>

                <Form.Group>
                  <FormSelectPicker
                    name={`mentor_questions.${index}.question_type`}
                    control={control}
                    errors={errors}
                    placeholder={"Question Type"}
                    style={{ width: "145px" }}
                    data={[
                      {
                        label: "Scale",
                        value: "scale",
                      },
                      {
                        label: "Text",
                        value: "text",
                      },
                      {
                        label: "Options",
                        value: "options",
                      },
                    ]}
                    searchable={false}
                  />
                </Form.Group>
              </div>

              <Form.Group>
                <FormInputText
                  name={`mentor_questions.${index}.description`}
                  control={control}
                  errors={errors}
                  placeholder={"Description"}
                  label={"Description (Optional)"}
                  as="textarea"
                  rows={2}
                />
              </Form.Group>

              <Form.Group>
                {getQuestionField({
                  question: watchQuestions[index],
                  name: "mentor_questions",
                  control,
                  register,
                  index,
                  field,
                  errors,
                })}
              </Form.Group>

              <div className="tw-inline-flex tw-w-full tw-align-middle">
                <div className="tw-pr-2">
                  <Form.ControlLabel>{"Required"}</Form.ControlLabel>
                </div>
                <div className="tw-pr-4">
                  <Controller
                    name={`mentor_questions.${index}.required`}
                    control={control}
                    defaultValue={false}
                    render={({ field: { name, value } }) => (
                      <Toggle
                        checked={value}
                        onChange={(value) => setValue(name, value)}
                      />
                    )}
                  />
                </div>

                <div>
                  <a
                    onClick={() => {
                      let values = getValues();
                      append(
                        _.omit(values.mentor_questions[index], ["idx", "id"])
                      );
                    }}
                    className="tw-px-2 tw-pr-4 tw-cursor-pointer"
                  >
                    <MdContentCopy />
                  </a>
                </div>
                <div>
                  <a
                    onClick={() => {
                      // Keyname is required in useFieldArray() else it would generate an hash id.
                      if (field.id !== undefined) {
                        setValue(`mentor_questions.${index}._destroy`, true);
                      } else {
                        remove(index);
                      }
                    }}
                    className="tw-px-2 tw-cursor-pointer"
                  >
                    <MdOutlineDelete />
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
        <Button
          appearance="link"
          className="tw-p-0"
          onClick={() => append({ question_type: "text" })}
        >
          + {"Add more questions"}
        </Button>
      </Form.Group>
    </>
  );
};

export default MentorImpactForm;
