// React
import React from "react";

// FutureLab
import ActionButtons from "./ActionButtons";
import UserPhoto from "../../../ui/UserPhoto";
import ProgramTableEmptyState from "components/enterprise/components/dashboard/ProgramTableEmptyState";

// UI
import { Button, Pagination, Table } from "rsuite";

const { Column, HeaderCell, Cell } = Table;

export const EmptyState = () => {
  return (
    <>
      <h6>There is no participants in this program</h6>
      <p className="montserrat tw-text-center">
        It looks like you haven’t invited any participants.
        <br /> Start now by sending invites.
      </p>
    </>
  );
};

export default function UserRoleTable({
  searchKeyword,
  userProfilePath,
  portalUrl,
  program,
  handleRemoveUser,
  data,
  page,
  setPage,
}) {
  const dataLimit = 10;

  const filteredData = data?.filter((rowData) => {
    const { full_name, email, phone_no } = rowData;
    const lowerCaseKeyword = searchKeyword.toLowerCase();

    return (
      (full_name && full_name.toLowerCase().includes(lowerCaseKeyword)) ||
      (email && email.toLowerCase().includes(lowerCaseKeyword)) ||
      (phone_no && phone_no.toLowerCase().includes(lowerCaseKeyword))
    );
  });

  const paginatedData = data?.slice((page - 1) * dataLimit, page * dataLimit);

  return (
    <>
      <Table
        data={searchKeyword ? filteredData : paginatedData}
        wordWrap="break-word"
        className="tw-w-full tw-mt-4 tw-mb-2 tw-text-sm tw-text-[#222222]"
        fillHeight
        rowHeight={82}
        affixHorizontalScrollbar
        renderEmpty={() => <ProgramTableEmptyState><EmptyState/></ProgramTableEmptyState>}
      >
        <Column verticalAlign="middle" align="center" width={100}>
          <HeaderCell className="tw-text-sm tw-text-[#222222]">
            Photo
          </HeaderCell>
          <Cell>{(rowData) => <UserPhoto user={rowData} />}</Cell>
        </Column>
        <Column
          verticalAlign="middle"
          align="center"
          minWidth={200}
          flexGrow={1}
        >
          <HeaderCell className="tw-text-sm tw-text-[#222222]">Name</HeaderCell>
          <Cell>
            {(rowData) => (
              <Button
                href={`${userProfilePath}/${rowData.id}`}
                target="_blank"
                appearance="link"
              >
                {rowData.full_name}
              </Button>
            )}
          </Cell>
        </Column>
        <Column
          verticalAlign="middle"
          align="center"
          minWidth={250}
          flexGrow={1}
        >
          <HeaderCell className="tw-text-sm tw-text-[#222222]">
            Email
          </HeaderCell>
          <Cell dataKey="email" />
        </Column>
        <Column verticalAlign="middle" align="center" width={150}>
          <HeaderCell className="tw-text-sm tw-text-[#222222]">
            Phone Number
          </HeaderCell>
          <Cell>{(rowData) => rowData.phone_no ?? "-"}</Cell>
        </Column>
        <Column
          verticalAlign="middle"
          align="center"
          minWidth={160}
          flexGrow={1}
        >
          <HeaderCell className="tw-text-sm tw-text-[#222222]">
            Actions
          </HeaderCell>
          <Cell>
            {(rowData) => (
              <ActionButtons
                rowData={rowData}
                portalUrl={portalUrl}
                program_id={program.id}
                handleRemoveUser={handleRemoveUser}
              />
            )}
          </Cell>
        </Column>
      </Table>
      <Pagination
        prev
        next
        ellipsis
        boundaryLinks
        maxButtons={3}
        size="sm"
        layout={["pager", "|", "skip"]}
        total={data?.length}
        limit={dataLimit}
        activePage={page}
        onChangePage={setPage}
      />
    </>
  );
}
