import React, { useEffect, useState } from "react";
import { Divider, Loader, Modal, Nav, Rate } from "rsuite";
import { PortalApi } from "../../../util/portal_api";

const RatingDetailsModal = ({ openModal, handleClose, id, group }) => {
  const [loading, setLoading] = useState(false);
  const [menteeReview, setmenteeReview] = useState([]);
  const [mentorReview, setmentorReview] = useState([]);
  const [activeKey, setActiveKey] = useState("1");

  useEffect(() => {
    setLoading(true);

    if (id) {
      PortalApi({
        method: "get",
        path: "/mentorship_ratings/booking_reviews",
        options: { booking_id: id },
        success: ({ data }) => {
          setmenteeReview(data.mentee);
          setmentorReview(data.mentor);
          setLoading(false);
        },
      });
      return () => {
        setActiveKey("1");
      };
    }
  }, [id]);

  const display = (activeKey) => {
    const obj = {
      1: { user: "mentee", data: menteeReview },
      2: { user: "mentor", data: mentorReview },
    };

    return obj[activeKey];
  };

  const showDisplay = display(activeKey);

  const feedbackList = (feedback, idx) => {
    const list = {
      stars: (
        <div key={`stars-${idx}`} className="tw-my-6">
          <h6>{feedback.title}</h6>
          <p className="montserrat tw-text-sm">{feedback.description}</p>
          <Rate value={feedback.value} />
        </div>
      ),
      text: (
        <div key={`text-${idx}`} className="tw-my-6">
          <h6>{feedback.title}</h6>
          <p className="montserrat tw-text-sm">{feedback.description}</p>
          <p className="montserrat">{feedback.text}</p>
        </div>
      ),
      options: (
        <div key={`options-${idx}`} className="tw-my-6">
          <h6>{feedback.title}</h6>
          <p className="montserrat tw-text-sm">{feedback.description}</p>
          <p className="montserrat">{feedback.options}</p>
        </div>
      ),
    };

    return list[feedback.category];
  };

  const feedbackDisplay = (data) => {
    if (data) {
      return (
        <div>
          {data.map((review) => (
            <>
              {review.submitted && (
                <p className="montserrat tw-my-6 tw-text-sm">
                  Review submitted on {review.submitted}
                </p>
              )}
              <div className="tw-my-6">
                {review.feedback.length > 0 ? (
                  review.feedback.map((feedback, idx) =>
                    feedbackList(feedback, idx)
                  )
                ) : (
                  <p className="montserrat tw-py-6 tw-text-center">
                    This user has not rated.
                  </p>
                )}
              </div>
            </>
          ))}
        </div>
      );
    }
  };

  return (
    <>
      <Modal
        size="md"
        backdrop={"static"}
        open={openModal}
        onClose={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="tw-text-xl tw-font-medium">
            Rating Details
          </Modal.Title>
        </Modal.Header>
        <Divider className="tw-absolute tw-w-full tw-left-0 tw-mt-4 tw-bg-gray-300" />
        <Modal.Body className="tw-py-5 tw-my-5">
          <Nav
            appearance="subtle"
            activeKey={activeKey}
            onSelect={setActiveKey}
          >
            <Nav.Item eventKey="1">{group?.mentee?.full_name}</Nav.Item>
            <Nav.Item eventKey="2">{group?.mentor?.full_name}</Nav.Item>
          </Nav>
          {feedbackDisplay(showDisplay.data)}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RatingDetailsModal;