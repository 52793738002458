import React from "react";

const getMenteeNames = (menteeParticipants) => {
  return `"${menteeParticipants.map((mentee) => mentee.full_name).join(", ")}"`;
};

const getPairingHealthScore = (pairingHealth) => {
  const scoreMap = {
    1: "Good",
    2: "Fair",
  };

  return scoreMap[pairingHealth] || "Poor";
};

export function getHeaderAndCellData(data, activeKey) {
  const dataMappings = {
    "1": {
      headerData: [
        "Mentee",
        "Confirmed Session",
        "Completed Session",
        "% Completed Session",
        "Mentee Feedback",
        "% Mentee Feedback",
        "% Sessions Fulfillment",
        "Avg. Rating (by mentors)",
      ],
      cellData: data.overviewPairing.map((rowData) => [
        rowData.mentee_participants.full_name,
        rowData.user_confirmed_bookings_count,
        rowData.user_completed_bookings_count,
        rowData.user_completed_bookings_count > 0
          ? rowData.user_completed_bookings_percentage
          : "-",
        rowData.user_rated_percentage > 0 ? rowData.user_rated_count : "-",
        rowData.user_rated_percentage > 0 ? rowData.user_rated_percentage : "-",
        rowData.user_session_fulfillment > 0
          ? rowData.user_session_fulfillment
          : "-",
        rowData.average_ratings_score > 0 ? rowData.average_ratings_score : "-",
      ]),
    },
    "2": {
      headerData: [
        "Mentee",
        "Mentor",
        "Pairing Health",
        "Confirmed Sessions",
        "Completed Sessions",
        "Mentee Feedback",
        "Mentor Feedback",
        "Avg. Rating",
      ],
      cellData: data.personalPairing.map((rowData) => [
        rowData.mentee_participants.full_name,
        rowData.mentor_participants.full_name,
        getPairingHealthScore(rowData.pairing_health_score),
        rowData.confirmed_bookings_count,
        rowData.completed_bookings_count,
        rowData.completed_bookings_count > 0 ? rowData.mentee_rated_count : "-",
        rowData.completed_bookings_count > 0 ? rowData.mentor_rated_count : "-",
        rowData.average_ratings_score > 0 ? rowData.average_ratings_score : "-",
      ]),
    },
    "3": {
      headerData: [
        "Group Name",
        "Mentee",
        "Mentor",
        "Pairing Health",
        "Confirmed Sessions",
        "Completed Sessions",
        "% Mentee Feedback",
        "% Mentor Feedback",
        "Avg. Rating",
      ],
      cellData: data.groupPairing.map((rowData) => [
        rowData.name ? rowData.name : `Group #${rowData.id}`,
        getMenteeNames(rowData.mentee_participants),
        rowData.mentor_participants.full_name,
        getPairingHealthScore(rowData.pairing_health_score),
        rowData.confirmed_bookings_count,
        rowData.completed_bookings_count,
        rowData.completed_bookings_count > 0 ? rowData.mentee_rated_count : "-",
        rowData.completed_bookings_count > 0 ? rowData.mentor_rated_count : "-",
        rowData.average_ratings_score > 0 ? rowData.average_ratings_score : "-",
      ]),
    },
  };

  return dataMappings[activeKey] || { headerData: [], cellData: [] };
}
