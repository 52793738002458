import React from "react";
import { Button, Divider, Modal } from "rsuite";

const DeletionModal = (props) => {
  const { open, handleModalClose, clickHandler, title, bodyMessage } = props;

  return (
    <Modal
      backdrop="static"
      open={open}
      size="xs"
      className="tw-top-1/4"
      onClose={handleModalClose}
    >
      <Modal.Header>
        <Modal.Title className="tw-text-xl tw-font-medium">{title}</Modal.Title>
        <Divider className="tw-absolute tw-w-full tw-left-0 tw-mt-4 tw-bg-gray-300" />
      </Modal.Header>
      <Modal.Body className="tw-py-8">
        <div className="tw-text-center">{bodyMessage}</div>
      </Modal.Body>
      <Divider className="tw-absolute tw-w-full tw-left-0 tw-m-0 tw-bg-gray-300" />
      <Modal.Footer className="tw-pt-5">
        <Button appearance="ghost" onClick={handleModalClose}>
          Cancel
        </Button>
        <Button color="red" appearance="primary" onClick={clickHandler}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeletionModal;