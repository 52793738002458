import React from "react";
import { useFormContext } from "react-hook-form";

import { Divider, Form } from "rsuite";

import PortalLogoUploaderV2 from "../../../setup/PortalLogoUploaderV2";
import { FormInputText } from "../../../../form/default/FormInputs";

import { usePortal } from "../../../../../contexts/portal";
import PhotoUploader from "../../components/BannerUploader";
import FormSubmitButton from "./FormSubmitButton";

const PageCustomizationForm = () => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { program } = usePortal();

  return (
    <div>
      <Form>
        <div className="tw-flex tw-flex-row tw-justify-between tw-items-center">
          <p className="montserrat">Sign up Page Customization</p>
          <FormSubmitButton />
        </div>

        <div className="tw-flex tw-flex-row tw-gap-6 tw-mt-4">
          <Form.Group>
            <Form.ControlLabel>Vertical Banner</Form.ControlLabel>
            <PhotoUploader
              imageUrl={program?.white_label?.signup_vertical_banner_url}
              target={"white_label.sign_up_vertical_banner"}
              name={"white_label.sign_up_vertical_banner"}
              aspectRatio={1}
              setValue={setValue}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Sign up Logo</Form.ControlLabel>
            <PortalLogoUploaderV2
              name={"white_label.sign_up_logo"}
              imageUrl={program?.white_label?.sign_up_logo_url}
              control={control}
              errors={errors}
              setValue={setValue}
            />
          </Form.Group>
        </div>
        <Form.Group>
          <FormInputText
            name={"white_label.sign_up_logo_link"}
            label={"Sign up Logo URL"}
            sublabel={"This hyperlink will be added to your logo."}
            placeholder={"https://www.example.com"}
            control={control}
            errors={errors}
          />
        </Form.Group>
        <Divider />
        <div className="tw-flex tw-flex-row tw-justify-between tw-items-center">
          <p className="montserrat">Sign in Page Customization</p>
          <FormSubmitButton />
        </div>
        <div className="tw-flex tw-flex-row tw-gap-6 tw-mt-4">
          <Form.Group>
            <Form.ControlLabel>Vertical Banner</Form.ControlLabel>
            <PhotoUploader
              imageUrl={program?.white_label?.signup_vertical_banner_url}
              target={"white_label.sign_in_vertical_banner"}
              name={"white_label.sign_in_vertical_banner"}
              aspectRatio={1}
              setValue={setValue}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Sign in Logo</Form.ControlLabel>
            <PortalLogoUploaderV2
              name={"white_label.sign_in_logo"}
              imageUrl={program?.white_label?.sign_in_logo_url}
              control={control}
              errors={errors}
              setValue={setValue}
            />
          </Form.Group>
        </div>
        <Form.Group>
          <FormInputText
            name={"white_label.sign_in_logo_link"}
            label={"Sign in Logo URL"}
            sublabel={"This hyperlink will be added to your logo."}
            placeholder={"https://www.example.com"}
            control={control}
            errors={errors}
          />
        </Form.Group>
        <Divider />
      </Form>
    </div>
  );
};

export default PageCustomizationForm;
