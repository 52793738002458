// React
import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";

// FutureLab
import { PlatformType } from "../../../../../contexts/mentor_profile";
import translateText from "../../../../../util/translateText";
import MenteeInterest from "./MenteeInterest";
import MenteeImpact from "./MenteeImpact";
import Badges from "../../../shared/Badges";
import Reviews from "../../../shared/Reviews";
import Goals from "../../../shared/Goals";
import SocialMedia from "../../../shared/SocialMedia";
import MenteeActionCard from "../portal/MenteeActionCard";

// UI
import { Affix, Col, Divider, FlexboxGrid, Nav } from "rsuite";

export default function MenteeProfileBody({ convertText, currentUser }) {
  const { mentee, type } = useOutletContext<any>();
  const {
    reviews,
    interested_companies,
    interested_industries,
    goals,
    badges,
    info,
    mentoring_sessions_count,
    programs_joined_count,
  } = mentee;
  const [activeKey, setActiveKey] = useState("1");

  const isMenteeImpactPresent: boolean =
    !!mentoring_sessions_count || !!programs_joined_count;

  return (
    <div>
      <FlexboxGrid justify="center" className="tw-my-7">
        <FlexboxGrid.Item
          as={Col}
          colspan={22}
          xs={22}
          md={14}
          lg={12}
          className="tw-h-full tw-pr-16 sm:tw-pr-0"
        >
          <Nav
            appearance="subtle"
            justified
            activeKey={activeKey}
            onSelect={setActiveKey}
            className="tw-mb-5"
          >
            <Nav.Item eventKey="1" className="tw-justify-center tw-font-medium">
              {translateText(convertText, "Overview")}
            </Nav.Item>
            {reviews?.length > 0 && (
              <Nav.Item
                eventKey="2"
                className="tw-justify-center tw-font-medium"
              >
                Reviews
              </Nav.Item>
            )}
          </Nav>
          <div>
            {activeKey === "1" && (
              <>
                {(interested_companies.length || interested_industries.length) >
                  0 && (
                  <>
                    <MenteeInterest convertText={convertText} />
                    <Divider />
                  </>
                )}
                {goals.length > 0 && (
                  <>
                    <Goals convertText={convertText} user={mentee} />
                    <Divider />
                  </>
                )}
                {isMenteeImpactPresent && (
                  <MenteeImpact convertText={convertText} />
                )}
              </>
            )}

            {activeKey === "2" && <Reviews user={mentee} />}
          </div>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          as={Col}
          colspan={22}
          xs={22}
          md={6}
          lg={6}
          className="tw-h-full sm:tw-hidden"
        >
          {badges?.length > 0 && type === PlatformType.FutureLab && (
            <Badges user={mentee} />
          )}
          {(info?.linkedin_url || info?.instagram_url) && (
            <Affix top={70}>
              {type === PlatformType.Portal && currentUser.id !== mentee.id && (
                <MenteeActionCard convertText={convertText} />
              )}
              <SocialMedia convertText={convertText} user={mentee} />
            </Affix>
          )}
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </div>
  );
}
