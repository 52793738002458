import React, { useEffect, useState } from "react";
import { Divider, FlexboxGrid, Modal, Table } from "rsuite";
const { Column, HeaderCell, Cell } = Table;

import RatingDetailsModal from "./RatingDetailsModal";
import { PortalApi } from "../../../util/portal_api";
import { scoreCalculator, UserInfo } from "../PairingTable";

const RatingOverviewModal = ({ show, closeModal, group, id }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [bookingId, setBookingId] = useState(null);

  useEffect(() => {
    setLoading(true);

    if (id) {
      PortalApi({
        method: "get",
        path: "/mentorship_ratings/booking_list",
        options: { group_id: id },
        success: ({ data }) => {
          setData(data.reverse());
          setLoading(false);
        },
      });
      return () => {
        setData([])
      }
    }
  }, [id]);

  const handleRatingDetailModal = (booking_id) => {
    setOpenModal(true);
    setBookingId(booking_id);
  };

  const handleCloseRatingDetailModal = () => {
    setOpenModal(false);
    setBookingId(null);
  };

  return (
    <>
      <Modal size="md" backdrop={"static"} open={show} onClose={closeModal} overflow>
        <Modal.Header>
          <Modal.Title className="tw-text-xl tw-font-medium">
            Rating Overview
          </Modal.Title>
        </Modal.Header>
        <Divider className="tw-absolute tw-w-full tw-left-0 tw-mt-4 tw-bg-gray-300" />
        <Modal.Body className="tw-py-5 tw-my-5">
          <FlexboxGrid className="tw-mb-6">
            {group && (
              <>
                <FlexboxGrid.Item colspan={12}>
                  <span className="tw-text-xs tw-mb-2">Mentee</span>
                  <UserInfo user={group.mentee} />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={12}>
                  <span className="tw-text-xs tw-mb-2">Mentor</span>
                  <UserInfo user={group.mentor} />
                </FlexboxGrid.Item>
              </>
            )}
          </FlexboxGrid>

          <Table
            data={data}
            loading={loading}
            shouldUpdateScroll
            minHeight={300}
            autoHeight
          >
            <Column verticalAlign="middle" width={100} align="center">
              <HeaderCell>Session</HeaderCell>
              <Cell>
                {(rowData) => (
                  <span className="tw-text-sm">{rowData.session}</span>
                )}
              </Cell>
            </Column>

            <Column verticalAlign="middle" width={200} align="center">
              <HeaderCell>Completed on</HeaderCell>
              <Cell>
                {(rowData) => (
                  <span className="tw-text-sm">{rowData.completed_on}</span>
                )}
              </Cell>
            </Column>

            <Column verticalAlign="middle" width={150} align="center">
              <HeaderCell>Mentee Rating (Avg)</HeaderCell>
              <Cell>
                {(rowData) => (
                  <span className="tw-text-sm">
                    {rowData.mentee_score > 0
                      ? scoreCalculator(rowData.mentee_score)
                      : rowData.mentee_score}
                  </span>
                )}
              </Cell>
            </Column>

            <Column verticalAlign="middle" width={200} align="center">
              <HeaderCell>Mentor Rating (Avg)</HeaderCell>
              <Cell>
                {(rowData) => (
                  <span className="tw-text-sm">
                    {rowData.mentor_score > 0
                      ? scoreCalculator(rowData.mentor_score)
                      : rowData.mentor_score}
                  </span>
                )}
              </Cell>
            </Column>

            <Column verticalAlign="middle" fixed="right" align="center">
              <HeaderCell>...</HeaderCell>
              <Cell dataKey="mentor_rating">
                {(rowData) => (
                  <a
                    onClick={() => handleRatingDetailModal(rowData.booking_id)}
                    className="tw-text-sm"
                  >
                    View details
                  </a>
                )}
              </Cell>
            </Column>
          </Table>
        </Modal.Body>
      </Modal>
      <RatingDetailsModal
        openModal={openModal}
        handleClose={handleCloseRatingDetailModal}
        id={bookingId}
        group={group}
      />
    </>
  );
};

export default RatingOverviewModal;