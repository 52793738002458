import React, { useEffect } from "react";
import { FaUser } from "react-icons/fa";
import { useTable } from "react-table";
import api from "axios";
import { Button, Input } from "rsuite";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, InputPicker } from "rsuite";

const axios = api.create({
  withCredentials: true,
});

type Goal = {
  id: number;
  name: string;
  mentor_label: string;
  icon_key: string;
  image_url: string;
  status?: boolean;
};

interface GoalsProps {
  goals: Goal[];
  programGoals: Goal[];
}

const cols = [
  {
    Header: "No",
    id: "No",
    Cell: ({ row }: any) => row.index + 1,
  },
  {
    Header: "Service Name",
    accessor: "mentor_label",
  },
  // {
  //   Header: "Mentee Goal Name",
  //   accessor: "name",
  // },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ value }: any) => {
      const title = value ? "Active" : "Inactive";
      const color = value
        ? "tw-text-green-500 tw-font-bold"
        : "tw-text-red-500 tw-font-bold";

      return <span className={color}>{title}</span>;
    },
  },
  {
    Header: "Action",
    id: "Action",
    accessor: "status",
    Cell: ({ value, toggleActive, row: { original } }: any) => {
      const isActive = value;
      const [loading, setLoading] = React.useState(false);

      const handleClick = async () => {
        setLoading(true);
        await toggleActive(original.id, value);
        setLoading(false);
      };

      return (
        <div className="tw-flex tw-gap-2">
          <Button
            onClick={handleClick}
            className={
              !isActive
                ? "tw-bg-blue-500 tw-text-white tw-p-2 tw-rounded"
                : "tw-text-red-500 tw-p-2 tw-rounded border tw-border-red-500"
            }
            loading={loading}
            disabled={loading}
            appearance={!isActive ? "primary" : "ghost"}
          >
            {!isActive ? "Activate" : "Deactivate"}
          </Button>
        </div>
      );
    },
  },
];

const Goals: React.FC<React.PropsWithoutRef<GoalsProps>> = (props) => {
  const [programGoals, setProgramGoals] = React.useState<Goal[]>(
    props.programGoals || []
  );
  const [goals, setGoals] = React.useState<Goal[]>(props.goals || []);
  const [addMode, setAddMode] = React.useState(false);

  const data = React.useMemo(() => programGoals, [programGoals]);
  const columns = React.useMemo(() => cols, []);

  const refetch = async () => {
    try {
      const { data } = await axios.get("goals", {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      });
      setProgramGoals(data.program_goals);
      setGoals(data.goals);
    } catch (error) {
      console.error(error);
    }
  };

  const toggleActive = async (id: number, status: boolean) => {
    try {
      await axios.put(`goals/${id}`, {
        enabled: !status,
      });
      await refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const tableInstance = useTable({ columns, data, toggleActive });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <div>
      <h2 className="tw-flex tw-items-center tw-gap-2">
        <FaUser />
        <span>Mentor Services</span>
      </h2>
      <table {...getTableProps()} className="tw-w-full tw-mt-4">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="tw-bg-gray-200"
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className="tw-p-2 tw-text-left"
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className="tw-border-b tw-border-gray-200"
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className="tw-p-2 tw-text-left"
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          <AddRow
            addMode={addMode}
            setAddMode={setAddMode}
            goals={goals}
            refetch={refetch}
          />
        </tbody>
      </table>
    </div>
  );
};

function AddRow({ addMode = false, setAddMode, goals = [], refetch }) {
  return (
    <>
      {addMode && (
        <AddRowFields
          setAddMode={setAddMode}
          options={goals.map((v) => ({
            label: v.mentor_label,
            value: v,
          }))}
          refetch={refetch}
        />
      )}
      <tr>
        <td colSpan={5} className="tw-text-center tw-p-4">
          <Button
            onClick={() => setAddMode(!addMode)}
            className="tw-p-2 tw-rounded tw-block tw-w-full"
            appearance={!addMode ? "primary" : "ghost"}
            color={addMode ? "red" : "blue"}
          >
            {addMode ? "Cancel" : "Add New Goal"}
          </Button>
        </td>
      </tr>
    </>
  );
}

const schema = yup.object().shape({
  id: yup.number().optional().nullable(),
  // name: yup.string().required("Name is required"),
  mentor_label: yup.string().required("Mentor label is required"),
});
function AddRowFields({ addMode = false, setAddMode, options = [], refetch }) {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const exist = !!watch("id");

  const onSubmit = async (data) => {
    try {
      const { data: goal } = await axios.post("goals", {
        ...data,
        name: data.mentor_label,
      });
      setAddMode(false);
      refetch();
    } catch (error) {
      console.error(error);
    } finally {
      setAddMode(false);
    }
  };

  const onErrors = (errors) => {
    console.error(errors);
    alert("Please fill in the required fields");
  };

  return (
    <tr>
      <td colSpan={5} className="tw-p-4">
        <form onSubmit={handleSubmit(onSubmit, onErrors)}>
          <div className="tw-flex tw-flex-col tw-gap-4">
            <Form.Group>
              <Form.ControlLabel
                htmlFor="mentor_label"
                className="tw-text-left tw-font-bold tw-text-gray-600"
              >
                Service Name
              </Form.ControlLabel>
              <Controller
                control={control}
                name="mentor_label"
                render={({ field }) => (
                  <InputPicker
                    creatable
                    disabled={isSubmitting}
                    block
                    data={options}
                    value={field.value}
                    onCreate={(val) => field.onChange(val)}
                    onChange={(value) => {
                      if (typeof value === "string") {
                        return field.onChange(value);
                      }
                      const id = value?.id;
                      setValue("id", id || null);
                      setValue("name", value?.name);
                      field.onChange(value?.mentor_label);
                    }}
                    placeholder={field.value || "Select Service"}
                  />
                )}
              />
            </Form.Group>
            {/** 
            <Form.Group>
              <Form.ControlLabel
                htmlFor="name"
                className="tw-text-left tw-font-bold tw-text-gray-600"
              >
                Mentee Goal Name
              </Form.ControlLabel>
              <input
                type="text"
                name="name"
                placeholder="Cv Review, Interview Prep, etc."
                className="tw-p-2 tw-rounded tw-border tw-border-gray-300"
                disabled={exist || isSubmitting}
                {...register("name")}
              />
            </Form.Group>
            */}
            <Button
              type="submit"
              className="tw-p-2 tw-rounded tw-w-full"
              appearance="primary"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              Add Goal
            </Button>
          </div>
        </form>
      </td>
    </tr>
  );
}

export default Goals;
