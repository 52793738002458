import React from "react";
import RoleCard from "./RoleCard";
import { usePortal } from "../../../contexts/portal";

const RenderRoles = () => {
  const { program } = usePortal();
  const roles = program?.available_roles || [];

  return (
    <div className="tw-grid tw-grid-cols-1 tw-gap-5 tw-mt-8" data-tab="0">
      {roles.map((role) => (
        <RoleCard key={role.id} {...role} />
      ))}
    </div>
  );
};

export default RenderRoles;
