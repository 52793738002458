// React
import React, { forwardRef, useState } from "react";

// FutureLab
import avatarPlaceholder from "../../../images/avatar_placeholder.png";
import truncateString from "../../../util/truncateString";
import translateText from "../../../util/translateText";
import { handleUserFollow } from "../../../util/handleUserFollow";

// UI
import { FaStar } from "react-icons/fa";
import {
  MdOutlineAutoAwesome,
  MdOutlineBusiness,
  MdOutlineBusinessCenter,
  MdOutlineDesignServices,
  MdOutlineForum,
  MdOutlineLocationOn,
} from "react-icons/md";
import {
  Button,
  FlexboxGrid,
  Message,
  Stack,
  Tooltip,
  Whisper,
  toaster,
} from "rsuite";

// 3rd Party
import mixpanel from "mixpanel-browser";

type UserCardsProps = {
  user: any;
  setUsers?: React.Dispatch<React.SetStateAction<any>>;
  setRecall?: React.Dispatch<React.SetStateAction<any>>;
  forMentee?: boolean;
  currentUser?: any;
  convertText: string[];
  location: string;
  program: boolean;
};

const UserCards = forwardRef<any, any>((props: UserCardsProps, ref) => {
  const {
    user,
    setUsers,
    setRecall,
    forMentee,
    currentUser,
    convertText,
    location = "Dashboard",
    program,
  } = props;
  const [isFollowing, setIsFollowing] = useState(user.is_following);

  const toggleFollow = async () => {
    try {
      await handleUserFollow({
        mentor: user,
        setIsFollowing,
        setUsers,
        onSuccess: (data) => {
          toaster.push(
            !data ? (
              <Message showIcon type="error">
                {`${translateText(
                  convertText,
                  "You are no longer following this mentor"
                )}.`}
              </Message>
            ) : (
              <Message showIcon type="success">
                {`${translateText(
                  convertText,
                  "You are following this mentor"
                )}.`}
              </Message>
            )
          );
          mixpanel.track("Clicked Follow Button", {
            Name: `${currentUser?.firstname} ${currentUser?.lastname}`,
            "User Type": currentUser?.user_type,
            Action: isFollowing ? "Unfollowing" : "Following",
            "Mentor Name": user.fullname,
            "Mentor Profession": user.info,
            Location: location,
          });
        },
        onFailure: () => {
          toaster.push(
            <Message showIcon type="error">
              An error occurred
            </Message>
          );
        },
      });
    } catch (error) {
      console.error("Error toggling follow:", error);
    } finally {
      if (setRecall) {
        setRecall(true);
      }
    }
  };

  const navigateToMentorProfile = () => {
    window.location.assign(`/mentor_profile/${user.slug}`);

    mixpanel.track("Clicked View Profile Button", {
      Name: currentUser?.full_name,
      "User Type": currentUser?.user_type,
      "Mentor Name": user.fullname,
      "Mentor Profession": user.info,
      Location: location,
    });
  };

  return (
    <Stack.Item key={user.id} className="user-wrapper">
      <div>
        <div className="tw-p-3 tw-relative">
          {user.is_new_matched && <span className="new-match-tag tw-w-28" />}
          <div
            className="tw-relative"
            style={{ borderRadius: "10px", overflow: "hidden" }}
          >
            <img
              ref={ref}
              src={user.photo || avatarPlaceholder}
              alt=""
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = avatarPlaceholder;
              }}
            />
            {(user?.fl_average_rating >= 4 || user?.new_mentor === true) && (
              <span
                className="tw-font-bold tw-ml-4 sm:tw-block sm:tw-ml-0 profile-image-tag"
                style={{ color: "#FFF" }}
              >
                {user?.fl_average_rating >= 4 ? (
                  <div className="tw-flex">
                    <FaStar size={20} color="#FFC757" className="tw-mr-1" />

                    <span className="tw-text-sm">
                      {`${user.fl_average_rating.toFixed(0)}.0`}
                    </span>
                  </div>
                ) : (
                  <>
                    <MdOutlineAutoAwesome className="font-size-5 tw-mb-1" />{" "}
                    {`New Mentor`}
                  </>
                )}
              </span>
            )}
            {user?.top_badges?.length > 0 && (
              <div className="profile-badges-tag tw-w-full tw-flex tw-justify-between tw-items-center">
                <span className="tw-text-sm tw-font-bold">Top badges</span>
                <span>
                  {user.top_badges.map((badge, idx) => (
                    <Whisper
                      placement="top"
                      controlId="control-id-hover"
                      trigger="hover"
                      speaker={<Tooltip>{badge.name}</Tooltip>}
                      key={idx}
                    >
                      <img
                        key={idx}
                        src={badge.image}
                        alt=""
                        className="tw-mr-1"
                        style={{ height: 23 }}
                      />
                    </Whisper>
                  ))}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="tw-w-full tw-px-4 tw-py-2">
          <div className="tw-mb-3">
            <h6 className="tw-text-base tw-m-0 tw-truncate">{user.fullname}</h6>
          </div>
          <div className="xs:tw-text-sm tw-h-48">
            <div className="tw-flex tw-my-3">
              <MdOutlineBusinessCenter
                size={22}
                className="tw-mr-2 tw-overflow-visible"
              />

              <span className="tw-line-clamp-2 tw-text-sm tw-font-medium">
                {user?.info?.job_title}
              </span>
            </div>
            <div className="tw-flex tw-my-3">
              <MdOutlineBusiness
                size={22}
                className="tw-mr-2 tw-overflow-visible"
              />

              <span className="tw-line-clamp-2 tw-text-sm tw-font-medium">
                {user?.info?.company}
              </span>
            </div>
            <div className="tw-flex tw-my-3">
              <MdOutlineDesignServices
                size={22}
                className="tw-mr-2 tw-overflow-visible"
              />

              <span className="tw-line-clamp-2 tw-text-sm tw-font-medium">
                {user?.goals.map((goal, index) => (
                  <span key={goal.id}>
                    {" "}
                    <span>{goal?.name}</span>
                    {index < user.goals.length - 1 && <span>, </span>}
                  </span>
                ))}
              </span>
            </div>
            <div className="tw-flex tw-my-3">
              <MdOutlineLocationOn
                size={22}
                className="tw-mr-2 tw-overflow-visible"
              />

              <span className="tw-truncate tw-text-sm tw-font-medium">
                {user?.country?.name}
              </span>
            </div>
          </div>

          {/* <FlexboxGrid.Item colspan={24}> */}
          <FlexboxGrid.Item
            className="tw-flex tw-pt-3"
            style={{ borderTop: "1px solid #E5E5EA" }}
          >
            {currentUser && !program && (
              <Button
                appearance="ghost"
                onClick={toggleFollow}
                className="tw-w-1/2 tw-mr-2"
              >
                {isFollowing ? "Unfollow" : "Follow"}
              </Button>
            )}

            {/* Track this button to get the number of user viewing a mentor profile for the dashboard */}
            <Button
              appearance="primary"
              onClick={navigateToMentorProfile}
              className={currentUser && !program ? "tw-w-1/2" : "tw-w-full"}
            >
              View Profile
            </Button>
          </FlexboxGrid.Item>
          {/* </FlexboxGrid.Item> */}

          {forMentee && (
            <>
              <div className="tw-flex tw-mb-2">
                <span className="tw-shrink-0">
                  <MdOutlineForum className="font-size-8" />
                </span>
                <span className="montserrat tw-inline font-size-4 tw-truncate">
                  {user.user_languages?.length > 0
                    ? user.user_languages.map(
                        (language, index) => (index ? ", " : "") + language.name
                      )
                    : "-"}
                </span>
              </div>

              {user.interests?.length > 0 && (
                <div>
                  <h6 className="font-size-5 mb-0">Interested Industries</h6>
                  <span className="tw-inline-flex montserrat font-size-4 tw-leading-7">
                    {truncateString(
                      `${user.interests
                        ?.slice(0, 3)
                        .map((interests) => " " + interests)}`,
                      80
                    )}
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Stack.Item>
  );
});
export default UserCards;
