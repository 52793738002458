// React
import React, { useEffect, useState } from "react";

// FutureLab
import { PortalApi } from "../util/portal_api";
import UserRecommendationModal from "./shared/UserRecommendationModal";

// 3rd Party
import mixpanel from "mixpanel-browser";

interface Mentor {
  photo: string;
  fullname: string;
  goals: MentorGoal[];
  info: MentorInfo;
  chat_link: string;
  profile_link: string;
  country: string;
}

interface MentorInfo {
  job_title: string;
  company: string;
}

interface MentorGoal {
  name: string;
}

const PortalMentorRecommendation = ({ programId, currentUser }) => {
  const [openModal, setOpenModal] = useState(true);
  const [mentors, setMentors] = useState<Mentor[] | null>();

  useEffect(() => {
    PortalApi({
      method: "get",
      options: { program_id: programId },
      path: "/dashboard/users/mentors",
      success: ({ data }) => {
        const selectedMentor =
          data.suggested.length > 0 ? data.suggested[0] : data.my_mentors[0];
        setMentors([selectedMentor]);
      },
    });
  }, [programId]);

  const openInbox = (chat_link) => chat_link && window.open(chat_link, "_self");

  const handleSeenPopup = () => {
    PortalApi({
      method: "post",
      options: { program_id: programId },
      path: "/dashboard/users/seen_recommendation",
    });
  };

  const handleCloseModal = (action = null, mentor) => {
    setOpenModal(false);
    handleSeenPopup();

    if (action === "view_profile") {
      mixpanel.track("Clicked View Profile on Portal Recommendation Pop Up", {
        Name: `${currentUser?.firstname} ${currentUser?.lastname}`,
        "User Type": currentUser?.user_type,
        "Mentor Name": mentor?.fullname,
        "Mentor Profession": mentor?.info,
      });

      return window.location.replace(mentor?.profile_link);

    } else if (action === "chat") {
      mixpanel.track("Clicked Chat on Portal Recommendation Pop Up", {
        Name: `${currentUser?.firstname} ${currentUser?.lastname}`,
        "User Type": currentUser?.user_type,
        "Mentor Name": mentor?.fullname,
        "Mentor Profession": mentor?.info,
      });

      return openInbox(mentor?.chat_link);

    } else {
      mixpanel.track("Close Portal Recommendation Pop Up", {
        Name: `${currentUser?.firstname} ${currentUser?.lastname}`,
        "User Type": currentUser?.user_type,
      });
    }

    return;
  };

  return (
    <UserRecommendationModal
      mentors={mentors}
      openModal={openModal}
      handleCloseModal={handleCloseModal}
    />
  );
};

export default PortalMentorRecommendation;
