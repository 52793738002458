// React
import React from "react";
import { useNavigate } from "react-router-dom";

// UI
import {
  Dropdown,
  IconButton,
  Message,
  Popover,
  Table,
  toaster,
  Toggle,
  Whisper,
} from "rsuite";
import { MdOutlineMoreHoriz } from "react-icons/md";

// 3rd Party
import { CopyToClipboard } from "react-copy-to-clipboard";

const { Column, HeaderCell, Cell } = Table;

interface ApplicationDataTableProps {
  handleToggle: (string) => void;
  setOpenModal: any;
  data: any;
  setApplicationId: any;
  loading: boolean;
}

const getApplicationUrl = (environment, rowData) => {
  const baseUrls = {
    production: "https://futurelab.my",
    staging: "https://futurelab.global",
    development: "http://lvh.me:3000",
  };

  const baseUrl = baseUrls[environment];
  return `${baseUrl}/main/programs/applications/${rowData.id}?ref=${rowData.link_ref}`;
};

const ApplicationDataTable = (
  applcationDataTableProps: ApplicationDataTableProps
) => {
  const { handleToggle, data, loading, setOpenModal, setApplicationId } =
    applcationDataTableProps;
  const navigate = useNavigate();

  return (
    <Table
      data={data}
      wordWrap
      autoHeight
      rowClassName="tw-text-sm"
      rowHeight={60}
      renderEmpty={() => (
        <p className="tw-text-center tw-mt-20 montserrat">
          You have no applications.
          <br />
          Start by creating a new application.
        </p>
      )}
      loading={loading}
      className="tw-my-6"
    >
      <Column
        align="left"
        verticalAlign="middle"
        minWidth={200}
        flexGrow={2}
        fixed
      >
        <HeaderCell>Application Name</HeaderCell>
        <Cell>
          {(rowData) => (
            <div>
              <a
                onClick={() => navigate(`./show/${rowData.id}`)}
                className="tw-text-sm"
              >
                {rowData.name}
              </a>
            </div>
          )}
        </Cell>
      </Column>

      <Column
        align="center"
        verticalAlign="middle"
        minWidth={100}
        flexGrow={1}
        fixed
        // sortable
      >
        <HeaderCell>Date Created</HeaderCell>
        <Cell dataKey="created_at" />
      </Column>

      <Column
        align="center"
        verticalAlign="middle"
        minWidth={100}
        flexGrow={1}
        fixed
      >
        <HeaderCell>User Type</HeaderCell>
        <Cell dataKey="user_type" />
      </Column>

      <Column
        align="center"
        verticalAlign="middle"
        minWidth={100}
        flexGrow={1}
        fixed
      >
        <HeaderCell>Applicants</HeaderCell>
        <Cell dataKey="total_applicants" />
      </Column>
      <Column
        align="center"
        verticalAlign="middle"
        minWidth={150}
        flexGrow={1}
        fixed
      >
        <HeaderCell>Application Link</HeaderCell>
        <Cell>
          {(rowData) => (
            <CopyToClipboard
              text={getApplicationUrl(process.env.RAILS_ENV, rowData)}
              onCopy={() =>
                toaster.push(
                  <Message showIcon type="success">
                    Copied to clipboard
                  </Message>
                )
              }
            >
              <a className="tw-text-sm">Copy Link</a>
            </CopyToClipboard>
          )}
        </Cell>
      </Column>

      <Column
        align="center"
        verticalAlign="middle"
        minWidth={100}
        flexGrow={1}
        fixed
      >
        <HeaderCell>Status</HeaderCell>
        <Cell>
          {(rowData) => (
            <div>
              <span>{rowData.published == true ? "Open" : "Closed"}</span>
              <Toggle
                checked={rowData.published}
                onChange={() => handleToggle(rowData.id)}
                className="tw-inline tw-ml-2"
              />
            </div>
          )}
        </Cell>
      </Column>

      <Column
        align="center"
        verticalAlign="middle"
        minWidth={100}
        flexGrow={1}
        fixed
      >
        <HeaderCell>
          <MdOutlineMoreHoriz />
        </HeaderCell>
        <Cell className="link-group">
          {(rowData) => (
            <Whisper
              placement="autoVerticalStart"
              trigger="click"
              speaker={(props, ref) => {
                const { className, left, top } = props;
                return (
                  <Popover
                    ref={ref}
                    className={className}
                    style={{ left, top }}
                    full
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => navigate(`edit/${rowData.id}`)}
                      >
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setOpenModal(true)}>
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Popover>
                );
              }}
              onClick={() => setApplicationId(rowData.id)}
            >
              <IconButton appearance="subtle" icon={<MdOutlineMoreHoriz />} />
            </Whisper>
          )}
        </Cell>
      </Column>
    </Table>
  );
};

export default ApplicationDataTable;
