import React from "react";
import { FaSearch } from "react-icons/fa";
import { Input, InputGroup } from "rsuite";

const SearchInput = ({ state }) => {
  return (
    <InputGroup inside className="tw-w-1/2 tw-mr-2 tw-inline-flex">
      <Input placeholder="Search name" onChange={(value) => state(value)} />
      <InputGroup.Button>
        <FaSearch />
      </InputGroup.Button>
    </InputGroup>
  );
};

export default SearchInput;