// React
import React, { useState, useEffect } from "react";

// FutureLab
import ApplicationClosed from "../components/ApplicationClosed";
import ApplicationSubmitted from "../components/ApplicationSubmitted";
import { PortalApi } from "../../../../util/portal_api";
import { FutureLabApi } from "../../../../util/api";
import ApplicationForm from "../components/ApplicationForm";
import { AnswerInput, ApplicantFormProps, ApplicantInput } from "../types";
import NetPromoterScore from "../../../NetPromoterScore";

// UI
import { Loader, Message, toaster } from "rsuite";

// 3rd Party
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import mixpanel from "mixpanel-browser";

const applicationFormAnswerSchema = yup.object({
  application_id: yup.number(),
  applicant_status: yup.string(),
  answers: yup.array().when("applicant_status", {
    is: (applicantStatus) => applicantStatus !== "draft",
    then: yup.array().of(
      yup.object({
        question_id: yup.number(),
        application_id: yup.number(),
        required: yup.bool(),
        answer: yup.string().when("required", {
          is: true,
          then: yup.string().trim().required("This field is required"),
          otherwise: yup.string().nullable().default(null),
        }),
      })
    ),
    otherwise: yup.array().of(
      yup.object({
        question_id: yup.number(),
        application_id: yup.number(),
        required: yup.bool(),
        answer: yup.string().nullable().default(null),
      })
    ),
  }),
});

const ApplicantForm = (props) => {
  const {
    applicationId,
    applicationStatusURL,
    currentUser,
    programId,
  } = props;
  const [application, setApplication] = useState<ApplicantFormProps>();
  const [program, setProgram] = useState<any>();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [openNpsModel, setOpenNpsModel] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    watch,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm<ApplicantInput>({
    resolver: yupResolver(applicationFormAnswerSchema),
  });

  useEffect(() => {
    PortalApi({
      method: "get",
      path: `/applicants/applicant_status`,
      options: { user_id: currentUser?.id, application_id: applicationId },
      success: ({ data }) => {
        setIsSubmitted(data);
      },
    });

    PortalApi({
      method: "get",
      path: `/programs`,
      options: { program_id: programId },
      success: ({ data }) => {
        setProgram(data);
      },
    });

    PortalApi({
      method: "get",
      path: `/applicants/applicant_form`,
      options: { application_id: applicationId },
      success: ({ data }) => {
        setApplication(data);
      },
    });
  }, []);

  const onSubmit = (data) => {
    let status = data.applicant_status === "applied";
    data.application_id = applicationId;

    PortalApi({
      method: "post",
      path: `/applicants/submission`,
      options: data,
      success: ({ data }) => {
        setIsSubmitted(status);
        setOpenNpsModel(true);
        toaster.push(
          <Message showIcon type="success">
            {data.message}
          </Message>
        );
      },
      error: ({ data }) => {
        toaster.push(
          <Message showIcon type="error">
            {data?.message}
          </Message>
        );
      },
    });
    mixpanel.track("Application Submitted", {
      "Application Title": application.name,
      "Application Type": application.user_type,
      "Applicant Name": `${currentUser?.firstname} ${currentUser?.lastname}`,
      "User Id": currentUser?.id,
      "User Type": currentUser?.user_type,
    });
  };

  useEffect(() => {
    if (application) {
      setValue(
        "answers",
        application?.questions.map((question) => ({
          question_id: question.id,
          application_id: question.application_id,
          required: question.required,
          answer: question.answer || "",
        })) as AnswerInput[]
      );
    }
  }, [application]);

  if (!application) return <Loader center content="Loading" />;

  return (
    <>
      {application?.published ? (
        <>
          {!isSubmitted ? (
            <ApplicationForm
              currentUser={currentUser}
              program={program}
              application={application}
              register={register}
              control={control}
              errors={errors}
              watch={watch}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              setValue={setValue}
            />
          ) : (
            <>
              <ApplicationSubmitted
                applicationStatusURL={applicationStatusURL}
                currentUser={currentUser}
                program={program}
              />
              {openNpsModel && (
                <NetPromoterScore
                  userNpsDetails={{
                    type: `program_application_nps#${applicationId}`,
                    text_type: "program_application",
                  }}
                  portalId={programId}
                  onPortal={{
                    enterpriseSlug: program?.enterprise?.slug,
                    programSlug: program?.slug,
                  }}
                />
              )}
            </>
          )}
        </>
      ) : (
        <ApplicationClosed program={program} />
      )}
    </>
  );
};

export default ApplicantForm;
