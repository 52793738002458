import React from 'react';
import { Button } from 'rsuite';

const LinkButton = ({ children, className = '', ...props }) => {
  return (
    <Button
      appearance="link"
      className={`tw-p-0 tw-underline ${className}`}
      {...props}
    >
      {children}
    </Button>
  );
};

export default LinkButton;
