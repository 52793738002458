import React, { useRef, useState } from "react";
import { Button, Form, Stack, Uploader } from "rsuite";

import { MdOutlineImage } from "react-icons/md";
import { Controller, useFormContext } from "react-hook-form";

import FieldError from "../../form/FieldError";
import { ErrorMessage } from "@hookform/error-message";

const EventBannerUploaderV2 = () => {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const [fileInfo, setFileInfo] = useState(
    getValues("event.photo") || undefined
  );
  const [fileError, setFileError] = useState(false);

  const uploaderRef = useRef(null);

  const previewFile = (file, callback) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleUpload = (file) => {
    // File size doesn't exceed 2MB - 2097152
    if (file?.blobFile.size > 2 * 1024 * 1024) {
      setFileError(true);
      return;
    }

    setFileError(false);
    setValue("event.photo", file.blobFile);
    previewFile(file.blobFile, (value) => {
      setFileInfo(value);
    });
  };

  return (
    <div className="tw-flex tw-flex-1 tw-items-center">
      <Form.Group className="tw-w-1/2">
        <Form.ControlLabel>Programme Banner</Form.ControlLabel>
        <Controller
          name="event.photo"
          control={control}
          render={({ field }) => (
            <>
              <Uploader
                accept=".jpg, .png"
                fileListVisible={false}
                listType="picture"
                onUpload={(file) => {
                  handleUpload(file);
                }}
                action=""
              >
                <button style={{ width: 300, height: 100 }} ref={uploaderRef}>
                  {event && fileInfo?.includes("https") ? (
                    <img src={fileInfo} width="100%" height="100%" />
                  ) : fileInfo ? (
                    <img src={fileInfo} width="100%" height="100%" />
                  ) : (
                    <MdOutlineImage className="tw-text-4xl" />
                  )}
                </button>
              </Uploader>
              {fileError && <FieldError>File exceeds 2MB</FieldError>}

              <ErrorMessage
                errors={errors}
                name={"event.photo"}
                as={FieldError}
              />
            </>
          )}
        />
      </Form.Group>

      <div className="tw-w-1/2 tw-mb-2 tw-px-4">
        <p className="montserrat tw-text-sm">
          Recommended size for banner upload
        </p>
        <ul>
          <li>1600px x 900px</li>
          <li>Accepted file types - *.jpg, *.png</li>
        </ul>
        <Button
          appearance="primary"
          className="tw-font-medium"
          onClick={() => uploaderRef.current.click()}
        >
          Upload
        </Button>
        {/* <PortalImageGuideModal isBanner /> */}
      </div>
    </div>
  );
};

export default EventBannerUploaderV2;
