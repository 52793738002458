import React from "react";

// UI
import { withContainer, FormWrapper, FormInput } from "../components";

import ForgotPasswordForm from "../components/forms/ForgotPasswordForm";

export const ForgotPassword = withContainer(() => {
  return (
    <div className="tw-flex tw-items-center tw-h-screen tw-w-1/2 sm:tw-w-full lg:tw-w-2/3">
      <FormWrapper
        title="Reset your password"
        footerText=""
        footerLink={{
          title: "Return to login",
          to: "/login",
        }}
      >
        <p className="montserrat">
          Fear not. We'll email you instructions to reset your password.
        </p>
        <ForgotPasswordForm />
      </FormWrapper>
    </div>
  );
});
