import React, { useEffect, useState } from "react";
import { Divider, Table } from "rsuite";
import { Button, Modal } from "rsuite";

const { HeaderCell, Cell, Column } = Table;

const EventMyTicketModal = ({ userTickets }) => {
  const [openModal, setOpenModal] = useState(false);
  const [tickets, setTickets] = useState(userTickets);

  useEffect(() => {
    setTickets(userTickets);

    return () => {
      setTickets(null);
    };
  }, [userTickets]);

  return (
    <>
      <div>
        <Button appearance="ghost" onClick={() => setOpenModal(true)} block>
          View my ticket
        </Button>
      </div>
      <div>
        <Modal open={openModal} size="md" onClose={() => setOpenModal(false)}>
          <Modal.Header>
            <Modal.Title className="tw-font-medium">
              Your tickets for this event
            </Modal.Title>
            <Divider className="tw-absolute tw-w-full tw-left-0 tw-mt-4 tw-bg-gray-300" />
          </Modal.Header>
          <Modal.Body className="tw-py-8">
            <Table
              data={tickets}
              wordWrap
              autoHeight
              rowClassName="tw-text-sm tw-w-full"
              rowHeight={60}
            >
              <Column
                align="left"
                verticalAlign="middle"
                minWidth={200}
                flexGrow={2}
              >
                <HeaderCell>Purchased at</HeaderCell>
                <Cell dataKey="paid_at" />
              </Column>
              <Column
                align="left"
                verticalAlign="middle"
                minWidth={150}
                flexGrow={2}
              >
                <HeaderCell>Ticket Name</HeaderCell>
                <Cell>{(rowData) => rowData.ticket.name}</Cell>
              </Column>
              <Column
                align="center"
                verticalAlign="middle"
                minWidth={150}
                flexGrow={2}
              >
                <HeaderCell>Quantity</HeaderCell>
                <Cell dataKey="amount" />
              </Column>
              <Column
                align="center"
                verticalAlign="middle"
                minWidth={150}
                flexGrow={2}
              >
                <HeaderCell>Total</HeaderCell>
                <Cell>{(rowData) => `RM${rowData.total_paid.toFixed(2)}`}</Cell>
              </Column>
            </Table>
          </Modal.Body>
          <Divider className="tw-absolute tw-w-full tw-left-0 tw-m-0 tw-bg-gray-300" />
          <Modal.Footer className="tw-pt-5">
            <Button appearance="primary" onClick={() => setOpenModal(false)}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default EventMyTicketModal;
