// React
import React from "react";
import { useOutletContext } from "react-router-dom";

// FutureLab
import translateText from "../../../../../util/translateText";
import menteesHelpedImg from "../../../../../images/mentorprofile-impact-mentee-helped.png";
import mentoringSessionImg from "../../../../../images/mentorprofile-impact-mentoring-sessions.png";
import programsJoinedImg from "../../../../../images/mentorprofile-impact-programme-joined.png";
import ActivityCard from "../../../shared/ActivityCard";
import { PlatformType } from "../../../../../contexts/mentor_profile";

export default function MenteeImpact({ convertText }) {
  const { mentee, type } = useOutletContext<any>();
  const {
    following_count: followingCount,
    mentoring_sessions_count: mentoringSessionsCount,
    programs_joined_count: programsJoinedCount,
  } = mentee;

  return (
    <div>
      <p className="tw-font-bold tw-text-base montserrat">
        {translateText(convertText, "Impact")}
      </p>
      <div className="tw-w-full tw-flex tw-flex-row tw-flex-wrap">
        <div className="tw-flex tw-flex-col tw-w-1/3 sm:tw-w-1/2">
          <ActivityCard
            convertText={convertText}
            img={mentoringSessionImg}
            count={mentoringSessionsCount}
            text="Mentoring Sessions"
          />
        </div>
        {type === PlatformType.FutureLab && (
          <>
            <div className="tw-flex tw-flex-col tw-w-1/3 sm:tw-w-1/2">
              <ActivityCard
                convertText={convertText}
                img={programsJoinedImg}
                count={programsJoinedCount}
                text="Programs Joined"
              />
            </div>
            <div className="tw-flex tw-flex-col tw-w-1/3 sm:tw-w-1/2">
              <ActivityCard
                convertText={convertText}
                img={menteesHelpedImg}
                count={followingCount}
                text="Mentor Followed"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
