import React, { useEffect, useRef } from "react";

import { ErrorMessage } from "@hookform/error-message";
import { Controller } from "react-hook-form";
import { Form } from "rsuite";
import ReactQuill, { Quill } from "react-quill";
import DOMPurify from "dompurify";

import "react-quill/dist/quill.snow.css";
import FieldError from "../form/FieldError";

// Link sanitizer - appends https if user doesn't include it.
const Link = Quill.import("formats/link");
Link.sanitize = (url) => {
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    return `https://${url}`;
  }
  return url;
};

// Controlled Rich Text Editor Forms
export const FormRichTextInput = ({
  name,
  control,
  label,
  errors,
  defaultValue = null,
}) => {
  return (
    <>
      {label && <Form.ControlLabel>{label}</Form.ControlLabel>}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <ReactQuill
            {...field}
            style={{ fontFamily: "Montserrat, sans-serif" }}
            className="montserrat tw-text-sm"
            placeholder="Description"
          />
        )}
      />
      <ErrorMessage errors={errors} name={name} as={FieldError} />
    </>
  );
};

// Convert Rich Text Editor saved string to HTML format.
export const RichTextEditorToHTML = ({ content, ...restProps }) => {
  const sanitizedHTML = DOMPurify.sanitize(content);
  const divRef = useRef(null);

  useEffect(() => {
    const range = document.createRange();
    range.selectNode(divRef.current);

    const fragment = range.createContextualFragment(sanitizedHTML);
    divRef.current.innerHTML = "";
    divRef.current.appendChild(fragment);

    // Apply font size to <p> tags
    const paragraphs = divRef.current.querySelectorAll("p");
    paragraphs.forEach((paragraph) => {
      paragraph.style.fontSize = "14px";
    });
  }, [sanitizedHTML]);

  return (
    <div
      ref={divRef}
      {...restProps}
    ></div>
  );
};
