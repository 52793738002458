import React from "react";
import { Form, TagPicker } from "rsuite";
import { FormInputText } from "../../form/default/FormInputs";
import { Controller, useFormContext } from "react-hook-form";
import { FormRichTextInput } from "../../ui/RichTextEditor";
import { ErrorMessage } from "@hookform/error-message";
import FieldError from "../../ui/FieldError";
import EventBannerUploaderV2 from "./EventBannerUploaderv2";

const EventInfoForm = ({ mentors = [] }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <h6>Details</h6>

      {/* Event Banner */}
      <EventBannerUploaderV2 />

      {/* WYSIWIG */}
      <Form.Group>
        <FormRichTextInput
          name="event.description"
          control={control}
          errors={errors}
          label={"Event Description"}
        />
      </Form.Group>

      <h6>Organizer</h6>
      <div className="tw-flex tw-flex-row">
        <Form.Group className="tw-mr-2 tw-flex-1">
          <FormInputText
            name="event.organizer_name"
            label={"Organizer name"}
            placeholder={"Organizer name"}
            control={control}
            errors={errors}
            className="tw-w-full"
          />
        </Form.Group>

        <Form.Group className="tw-ml-2 tw-flex-1">
          <FormInputText
            name="event.inquiry_email"
            label={"Organizer email address"}
            placeholder={"Organizer email address"}
            control={control}
            errors={errors}
            className="tw-w-full"
          />
        </Form.Group>
      </div>
      
      <Form.Group className="tw-pr-2">
        <FormInputText
          name="event.inquiry_number"
          label={"Organizer contact"}
          placeholder={"Organizer contact"}
          control={control}
          errors={errors}
          className="tw-w-1/2 tw-mr-2"
        />
      </Form.Group>

      <h6>Speaker</h6>
      You can add mentor(s) as a speaker for this event.
      <Form.Group>
        <p className="montserrat tw-text-sm">Choose a maximum of 5 mentors.</p>
        <Controller
          name={"event_speakers"}
          control={control}
          render={({ field }) => (
            <>
              <TagPicker
                {...field}
                data={mentors.map((data) => ({
                  value: data.value,
                  label: data.label,
                }))}
                size="lg"
                placement="autoVerticalStart"
                placeholder="Type your options"
                className="tw-w-full"
                creatable={false}
              />
              <ErrorMessage
                errors={errors}
                name={"event.event_speakers"}
                as={FieldError}
              />
            </>
          )}
        />
      </Form.Group>
    </>
  );
};

export default EventInfoForm;
