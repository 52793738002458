import React, { useEffect, useState } from "react";
import { Loader } from "rsuite";

const generateRandomLoadingMessage = () => {
  const loadingMessages = [
    "Loading",
    "Hold on tight",
    "Getting the necessary info",
    "Sit tight, loading in progress",
    "Just a moment, almost there",
    // Add more loading messages as needed
  ];

  const randomIndex = Math.floor(Math.random() * loadingMessages.length);
  return loadingMessages[randomIndex];
};

const GenericMsgLoader = (restProps) => {
  const [loadingMessage, setLoadingMessage] = useState(
    generateRandomLoadingMessage
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setLoadingMessage(generateRandomLoadingMessage);
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return <Loader {...restProps} content={loadingMessage} />;
};

export default GenericMsgLoader;
