// React
import React from "react";

// Futurelab
import applicationClosed from "../assets/application-closed.png";

// UI
import { FlexboxGrid, Col } from "rsuite";

const ApplicationClosed = (props) => {
  const { program } = props;

  return (
    <FlexboxGrid className="tw-pt-12" justify="center">
      <FlexboxGrid.Item colspan={24} className="tw-flex tw-justify-center">
        <img className="tw-w-28 tw-h-28 tw-mb-2" src={program?.photo_url} />
      </FlexboxGrid.Item>

      <FlexboxGrid.Item colspan={24} className="tw-flex tw-justify-center">
        <img className="tw-w-32 tw-h-32 tw-mb-4" src={applicationClosed} />
      </FlexboxGrid.Item>

      <FlexboxGrid justify="center">
        <FlexboxGrid.Item
          colspan={24}
          as={Col}
          xs={21}
          md={12}
          lg={10}
          xl={8}
          className="tw-text-center tw-pb-6 tw-text-gray-700"
        >
          <div className="tw-font-bold tw-text-lg tw-pb-3 tw-text-center tw-text-gray-700">
            Applications are now closed
          </div>
          <div>
            Sorry, we are no longer accepting applications for the program. Thank
            you for your interest. Stay tune for other opportunities from us!
          </div>
        </FlexboxGrid.Item>
        <div className="tw-fixed tw-bottom-0">
          {program?.support_email && (
            <p className="tw-text-sm tw-text-center">
              Need further assistance?
              <br />
              Contact{" "}
              <a href={`mailto:${program.support_email}`}>{program.support_email}</a>
            </p>
          )}
          <p className=" tw-text-sm tw-italic tw-text-center">
            Powered by FutureLab
          </p>
        </div>
      </FlexboxGrid>
    </FlexboxGrid>
  );
};

export default ApplicationClosed;
