import translateText from "./translateText";

const greeting = (convertText) => {
  const date = new Date();
  const hour = date.getHours();

  if (hour < 12) return translateText(convertText, "Good Morning");
  if (hour > 12 && hour < 17)
    return translateText(convertText, "Good Afternoon");
  else return translateText(convertText, "Good Evening");
};

export default greeting;
