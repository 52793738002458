import { createApiInstance } from "api/config";

const api = createApiInstance('portal')

export const checkProgramName = async (name: string, enterpriseId: number) => {
  return await api.get(`/programs/check_name`, {
    params: { name: name, enterprise_id: enterpriseId },
  });
};

export const getProgram = async (queryParams: string | number) => {
  return await api.get(`/programs`, {
    params: { id: queryParams },
  });
}

export const getEnterprise = async (id: number) => {
  return await api.get(`/enterprises/${id}`);
}
