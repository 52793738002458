import React from "react";
import { Route, Routes, BrowserRouter, Outlet } from "react-router-dom";
import {
  MentorProfileProvider,
  useMentorProfile,
} from "../contexts/mentor_profile";
import { UserProvider } from "../contexts/user";
import { VariablesProvider } from "../contexts/variables";
import AppContainer from "./AppContainer";
import MentorProfileContent from "./user_profile/mentor/MentorProfileContent";
import { Loader } from "rsuite";

const MentorProfile = ({ variables }) => {
  return (
    <BrowserRouter>
      <AppContainer>
        <Routes>
          <Route
            path="/mentor_profile/:hexId"
            element={<MentorProvider variables={variables} />}
          >
            <Route
              path="/mentor_profile/:hexId"
              element={
                <MentorProfileContent
                  convertText={variables.convertText}
                  showSurvey={variables.survey}
                />
              }
            />
          </Route>
        </Routes>
      </AppContainer>
    </BrowserRouter>
  );
};

function MentorProvider({ variables }) {
  return (
    <VariablesProvider variables={variables}>
      <UserProvider>
        <MentorProfileProvider>
          <RenderMentorProfile />
        </MentorProfileProvider>
      </UserProvider>
    </VariablesProvider>
  );
}

function RenderMentorProfile() {
  const { mentor, setMentor, refreshMentor, type } = useMentorProfile();

  if (!mentor) return <Loader center content="loading" />;
  return (
    <Outlet
      context={{
        mentor,
        setMentor,
        refreshMentor,
        type,
      }}
    />
  );
}

export default MentorProfile;
