// React
import React, { useEffect, useState } from "react";

// FutureLab
import { FutureLabApi } from "../../util/api";
import translateText from "../../util/translateText";
import TooltipHelper from "../ui/TooltipHelper";
import BookingCards from "./bookings/BookingCards";
import noBooking from "../../images/no_bookings.png";

// UI
import { Button, Nav } from "rsuite";

const DashboardBookings = ({ convertText, currentUser }) => {
  const [upcoming, setUpcoming] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [missed, setMissed] = useState([]);
  const [cancelled, setCancelled] = useState([]);
  const [pending, setPending] = useState([]);
  const [activeKey, setActiveKey] = useState("1");

  useEffect(() => {
    FutureLabApi({
      method: "get",
      path: `/dashboard/bookings/main`,
      success: ({ data }) => {
        setUpcoming(data.upcoming);
        setCompleted(data.completed);
        setMissed(data.missed);
        setCancelled(data.cancelled);
        setPending(data.pending);
      },
    });
  }, []);

  const updateUpcoming = (booking_id) => {
    const booking = pending.find((e) => e.id === booking_id);
    if (booking) {
      setPending(pending.filter((e) => e.id !== booking_id));
      setUpcoming([booking, ...upcoming]);
    }
  };

  const activeDisplay = (activeKey) => {
    const obj = {
      1: {
        data: upcoming,
        category: "upcoming",
        button: true,
        empty_text: `${translateText(
          convertText,
          "There are no upcoming bookings made from you at the moment."
        )}`,
      },
      2: {
        data: completed,
        category: "completed",
        empty_text: `${translateText(
          convertText,
          "You have no completed sessions."
        )}`,
      },
      3: {
        data: pending,
        category: "pending",
        empty_text: `${translateText(
          convertText,
          "You have no pending sessions."
        )}`,
      },
      4: {
        data: missed,
        category: "missed",
        empty_text: `${translateText(
          convertText,
          "You have no missed sessions."
        )}`,
      },
      5: {
        data: cancelled,
        category: "cancelled",
        empty_text: `${translateText(
          convertText,
          "You have no cancelled sessions."
        )}`,
      },
    };
    return obj[activeKey];
  };

  const dataitems = activeDisplay(activeKey);

  return (
    <>
      <div className="tw-w-full sm:tw-block">
        <div className="sm:tw-w-full sm:tw-mb-4 tw-mb-5 tw-flex tw-justify-between">
          <div className="tw-inline-flex tw-items-center">
            <p className="section-header montserrat">
              {translateText(convertText, "Bookings")}
            </p>
            <TooltipHelper
              tip={
                "View all the mentoring sessions that you have scheduled and/or missed"
              }
            />
          </div>
        </div>
        <Nav
          appearance="subtle"
          activeKey={activeKey}
          onSelect={setActiveKey}
          justified
          className="tw-mb-5 xs:tw-overflow-x-auto no-scrollbar"
        >
          <Nav.Item eventKey="1" className="tw-flex tw-justify-center tw-min-w-[140px]">
            {translateText(convertText, "Upcoming")} ({upcoming.length})
          </Nav.Item>
          <Nav.Item eventKey="2" className="tw-flex tw-justify-center tw-min-w-[140px]">
            {translateText(convertText, "Completed")} ({completed.length})
          </Nav.Item>
          <Nav.Item eventKey="3" className="tw-flex tw-justify-center tw-min-w-[140px]">
            {translateText(convertText, "Pending")} ({pending.length})
          </Nav.Item>
          {/* <Nav.Item eventKey="4" className="tw-text-center">
            {translateText(convertText, "Missed")} ({missed.length})
          </Nav.Item>
          <Nav.Item eventKey="5" className="tw-text-center">
            {translateText(convertText, "Cancelled")} ({cancelled.length})
          </Nav.Item> */}
        </Nav>

        {dataitems.data.length > 0 ? (
          <BookingCards
            bookings={dataitems.data}
            category={dataitems.category}
            func={updateUpcoming}
            currentUser={currentUser}
          />
        ) : (
          <div className="sm:tw-mb-4 tw-flex tw-flex-col tw-items-center tw-h-[235px] tw-justify-between tw-pt-10 tw-gap-2 sm:tw-h-auto">
            <img src={noBooking} alt="no booking" className="tw-w-20 tw-h-20" />
            <p className="montserrat tw-text-center tw-mb-5">
              {dataitems.empty_text}
            </p>
            {dataitems.button === true ? (
              <Button href="/discover/mentors" appearance="primary">
                See Mentors
              </Button>
            ) : (
              <div className="tw-h-9" />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default DashboardBookings;
