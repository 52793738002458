import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Loader, Nav } from "rsuite";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import BottomNavCTA from "../component/BottomNavCTA";
import { PortalApi } from "../../../../util/portal_api";
import { usePortalSetup } from "../../../../contexts/portal/setup";
import ImpactForm from "./ImpactForm";
import { useSetupVariable } from "../../../../contexts/portal/setup/setupVariables";

const schema = yup.object().shape({
  impact_questions: yup.array().of(
    yup.object({
      id: yup.mixed().nullable(),
      title: yup.string().required().nullable().label("Question Title"),
      description: yup.string().nullable(),
      required: yup.bool(),
      scale_start: yup.number().nullable(),
      scale_end: yup.number().nullable(),
      options: yup.array().of(yup.string()),
      category: yup.string().required().label("Type"),
      _destroy: yup.bool(),
    })
  ),
});

const PortalImpactTrackingForm = (props) => {
  const { handleStep } = useSetupVariable();
  const { program, setProgram } = usePortalSetup();

  const [activeKey, setActiveKey] = useState("mentee");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const impactForm = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      impact_questions:
        program?.review_questions.length > 0
          ? program?.review_questions
          : [
              {
                title:
                  "On a scale of 1-5, how much did this conversation help you in achieving your goals?",
                description: "",
                category: "stars",
                stars: 5,
                user_type: "mentor",
                primary: true,
                primary_tag: "PMENTOR1",
                required: true,
                _destroy: false,
              },
              {
                title:
                  "On a scale of 1-5, how comfortable do you feel talking to this mentor? (Ease of conversation, open to hearing your opinions and point of view, open to answering your questions)",
                description: "",
                category: "stars",
                stars: 5,
                user_type: "mentor",
                primary: true,
                primary_tag: "PMENTOR2",
                required: true,
                _destroy: false,
              },
              {
                title:
                  "On a scale of 1-5, how prepared was your mentee for the session? (Punctuality, Focus, Curiosity)",
                description: "",
                category: "stars",
                stars: 5,
                user_type: "mentee",
                primary: true,
                primary_tag: "PMENTEE2",
                required: true,
                _destroy: false,
              },
              {
                title:
                  "On a scale of 1-5, how comfortable do you feel speaking to this mentee?",
                description: "",
                category: "stars",
                stars: 5,
                user_type: "mentee",
                primary: true,
                primary_tag: "PMENTEE3",
                required: true,
                _destroy: false,
              },
            ],
    },
  });

  const { handleSubmit, watch } = impactForm;

  function filterQuestions(reviewQuestions, activeKey) {
    const menteeQuestions = reviewQuestions.filter(
      (question) => question.user_type === "mentee"
    );
    const mentorQuestions = reviewQuestions.filter(
      (question) => question.user_type === "mentor"
    );

    if (activeKey === "mentee") {
      return mentorQuestions;
    } else if (activeKey === "mentor") {
      return menteeQuestions;
    } else {
      // Return all questions if activeKey is not 'mentee' or 'mentor'
      return reviewQuestions;
    }
  }

  const onSubmit = (data, saveDraft = false) => {
    setIsSubmitting(true);

    data.program_id = program.id;

    PortalApi({
      method: "post",
      path: "/programs",
      options: data,
      success: ({ data }) => {
        setIsSubmitting(false);
        setProgram(data);
        handleStep(saveDraft ? "save_draft" : "complete");
      },
      error: ({ data }) => {
        setIsSubmitting(false);
        console.log(data);
      },
    });
    console.log("onSubmit", data);
  };

  const watchImpactQuestions = watch("impact_questions");

  if (!program) return <Loader className="tw-my-10" center content="loading" />;

  return (
    <>
      <h6>Post Reflection Questions</h6>
      <p className="montserrat tw-text-sm">
        These questions will be presented at the conclusion of each mentoring
        session.
      </p>

      <Nav
        appearance="subtle"
        activeKey={activeKey}
        onSelect={setActiveKey}
        className="tw-mb-5"
        justified
      >
        <Nav.Item eventKey="mentee" className="tw-text-center tw-inline-block">
          Questions for Mentee (
          {filterQuestions(watchImpactQuestions, "mentee")?.length || 0})
        </Nav.Item>
        <Nav.Item eventKey="mentor" className="tw-text-center tw-inline-block">
          Questions for Mentor (
          {filterQuestions(watchImpactQuestions, "mentor")?.length || 0})
        </Nav.Item>
      </Nav>

      <Form>
        <ImpactForm
          formProps={impactForm}
          watchQuestions={watchImpactQuestions}
          activeKey={activeKey}
        />
      </Form>

      <BottomNavCTA
        // Second onSubmit argument is SaveDraft
        handleSubmit={handleSubmit((data) => onSubmit(data, false))}
        handleSaveDraft={handleSubmit((data) => onSubmit(data, true))}
        loading={isSubmitting}
      />
    </>
  );
};

export default PortalImpactTrackingForm;
