import { useMediaQuery } from "@mantine/hooks";

type useDesktopOrMobileType = () => boolean;

//desktop is > 768px
/**
 *
 * @returns true if desktop, false if mobile
 */

const useDesktopOrMobile: useDesktopOrMobileType = () => {
  const isDesktop = useMediaQuery("(min-width: 768px)");

  return isDesktop;
};

export default useDesktopOrMobile;
