import React, { useEffect, useState } from "react";
import { useFormContext, useFormState } from "react-hook-form";
import {
  Breadcrumb,
  Button,
  Col,
  FlexboxGrid,
  Form,
  Loader,
  Message,
  Nav,
  Tag,
  Toggle,
  toaster,
} from "rsuite";

import EventInfoForm from "./EventInfoForm";
import EventNewTicketForm from "./EventNewTicketForm";
import EventDetailForm from "./EventDetailForm";
import EventAdditionalDataForm from "./EventAdditionalDataForm";
import {
  detailValidation,
  extraFieldsValidation,
  infoValidation,
  ticketValidation,
} from "./EventFormProvider";
import { FlAdminApi } from "../../../util/fladmin_api";
import moment from "moment";
import { FutureLabApi } from "../../../util/api";
import EventSegmentModal from "./EventSegmentModal";

interface Event {
  id: number;
  event_type: string;
  title: string;
  privacy: string;
  timezone: string;
  start_time: Date;
  end_time: Date;
  webinar_link: string;
  webinar_password: string;
  venue_title: string;
  geocode: string;
  photo: File | string;
  description: string;
  organizer_name: string;
  inquiry_email: string;
  inquiry_number: number;
  event_speakers: string[];
  active: boolean;
  event_ended: boolean;
  tickets: string[] | number[];
  extra_fields: string[];
}

const NavItem = ({ children, eventKey }) => (
  <Nav.Item className="tw-block tw-text-center" eventKey={eventKey}>
    {children}
  </Nav.Item>
);

const formObjects = (activeKey, variables = null) => {
  const obj = {
    info: {
      form: <EventInfoForm timezones={variables.timezones} />,
      validateFields: infoValidation,
    },
    detail: {
      form: <EventDetailForm mentors={variables.mentors} />,
      validateFields: detailValidation,
    },
    ticket: { form: <EventNewTicketForm />, validateFields: ticketValidation },
    data: {
      form: <EventAdditionalDataForm />,
      validateFields: extraFieldsValidation,
    },
  };
  return obj[activeKey];
};

const parseDateFormat = (dateTime) => {
  const parsedDate = moment(dateTime);
  return parsedDate.format("DD MMM YYYY (h:mm A)");
};

const navKey = ["info", "detail", "ticket", "data"];

const maxTabLength = (activeKey) => {
  if (navKey.indexOf(activeKey) === navKey.length - 1) {
    return "last";
  }

  if (navKey.indexOf(activeKey) === 0) {
    return "first";
  }

  return navKey.indexOf(activeKey);
};

const EventCreateComponent = ({ eventId = null }) => {
  const [activeKey, setActiveKey] = useState("info");
  const [event, setEvent] = useState<Event>();
  const [toggleLoading, setToggleLoading] = useState(false);
  const [eventLoading, setEventLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [variables, setVariables] = useState({});
  const [eventActive, setEventActive] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const { handleSubmit, reset, control, trigger, getValues } = useFormContext();
  const { errors } = useFormState({ control });

  const handleNavigate = async (activeKey, direction) => {
    const currentIndex = navKey.indexOf(activeKey);
    const newIndex = direction === "next" ? currentIndex + 1 : currentIndex - 1;

    if (newIndex >= 0 && newIndex < navKey.length) {
      const nextKey = navKey[newIndex];
      const { validateFields } = formObjects(activeKey, variables);

      // Trigger validation for the current section
      await trigger(validateFields);

      // Check if there are errors or if any fields are dirty
      if (Object.keys(errors).length > 0) {
        // Handle the case where there are errors or fields are dirty
        console.log("Form has errors or is dirty. Cannot proceed.");
      } else {
        // Move to the next section
        setActiveKey(nextKey);
      }
    }
  };

  // Suggest to move useEffect to a higher order Context
  useEffect(() => {
    if (eventId) {
      FlAdminApi({
        method: "get",
        path: "/events",
        options: { event_id: eventId },
        success: ({ data }) => {
          console.log(data);
          setEventLoading(false);
          setEvent(data);
          setEventActive(data.active);
        },
        error: () => {
          setEventLoading(false);
        },
      });
    }
  }, [eventId]);

  useEffect(() => {
    FutureLabApi({
      method: "get",
      path: "/timezones",
      success: ({ data }) => {
        setVariables((prevVariables) => ({
          ...prevVariables,
          timezones: data,
        }));
      },
    });
    return () => {
      setVariables({});
    };
  }, []);

  useEffect(() => {
    FlAdminApi({
      method: "get",
      path: "/events/event_mentors",
      success: ({ data }) => {
        setVariables((prevVariables) => ({
          ...prevVariables,
          mentors: data,
        }));
      },
    });
  }, []);

  useEffect(() => {
    if (event) {
      reset({
        event: {
          event_type: event.event_type,
          title: event.title,
          privacy: event.privacy,
          timezone: event.timezone,
          webinar_link: event.webinar_link,
          webinar_password: event.webinar_password,
          venue_title: event.venue_title,
          geocode: event.geocode,
          description: event.description,
          photo: event.photo,
          organizer_name: event.organizer_name,
          inquiry_email: event.inquiry_email,
          inquiry_number: event.inquiry_number,
          start_time: new Date(event.start_time),
          end_time: new Date(event.end_time),
        },
        event_speakers: event.event_speakers,
        tickets: event.tickets,
        extra_fields: event.extra_fields,
      });
    }
  }, [event]);

  const uploadPhoto = (eventId, file) => {
    setLoading(true);

    const formData = new FormData();

    formData.append("event_id", eventId);
    formData.append("photo", file);

    FlAdminApi({
      method: "post",
      path: "/events/upload_photo",
      options: formData,
      config: {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      success: ({ data }) => {
        setLoading(false);
        console.log(data);
        window.location.replace("/main/admins/events/");
      },
      error: ({ data }) => {
        setLoading(false);
        console.log(data);
      },
    });
  };

  const onSubmit = (data) => {
    // Assign const variable with photo, we want to ultimately remove photo (File)
    // to allow the other variables to be submitted via "application/json" api call.
    // Handling the photo will be submitted after a success response callback has been given.

    const eventPhoto =
      data.event.photo instanceof File ? data.event.photo : undefined;
    delete data.event.photo;

    if (event) {
      data.event_id = event.id;
    }

    setLoading(true)

    FlAdminApi({
      method: "post",
      path: "/events",
      options: data,
      success: ({ data }) => {
        setLoading(false);
        eventPhoto
          ? uploadPhoto(data.id, eventPhoto)
          : window.location.replace("/main/admins/events/");
      },
      error: () => {
        setLoading(false);
      },
    });
  };

  const handleToggleActive = (eventId: number) => {
    setToggleLoading(true);
    FlAdminApi({
      method: "post",
      path: "/events/activity",
      options: { event_id: eventId },
      success: ({ data }) => {
        setEventActive(data.response);
        setToggleLoading(false);
        toaster.push(
          <Message showIcon type="success">
            <span>
              Event is {eventActive ? "no longer Published" : "Published"}
            </span>
          </Message>,
          { placement: "topEnd" }
        );
      },
      error: ({ data }) => {
        setToggleLoading(false);
        toaster.push(
          <Message showIcon type="error">
            {data.message}
          </Message>,
          { placement: "topEnd" }
        );
      },
    });
  };

  if (eventLoading && eventId) return <Loader center content="Loading" />;

  return (
    <div>
      <FlexboxGrid justify="center" className="tw-my-6">
        <FlexboxGrid.Item as={Col} colspan={24} lg={12}>
          <Breadcrumb>
            <Breadcrumb.Item href="/main/admins/events/">Back</Breadcrumb.Item>
          </Breadcrumb>

          <h1 className="tw-text-xl">{event ? "Edit" : "Create an"} Event</h1>

          {event && (
            <div className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-border tw-border-solid tw-border-gray-300 tw-rounded-lg tw-p-4">
              <div>
                <div className="tw-mb-4">
                  {event.event_ended ? (
                    <Tag color="blue">Event Ended</Tag>
                  ) : (
                    <Tag color="green">Ongoing</Tag>
                  )}
                </div>
                <div>{event.title}</div>
                <div className="tw-text-sm">
                  {`${parseDateFormat(event.start_time)} - ${parseDateFormat(
                    event.end_time
                  )}`}
                </div>
              </div>
              <div className="tw-flex tw-flex-row tw-items-center">
                <span className="tw-mr-2 tw-text-sm">Published</span>
                <Toggle
                  loading={toggleLoading}
                  checkedChildren="Yes"
                  defaultChecked={getValues("event.active")}
                  unCheckedChildren="No"
                  checked={eventActive}
                  onClick={() => handleToggleActive(event.id)}
                />
              </div>
            </div>
          )}

          <Nav
            appearance="subtle"
            activeKey={activeKey}
            onSelect={setActiveKey}
            justified
            className="tw-w-full tw-my-6"
          >
            <NavItem eventKey="info">Event Info</NavItem>
            <NavItem eventKey="detail">Event Details</NavItem>
            <NavItem eventKey="ticket">Tickets</NavItem>
            <NavItem eventKey="data">Additional Data</NavItem>
          </Nav>

          <Form>
            {/* Leave room for position fixed buttons below */}
            <div className="tw-mb-10 tw-pb-10">
              {formObjects(activeKey, variables).form}
            </div>

            <div
              style={{
                position: "fixed",
                display: "flex",
                flexDirection: "row-reverse",
                bottom: 0,
                padding: "15px 0",
                backgroundColor: "#FFF",
                zIndex: "5",
                width: "49.5%",
              }}
            >
              <div>
                {maxTabLength(activeKey) !== "first" && (
                  <Button
                    appearance="ghost"
                    className="tw-mr-2"
                    onClick={() => handleNavigate(activeKey, "")}
                    loading={loading}
                  >
                    Back
                  </Button>
                )}

                {/* Edit event scenario */}
                {event && (
                  <Button
                    appearance="primary"
                    onClick={() => setOpenModal(true)}
                    loading={loading}
                  >
                    Save
                  </Button>
                )}

                {/* Create event scenario */}
                {!event && (
                  <>
                    {maxTabLength(activeKey) === "last" ? (
                      <Button
                        appearance="primary"
                        onClick={() => setOpenModal(true)}
                        loading={loading}
                      >
                        Create
                      </Button>
                    ) : (
                      <Button
                        appearance="primary"
                        onClick={() => handleNavigate(activeKey, "next")}
                        loading={loading}
                      >
                        Next
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
            <EventSegmentModal
              openModal={openModal}
              setOpenModal={setOpenModal}
              handleSubmit={handleSubmit(onSubmit)}
              event={event}
              loading={loading}
            />
          </Form>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </div>
  );
};

export default EventCreateComponent;
