import {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "components/shared/modal/BaseModal";
import React from "react";
import { useFormContext } from "react-hook-form";
import { Button, Modal } from "rsuite";

const PairingMethodConfirmationModal = ({
  optionSelected,
  openModal,
  setOpenModal,
  handleModalResponse,
}) => {
  return (
    <Modal open={openModal} size={"xs"}>
      <ModalHeader>Confirm Pairing Method</ModalHeader>
      <ModalBody>
        <p className="montserrat">
          We recommend not changing these settings as it would greatly affect
          your participant's on going mentorship experience.
        </p>

        <p className="montserrat">
          Are you sure you want to change this setting?
        </p>
      </ModalBody>
      <ModalFooter className="tw-flex tw-items-center tw-pt-4 tw-gap-2">
        <Button
          appearance="ghost"
          style={{ marginTop: "5px" }}
          block
          onClick={() => setOpenModal(false)}
        >
          Cancel
        </Button>
        <Button appearance="primary" block onClick={handleModalResponse}>
          Yes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PairingMethodConfirmationModal;
