import React from "react";
import { UserProvider } from "../contexts/user";
import NavbarContent from "./navbar/NavbarContent";
import { PortalProvider } from "../contexts/portal";
import { NavbarProvider } from "../contexts/navbarContext";

const Navbar = ({ onPortal, portalId = null, baseUrl, showFooter }) => {
  return (
    <UserProvider>
      <NavbarProvider
        onPortal={onPortal}
        portalId={portalId}
        baseUrl={baseUrl}
        showFooter={showFooter}
      >
        <PortalProvider
          programSlug={onPortal?.programSlug}
          enterpriseSlug={onPortal?.enterpriseSlug}
        >
          <NavbarContent />
        </PortalProvider>
      </NavbarProvider>
    </UserProvider>
  );
};

export default Navbar;
