// React
import React, { useEffect, useState } from "react";

// FutureLab
import { PortalApi } from "../../../util/portal_api";
import { OneOnOneRatingDrawer } from "./drawer/OneOnOneRatingDrawer";
import { GroupRatingDrawer } from "./drawer/GroupRatingDrawer";

// UI
import { Message, toaster } from "rsuite";

const RatingDetailsDrawer = (props) => {
  const { openDrawer, setOpenDrawer, groupId, mentee, mentor, type } = props;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [bookingId, setBookingId] = useState(null);

  useEffect(() => {
    setLoading(true);

    if (groupId) {
      PortalApi({
        method: "get",
        path: "/mentorship_ratings/booking_list",
        options: { group_id: groupId },
        success: ({ data }) => {
          setData(data.reverse());
          setLoading(false);
        },
        error: (err) => {
          const { error } = err.response.data;
          toaster.push(
            <Message showIcon type="error">
              {error}
            </Message>
          );
        },
      });
      return () => {
        setData([]);
      };
    }
  }, [groupId]);

  return (
    <>
      {type === "Pair" && (
        <OneOnOneRatingDrawer
          setOpenDrawer={setOpenDrawer}
          openDrawer={openDrawer}
          setBookingId={setBookingId}
          bookingId={bookingId}
          mentee={mentee}
          mentor={mentor}
          data={data}
          loading={loading}
          type={type}
        />
      )}

      {type === "Group" && (
        <GroupRatingDrawer
          setOpenDrawer={setOpenDrawer}
          openDrawer={openDrawer}
          setBookingId={setBookingId}
          bookingId={bookingId}
          mentee={mentee}
          mentor={mentor}
          data={data}
          loading={loading}
          type={type}
        />
      )}
    </>
  );
};

export default RatingDetailsDrawer;
