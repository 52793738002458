// React
import React from "react";
import {
  Route,
  Routes,
  BrowserRouter,
  Outlet,
  useParams,
} from "react-router-dom";

// FutureLab
import { UserProvider } from "../contexts/user";
import { PortalProvider } from "../contexts/portal";
import { VariablesProvider } from "../contexts/variables";
import AppContainer from "./AppContainer";
import MenteeProfileContent from "./user_profile/mentee/MenteeProfileContent";
import { PortalMenteeProfileProvider, usePortalMenteeProfile } from "../contexts/mentee_profile";

// UI
import { Loader } from "rsuite";

export default function MenteeProfilePortal({ variables }) {
  return (
    <BrowserRouter>
      <AppContainer>
        <Routes>
          <Route
            path="/app/:enterpriseSlug/:programSlug/members/:menteeId"
            element={<MenteeProvider variables={variables} />}
          >
            <Route
              path="/app/:enterpriseSlug/:programSlug/members/:menteeId"
              element={
                <MenteeProfileContent convertText={[]} currentUser={variables.currentUser} />
              }
            />
          </Route>
        </Routes>
      </AppContainer>
    </BrowserRouter>
  );
};

function MenteeProvider({ variables }) {
  const { enterpriseSlug, programSlug } = useParams();

  return (
    <VariablesProvider variables={variables}>
      <UserProvider>
        <PortalProvider
          enterpriseSlug={enterpriseSlug}
          programSlug={programSlug}
        >
          <PortalMenteeProfileProvider>
            <RenderMentorProfile />
          </PortalMenteeProfileProvider>
        </PortalProvider>
      </UserProvider>
    </VariablesProvider>
  );
}

function RenderMentorProfile() {
  const { mentee, setMentee, refreshMentee, type } = usePortalMenteeProfile();

  if (!mentee) return <Loader center content="loading" />;
  return (
    <Outlet
      context={
        {
          mentee,
          setMentee,
          refreshMentee,
          type,
        } 
      }
    />
  );
}
