import React, { useState } from "react";

import { ErrorMessage } from "@hookform/error-message";
import { Controller } from "react-hook-form";
import {
  DatePicker,
  DateRangePicker,
  Form,
  Input,
  InputGroup,
  InputNumber,
  InputPicker,
  SelectPicker,
  TagPicker,
} from "rsuite";
import FieldError from "../FieldError";

// Input Box
export const FormInputText = ({
  name,
  control,
  placeholder = null,
  errors,
  label = null,
  helplabel = null,
  defaultValue = null,
  max_length = null,
  ...restProps
}) => {
  return (
    <>
      {label && (
        <Form.ControlLabel>
          <span className="tw-text-sm tw-font-medium">{label}</span>
        </Form.ControlLabel>
      )}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <>
            <Input
              {...field}
              {...restProps}
              name={name}
              placeholder={placeholder}
            />
            <div className="tw-flex tw-justify-between">
              <ErrorMessage
                className="flex-1	"
                errors={errors}
                name={name}
                as={FieldError}
              />
              {max_length !== null && (
                // Validation handled by yupResolver
                <div className="tw-my-1 tw-text-sm tw-flex-1 tw-text-right">
                  {field.value.length}/{max_length}
                </div>
              )}
            </div>
          </>
        )}
      />
      {helplabel && (
        <Form.HelpText>
          <span className=" tw-text-sm">{helplabel}</span>
        </Form.HelpText>
      )}
    </>
  );
};

// Form Input Text with Add On
export const FormInputTextAddOn = ({
  name,
  control,
  placeholder = null,
  errors,
  label = null,
  defaultValue = null,
  addonLabel,
  block = false,
  ...restProps
}) => {
  return (
    <>
      {label && <Form.ControlLabel className="tw-text-sm tw-font-medium">{label}</Form.ControlLabel>}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <>
            <InputGroup className={block ? "tw-w-full" : ""}>
              <InputGroup.Addon>{addonLabel}</InputGroup.Addon>
              <Input
                {...field}
                {...restProps}
                name={name}
                placeholder={placeholder}
              />
            </InputGroup>
            <div className="tw-flex tw-justify-between">
              <ErrorMessage
                className="flex-1	"
                errors={errors}
                name={name}
                as={FieldError}
              />
            </div>
          </>
        )}
      />
    </>
  );
};

// Tag Picker
export const FormTagPicker = ({
  name,
  control,
  placeholder,
  errors,
  label = null,
  data,
  ...restProps
}) => {
  return (
    <>
      <Form.ControlLabel>{label}</Form.ControlLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            <TagPicker
              {...field}
              {...restProps}
              data={data}
              placeholder={placeholder}
            />
            <ErrorMessage errors={errors} name={name} as={FieldError} />
          </>
        )}
      />
    </>
  );
};

// Input Picker
export const FormInputPicker = ({
  name,
  control,
  placeholder,
  errors,
  label = null,
  defaultValue = null,
  data,
  ...restProps
}) => {
  return (
    <>
      {label && <Form.ControlLabel className="tw-text-sm tw-font-medium">{label}</Form.ControlLabel>}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <>
            <InputPicker
              {...field}
              {...restProps}
              data={data}
              placeholder={placeholder}
            />
            <ErrorMessage errors={errors} name={name} as={FieldError} />
          </>
        )}
      />
    </>
  );
};

// Select Picker
export const FormSelectPicker = ({
  name,
  control,
  placeholder,
  errors,
  label = null,
  data,
  ...restProps
}) => {
  return (
    <>
      {label && <Form.ControlLabel>{label}</Form.ControlLabel>}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            <SelectPicker
              {...field}
              {...restProps}
              searchable={false}
              placeholder={placeholder}
              data={data}
            />
            <ErrorMessage errors={errors} name={name} as={FieldError} />
          </>
        )}
      />
    </>
  );
};

// Select DatePicker
export const FormDatePicker = ({
  name,
  control,
  placeholder,
  errors,
  label = null,
  defaultValue = null,
  ...restProps
}) => {
  return (
    <>
      {label && <Form.ControlLabel>{label}</Form.ControlLabel>}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <>
            <DatePicker
              cleanable={false}
              editable={false}
              {...field}
              {...restProps}
              placeholder={placeholder}
            />
            <ErrorMessage errors={errors} name={name} as={FieldError} />
          </>
        )}
      />
    </>
  );
};

// Select DateRangePicker
export const FormDateRangePicker = ({
  name,
  control,
  placeholder,
  errors,
  label = null,
  defaultValue = null,
  ...restProps
}) => {
  return (
    <>
      {label && <Form.ControlLabel>{label}</Form.ControlLabel>}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <>
            <DateRangePicker
              cleanable={false}
              editable={false}
              {...field}
              {...restProps}
              placeholder={placeholder}
            />
            <ErrorMessage errors={errors} name={name} as={FieldError} />
          </>
        )}
      />
    </>
  );
};

export const FormInputNumber = ({
  name,
  control,
  placeholder = null,
  errors,
  label = null,
  defaultValue = null,
  ...restProps
}) => {
  return (
    <>
      {label && <Form.ControlLabel>{label}</Form.ControlLabel>}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <>
            <InputNumber
              {...field}
              {...restProps}
              name={name}
              placeholder={placeholder}
            />
            <ErrorMessage errors={errors} name={name} as={FieldError} />
          </>
        )}
      />
    </>
  );
};
