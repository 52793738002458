// React
import React, { useState } from "react";

// Futurelab
import newChatApp from "../images/new_chat.png";

// UI
import { FlexboxGrid, ButtonToolbar, Button, Modal, Divider } from "rsuite";

const urls = {
  development: "http://lvh.me:3000/",
  production: "https://newapp.futurelab.my",
  staging: "https://newapp.futurelab.global",
};

const href = urls?.[process.env.RAILS_ENV] || urls["development"];

const ChatModalPopup = () => {
  // const [open, setOpen] = useState(true);
  // const handleClose = () => setOpen(false);

  return (
    <Modal
      size="xs"
      backdrop={"static"}
      keyboard={false}
      open={true}
      onClose={() => {}}
    >
      <FlexboxGrid.Item className="tw-text-center tw-border tw-pb-2">
        <span className="tw-font-bold tw-text-xl tw-text-gray-700">
          We have a brand new chat app!
        </span>
      </FlexboxGrid.Item>
      <Divider className="tw-absolute tw-w-full tw-left-0 tw-m-0  tw-bg-gray-300" />
      <FlexboxGrid.Item className="tw-text-center tw-pb-2">
        <img className="tw-w-60 tw-h-60 tw-pt-3 tw-pb-4" src={newChatApp} />
        <div className="tw-text-base tw-text-gray-700 tw-leading-7">
          You can now have a smoother chat experience with your mentors and
          mentees!
        </div>
      </FlexboxGrid.Item>
      <Divider className="tw-absolute tw-w-full tw-left-0 tw-m-0 tw-bg-gray-300" />
      <ButtonToolbar className="tw-text-center tw-flex tw-justify-center tw-items-center tw-pt-4">
        <Button
          className="new-chat-redirect-button"
          appearance="primary"
          href={href}
          target="_blank"
        >
          Let's go
        </Button>
      </ButtonToolbar>
    </Modal>
  );
};
export default ChatModalPopup;
