import React, { useEffect, useState } from "react";
import { Button, Divider, Form, Modal } from "rsuite";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FutureLabApi } from "../../util/api";
import EventQuestionForm from "./forms/EventQuestionForm";
import { useUser } from "../../contexts/user";

const schema = yup.object().shape({
  name: yup.string().required().label("Name"),
  email: yup.string().required().label("Email"),
  phone_no: yup.number().required().nullable().label("Phone number"),
  answers: yup.array().of(
    yup.object().shape({
      event_extra_field_id: yup.number().required(),
      answer: yup.string().required().min(1).label("This"),
    })
  ),
});

const EventFormModal = ({
  open,
  eventId,
  handleOpenModal,
  purchasedTickets,
}) => {
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const { user } = useUser();

  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (eventId) {
      FutureLabApi({
        method: "get",
        path: `/events/${eventId}/questions`,
        success: ({ data }) => {
          setQuestions(data);
        },
      });
      return () => {
        setQuestions([]);
      };
    }
  }, [eventId]);

  const formSubmit = (data) => {
    data.tickets = purchasedTickets;
    data.event_id = eventId
    data.user_id = user.id;

    setLoading(true);
    FutureLabApi({
      method: "post",
      path: "/tickets/purchase",
      options: data,
      success: ({ data }) => {
        setLoading(false)
        window.location.replace(data);
      },
      error: () => {
        setLoading(false)
      },
    });
  };

  return (
    <Modal backdrop="static" open={open} size="xs" onClose={handleOpenModal}>
      <Modal.Header>
        <Modal.Title className="tw-font-medium">
          Additional Information
        </Modal.Title>
        <Divider className="tw-absolute tw-w-full tw-left-0 tw-mt-4 tw-bg-gray-300" />
      </Modal.Header>
      <Modal.Body className="tw-py-8">
        <EventQuestionForm
          questions={questions}
          errors={errors}
          control={control}
          reset={reset}
        />
      </Modal.Body>
      <Divider className="tw-absolute tw-w-full tw-left-0 tw-m-0 tw-bg-gray-300" />
      <Modal.Footer className="tw-pt-5">
        <Button appearance="ghost" onClick={handleOpenModal}>
          Cancel
        </Button>
        <Button
          appearance="primary"
          loading={loading}
          onClick={handleSubmit(formSubmit)}
        >
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EventFormModal;
