import React from "react";
import { DatePicker, Form, Radio, RadioGroup } from "rsuite";
import {
  FormInputPicker,
  FormInputText,
  FormSelectPicker,
} from "../../form/default/FormInputs";
import { Controller, useFormContext } from "react-hook-form";
import moment from "moment";
import { ErrorMessage } from "@hookform/error-message";
import FieldError from "../../form/FieldError";

const EventInfoForm = ({ timezones = [] }) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const watchEventType = watch("event.event_type");

  return (
    <>
      <h6>Event Information</h6>

      <Form.Group>
        <FormSelectPicker
          name="event.event_type"
          label={"Event type"}
          placeholder={"Event Type"}
          control={control}
          errors={errors}
          block
          cleanable={false}
          data={[
            { value: "webinar", label: "Online Webinar" },
            { value: "physical", label: "Physical Event" },
          ]}
        />
      </Form.Group>

      <Form.Group>
        <FormInputText
          name="event.title"
          label={"Event name"}
          placeholder={"Event Title"}
          control={control}
          errors={errors}
        />
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>Event privacy</Form.ControlLabel>
        <Controller
          name="event.privacy"
          control={control}
          render={({ field }) => (
            <RadioGroup inline {...field} defaultChecked={field.value}>
              <Radio className="tw-mr-4" value={"is_private"}>
                Private
              </Radio>
              <Radio value={"is_public"}>Public</Radio>
            </RadioGroup>
          )}
        />
      </Form.Group>

      <Form.Group className="tw-w-1/2 tw-pr-2">
        <FormInputPicker
          name="event.timezone"
          control={control}
          errors={errors}
          label={"Timezone"}
          placeholder={"Select Timezone"}
          block
          cleanable={false}
          placement="autoVerticalStart"
          data={timezones}
        />
      </Form.Group>

      <div className="tw-flex tw-flex-row">
        <Form.Group className="tw-flex-1 tw-mr-2">
          <Form.ControlLabel>Start Time</Form.ControlLabel>
          <Controller
            name="event.start_time"
            control={control}
            render={({ field }) => (
              <>
                <DatePicker
                  {...field}
                  className="tw-w-full"
                  showMeridian
                  isoWeek
                  cleanable={false}
                  format={"dd MMM yyyy (h:mm aa)"}
                  placement="autoVerticalStart"
                  placeholder={"Start date and time"}
                  ranges={[]}
                />
                <ErrorMessage
                  errors={errors}
                  name="event.start_time"
                  as={FieldError}
                />
              </>
            )}
          />
        </Form.Group>

        <Form.Group className="tw-flex-1 tw-ml-2">
          <Form.ControlLabel>End Time</Form.ControlLabel>
          <Controller
            name="event.end_time"
            control={control}
            render={({ field }) => (
              <>
                <DatePicker
                  {...field}
                  className="tw-w-full"
                  showMeridian
                  isoWeek
                  cleanable={false}
                  format={"dd MMM yyyy (h:mm aa)"}
                  placement="autoVerticalStart"
                  placeholder={"End date and time"}
                  ranges={[]}
                  shouldDisableDate={(date) =>
                    moment(date).isBefore(
                      moment(watch("event.start_time")),
                      "day"
                    )
                  }
                />
                <ErrorMessage
                  errors={errors}
                  name="event.end_time"
                  as={FieldError}
                />
              </>
            )}
          />
        </Form.Group>
      </div>

      {/* Need to render rather than using ternary to allow form reset to take place */}
      <div className={`${watchEventType === "webinar" ? "" : "tw-hidden"}`}>
        <h6>Webinar Options - (Optional)</h6>
        <Form.Group>
          <FormInputText
            name="event.webinar_link"
            label={"Webinar Link"}
            placeholder={"Insert link to your webinar"}
            control={control}
            errors={errors}
          />
        </Form.Group>
        <Form.Group>
          <FormInputText
            name="event.webinar_password"
            label={"Webinar Password"}
            placeholder={"Webinar password"}
            control={control}
            errors={errors}
          />
        </Form.Group>
      </div>

      <div className={`${watchEventType === "physical" ? "" : "tw-hidden"}`}>
        <h6>Physical Event Options - (Optional)</h6>
        <Form.Group>
          <FormInputText
            name="event.venue_title"
            label={"Location"}
            placeholder={"Location of event"}
            control={control}
            errors={errors}
          />
        </Form.Group>
        <Form.Group>
          <FormInputText
            name="event.geocode"
            label={"Google Map location link"}
            placeholder={"Google Map location link"}
            control={control}
            errors={errors}
          />
        </Form.Group>
      </div>
    </>
  );
};

export default EventInfoForm;
