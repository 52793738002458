import React from "react";
import RenderRoles from "./portal/invitation/RenderRoles";
import { Link } from "react-router-dom";
import { usePortal } from "../contexts/portal";
import Toaster from "./shared/Toaster";
import { LuLink } from "react-icons/lu";
import copyLink from "../util/copyLink";

const PortalInvitation = () => {
  const { program, enterprise } = usePortal();
  const handleCopyLink = async () => {
    const domain = window.location.origin;
    const link = `${domain}/users/login?enterprise_id=${enterprise.id}&program_id=${program.id}`;
    const success = await copyLink(link);
    success
      ? Toaster({
          type: "success",
          msg: "Link copied to clipboard",
        })
      : Toaster({
          type: "error",
          msg: "Unable to copy",
        });
  };

  return (
    <main className="tw-flex tw-justify-center">
      <div className="tw-max-w-4xl">
        <h1 className="tw-text-xl">Invitations</h1>
        <div className="tw-mt-6">
          <p className="tw-text-base">
            Invite participants to join your program, ensuring seamless
            onboarding and active engagement from the start
          </p>
        </div>
        <div className="tw-mt-6">
          <RenderRoles />
        </div>
        <div className="tw-mt-6 tw-p-4 tw-bg-white tw-flex tw-flex-col tw-justify-center tw-items-center tw-border-t-2 tw-border-solid tw-border-gray-100 tw-border-0 ">
          <p className="tw-text-center tw-text-base tw-mb-4">
            Not your user type?{" "}
            <Link
              to={window.location.pathname.replace(
                "user-invites",
                "user_management"
              )}
            >
              You can also customize your user types
            </Link>
          </p>

          <div className="tw-text-center" onClick={handleCopyLink}>
            <span
              className="tw-text-[#4A4A4A] tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw-text-base tw-font-medium tw-mb-4 tw-gap-2"
              data-id="0"
            >
              <LuLink className="tw-rotate-45 tw-text-xl" />
              <span className="tw-text-[#428ac9]">
                Get a sign in link to share with your users
              </span>
            </span>
          </div>
        </div>
      </div>
    </main>
  );
};

export default PortalInvitation;
