// React
import React from "react";

// FutureLab
import { getNpsText } from "../utils/getText";
import RenderCharacter from "./ScoringButton";
import { UserNps } from "../../NetPromoterScore";

// UI
import { Form, Input, Rate, TagPicker } from "rsuite";

// 3rd Party
import { Controller } from "react-hook-form";

const filteredData = (type) => {
  const data = [
    "Profile Setup",
    "Buying Mentorship Credits",
    "Finding a Mentor and Session Scheduling",
    "Carrying Out Session",
    "Messenger and Communication",
    "None of the above",
  ].map((item) => ({
    label: item,
    value: item,
  }));

  // Filter the array based on the condition
  return data.filter(
    (item) =>
      !(
        item.value === "Buying Mentorship Credits" &&
        type === "portal_first_booking_nps"
      )
  );
};

interface NpsFormProps {
  userNpsDetails: UserNps;
  npsScore: number;
  control: any;
  errors: any;
  portalId: string | null;
  isMobile: boolean;
}

export default function NpsForm(npsFormProps: NpsFormProps) {
  const { userNpsDetails, npsScore, control, portalId, isMobile, errors } =
    npsFormProps;

  const {
    title,
    scaleDescription,
    description,
    inputQuestion,
    inputPlaceHolder,
  } = getNpsText(userNpsDetails?.text_type, npsScore);

  const [startScale, endScale] = scaleDescription

  return (
    <Form>
      <div className="tw-mb-6">
        <p className="xs:tw-text-sm tw-font-medium tw-text-center montserrat">
          {title}
        </p>
        <div>
          <div className="tw-flex tw-justify-center">
            <p className="tw-text-xs tw-inline-block xs:tw-w-1/2 tw-w-2/5 montserrat">
              {startScale}
            </p>
            <p className="tw-text-xs tw-text-right tw-inline-block xs:tw-w-1/2 tw-w-2/5 montserrat">
              {endScale}
            </p>
          </div>
          <div className="tw-flex tw-justify-center">
            <Controller
              name="feedback_type"
              control={control}
              defaultValue={userNpsDetails?.type}
              render={({ field }) => <Input type="hidden" {...field} />}
            />

            <Controller
              name="program_id"
              control={control}
              defaultValue={portalId || ""}
              render={({ field }) => <Input type="hidden" {...field} />}
            />
            <Controller
              name="number_of_stars"
              control={control}
              render={({ field }) => (
                <Rate
                  id="nps-score"
                  {...field}
                  cleanable={false}
                  max={10}
                  onChangeActive={(value) => {
                    if (value !== field.value && isMobile) {
                      field.onChange(value);
                    }
                  }}
                  renderCharacter={RenderCharacter}
                  className="tw-max-w-full tw-flex tw-space-between"
                />
              )}
            />
            <Controller
              name="nps_category"
              defaultValue={""}
              control={control}
              render={({ field }) => <Input type="hidden" {...field} />}
            />
          </div>
        </div>
        <p className="tw-mt-4 tw-text-center xs:tw-text-xs tw-text-sm montserrat">
          {npsScore && description}
        </p>
      </div>
      <div className={npsScore ? "block" : "hidden"}>
        {npsScore < 6 && userNpsDetails?.type.includes("first_booking_nps") && (
          <Form.Group>
            <p className="xs:tw-mb-3 tw-mb-2 xs:tw-text-sm montserrat tw-font-medium ">
              Which specific area you want to highlight?
            </p>
            <Controller
              name="feedback_text"
              control={control}
              defaultValue={[]}
              render={({ field }) => (
                <TagPicker
                  block
                  {...field}
                  menuMaxHeight={200}
                  data={filteredData(userNpsDetails?.type)}
                  virtualized
                />
              )}
            />
            {errors?.feedback_text?.message && npsScore < 6 && (
              <p className="tw-mt-2 tw-mb-0 tw-text-xs tw-text-red-500 montserrat">
                {errors.feedback_text.message}
              </p>
            )}
          </Form.Group>
        )}
        <Form.Group>
          <p className="xs:tw-mb-3 tw-mb-2 xs:tw-text-sm montserrat tw-font-medium ">
            {inputQuestion}
          </p>
          <Controller
            name="comment"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                as="textarea"
                rows={3}
                placeholder={inputPlaceHolder}
              />
            )}
          />
          {errors.comment && npsScore < 6 && (
            <p className="tw-mt-2 tw-mb-0 tw-text-xs tw-text-red-500 montserrat">
              {errors.comment.message}
            </p>
          )}
        </Form.Group>
      </div>
    </Form>
  );
}
