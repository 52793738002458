import React from "react";
import { Modal, Input, Button } from "rsuite";
import { usePortal } from "../../../contexts/portal";
import { useFieldArray, Controller } from "react-hook-form";
import copyLink from "../../../util/copyLink";
import Toaster from "../../shared/Toaster";
import { LuLink } from "react-icons/lu";
import { MdOutlinePermContactCalendar } from "react-icons/md";

const InvitationModal = ({ open, setOpen, role, instance }) => {
  const { program, enterprise } = usePortal();
  const { control, watch } = instance;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "email",
  });
  const data = watch();
  const total = data.email?.filter((e) => e.email !== "").length;
  const [multiOpen, setMultiOpen] = React.useState(false);
  const multiRef = React.useRef(null);

  const handleShareLink = async () => {
    const pwaHost = document.getElementById("pwa-host");
    const host = pwaHost?.getAttribute("data-pwa-host");
    const link = `${host}/${enterprise.slug}/${program.slug}/roles/${role.id}/sign_up`;
    const copied = await copyLink(link);
    if (copied) {
      Toaster({
        type: "success",
        msg: "Link copied to clipboard",
      });
    } else {
      Toaster({
        type: "error",
        msg: "Unable to copy",
      });
    }
  };

  const handleAddOther = () => {
    append({ email: "" });
  };

  const handleRemove = (index) => {
    remove(index);
  };

  const handleCloseMulti = (e) => {
    e.preventDefault();
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/g;
    const emails = multiRef?.current?.value?.trim()?.match(emailRegex) || [];
    emails.forEach((email) => {
      append({ email });
    });
    setMultiOpen(false);
  };

  React.useEffect(() => {
    if (fields.length === 0) {
      append({ email: "" });
    }
  }, [fields]);

  React.useEffect(() => {
    console.log(enterprise);
  }, [enterprise]);

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Modal.Header className="tw-border-b-2 tw-border-gray-200 tw-border-solid tw-border-0 tw-py-2 tw-px-1">
        <Modal.Title className="tw-capitalize">Invite {role.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="tw-px-6 tw-py-4">
        <form onSubmit={instance.handleFormSubmit}>
          <div className="tw-mb-4 tw-flex tw-items-center tw-justify-center tw-flex-col">
            <span>Invite {role.name}(s) to</span>
            <span className="tw-ml-1 tw-font-semibold"> {program.name}</span>
            <span>(Total: {total})</span>
          </div>
          <div className="tw-mb-4">
            <span className="tw-text-lg">Email Address</span>
            {!multiOpen &&
              fields?.map((f, index) => (
                <Controller
                  control={control}
                  name={`email.${index}.email`}
                  render={({ field }) => (
                    <div className="tw-mt-2 tw-relative tw-group">
                      <Input
                        placeholder="name@example.com"
                        className="tw-w-full tw-mt-2"
                        type="email"
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                      />
                      <i
                        className="fas fa-times tw-absolute tw-top-1 tw-right-3 tw-cursor-pointer tw-transform tw-translate-y-1/2 tw-opacity-25 group-hover:tw-opacity-100"
                        onClick={() => handleRemove(index)}
                      ></i>
                    </div>
                  )}
                />
              ))}
            {multiOpen && (
              <div className="tw-mt-2 tw-flex tw-justify-center tw-h-20">
                <Input
                  as="textarea"
                  rows={3}
                  className="tw-w-full tw-p-2 tw-border tw-border-gray-100 tw-rounded-md tw-resize-none tw-h-20"
                  placeholder="You may add multiple emails separated by commas e.g. a@.com,a@b.com"
                  ref={multiRef}
                />
                <button
                  type="button"
                  className="tw-ml-2 tw-px-4 tw-py-2 tw-bg-[#428ac9] tw-text-white tw-rounded-md tw-cursor-pointer"
                  onClick={handleCloseMulti}
                >
                  Done
                </button>
              </div>
            )}
            {!multiOpen && (
              <>
                <div className="tw-text-center tw-mt-5">
                  <button
                    type="button"
                    className="tw-text-[#428ac9] tw-cursor-pointer"
                    onClick={handleAddOther}
                  >
                    + add another
                  </button>{" "}
                  or{" "}
                  <button
                    type="button"
                    className="tw-text-[#428ac9] tw-cursor-pointer"
                    onClick={() => setMultiOpen(true)}
                  >
                    add many at once
                  </button>
                </div>
                <div className="tw-my-5 tw-flex tw-justify-center tw-items-center">
                  <Button
                    appearance="primary"
                    type="submit"
                    disabled={total === 0}
                    className="tw-text-lg"
                  >
                    Send Invite
                  </Button>
                </div>
              </>
            )}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className="tw-flex tw-justify-center gap-5 tw-items-center tw-w-full tw-mt-5">
          <div className="tw-flex tw-flex-col tw-items-center tw-gap-4">
            <div
              className="tw-cursor-pointer tw-text-[#428ac9] tw-rounded-md tw-border tw-border-solid tw-border-[#428ac9] tw-flex tw-items-center tw-justify-center tw-mx-auto tw-w-14 tw-h-14"
              onClick={handleShareLink}
            >
              <LuLink className="tw-text-2xl" />
            </div>
            <span className="tw-text-sm">Share Invite Link</span>
          </div>
          {/* <div className="tw-flex tw-flex-col tw-items-center tw-gap-4">
            <div className="tw-cursor-pointer tw-text-[#428ac9] tw-rounded-md tw-border tw-border-solid tw-border-[#428ac9] tw-flex tw-items-center tw-justify-center tw-mx-auto tw-w-14 tw-h-14">
              <MdOutlinePermContactCalendar className="tw-text-2xl" />
            </div>
            <span className="tw-text-sm">Import</span>
          </div> */}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default InvitationModal;
