// React
import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

// FutureLab
import jobIcon from "../../../../../images/job_icon.png";
import companyIcon from "../../../../../images/company_icon.png";
import mentorIcon from "../../../../../images/mentor_icon.png";
import bgImage from "../../../../../images/mentor_profile_background.png";
import ReviewTag from "../../../../ui/ReviewTag";
import avatarPlaceholder from "../../../../../images/avatar_placeholder.png";
import translateText from "../../../../../util/translateText";

// UI
import { FaShareAlt, FaStar } from "react-icons/fa";
import {
  Button,
  Col,
  Divider,
  FlexboxGrid,
  IconButton,
  Message,
  Stack,
  toaster,
} from "rsuite";

// 3rd Party
import * as humanizeDuration from "humanize-duration";
import { DateTime, Interval } from "luxon";
import { CopyToClipboard } from "react-copy-to-clipboard";

const SimilarMentors = (props) => {
  const { mentor } = useOutletContext<any>();
  const navigate = useNavigate();
  if (!mentor.similar_mentors.length) return null;

  return (
    <FlexboxGrid
      justify="center"
      className="tw-z-20"
      style={{ background: "#fefefe" }}
    >
      <FlexboxGrid.Item as={Col} colspan={22} xs={22} md={20} lg={18}>
        <Divider />
        <div className="tw-mb-8">
          <p className="tw-font-bold tw-text-base">
            {translateText(props.convertText, "Mentor with similar background")}
          </p>
          <Stack
            className="tw-overflow-x-auto no-scrollbar"
            alignItems="flex-start"
          >
            {mentor.similar_mentors.map((mentor) => (
              <div
                className="tw-w-64 tw-px-3 tw-py-4 tw-border-solid tw-border tw-border-gray-300 tw-rounded-lg tw-mr-5 tw-mb-7 tw-relative"
                key={mentor.id}
              >
                <img
                  src={bgImage}
                  className="tw-absolute tw-w-full tw-top-0 tw-left-0"
                  style={{ zIndex: "-2" }}
                />
                <div className="tw-flex">
                  <img
                    src={mentor.profile_photo.url}
                    alt=""
                    className="tw-w-20 tw-rounded-full"
                    style={{ maxHeight: 80 }}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = avatarPlaceholder;
                    }}
                  />
                  <div className="tw-ml-3 tw-text-sm tw-flex-1">
                    <CopyToClipboard
                      text={`${window.location.protocol}//${window.location.host}/mentor_profile/${mentor.slug}`}
                      onCopy={() =>
                        toaster.push(
                          <Message showIcon type="success">
                            {translateText(
                              props.convertText,
                              "Copied to clipboard"
                            )}
                          </Message>
                        )
                      }
                    >
                      <IconButton
                        size="xs"
                        icon={<FaShareAlt />}
                        className="tw-float-right tw-rounded-lg"
                        style={{ color: "#1675E0" }}
                      />
                    </CopyToClipboard>
                    {mentor.fl_average_rating && (
                      <div className="tw-text-white tw-font-bold tw-mb-2">
                        <FaStar className="tw-mr-2" />{" "}
                        {mentor.fl_average_rating.toFixed(1)}{" "}
                      </div>
                    )}
                    <p className="tw-text-sm tw-text-white tw-font-bold">
                      {mentor.firstname} {mentor.lastname}
                    </p>
                  </div>
                </div>
                <p className="tw-my-2 tw-text-sm">
                  <img src={jobIcon} className="tw-mr-2" />{" "}
                  {mentor.info.job_title}
                </p>
                <p className="tw-mb-2 tw-text-sm">
                  <img src={companyIcon} className="tw-mr-2" />{" "}
                  {mentor.info.company}
                </p>
                <p className="tw-mb-2 tw-text-sm">
                  <img src={mentorIcon} className="tw-mr-2" />{" "}
                  {translateText(props.convertText, "Mentor since")}{" "}
                  {DateTime.fromISO(mentor.created_at).year} (
                  {humanizeDuration(
                    Interval.fromDateTimes(
                      DateTime.fromISO(mentor.created_at),
                      DateTime.local()
                    )
                      .toDuration()
                      .valueOf(),
                    { largest: 2, round: true }
                  )}
                  )
                </p>
                <div className="tw-mb-4 tw-overflow-x-auto no-scrollbar tw-flex">
                  {mentor.skills.map((skill) => (
                    <ReviewTag
                      text={skill.name}
                      key={skill.id}
                      size="xs"
                      className="tw-mb-1"
                    />
                  ))}
                  {mentor.skills.length === 0 &&
                    mentor.industries.map((industry) => (
                      <ReviewTag
                        text={industry.name}
                        key={industry.id}
                        size="xs"
                        className="tw-mb-1"
                      />
                    ))}
                </div>
                <Button
                  appearance="ghost"
                  block
                  onClick={() => navigate(`/mentor_profile/${mentor.slug}`)}
                >
                  {translateText(props.convertText, "See profile")}
                </Button>
              </div>
            ))}
          </Stack>
        </div>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

export default SimilarMentors;
