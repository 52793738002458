import React, { useState } from "react";

// FL
import { useEnterprise } from "../../../../contexts/enterpriseContext";
import { loginEnterprise } from "../../api";
import Toaster from "../../../shared/Toaster";

// UI
import { Button, Form } from "rsuite";
import { FormInputText } from "../../../form/default/FormInputs";
import { FaEye, FaEyeSlash } from "react-icons/fa";

// 3rd party
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";

const schema = yup.object().shape({
  email: yup.string().email().required("Email is required").nullable(),
  password: yup.string().required("Password is required").nullable(),
});

const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    }
  });

  const handleShowPassword = () => setShowPassword(!showPassword);

  const { loginAdmin } = useEnterprise();
  const onSubmit = async (data) => {
    try {
      const response = await loginEnterprise(data);
      loginAdmin(response.data);
      Toaster({
        type: "success",
        msg: "Login successful",
      });
    } catch (error) {
      const data = error.response.data;
      Toaster({
        type: "error",
        msg: data.message ? data.message : "Oops, something went wrong!",
      });
    }
  };

  return (
    <>
      <Form>
        <Form.Group>
          <FormInputText
            name={"email"}
            label={"Email"}
            placeholder={"Email"}
            control={control}
            errors={errors}
          />
        </Form.Group>
        <Form.Group>
          <div className="tw-mb-1 tw-flex tw-justify-between">
            <span
              style={{
                fontSize: "0.8rem",
                lineHeight: "1.8",
                color: "#0a0a0a",
              }}
            >
              Password
            </span>
            <span
              onClick={handleShowPassword}
              style={{ userSelect: "none" }}
              className="tw-text-sm tw-cursor-pointer tw-flex tw-items-center"
            >
              {showPassword ? (
                <>
                  <FaEyeSlash className="tw-mr-2" /> Hide
                </>
              ) : (
                <>
                  <FaEye className="tw-mr-2" /> Show
                </>
              )}
            </span>
          </div>
          <FormInputText
            type={showPassword ? "text" : "password"}
            name={"password"}
            placeholder={"Password"}
            control={control}
            errors={errors}
          />
        </Form.Group>
        <Button
          loading={isSubmitting}
          onClick={handleSubmit(onSubmit)}
          className="tw-mt-10"
          appearance="primary"
          block
          type="submit"
        >
          Login
        </Button>
        <p className="montserrat tw-mt-5 tw-text-sm tw-text-[#222] tw-text-center">
          Forgot your password?{" "}
          <Link to="/forgot-password">Click here to recover it</Link>
        </p>
      </Form>
    </>
  );
};

export default LoginForm;
