import React from 'react';
import { Form, Radio, RadioGroup } from "rsuite";
import { ErrorMessage } from "@hookform/error-message";
import FieldError from "../ui/FieldError";
import { Controller } from 'react-hook-form';
import translateText from "../../util/translateText";

const MentoringTime = ({ convertText, control, errors }) => {
  
  return (
    <Form fluid className="tw-mt-6">
      <Form.Group className="tw-text-base">
        <Form.ControlLabel className="tw-text-base">
          {translateText(convertText, "How many hours per month can you mentor?")}
          <Form.HelpText tooltip>
            {translateText(convertText, "Your availability allows us to set your mentoring hours for upcoming events")}
          </Form.HelpText>
        </Form.ControlLabel>
        <Controller
          name="info.total_mentoring_hour"
          control={control}
          render={({ field: { onChange, value } }) => (
            <RadioGroup onChange={onChange} value={value.toString()}>
              <Radio value="0">{translateText(convertText, "Less than 4 hours a month")}</Radio>
              <Radio value="1">{translateText(convertText, "4 - 10 hours a month")}</Radio>
              <Radio value="2">{translateText(convertText, "More than 10 hours a month")}</Radio>
            </RadioGroup>
          )}
        />
        <ErrorMessage
          errors={errors}
          name="info.total_mentoring_hour"
          as={FieldError}
        />
      </Form.Group>
    </Form>
  );
};

export default MentoringTime;
