import React from "react";
import { Button, Loader, Message, Stack, toaster, Uploader } from "rsuite";
import { useUser } from "../../contexts/user";
import FieldError from "../ui/FieldError";
import { FaCamera, FaUserAlt } from "react-icons/fa";
import { last } from "lodash";
import Api from "../../util/api";
import LinkButton from "../ui/LinkButton";
import { FileType } from "rsuite/esm/Uploader/Uploader";
import ProfilePhotoGuideModal from "./ProfilePhotoGuideModal";
import translateText from "../../util/translateText";

const UserProfilePhotoUploader = (props) => {
  const { user, setUser } = useUser();
  const [uploading, setUploading] = React.useState(false);
  const [fileInfo, setFileInfo] = React.useState(user.profile_photo_url);
  const [selectedFiles, setSelectedFiles] = React.useState<FileType[]>();
  const [showProfilePhotoGuideModal, setShowProfilePhotoGuideModal] =
    React.useState(false);
  const uploaderRef = React.useRef(null);

  React.useEffect(() => {
    if (selectedFiles) {
      uploadPhoto();
    }
  }, [selectedFiles]);

  const previewFile = (file, callback) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const uploadPhoto = () => {
    if (!selectedFiles) return;
    const file = last(selectedFiles).blobFile;
    setUploading(true);
    let formData = new FormData();
    formData.append("profile_photo", file);

    Api.post("/users/current/update_profile_photo", formData, {
      headers: { "content-type": "multipart/form-data" },
    })
      .then((response) => {
        setUploading(false);
        setSelectedFiles(undefined);
        setUser(response.data);
        toaster.push(
          <Message showIcon type="success">
            {translateText(props.convertText, "File uploaded successfully")}
          </Message>
        );
      })
      .catch((error) => {
        setFileInfo(null);
        setUploading(false);
        setSelectedFiles(undefined);
        toaster.push(
          <Message showIcon type="error">
            {error.response.data.error}
          </Message>
        );
        console.error(error);
      });
  };

  return (
    <>
      <Stack spacing={8} alignItems="flex-end">
        <Uploader
          accept=".jpg, .png"
          fileListVisible={false}
          listType="picture"
          onChange={(files) => {
            previewFile(last(files).blobFile, (value) => {
              setFileInfo(value);
            });
            setSelectedFiles(files);
          }}
          autoUpload={false}
          action=""
        >
          <button style={{ width: 100, height: 100 }} ref={uploaderRef}>
            {uploading && <Loader backdrop center />}
            {fileInfo ? (
              <img src={fileInfo} width="100%" height="100%" />
            ) : (
              <FaUserAlt className="tw-text-4xl" />
            )}
          </button>
        </Uploader>
        <Stack
          className="tw-mb-2"
          direction="column"
          alignItems="flex-start"
          spacing={8}
        >
          <LinkButton
            className="tw-text-xs"
            onClick={() => setShowProfilePhotoGuideModal(true)}
          >
            {translateText(props.convertText, "Image guide")}
          </LinkButton>
          <Button
            appearance="primary"
            className="tw-font-medium"
            onClick={() => uploaderRef.current.click()}
          >
            <FaCamera /> {translateText(props.convertText, "Upload")}
          </Button>
        </Stack>
      </Stack>
      {!fileInfo && (
        <FieldError>
          {translateText(props.convertText, "Photo is required")}
        </FieldError>
      )}
      <ProfilePhotoGuideModal
        open={showProfilePhotoGuideModal}
        handleClose={() => setShowProfilePhotoGuideModal(false)}
      />
    </>
  );
};

export default UserProfilePhotoUploader;
