// React
import React from "react";
import { useOutletContext } from "react-router-dom";

// FutureLab
import translateText from "../../../../../util/translateText";
import menteesHelpedImg from "../../../../../images/mentorprofile-impact-mentee-helped.png";
import mentoringSessionImg from "../../../../../images/mentorprofile-impact-mentoring-sessions.png";
import programsJoinedImg from "../../../../../images/mentorprofile-impact-programme-joined.png";
import ActivityCard from "../../../shared/ActivityCard";

const MentorImpact = ({ convertText }) => {
  const { mentor } = useOutletContext<any>();
  const {
    mentees_helped_count: menteesHelpedCount,
    mentoring_sessions_count: mentoringSessionsCount,
    programs_joined_count: programsJoinedCount,
  } = mentor;

  return (
    <div>
      <p className="tw-font-bold tw-text-base montserrat">
        {translateText(convertText, "Impact")}
      </p>
      <div className="tw-w-full tw-flex tw-flex-row tw-flex-wrap">
        <div className="tw-flex tw-flex-col tw-w-1/3 sm:tw-w-1/2">
          <ActivityCard
            convertText={convertText}
            img={menteesHelpedImg}
            count={menteesHelpedCount}
            text="Mentees Helped"
          />
        </div>
        <div className="tw-flex tw-flex-col tw-w-1/3 sm:tw-w-1/2">
          <ActivityCard
            convertText={convertText}
            img={mentoringSessionImg}
            count={mentoringSessionsCount}
            text="Mentoring Sessions"
          />
        </div>
        <div className="tw-flex tw-flex-col tw-w-1/3 sm:tw-w-1/2">
          <ActivityCard
            convertText={convertText}
            img={programsJoinedImg}
            count={programsJoinedCount}
            text="Programs Joined"
          />
        </div>
      </div>
    </div>
  );
};

export default MentorImpact;
