import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  MdOutlineFontDownload,
  MdOutlineFontDownloadOff,
  MdOutlineSpeakerNotes,
  MdOutlineSpeakerNotesOff,
} from "react-icons/md";

import { Divider, Form, RadioTile, RadioTileGroup } from "rsuite";
import FormSubmitButton from "../appearance/FormSubmitButton";

const PrivacyForm = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <Form>
        <Form.Group>
          <Form.ControlLabel>Privacy Settings</Form.ControlLabel>
          <span className="tw-text-sm">
            Here you are able to choose if your portal is open to anyone or they
            are required to send you an application to join your portal.
          </span>
          <Controller
            name={"program.privacy_settings"}
            control={control}
            render={({ field }) => (
              <RadioTileGroup {...field} className="tw-mt-6">
                <RadioTile
                  value={"open"}
                  label="Application required"
                  icon={
                    <MdOutlineFontDownload
                      className="tw-mx-2"
                      style={{ color: "#696969" }}
                    />
                  }
                >
                  <span className="tw-text-sm">
                    Users are required admin permission to join the portal. This
                    is for closed group portals.
                  </span>
                </RadioTile>
                <RadioTile
                  value={"closed"}
                  label="No application needed"
                  icon={
                    <MdOutlineFontDownloadOff
                      className="tw-mx-2"
                      style={{ color: "#696969" }}
                    />
                  }
                >
                  <span className="tw-text-sm">
                    Users are able to join your portal without admin permission.
                    This is suitable for portals that are creating an open
                    community to share knowledge.
                  </span>
                </RadioTile>
              </RadioTileGroup>
            )}
          />
        </Form.Group>
        <Divider />
        <Form.Group>
          <Form.ControlLabel>Reviews Privacy</Form.ControlLabel>
          <span className="tw-text-sm">
            After every session, mentor and mentee are required to rate each
            other. Here you can set if you wish to display the reviews or not.
          </span>
          <Controller
            name={"program.review_settings"}
            control={control}
            render={({ field }) => (
              <RadioTileGroup {...field} className="tw-mt-6">
                <RadioTile
                  value={"shown"}
                  label={"Public"}
                  icon={
                    <MdOutlineSpeakerNotes
                      className="tw-mx-2"
                      style={{ color: "#696969" }}
                    />
                  }
                >
                  <span className="tw-text-sm">
                    Mentors and mentees can see the reviews they were given.
                  </span>
                </RadioTile>
                <RadioTile
                  value={"hidden"}
                  label={"Private"}
                  icon={
                    <MdOutlineSpeakerNotesOff
                      className="tw-mx-2"
                      style={{ color: "#696969" }}
                    />
                  }
                >
                  <span className="tw-text-sm">
                    Only Admin can view all the reviews by mentors and mentees.
                  </span>
                </RadioTile>
              </RadioTileGroup>
            )}
          />
        </Form.Group>
      </Form>
      <Divider />
      <div className="tw-flex tw-flex-row-reverse">
        <FormSubmitButton />
      </div>
    </div>
  );
};

export default PrivacyForm;
