// React
import React from "react";

// FutureLab
import { useChatAuth } from "../../contexts/chatAuth";

// 3rd Party
import axios from "axios";

const useGroupChatApi = (token: string, baseURL: string, programId: number) => {
  const api = axios.create({
    baseURL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      programId,
    },
  });

  return api;
};

const useGroupChat = ({
  programId = 1,
  baseUrl = "https://apichat.futurelab.global/api",
  programName = "futurelab",
}) => {
  const [groupChats, setGroupChats] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [token] = useChatAuth();
  const api = useGroupChatApi(token, baseUrl, programId);

  const getGroupChats = async () => {
    setLoading(true);
    try {
      const res = await api.get("/chat/group-chat", {
        params: {
          programId,
        },
      });
      setGroupChats(res.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteUsers = async (chatId: number, userIds: number[]) => {
    try {
      await api.delete(`/chat/group-remove`, {
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          chatId,
          recipientUserIds: userIds,
          programName,
        },
      });
      await getGroupChats();
    } catch (error) {
      console.log(error);
    }
  };

  const addUsers = async (chatId: number, userIds: number[]) => {
    try {
      await api.post(`/chat/group-add`, {
        chatId,
        recipientUserIds: userIds,
        programName,
      });
      await getGroupChats();
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    token && getGroupChats();
  }, [token]);

  return {
    groupChats,
    loading,
    deleteUsers,
    addUsers,
    api,
    getGroupChats,
  };
};

export default useGroupChat;
