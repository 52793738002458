import React from 'react';
import toSentenceCase from '../../util/toSentenceCase';

const FieldError = (props) => {
  const { children } = props;

  return (
    <div className="tw-text-red-500 tw-py-1">{toSentenceCase(children)}</div>
  );
};

export default FieldError;
