// React
import React from "react";

// FutureLab
import translateText from "../../../util/translateText";

export default function ActivityCard({ convertText, img, count, text }) {
  return (
    <>
      <div className="tw-flex tw-items-center tw-mb-2">
        <img src={img} alt="" className="tw-mr-3 tw-h-10" />
        <span className="tw-text-2xl tw-font-bold montserrat">{count}</span>
      </div>
      <div>
        <p
          className="tw-text-sm tw-font-medium montserrat"
          style={{ color: "#979797" }}
        >
          {translateText(convertText, text)}
        </p>
      </div>
    </>
  );
}
