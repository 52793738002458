import axios from 'axios';
import Cookies from 'js-cookie';
import {merge} from 'lodash';

const urls = {
  development: 'http://lvh.me:3000/api/admin/v1',
  production: 'https://futurelab.my/api/admin/v1',
  staging: 'https://futurelab.global/api/admin/v1',
};

class ApiFlAdmin {
  static uri(path) {
    const ROOT_URL = urls[process.env.RAILS_ENV];

    return ROOT_URL + path;
  }

  static headers() {
    return {
      'content-type': 'application/json',
      accept: 'application/json',
    };
  }

  static headersWithToken() {
    return Object.assign(this.headers(), {
      authorization: `Bearer ${this.getToken()}`,
    });
  }

  static httpRequest(path, opts?) {
    let headers = !this.hasToken() ? this.headers() : this.headersWithToken();
    if (opts.headers) headers = merge(headers, opts.headers);

    let url = this.uri(path);

    let options = merge({}, { url, headers }, opts);

    return axios(url, options);
  }

  static get(path, params?, opts?) {
    opts = merge({}, { method: 'GET', params }, opts);

    return this.httpRequest(path, opts);
  }

  static post(path, params, opts?) {
    opts = merge({}, { method: 'POST', data: params }, opts);

    return this.httpRequest(path, opts);
  }

  static delete(path, params, opts?) {
    opts = merge({}, { method: 'DELETE', data: params }, opts);

    return this.httpRequest(path, opts);
  }

  static put(path, params, opts?) {
    opts = merge({}, { method: 'PUT', data: params }, opts);

    return this.httpRequest(path, opts);
  }

  static patch(path, params, opts?) {
    opts = merge({}, { method: 'PATCH', data: params }, opts);

    return this.httpRequest(path, opts);
  }

  static hasToken() {
    return this.getToken() ? true : false;
  }

  static getToken() {
    return Cookies.get(`pwa_${process.env.RAILS_ENV}_session`);
  }
}

export default ApiFlAdmin;

type FlAdminApi = {
  method: string;
  path: string;
  options?: object;
  success?: (response: any) => void;
  error?: (err: any) => void;
  config?: object;
};

export const FlAdminApi = ({
  method,
  path,
  options,
  success,
  error,
  config = {},
}: FlAdminApi) => {
  return ApiFlAdmin[method](path, options, config)
    .then((response) => {
      if (success) success(response);
    })
    .catch(function (err) {
      console.error(err);
      if (error) error(err);
    });
};