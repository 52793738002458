// React
import React from "react";

// FutureLab
import translateText from "../../../util/translateText";
import { UserType } from "../../../contexts/mentor_profile";

export default function Languages({ user, convertText }) {
  const { languages, user_type } = user;

  const text =
    user_type === UserType.Mentee
      ? "Spoken Languages"
      : "Languages I can mentor in";

  return (
    <div>
      <p className="tw-font-bold tw-text-base montserrat">
        {translateText(convertText, text)}
      </p>
      <ul>
        {languages?.map((language, index) => (
          <li key={index} className="tw-text-base montserrat">
            {language.name}
          </li>
        ))}
      </ul>
    </div>
  );
}
