import React from "react";
import { PortalProvider, withPortal } from "../../contexts/portal";
import AppContainer from "../AppContainer";
import SessionManagement from "./session_management/SessionManagement";

const PortalSessionManagement = ({ enterpriseSlug, programSlug }) => {
  return (
    <AppContainer>
      <PortalProvider enterpriseSlug={enterpriseSlug} programSlug={programSlug}>
        <SessionManagement
          enterpriseSlug={enterpriseSlug}
          programSlug={programSlug}
        />
      </PortalProvider>
    </AppContainer>
  );
};

export default PortalSessionManagement;
