// React
import React from "react";

// UI
import { Button, Message, Modal, toaster } from "rsuite";

// 3rd Party
import axios from "axios";

const updateUserRolesData = (setUserRolesData, selectedUser) => {
  setUserRolesData((prevUserRolesData) => {
    const updatedUserRolesData = prevUserRolesData.map((role) => {
      if (role.id === selectedUser.role_id) {
        return {
          ...role,
          users: role.users.filter((user) => user.id !== selectedUser.id),
        };
      }
      return role;
    });

    return updatedUserRolesData;
  });
};

export default function RemoveUserModal({
  open,
  close,
  selectedUser,
  token,
  removeUserPath,
  setUserRolesData,
}) {
  const handleSubmit = async () => {
    try {
      await axios.post(removeUserPath, null, {
        headers: {
          "X-CSRF-Token": token,
        },
        params: {
          role_id: selectedUser.role_id,
          user_id: selectedUser.id,
        },
      });
    } catch (err) {
      console.log(err);
      toaster.push(
        <Message showIcon type="error">
          An error occured. Please try again later
        </Message>,
        { duration: 5000 }
      );
    } finally {
      updateUserRolesData(setUserRolesData, selectedUser);

      toaster.push(
        <Message showIcon type="success">
          {`${selectedUser.full_name} has been successfully removed.`}
        </Message>,
        { duration: 5000 }
      );

      close();
    }
  };

  return (
    <Modal
      open={open}
      backdrop="static"
      className="tw-mt-8 tw-mb-16"
      size="sm"
      onClose={close}
    >
      <Modal.Header>
        <Modal.Title className="tw-font-semibold">Removing User</Modal.Title>
      </Modal.Header>
      <Modal.Body className="tw-pb-1">
        <p className="montserrat tw-text-sm tw-font-medium tw-text-[#222222]">
          {`Name: ${selectedUser.full_name}`}
        </p>{" "}
        <p className="montserrat tw-text-sm tw-font-medium tw-text-[#222222]">
          {`Email: ${selectedUser.email}`}
        </p>
        <p className="montserrat tw-text-sm tw-font-medium">
          Removing this user from the portal will hide all his/her bookings,
          mentor pairings, newsfeed posts, newsfeed comments & newsfeed likes.
        </p>
        <p className="montserrat tw-text-sm tw-font-medium">
          Do you want to remove this user?
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button appearance="ghost" onClick={close}>
          Cancel
        </Button>
        <Button appearance="primary" onClick={handleSubmit}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
