import moment from "moment";

export const formatDateTime = (time, format) => {
  const formattedDate = moment(time).format(format);

  return formattedDate;
};


export const isOnSameDay = (start_date, end_date) => {

 return moment(start_date).isSame(end_date, "day")
}
