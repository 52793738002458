// React
import React, { useEffect, useState, useMemo } from "react";

// FutureLab
import { FutureLabApi } from "../../util/api";
import TooltipHelper from "../ui/TooltipHelper";
import UserCards from "./users/UserCards";
import translateText from "../../util/translateText";
import DashboardCredits from "./DashboardCredits";
import profileNotComplete from "../../images/profile_not_complete.png";
import UserRecommendationModal from "../shared/UserRecommendationModal";
import HorizontalScroller from "../ui/HorizontalScroller";

// UI
import { Button, Nav, Stack } from "rsuite";
import { FaRegListAlt } from "react-icons/fa";

// 3rd Party
import _ from "lodash";
import moment from "moment";
import mixpanel from "mixpanel-browser";

type Data = {
  suggested: any[];
  my_mentors: any[];
  following: any[];
  latest_recommendation: string;
};

const url = {
  production: "https://app.futurelab.my/profile/setup",
  staging: "https://app.futurelab.global/profile/setup",
  development: "http://localhost:4000/profile/setup",
};

const env = process.env.RAILS_ENV;

const linkUrl = url[env];

const DashboardMentors = ({ convertText, currentUser }) => {
  const [data, setData] = useState<Data>(undefined);
  const [recall, setRecall] = useState(false);
  const [activeKey, setActiveKey] = useState("1");
  const [openModal, setOpenModal] = useState(true);
  const [isDisplay, setIsDisplay] = useState(false);

  const suggested = useMemo(() => data?.suggested || [], [data]);
  const mymentors = useMemo(() => data?.my_mentors || [], [data]);
  const following = useMemo(() => data?.following || [], [data]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await FutureLabApi({
          method: "get",
          path: `/dashboard/users/mentors`,
          success: ({ data: res }) => {
            setData(res);

            if (res.suggested) {
              setRecall(false);
            }

            const storedData = localStorage.getItem("newMatchedUser");

            if (!storedData) {
              return;
            }

            const { lastRecommendation, isReceived } = JSON.parse(storedData);
            setIsDisplay(isReceived);

            if (
              res?.latest_recommendation &&
              moment(res?.latest_recommendation).isAfter(
                moment(lastRecommendation)
              )
            ) {
              // If the timestamp has expired, clear the localStorage and return null
              localStorage.removeItem("newMatchedUser");
              setIsDisplay(false);
              return null;
            }
          },
        });
      } catch (error) {
        // Handle error fetching data
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [recall]);

  const mentorStatus = currentUser.is_fl_mentor
    ? "Network with mentors"
    : "Mentors";

  const dataitems = useMemo(
    () => activeDisplay(activeKey, data),
    [activeKey, data]
  );

  const handleCloseModal = (action = null, user) => {
    if (action === "view_profile") {
      window.open(
        `/mentor_profile/${user.slug}`,
        "_self"
      );
      mixpanel.track("Clicked View Profile on Recommendation Pop Up", {
        Name: `${currentUser?.firstname} ${currentUser?.lastname}`,
        "User Type": currentUser?.user_type,
        "Mentor Name": user.fullname,
        "Mentor Profession": user.info,
      });
    } else {
      mixpanel.track("Close Recommendation Pop Up", {
        Name: `${currentUser?.firstname} ${currentUser?.lastname}`,
        "User Type": currentUser?.user_type,
      });
    }

    setOpenModal(false);
    localStorage.setItem(
      "newMatchedUser",
      JSON.stringify({
        isReceived: true,
        lastRecommendation: data?.latest_recommendation,
      })
    );
  };

  let new_matches = suggested.filter((user) => user.is_new_matched === true);

  return (
    <>
      {!_.isEmpty(new_matches) && !isDisplay && (
        <UserRecommendationModal
          mentors={new_matches}
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          FLDashboard={true}
        />
      )}
      <div className="tw-w-full tw-block">
        <div className="sm:tw-w-full sm:tw-text-center sm:tw-mb-4 tw-mb-5 tw-flex tw-justify-between ">
          <div className="tw-inline-flex tw-items-center">
            <p className="section-header montserrat">
              {translateText(convertText, mentorStatus)}
            </p>
            <TooltipHelper
              tip={"These are the mentors that you have connected with"}
            />
          </div>
          {!currentUser.is_fl_mentor && (
            <DashboardCredits convertText={convertText} />
          )}
        </div>
        <Nav
          appearance="subtle"
          activeKey={activeKey}
          onSelect={setActiveKey}
          className="tw-mb-5 xs:tw-overflow-x-auto no-scrollbar"
          justified
        >
          <Nav.Item
            eventKey="1"
            className="tw-flex tw-justify-center tw-min-w-[140px]"
          >
            {translateText(convertText, "Matched")} ({suggested?.length || 0})
          </Nav.Item>
          <Nav.Item
            eventKey="2"
            className="tw-flex tw-justify-center tw-min-w-[140px]"
          >
            {translateText(convertText, "My Mentors")} ({mymentors?.length || 0}
            )
          </Nav.Item>
          <Nav.Item
            eventKey="3"
            className="tw-flex tw-justify-center tw-min-w-[140px]"
          >
            {translateText(convertText, "Following")} ({following?.length || 0})
          </Nav.Item>
        </Nav>
      </div>

      {dataitems?.data?.length > 0 ? (
        <HorizontalScroller>
          <Stack
            className="tw-overflow-x-auto no-scrollbar"
            alignItems="stretch"
          >
            {dataitems.data.map((user) => (
              <UserCards
                key={user.id}
                user={user}
                setRecall={setRecall}
                currentUser={currentUser}
                convertText={convertText}
              />
            ))}
            {activeKey === "1" && (
              <a href="/discover/mentors">
                <div className="user-see-all-wrapper">
                  <span>
                    <FaRegListAlt className="tw-w-12 tw-h-12" color="#428AC9" />
                  </span>
                  {translateText(convertText, "See all")}
                </div>
              </a>
            )}
          </Stack>
        </HorizontalScroller>
      ) : currentUser?.goals?.length < 1 &&
        currentUser?.user_type === "mentee" ? (
        <div className="sm:tw-mb-4 tw-flex tw-flex-col tw-items-center tw-h-[235px] tw-justify-between tw-pt-10 tw-gap-2 sm:tw-h-auto">
          <img
            src={profileNotComplete}
            alt="profile not complete"
            className="tw-w-20 tw-h-20"
          />
          <p className="montserrat tw-text-center tw-mb-5">
            Complete your profile to start seeing Mentor recommendations!
          </p>
          <Button appearance="primary" href={linkUrl}>
            Complete my profile
          </Button>
        </div>
      ) : (
        <div className="sm:tw-mb-4 tw-flex tw-flex-col tw-items-center tw-h-[235px] tw-justify-between tw-pt-10 tw-gap-2 sm:tw-h-auto">
          <img
            src={profileNotComplete}
            alt="profile not complete"
            className="tw-w-20 tw-h-20"
          />
          <p className="montserrat tw-text-center tw-mb-5">
            {dataitems.empty_text}
          </p>
          <Button appearance="primary" href={dataitems.button_link}>
            {translateText(convertText, "Find a mentor")}
          </Button>
        </div>
      )}
    </>
  );
};

function activeDisplay(
  activeKey,
  data = {
    suggested: [],
    my_mentors: [],
    following: [],
  }
) {
  const { suggested, my_mentors, following } = data;
  const obj = {
    1: {
      data: _.orderBy(suggested, ["is_new_matched"], ["desc"]),
      empty_text: "You do not have any suggested mentors at the moment.",
      button_link: "/discover/mentors",
    },
    2: {
      data: my_mentors,
      empty_text:
        "You are not currently connected with any mentors. Speak to your first mentor now!",
      button_link: "/discover/mentors",
    },
    3: {
      data: following,
      empty_text:
        "You are not currently not following any mentors. Follow your first mentor now!",
      button_link: "/discover/mentors",
    },
  };
  return obj[activeKey];
}

export default DashboardMentors;
