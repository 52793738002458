import React from "react";
import { Message, toaster } from "rsuite";

const Toaster = ({
  type,
  msg,
  closable = false,
  showIcon = true,
  ...restProps
}) =>
  toaster.push(
    <Message closable={closable} type={type} showIcon={showIcon} {...restProps}>
      {msg}
    </Message>
  );

export default Toaster;
