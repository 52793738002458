import React, { useState } from "react";
import { FaCheckCircle, FaRegTimesCircle } from "react-icons/fa";
import { Button, Divider, Modal } from "rsuite";

import bannerGuide from "../../../../images/banner_guide.png";
import logoGuideA from "../../../../images/setup-image-guide-modal-1.png";
import logoGuideB from "../../../../images/setup-image-guide-modal-2.png";
import logoGuideC from "../../../../images/setup-image-guide-modal-3.png";

const PortalImageGuideModal = (props) => {
  const { isBanner } = props;

  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Button
        appearance="link"
        className="tw-text-xs tw-px-0"
        onClick={() => setOpenModal(true)}
      >
        Image Guide
      </Button>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        className="tw-mt-16"
        size="xs"
      >
        <Modal.Header>
          <Modal.Title className="tw-font-medium">
            {`Programme ${isBanner ? "Banner" : "Logo"}  image guide`}
          </Modal.Title>
          <Divider className="tw-absolute tw-w-full tw-left-0 tw-mt-4 tw-bg-gray-300" />
        </Modal.Header>
        <Modal.Body>
          {isBanner ? (
            <div className="tw-text-center tw-py-7">
              <img src={bannerGuide} alt="" className="tw-w-72" />
              <div className="tw-text-green-600 tw-italic tw-mt-4 tw-text-xs">
                <FaCheckCircle className="tw-mr-2" />
                Perfect!
              </div>
            </div>
          ) : (
            <div className="tw-flex tw-flex-row tw-justify-around tw-py-7 tw-text-center">
              <div>
                <img src={logoGuideA} alt="" className="tw-w-20" />
                <div className="tw-text-red-600 tw-italic tw-mt-4 tw-text-xs">
                  <FaRegTimesCircle className="tw-mr-1" />
                  Don't
                </div>
              </div>
              <div>
                <img src={logoGuideB} alt="" className="tw-w-20" />
                <div className="tw-text-green-600 tw-italic tw-mt-4 tw-text-xs">
                  <FaCheckCircle className="tw-mr-1" />
                  Perfect!
                </div>
              </div>
              <div>
                <img src={logoGuideC} alt="" className="tw-w-20" />
                <div className="tw-text-green-600 tw-italic tw-mt-4 tw-text-xs">
                  <FaCheckCircle className="tw-mr-1" />
                  Perfect!
                </div>
              </div>
            </div>
          )}

          <div>
            <h6>QUICK TIPS!</h6>
            <ul>
              {isBanner ? (
                <li>Recommended resolution: 1440 x 400 (Landscaped)</li>
              ) : (
                <li>Recommended resolution: 300 x 300 (Squared or Rounded)</li>
              )}
              <li>
                File size not exceeding <b>2MB</b>.
              </li>
              <li>
                Only <b>JPG</b> and <b>PNG</b> images are accepted.
              </li>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setOpenModal(false)}
            appearance="primary"
            block
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PortalImageGuideModal;
