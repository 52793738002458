// React
import React, { useState } from "react";

// UI
import { Button, Drawer, Checkbox } from "rsuite";
import { AiOutlineClose } from "react-icons/ai";

const InputLabelCustom = (props) => {
  return (
    <Checkbox
      name={props.name}
      id={props.id}
      checked={props.checked}
      onChange={props.onChange}
    >
      {props.title}
    </Checkbox>
  );
};

export const ShowHideColumn = (props) => {
  const [open, setOpen] = useState(false);
  const openFilter = () => setOpen(true);
  const closeFilter = () => setOpen(false);

  const visible = props.visibleColumns.map((el) => el.id);

  return (
    <>
      <Button appearance="primary" onClick={openFilter}>
        Show/Hide Column
      </Button>
      <Drawer open={open} onClose={closeFilter}>
        <div className="tw-p-5 tw-overscroll-y-auto">
          <div
            style={{
              fontWeight: "bold",
              fontSize: "18px",
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <span>Show Hide Column</span>
            <AiOutlineClose onClick={closeFilter} />
          </div>
          <div className="tw-flex tw-flex-col">
            <InputLabelCustom
              title="All Column"
              checked={visible?.length === 0}
              onChange={props.toggleHideAllColumns}
            />
            {props.allColumns
              .filter((el) => el.id !== "id")
              .map((column) => (
                <InputLabelCustom
                  key={column.id}
                  margin="1rem 0"
                  title={
                    props.column.find((el) => el.accessor === column.id)?.Header
                  }
                  checked={visible.includes(column.id)}
                  onChange={() => props.toggleHideColumn(column.id)}
                />
              ))}
          </div>
        </div>
      </Drawer>
    </>
  );
};
