import React from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Col,
  Divider,
  Form,
  Grid,
  Input,
  InputGroup,
  Message,
  Modal,
  Row,
  Stack,
  toaster,
  Toggle,
} from "rsuite";
import { useUser } from "../../contexts/user";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ErrorMessage } from "@hookform/error-message";
import FieldError from "../ui/FieldError";
import { FutureLabApi } from "../../util/api";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import DeleteAccountModal from "../form/DeleteAccountModal";
import { useVariables } from "../../contexts/variables";
import PreviewSaveButton from "./PreviewSaveButton";
import PanelContainer from "./PanelContainer";
import translateText from "../../util/translateText";

type UserInput = {
  password: string;
  password_confirmation: string;
  current_password: string;
};

const schema = yup.object().shape({
  password: yup.string().required().label("Password"),
  password_confirmation: yup
    .string()
    .required()
    .label("Password confirmation")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  current_password: yup.string().required().label("Current password"),
});

const AccountSecurity = (props) => {
  const { variables } = useVariables();
  const { user, setUser } = useUser();
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<UserInput>({
    defaultValues: {
      password: "",
      password_confirmation: "",
      current_password: "",
    },
    resolver: yupResolver(schema),
  });
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isDeactivating, setIsDeactivating] = React.useState(false);
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const [passwordConfirmationVisible, setPasswordConfirmationVisible] =
    React.useState(false);
  const [currentPasswordVisible, setCurrentPasswordVisible] =
    React.useState(false);
  const [showDeactivateConfirmModal, setShowDeactivateConfirmModal] =
    React.useState(false);

  const onSubmit = (data) => {
    setIsSubmitting(true);
    FutureLabApi({
      method: "post",
      path: "/users/current/change_password",
      options: data,
      success: ({ data }) => {
        setUser(data);
        setIsSubmitting(false);
        toaster.push(
          <Message showIcon type="success">
            {translateText(props.convertText, "Successfully saved.")}
          </Message>
        );
      },
      error: (err) => {
        const { error, messages } = err.response.data;
        setIsSubmitting(false);
        console.log(messages);
        toaster.push(
          <Message showIcon type="error">
            {error}
          </Message>
        );
      },
    });
  };

  const onDeactivateToggle = (value) => {
    if (!value) setShowDeactivateConfirmModal(true);
  };

  const deactivateAccount = () => {
    setIsDeactivating(true);
    FutureLabApi({
      method: "post",
      path: "/users/current/deactivate_account",
      success: ({ data }) => {
        setUser(data);
        setIsDeactivating(false);
        toaster.push(
          <Message showIcon type="success">
            {translateText(props.convertText, "Successfully saved.")}
          </Message>
        );
        window.location.href = `${variables?.PWA_URL}/users/logout`;
      },
      error: (err) => {
        const { error, messages } = err.response.data;
        setIsDeactivating(false);
        console.log(messages);
        toaster.push(
          <Message showIcon type="error">
            {error}
          </Message>
        );
      },
    });
  };

  return (
    <div>
      <PreviewSaveButton noSave />
      <PanelContainer>
        <Grid fluid>
          <Row gutter={24}>
            <Col xs={24} md={12}>
              <Form fluid>
                <Form.Group>
                  <Form.ControlLabel className="tw-text-base">
                    {translateText(props.convertText, "Change Password")}
                  </Form.ControlLabel>
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel className="tw-text-base">
                    {translateText(props.convertText, "New password")}{" "}
                    <span className="tw-text-red-500">*</span>
                  </Form.ControlLabel>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <InputGroup inside className="tw-w-full">
                        <Input
                          type={passwordVisible ? "text" : "password"}
                          size="lg"
                          {...field}
                        />
                        <InputGroup.Button
                          onClick={() => setPasswordVisible(!passwordVisible)}
                        >
                          {passwordVisible ? <FaEye /> : <FaEyeSlash />}
                        </InputGroup.Button>
                      </InputGroup>
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="password"
                    as={FieldError}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel className="tw-text-base">
                    {translateText(props.convertText, "Re-type new password")}
                    <span className="tw-text-red-500">*</span>
                  </Form.ControlLabel>
                  <Controller
                    name="password_confirmation"
                    control={control}
                    render={({ field }) => (
                      <InputGroup inside className="tw-w-full">
                        <Input
                          type={
                            passwordConfirmationVisible ? "text" : "password"
                          }
                          size="lg"
                          {...field}
                        />
                        <InputGroup.Button
                          onClick={() =>
                            setPasswordConfirmationVisible(
                              !passwordConfirmationVisible
                            )
                          }
                        >
                          {passwordConfirmationVisible ? (
                            <FaEye />
                          ) : (
                            <FaEyeSlash />
                          )}
                        </InputGroup.Button>
                      </InputGroup>
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="password_confirmation"
                    as={FieldError}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel className="tw-text-base">
                    {translateText(props.convertText, "Current password")}{" "}
                    <span className="tw-text-red-500">*</span>{" "}
                    <Form.HelpText tooltip>
                      {translateText(
                        props.convertText,
                        "We need your current password to confirm your changes"
                      )}
                    </Form.HelpText>
                  </Form.ControlLabel>
                  <Controller
                    name="current_password"
                    control={control}
                    render={({ field }) => (
                      <InputGroup inside className="tw-w-full">
                        <Input
                          type={currentPasswordVisible ? "text" : "password"}
                          size="lg"
                          {...field}
                        />
                        <InputGroup.Button
                          onClick={() =>
                            setCurrentPasswordVisible(!currentPasswordVisible)
                          }
                        >
                          {currentPasswordVisible ? <FaEye /> : <FaEyeSlash />}
                        </InputGroup.Button>
                      </InputGroup>
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="current_password"
                    as={FieldError}
                  />
                  {/* <LinkButton className="tw-text-base">
                    Forgot password
                  </LinkButton> */}
                </Form.Group>
                <Form.Group>
                  <Button
                    appearance="primary"
                    onClick={handleSubmit(onSubmit)}
                    loading={isSubmitting}
                  >
                    {translateText(
                      props.convertText,
                      "Confirm change password"
                    )}
                  </Button>
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Divider />
          <Row gutter={24}>
            <Col xs={24} md={12} className="sm:tw-mb-4">
              <Form fluid>
                <Form.Group>
                  <Stack justifyContent="space-between">
                    <Form.ControlLabel className="tw-text-base">
                      {translateText(props.convertText, "Account status")}
                    </Form.ControlLabel>
                    <Stack spacing={4}>
                      <span>
                        {user.is_active
                          ? translateText(props.convertText, "Active")
                          : translateText(props.convertText, "Inactive")}
                      </span>{" "}
                      <Toggle
                        checked={user.is_active}
                        onChange={onDeactivateToggle}
                      />
                    </Stack>
                  </Stack>
                  <Modal
                    backdrop="static"
                    open={showDeactivateConfirmModal}
                    onClose={() => setShowDeactivateConfirmModal(false)}
                    size="xs"
                    className="tw-top-1/4"
                  >
                    <Modal.Header>
                      <Modal.Title className="tw-text-xl tw-font-medium">
                        {translateText(props.convertText, "Deactivate Account")}
                      </Modal.Title>
                      <Divider className="tw-absolute tw-w-full tw-left-0 tw-mt-4 tw-bg-gray-300" />
                    </Modal.Header>
                    <Modal.Body className="tw-py-12">
                      <div className="tw-text-center tw-font-bold">
                        {translateText(
                          props.convertText,
                          "Are you sure you want to deactivate your profile?"
                        )}
                      </div>
                      <div className="tw-text-center">
                        {/* {translateText(
                          props.convertText,
                          "Your mentee will missing you a lot."
                        )} */}
                        
                      </div>
                    </Modal.Body>
                    <Divider className="tw-absolute tw-w-full tw-left-0 tw-m-0 tw-bg-gray-300" />
                    <Modal.Footer className="tw-pt-5">
                      <Button
                        onClick={() => setShowDeactivateConfirmModal(false)}
                        appearance="ghost"
                      >
                        {translateText(props.convertText, "Cancel")}
                      </Button>
                      <Button
                        appearance="primary"
                        onClick={deactivateAccount}
                        loading={isDeactivating}
                      >
                        {translateText(props.convertText, "I am sure")}
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </Form.Group>
                <Form.Group>
                  <DeleteAccountModal convertText={props.convertText} />
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Grid>
      </PanelContainer>
    </div>
  );
};

export default AccountSecurity;
