// React
import React, { useState } from "react";

// FutureLab
import LinkButton from "../../ui/LinkButton";
import avatarPlaceholder from "../../../images/avatar_placeholder.png";

// UI
import { FaStar } from "react-icons/fa";
import { Tooltip, Whisper } from "rsuite";

export default function Reviews({ user }) {
  const { reviews } = user;
  const [hideReview, setHideReview] = useState("tw-hidden");

  return (
    <div>
      {reviews?.map((reviewer, idx) => (
        <div
          className={`tw-py-5 sm:tw-px-2 tw-px-4 tw-mb-8 tw-border tw-border-slate-300 tw-border-solid tw-shadow tw-rounded-xl ${
            idx >= 4 ? hideReview : ""
          }`}
          key={idx}
        >
          <div className="tw-flex tw-items-start tw-justify-between">
            <div className="tw-px-2 tw-flex">
              <img
                src={reviewer?.profile_photo_url}
                className="tw-w-11 tw-h-11 tw-rounded-full"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = avatarPlaceholder;
                }}
              />

              <div className="tw-ml-3">
                <p className="tw-mb-0 tw-text-base text-muted tw-font-bold montserrat">
                  {reviewer.full_name}
                </p>
                <p className="tw-mb-0 tw-text-sm tw-font-medium montserrat">
                  {reviewer?.job_title &&
                    reviewer?.company &&
                    `${reviewer?.job_title} at ${reviewer?.company}`}
                </p>
              </div>
            </div>
            <div className="tw-flex">
              <span className="tw-text-lg text-muted tw-font-bold">
                {reviewer.star_rating}
              </span>
              <FaStar size={24} color="#FFC757" className="tw-ml-1" />
            </div>
          </div>
          <p className="tw-text-sm tw-mt-4 tw-ml-4 montserrat">
            {reviewer.thank_you_note || reviewer.feedback_text}
          </p>
          <div className="tw-flex tw-ml-4 tw-flex-wrap">
            {reviewer.badges.length > 0 &&
              reviewer.badges.map((badge, idx) => (
                <Whisper
                  key={idx}
                  placement="top"
                  controlId="control-id-hover"
                  trigger="hover"
                  speaker={<Tooltip>{badge.name}</Tooltip>}
                >
                  <img
                    key={idx}
                    src={badge.image_tag}
                    alt=""
                    className="tw-mr-2"
                    style={{ height: 25 }}
                  />
                </Whisper>
              ))}
          </div>
        </div>
      ))}
      {reviews.length > 4 && hideReview === "tw-hidden" && (
        <div className="tw-text-center">
          <LinkButton onClick={() => setHideReview("")}>Load more</LinkButton>
        </div>
      )}
    </div>
  );
}
