export const sortData = (data, sortColumn, sortType) => {
  if (sortColumn && sortType) {
    return data.sort((a, b) => {
      let x = a[sortColumn];
      let y = b[sortColumn];
      if (typeof x === 'string') {
        x = x.charCodeAt(0);
      }
      if (typeof y === 'string') {
        y = y.charCodeAt(0);
      }
      if (sortType === 'asc') {
        return x - y;
      } else {
        return y - x;
      }
    });
  }
  return data;
};