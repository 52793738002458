// React
import React from "react";
import { Button } from "rsuite";
import translateText from "../../../../../util/translateText";
import Cookies from "js-cookie";
import { useVariables } from "../../../../../contexts/variables";

export default function BookingButtons({
  currentUserId,
  setShowAvailabilityModal,
  handleOpen,
  setInitialTime,
  convertText,
  mentorId,
}) {
  const { variables } = useVariables();

  return (
    <>
      {currentUserId ? (
        <>
          <Button
            onClick={() => {
              setShowAvailabilityModal(true);
            }}
            appearance="ghost"
            block
          >
            {translateText(convertText, "Check my availability")}
          </Button>
          {currentUserId != mentorId && (
            <Button
              appearance="primary"
              onClick={() => {
                handleOpen();
                setInitialTime(undefined);
              }}
              block
            >
              {translateText(convertText, "Connect with Mentor")}
            </Button>
          )}
        </>
      ) : (
        <Button
          appearance="primary"
          block
          href={`${variables?.PWA_URL}/users/sign_in`}
          onClick={() =>
            Cookies.set("url_return_to", window.location.href, {
              domain: `.${variables?.domain}`,
            })
          }
        >
          {translateText(convertText, "Sign In")}
        </Button>
      )}
    </>
  );
}
