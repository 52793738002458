import React from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Role } from "./RoleCard";
import { usePortal } from "../../../contexts/portal";

const schema = yup.object().shape({
  role_id: yup.number().required(),
  redirect_url: yup.string().required(),
  batch_send_hidden: yup.boolean().optional().default(false),
  batch_send_invites: yup.mixed().optional().default(""),
  email: yup
    .array()
    .of(yup.object().shape({ email: yup.string().email() }))
    .min(1),
});

interface RoleInstance extends UseFormReturn {
  handleFormSubmit: (e: React.BaseSyntheticEvent) => void;
}

const useCard = (
  role: Role
): {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  instance: RoleInstance;
} => {
  const [open, setOpen] = React.useState<boolean>(false);
  const instance = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      role_id: role.id,
      redirect_url: window.location.href,
      batch_send_hidden: false,
      email: [{ email: "" }],
      batch_send_invites: "",
    },
  });
  const { program, enterprise } = usePortal();

  const onSubmit = async (data) => {
    const bodyData = {
      ...data,
      send_invite: data.email.map((e) => e.email),
    };
    try {
      const res = await fetch(
        `/app/${enterprise.slug}/${program.slug}/admins/roles/invites_submit`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": document
              .querySelector('meta[name="csrf-token"]')
              .getAttribute("content"),
            Accept: "application/json",
          },
          body: JSON.stringify(bodyData),
          credentials: "include",
        }
      );
      if (res.ok) {
        instance.reset();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setOpen(false);
    }
  };

  const onErrors = (errors) => {
    console.error(errors);
  };

  const handleFormSubmit = instance.handleSubmit(onSubmit, onErrors);

  return {
    open,
    setOpen,
    instance: {
      ...(instance as any),
      handleFormSubmit,
    },
  };
};

export default useCard;
