import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { usePortal } from "../../../../../../contexts/portal";

const schema = yup.object().shape({
  program: yup.object().shape({
    name: yup.string(),
    description: yup.string(),
    start_date: yup.date().nullable(),
    end_date: yup.date().nullable(),
    support_email: yup.string(),
    pairing_method: yup.string(),
    pairing_method_type: yup.string(),
    privacy_settings: yup.string(),
    review_settings: yup.string(),
    whatsapp_notification: yup.bool(),
  }),
});

const defaultValues = {
  program: {
    name: "",
    description: "",
    start_date: null,
    end_date: null,
    support_email: "",
    pairing_method: "",
    pairing_method_type: "",
    privacy_settings: "",
    review_settings: "",
    whatsapp_notification: false,
  },
};

const SettingsFormProvider = ({ children }) => {
  const { program } = usePortal();

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    const extractDefaultValues = (program) => {
      if (!program) return defaultValues;

      return {
        program: {
          name: program?.name || "",
          description: program?.description || "",
          start_date: program.start_date ? new Date(program.start_date) : null,
          end_date: program.end_date ? new Date(program.end_date) : null,
          support_email: program?.support_email || "",
          pairing_method: program?.pairing_method || "",
          pairing_method_type: program?.pairing_method_type || "",
          privacy_settings: program?.privacy_settings || "",
          review_settings: program?.review_settings || "",
          whatsapp_notification: program?.whatsapp_notification || false,
        },
      };
    };

    methods.reset(extractDefaultValues(program));
  }, [program]);

  return <FormProvider {...methods}>{children}</FormProvider>;
};

export default SettingsFormProvider;
