// React
import React, { Fragment, useState } from "react";
import { useOutletContext } from "react-router-dom";

// FutureLab
import toSentenceCase from "../../../../../util/toSentenceCase";
import translateText from "../../../../../util/translateText";
import CustomTag from "../../../../ui/CustomTag";

// UI
import { Col, FlexboxGrid, Stack } from "rsuite";

const Skills = (props) => {
  const { mentor } = useOutletContext<any>();
  const {
    skills,
    who_to_mentor_list: whoToMentorList,
    info: { who_to_mentor: whoToMentor },
    industries,
  } = mentor;
  const [hideExtraSkills, setHideExtraSkills] = useState("tw-hidden");

  const colSize = skills?.length > 0 ? 8 : 12;

  return (
    <FlexboxGrid>
      {skills?.length > 0 && (
        <FlexboxGrid.Item
          as={Col}
          colspan={24}
          xs={24}
          sm={colSize}
          className="sm:tw-mb-4"
        >
          <p className="tw-font-bold tw-text-base montserrat">
            {translateText(props.convertText, "My Expertise")}
          </p>
          <Stack wrap spacing={4}>
            {skills.map((skill, idx) => (
              <Fragment key={skill.id}>
                {skills.length > 3 && idx === 3 && (
                  <div
                    className={`${hideExtraSkills === "" ? "tw-hidden" : ""}`}
                  >
                    <CustomTag
                      text={`+${skills.length - 3}`}
                      className="tw-rounded-full hover:tw-cursor-pointer"
                      onClick={() => setHideExtraSkills("")}
                    />
                  </div>
                )}
                <div className={`${idx >= 3 ? hideExtraSkills : ""}`}>
                  <CustomTag text={skill.name} />
                </div>
              </Fragment>
            ))}
          </Stack>
        </FlexboxGrid.Item>
      )}
      <FlexboxGrid.Item
        as={Col}
        colspan={24}
        xs={24}
        sm={colSize}
        className="sm:tw-mb-4"
      >
        <p className="tw-font-bold tw-text-base montserrat">
          {translateText(props.convertText, "Industries I'm familiar with")}
        </p>
        <Stack wrap spacing={4}>
          {industries.map((industry, idx) => (
            <Fragment key={industry.id}>
              {industries.length > 3 && idx === 3 && (
                <div className={`${hideExtraSkills === "" ? "tw-hidden" : ""}`}>
                  <CustomTag
                    text={`+${industries.length - 3}`}
                    className="tw-rounded-full hover:tw-cursor-pointer"
                    onClick={() => setHideExtraSkills("")}
                  />
                </div>
              )}
              <div className={`${idx >= 3 ? hideExtraSkills : ""}`}>
                <CustomTag text={industry.name} />
              </div>
            </Fragment>
          ))}
        </Stack>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item
        as={Col}
        colspan={24}
        xs={24}
        sm={colSize}
        className="sm:tw-mb-4"
      >
        <p className="tw-font-bold tw-text-base montserrat">
          {translateText(props.convertText, "Preferred mentees")}
        </p>
        <Stack wrap spacing={4}>
          {whoToMentor
            // The value "4" is an enum that represent "Rather not say" which is no longer desirable
            .filter((who) => who !== 4)
            .map((who) => (
              <div key={who}>
                <CustomTag text={toSentenceCase(whoToMentorList[who])} />
              </div>
            ))}
        </Stack>
        {whoToMentor.length === 0 && (
          <Stack wrap spacing={4}>
            <div>
              <CustomTag text={translateText(props.convertText, "Students")} />
            </div>
            <div>
              <CustomTag
                text={translateText(props.convertText, "Entrepreneurs")}
              />
            </div>
            <div>
              <CustomTag
                text={translateText(props.convertText, "Working Professionals")}
              />
            </div>
          </Stack>
        )}
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

export default Skills;
