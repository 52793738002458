// React
import React, { useState } from "react";

// UI
import { Button } from "rsuite";

// 3rd Party
import axios from "axios";
import moment from "moment";

const downloadCSV = async (title) => {
  const file = await axios.get(
    new URL(window.location.href).pathname + ".csv",
    {
      responseType: "blob",
      params: {
        is_csv: true,
      },
    }
  );
  const url = window.URL.createObjectURL(new Blob([file.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", title);
  document.body.appendChild(link);
  link.click();
};

export const CsvButton = ({ title }) => {
  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    setLoading(true);
    try {
      const titles = `${title} ${moment().format(
        "DD-MM-YYYY"
      )}.csv`;
      await downloadCSV(titles);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      disabled={loading}
      loading={loading}
      appearance="primary"
      onClick={handleDownload}
    >
      Download CSV
    </Button>
  );
};
