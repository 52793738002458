import React from "react";
import { Button, Divider, Input, Toggle, Tooltip, Whisper } from "rsuite";
import { FormInputNumber, FormInputText } from "../../form/default/FormInputs";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { MdOutlineDelete } from "react-icons/md";

interface EventTicket {
  idx: string;
  id: string | number | undefined;
  ticket_type: string;
  name: string;
  price: number;
  max_amount: number;
  active: boolean;
  // Define other properties as needed
}

const EventNewTicketForm = () => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useFormContext();

  const {
    fields: ticketFields,
    append: appendTicket,
    remove: removeTicket,
  } = useFieldArray({
    control,
    name: "tickets",
    keyName: "idx",
  });

  return (
    <>
      <h6>Event Tickets</h6>

      <div>
        <Button
          appearance="ghost"
          onClick={() => {
            appendTicket({
              name: "",
              ticket_type: "free",
              price: 0,
              max_amount: 0,
            });
          }}
        >
          Add Free Ticket
        </Button>

        <Divider vertical />

        <Button
          appearance="ghost"
          onClick={() => {
            appendTicket({
              name: "",
              ticket_type: "paid",
              price: 1.0,
              max_amount: 0,
            });
          }}
        >
          Add Paid Ticket
        </Button>
      </div>
      <div className="tw-w-full tw-flex tw-flex-row tw-my-6">
        <div className="tw-w-1/2">
          <span className="tw-text-sm">Ticket name</span>
        </div>
        <div className="tw-w-1/4">
          <span className="tw-text-sm">Price (RM)</span>
        </div>
        <div className="tw-w-1/4">
          <span className="tw-text-sm">
            Quantity <br />(<b>0</b> for unlimited)
          </span>
        </div>
      </div>

      {ticketFields.length == 0 ? (
        <div className="tw-flex tw-my-6 tw-justify-center tw-w-full">
          <span className="tw-text-center">
            There are currently no tickets available for this event.
            <br /> You can add a paid or free ticket to get started.
          </span>
        </div>
      ) : (
        <>
          {ticketFields.map((ticket: EventTicket, ticketIndex) => (
            <div
              className={`tw-flex tw-flex-row tw-items-start tw-my-4 ${
                watch(`tickets.${ticketIndex}.active`) === false
                  ? "tw-hidden"
                  : ""
              }`}
              key={ticketIndex}
            >
              {/* Ticket ID */}
              <Controller
                name={`tickets.${ticketIndex}.id`}
                control={control}
                defaultValue={ticket.id || undefined}
                render={({ field }) => (
                  <Input {...field} className="tw-hidden" />
                )}
              />

              {/* Ticket Ticket Type */}
              <Controller
                name={`tickets.${ticketIndex}.ticket_type`}
                control={control}
                defaultValue={ticket.ticket_type || undefined}
                render={({ field }) => (
                  <Input {...field} className="tw-hidden" />
                )}
              />

              {/* Ticket Active */}
              <Controller
                name={`tickets.${ticketIndex}.active`}
                control={control}
                defaultValue={ticket.active || true}
                render={({ field: { name, value } }) => (
                  <Toggle
                    defaultChecked={value}
                    onChange={(value) => setValue(name, value)}
                    className="tw-hidden"
                  />
                )}
              />

              <div className="tw-w-1/2">
                <FormInputText
                  name={`tickets.${ticketIndex}.name`}
                  className="tw-w-full"
                  placeholder={
                    ticket.ticket_type == "free"
                      ? "New Free Ticket"
                      : "New Paid Ticket"
                  }
                  control={control}
                  errors={errors}
                />
              </div>

              <div className="tw-w-1/4 tw-mx-2">
                {ticket.ticket_type === "free" ? (
                  <Input className="tw-w-full" placeholder="Free" disabled />
                ) : (
                  <FormInputNumber
                    name={`tickets.${ticketIndex}.price`}
                    className="tw-w-full"
                    placeholder={"Price"}
                    control={control}
                    defaultValue={0}
                    errors={errors}
                    min={0}
                    step={0.01}
                    disabled={ticket.ticket_type == "free" ? true : false}
                  />
                )}
              </div>
              <div>
                <FormInputNumber
                  name={`tickets.${ticketIndex}.max_amount`}
                  className="tw-w-full"
                  placeholder={"Quantity"}
                  defaultValue={0}
                  control={control}
                  errors={errors}
                  min={0}
                />
              </div>
              <div className="tw-flex tw-align-middle tw-mt-2 tw-mx-2">
                <Whisper
                  placement="top"
                  trigger="hover"
                  speaker={<Tooltip>Delete Ticket</Tooltip>}
                >
                  <a
                    onClick={() => {
                      if (ticket.id !== undefined) {
                        setValue(`tickets.${ticketIndex}.active`, false);
                      } else {
                        removeTicket(ticketIndex);
                      }
                    }}
                  >
                    <MdOutlineDelete
                      style={{ fontSize: "18px", color: "red" }}
                    />
                  </a>
                </Whisper>
              </div>
            </div>
          ))}
        </>
      )}

      {/* Event Ticket */}
    </>
  );
};

export default EventNewTicketForm;
