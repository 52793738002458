import React, { useState } from "react";

import { Navbar } from "rsuite";

import { MdChatBubbleOutline, MdOutlineSearch } from "react-icons/md";
import flCircleLogo from "../../../images/fl-circle-logo-border.png";
import NavSearchForm from "../component/NavSearchForm";
import NavItemNotificationButton from "./../component/NavItemNotificationButton";
import { NavItemButton } from "./../common";

const FLMobileHeaderNav = (props) => {
  const [searchActive, setSearchActive] = useState(false);

  const chatUrls = {
    development: "http://localhost:3005/chat",
    production: "https://newapp.futurelab.my/chat",
    staging: "https://newapp.futurelab.global/chat",
  };

  const chathref = chatUrls?.[process.env.RAILS_ENV] || chatUrls["development"];

  return (
    <>
      <Navbar
        appearance="subtle"
        className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-px-4"
        style={{ backgroundColor: "#428AC9", color: "#ffffff", height: 60 }}
      >
        {!searchActive ? (
          <>
            <div className="tw-grow">
              <a href="/">
                <img src={flCircleLogo} alt="" style={{ maxHeight: 35 }} />
              </a>
            </div>
            <div>
              <NavItemButton
                icon={<MdOutlineSearch />}
                onClick={() => setSearchActive(true)}
              />
              <NavItemButton
                icon={<MdChatBubbleOutline />}
                href={chathref}
                target="_self"
              />
              <NavItemNotificationButton />
            </div>
          </>
        ) : (
          <NavSearchForm setSearchActive={setSearchActive} />
        )}
      </Navbar>
    </>
  );
};

export default FLMobileHeaderNav;
