import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Divider, Form, Loader, RadioTile, RadioTileGroup } from "rsuite";
import {
  FormDatePicker,
  FormInputPicker,
} from "../../../form/default/FormInputs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import BottomNavCTA from "../component/BottomNavCTA";
import { FutureLabApi } from "../../../../util/api";
import FieldError from "../../../form/FieldError";
import { ErrorMessage } from "@hookform/error-message";
import {
  MdOutlineBusinessCenter,
  MdOutlineGridView,
  MdOutlineGroups3,
  MdOutlineSchool,
} from "react-icons/md";
import moment from "moment";
import { PortalApi } from "../../../../util/portal_api";
import { usePortalSetup } from "../../../../contexts/portal/setup";
import _ from "lodash";
import LearningModuleForm from "./LearningModuleForm";
import {
  EmployeeEngagementProgramme,
  EntrepeneurshipProgramme,
  YouthEmployabilityProgramme,
} from "../templates/setup_templates";
import { useSetupVariable } from "../../../../contexts/portal/setup/setupVariables";

const schema = yup.object().shape({
  program: yup.object().shape({
    start_date: yup.date().nullable().required().label("Program Start Date"),
    end_date: yup.date(),
    duration: yup.string().required().nullable().label("Program duration"),
    timezone: yup.string().required().nullable().label("Program timezone"),
    no_of_participants: yup
      .string()
      .required()
      .nullable()
      .label("Number of Participants"),
    template_type: yup.string().required().label("Program type"),
  }),
  learning_modules: yup.array().of(
    yup.object().shape({
      id: yup.mixed().nullable(),
      order: yup.number(),
      name: yup.string().required().label("Module name"),
      description: yup.string().nullable(),
      duration_range: yup.array().nullable(),
      start_date: yup
        .date()
        .required("Start date is required and shouldn't overlap other modules"),
      end_date: yup
        .date()
        .required("End date is required and shouldn't overlap other modules"),
      _destroy: yup.bool().nullable(),
      learning_module_tasks_attributes: yup.array(
        yup.object().shape({
          id: yup.mixed().nullable(),
          name: yup.string(),
          description: yup.string().nullable(),
          link_name: yup.string().nullable(),
          link_url: yup.string().nullable(),
          _destroy: yup.bool().nullable(),
        })
      ),
    })
  ),
});

const PortalStructureForm = (props) => {
  const { handleStep } = useSetupVariable();
  const { program, setProgram } = usePortalSetup();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [timezones, setTimezones] = useState([]);

  const {
    handleSubmit,
    control,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      program: {
        start_date: program?.start_date
          ? new Date(program?.start_date)
          : new Date(),
        end_date: program?.end_date ? new Date(program?.end_date) : new Date(),
        duration: program?.duration || "3_months",
        timezone: program?.timezone || "Asia/Kuala_Lumpur",
        template_type: program?.template_type || "create_my_own",
        no_of_participants: program?.no_of_participants || "less_than_20",
      },
      learning_modules: program?.learning_modules || [],
    },
  });

  const watchDuration = watch("program.duration");
  const watchStartDate = watch("program.start_date");

  useEffect(() => {
    FutureLabApi({
      method: "get",
      path: "/timezones",
      success: ({ data }) => {
        setTimezones(data);
      },
    });
    return () => {
      setTimezones([]);
    };
  }, []);

  // Calculates program end date automatically when duration selected other than 'set_end_date'
  const calculateEndDate = (start_date, months: string) => {
    const startDateMoment = moment(start_date);
    const monthsNumeric = parseInt(months);
    const endDateMoment = startDateMoment.add(monthsNumeric, "months");
    const endDateString = new Date(endDateMoment.toString());
    setValue("program.end_date", endDateString);
  };

  // Sets end date if no program available and with default standard form values.
  useEffect(() => {
    if (!program?.end_date) {
      calculateEndDate(watchStartDate, watchDuration);
    }
  }, [program]);

  const onSubmit = (data, saveDraft = false) => {
    setIsSubmitting(true);

    data.program_id = program.id;

    PortalApi({
      method: "post",
      path: "/programs",
      options: data,
      success: ({ data }) => {
        handleStep(saveDraft ? "save_draft" : "");
        setIsSubmitting(false);
        setProgram(data);
      },
      error: ({ data }) => {
        setIsSubmitting(false);
        console.log(data);
      },
    });
    console.log("onSubmit", data);
  };

  const handleChangeTemplate = (key) => {
    const template = {
      create_my_own: [],
      employee_engagement_programme:
        EmployeeEngagementProgramme.learning_modules,
      youth_employability_programme:
        YouthEmployabilityProgramme.learning_modules,
      entrepreneurship_programme: EntrepeneurshipProgramme.learning_modules,
    };

    if (program?.learning_modules?.length === 0) {
      setValue("learning_modules", template[key]);
    }

    setValue("program.template_type", key);
  };

  if (!program) return <Loader className="tw-my-10" center content="loading" />;

  return (
    <>
      <h6>Programme Structure</h6>

      <Form>
        <div className="tw-flex tw-flex-wrap">
          <Form.Group className="tw-w-1/2 ">
            {/* Program Start Date */}
            <FormDatePicker
              name="program.start_date"
              label={"Programme Start Date"}
              control={control}
              errors={errors}
              showMeridian
              format={"dd-MMM-yyyy"}
              placeholder={"Start Date"}
              className="tw-w-full"
              shouldDisableDate={(date) =>
                moment(date).isBefore(moment(), "day")
              }
            />
          </Form.Group>

          {/* Program Duration */}
          <Form.Group className="tw-w-1/2 tw-pl-2">
            <FormInputPicker
              name="program.duration"
              control={control}
              errors={errors}
              label={"Duration of Program"}
              placeholder={"Select duration"}
              block
              cleanable={false}
              data={[
                { value: "1_months", label: "1 month" },
                { value: "2_months", label: "2 months" },
                { value: "3_months", label: "3 months" },
                { value: "set_end_duration", label: "Set end duration" },
              ]}
              onChange={(value) => {
                setValue("program.duration", value);
                if (value !== "set_end_duration") {
                  calculateEndDate(watchStartDate, value);
                }
              }}
            />
          </Form.Group>
        </div>

        {watchDuration === "set_end_duration" && (
          <Form.Group className="tw-w-1/2 ">
            {/* Program End Date */}
            <FormDatePicker
              name="program.end_date"
              label={"Programme End Date and Time"}
              control={control}
              errors={errors}
              showMeridian
              format={"dd-MMM-yyyy"}
              placeholder={"End Date and Time"}
              className="tw-w-full"
              shouldDisableDate={(date) =>
                moment(date).isBefore(moment(), "day")
              }
            />
          </Form.Group>
        )}

        {/* Location & Timezone */}
        <Form.Group className="tw-w-1/2">
          <FormInputPicker
            name="program.timezone"
            control={control}
            errors={errors}
            label={"Timezone"}
            placeholder={"Select Timezone"}
            block
            cleanable={false}
            data={timezones}
          />
        </Form.Group>

        {/* Number of Participants */}
        <Form.Group className="tw-w-1/2">
          <FormInputPicker
            name="program.no_of_participants"
            control={control}
            errors={errors}
            label={"Estimated number of Participants"}
            data={[
              { value: "less_than_20", label: "Less than 20 participants" },
              { value: "20_to_80", label: "20 - 80 participants" },
              { value: "more_than_80", label: "More than 80 participants" },
            ]}
            placeholder={"No of Participants"}
            block
            cleanable={false}
          />
        </Form.Group>

        {/* Program Type Selection */}
        <Form.Group>
          <Form.ControlLabel>Choose programme type</Form.ControlLabel>
          <Controller
            name="program.template_type"
            control={control}
            render={({ field }) => (
              <>
                <RadioTileGroup
                  {...field}
                  value={field.value}
                  onChange={(value) => handleChangeTemplate(value)}
                  aria-label="Choose programme type"
                >
                  <RadioTile
                    icon={
                      <MdOutlineGridView
                        className="tw-mx-2"
                        style={{ color: "#696969" }}
                      />
                    }
                    label="Create my own"
                    value={"create_my_own"}
                  >
                    Tailor learning experiences to meet your specific
                    participant needs and program objectives.
                  </RadioTile>
                  <RadioTile
                    icon={
                      <MdOutlineGroups3
                        className="tw-mx-2"
                        style={{ color: "#696969" }}
                      />
                    }
                    label="Employee Engagement Programme"
                    value={"employee_engagement_programme"}
                  >
                    Foster a motivated and skilled workforce, improving job
                    satisfaction and retention through targeted professional
                    development initiatives.
                  </RadioTile>
                  <RadioTile
                    icon={
                      <MdOutlineBusinessCenter
                        className="tw-mx-2"
                        style={{ color: "#696969" }}
                      />
                    }
                    label="Entrepreneurship Programme"
                    value={"entrepreneurship_programme"}
                  >
                    Nurturing entrepreneurship, mentoring future business
                    leaders, and contributing to the growth of vibrant startup
                    ecosystems.
                  </RadioTile>
                  <RadioTile
                    icon={
                      <MdOutlineSchool
                        className="tw-mx-2"
                        style={{ color: "#696969" }}
                      />
                    }
                    label="Youth Employability Programme"
                    value={"youth_employability_programme"}
                  >
                    Equip young adults with essential job skills, improving
                    employability prospects, and facilitating a smoother
                    transition into the workforce.
                  </RadioTile>
                </RadioTileGroup>
                <ErrorMessage
                  errors={errors}
                  name="program.template_type"
                  as={FieldError}
                />
              </>
            )}
          />
        </Form.Group>

        <Divider />

        {/* Programme Module */}
        <LearningModuleForm
          control={control}
          getValues={getValues}
          setValue={setValue}
          errors={errors}
          watch={watch}
        />

        <BottomNavCTA
          // Second onSubmit argument is SaveDraft
          handleSubmit={handleSubmit((data) => onSubmit(data, false))}
          handleSaveDraft={handleSubmit((data) => onSubmit(data, true))}
          loading={isSubmitting}
        />
      </Form>
    </>
  );
};

export default PortalStructureForm;
