// React
import React from "react";

// FutureLab
import { sortData } from "../callbacks/sortColumnsCallback";
import { filterData } from "../callbacks/filterDataCallback";
import { UserInfo } from "../../PairingTable";

// UI
import { Button, Table } from "rsuite";
import { FaStar } from "react-icons/fa";

const { Column, HeaderCell, Cell } = Table;

export function OverviewPairing(props: any) {
  const {
    data,
    searchKeyword,
    healthFilter,
    sortColumn,
    sortType,
    loading,
    handleSortColumn,
  } = props;

  return (
    <Table
    data={sortData(
      filterData(data.overviewPairing, searchKeyword, healthFilter),
      sortColumn,
      sortType
    )}
    autoHeight
    rowHeight={66}
    headerHeight={60}
    loading={loading}
    wordWrap="break-word"
    className="tw-w-full tw-mt-6"
    sortColumn={sortColumn}
    sortType={sortType}
    onSortColumn={handleSortColumn}
    affixHorizontalScrollbar
    >
      <Column verticalAlign="middle" flexGrow={2} minWidth={250}>
        <HeaderCell>Mentee</HeaderCell>
        <Cell>
          {(rowData) => <UserInfo user={rowData.mentee_participants} />}
        </Cell>
      </Column>

      <Column verticalAlign="middle" align="center" flexGrow={1} minWidth={150} sortable>
        <HeaderCell>Confirmed Session</HeaderCell>
        <Cell dataKey="user_confirmed_bookings_count" />
      </Column>

      <Column verticalAlign="middle" align="center" flexGrow={1} minWidth={150} sortable>
        <HeaderCell>Completed Session</HeaderCell>
        <Cell dataKey="user_completed_bookings_count" />
      </Column>

      <Column verticalAlign="middle" align="center" flexGrow={1} minWidth={150} sortable>
        <HeaderCell>% Completed Session</HeaderCell>
        <Cell dataKey="user_completed_bookings_percentage">
          {(rowData) =>
            rowData.user_completed_bookings_count > 0
              ? rowData.user_completed_bookings_percentage
              : "-"
          }
        </Cell>
      </Column>

      <Column verticalAlign="middle" align="center" flexGrow={1} minWidth={150}>
        <HeaderCell>Mentee Feedback</HeaderCell>
        <Cell>
          {(rowData) =>
            rowData.user_rated_percentage > 0
              ? rowData.user_rated_count
              : "-"
          }
        </Cell>
      </Column>

      <Column verticalAlign="middle" align="center" flexGrow={1} minWidth={150} sortable>
        <HeaderCell>% Mentee Feedback</HeaderCell>
        <Cell dataKey="user_rated_percentage">
          {(rowData) =>
            rowData.user_rated_percentage > 0
              ? rowData.user_rated_percentage
              : "-"
          }
        </Cell>
      </Column>

      <Column verticalAlign="middle" align="center" flexGrow={1} minWidth={150} sortable>
        <HeaderCell>% Sessions Fulfillment</HeaderCell>
        <Cell dataKey="user_session_fulfillment">
          {(rowData) =>
            rowData.user_session_fulfillment > 0
              ? rowData.user_session_fulfillment
              : "-"
          }
        </Cell>
      </Column>

      <Column verticalAlign="middle" align="center" flexGrow={1} minWidth={150}>
        <HeaderCell>{`Avg. Rating (by mentors)`}</HeaderCell>
        <Cell>
          {(rowData) =>
            rowData.average_ratings_score > 0 ? (
              <div className="tw-flex tw-items-center tw-justify-center	">
                <span className="tw-mr-1">{rowData.average_ratings_score}</span>
                <FaStar color="428ac9" size={20} className="tw-mb-1"/>
              </div>
            ) : (
              "-"
            )
          }
        </Cell>
      </Column>
    </Table>
  );
}
