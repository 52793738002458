import React from 'react';
import { Progress } from 'rsuite';

const ProfileCompletionBar = () => {
  const completion = 50;
  return (
    <div className="tw-relative tw-invisible">
      <span
        className={`tw-absolute tw-left-0 tw-right-0 tw-top-0 tw-bottom-0 tw-h-fit tw-m-auto tw-align-middle tw-text-sm tw-text-center ${
          completion < 65 ? 'tw-text-gray-800' : 'tw-text-white'
        }`}
      >
        {completion}% completed
      </span>
      <Progress.Line
        percent={completion}
        showInfo={false}
        status="success"
        strokeWidth={24}
        className="tw-w-96 sm:tw-px-0"
      />
    </div>
  );
};

export default ProfileCompletionBar;
