// React
import React from "react";

// FutureLab
import avatarPlaceholder from "../../../../images/avatar_placeholder.png";

// UI
import { Nav } from "rsuite";
import { FaRegCheckCircle } from "react-icons/fa";

export function GroupModalParticipantList(list, reviewType) {
  return list.map((item, index) => {
    let submitStatus = reviewType[index]?.submitted;

    return (
      <Nav.Item key={index} eventKey={`${index}`}>
        <div className="tw-my-2 tw-flex tw-items-center tw-justify-between">
          <div>
            <img
              src={item?.profile_photo || avatarPlaceholder}
              style={{ width: 40 }}
              className="tw-rounded-xl tw-mr-2"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = avatarPlaceholder;
              }}
              alt=""
            />
            {item?.full_name || "Deleted User"}
          </div>
          {submitStatus && (
            <div>
              <FaRegCheckCircle size={22} color="#428ac9" />
            </div>
          )}
        </div>
      </Nav.Item>
    );
  });
}
