// React
import React, { Fragment, useState } from "react";
import { useOutletContext } from "react-router-dom";

// FutureLab
import translateText from "../../../../../util/translateText";
import CustomTag from "../../../../ui/CustomTag";
import Languages from "../../../shared/Languages";

// UI
import { Col, FlexboxGrid, Stack } from "rsuite";

export default function MenteeInterest({ convertText }) {
  const { mentee } = useOutletContext<any>();
  const {
    interested_companies: interestedCompanies,
    interested_industries: interestedIndustries,
  } = mentee;
  const [hideExtraCompanies, setHideExtraCompanies] = useState("tw-hidden");

  const colSize = interestedCompanies.length > 0 ? 8 : 12;

  return (
    <FlexboxGrid>
      {interestedCompanies.length > 0 && (
        <FlexboxGrid.Item
          as={Col}
          colspan={24}
          xs={24}
          sm={colSize}
          className="sm:tw-mb-4"
        >
          <p className="tw-font-bold tw-text-base montserrat">
            {translateText(convertText, "Interested Company")}
          </p>
          <Stack wrap spacing={4}>
            {interestedCompanies.map((company, idx) => (
              <Fragment key={company.id}>
                {interestedCompanies.length > 3 && idx === 3 && (
                  <div
                    className={`${
                      hideExtraCompanies === "" ? "tw-hidden" : ""
                    }`}
                  >
                    <CustomTag
                      text={`+${interestedCompanies.length - 3}`}
                      className="tw-rounded-full hover:tw-cursor-pointer"
                      onClick={() => setHideExtraCompanies("")}
                    />
                  </div>
                )}
                <div className={`${idx >= 3 ? hideExtraCompanies : ""}`}>
                  <CustomTag text={company.name} />
                </div>
              </Fragment>
            ))}
          </Stack>
        </FlexboxGrid.Item>
      )}
      <FlexboxGrid.Item
        as={Col}
        colspan={24}
        xs={24}
        sm={colSize}
        className="sm:tw-mb-4"
      >
        <p className="tw-font-bold tw-text-base montserrat">
          {translateText(convertText, "Job Role")}
        </p>
        <Stack wrap spacing={4}>
          {interestedIndustries.map((industry, idx) => (
            <Fragment key={industry.id}>
              {interestedIndustries.length > 3 && idx === 3 && (
                <div
                  className={`${hideExtraCompanies === "" ? "tw-hidden" : ""}`}
                >
                  <CustomTag
                    text={`+${interestedIndustries.length - 3}`}
                    className="tw-rounded-full hover:tw-cursor-pointer"
                    onClick={() => setHideExtraCompanies("")}
                  />
                </div>
              )}
              <div className={`${idx >= 3 ? hideExtraCompanies : ""}`}>
                <CustomTag text={industry.name} />
              </div>
            </Fragment>
          ))}
        </Stack>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item
        as={Col}
        colspan={24}
        xs={24}
        sm={colSize}
        className="sm:tw-mb-4"
      >
        <Languages convertText={convertText} user={mentee} />
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}
