import React, { useEffect, useState } from "react";

import { MdNotificationsNone } from "react-icons/md";
import { FutureLabApi } from "../../../util/api";
import { Button, Drawer, Loader } from "rsuite";
import NavNotificationContent from "./NavNotificationContent";
import { NavItemButton } from "../common";
import useDesktopOrMobile from "../../../hooks/useDesktopOrMobile";

const NavItemNotificationButton = () => {
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const isDesktop = useDesktopOrMobile();

  useEffect(() => {
    getNotifications();
  }, []);

  const getNotifications = () => {
    FutureLabApi({
      method: "get",
      path: "/notifications",
      success: ({ data }) => {
        setNotifications(data);
      },
    });
  };

  const handleMarkAllRead = () => {
    FutureLabApi({
      method: "post",
      path: "/notifications/mark_all_read",
      success: () => {
        getNotifications();
      },
    });
  };

  return (
    <>
      <NavItemButton
        icon={<MdNotificationsNone />}
        badge={notifications.length > 0 ? true : false}
        onClick={() => setOpen(!open)}
      />

      {/* Drawer for Notification */}
      <Drawer
        size={isDesktop ? "xs" : "full"}
        placement={isDesktop ? "right" : "top"}
        open={open}
        onClose={() => setOpen(false)}
      >
        <Drawer.Header>
          <Drawer.Title>Notifications</Drawer.Title>
          <Drawer.Actions>
            <Button appearance="ghost" onClick={() => handleMarkAllRead()}>
              Mark all read
            </Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body className="tw-flex tw-flex-col tw-px-6">
          {notifications ? (
            <NavNotificationContent
              notifications={notifications}
              getNotifications={getNotifications}
            />
          ) : (
            <Loader center content="loading" />
          )}
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default NavItemNotificationButton;
