import React from "react";
import { Modal, Avatar, IconButton, Input, Tag, Divider } from "rsuite";
import { Program } from "../../../hooks/usePrograms";
import { useUser } from "../../../contexts/user";
import { RxEnter } from "react-icons/rx";
import usePrograms from "../../../hooks/usePrograms";
import { useNavbarContext } from "../../../contexts/navbarContext";

const ProgramItem = (props) => {
  const { program, activeId } = props as { program: Program; activeId: number };

  const isActive = program.id === activeId;

  return (
    <div className="tw-flex tw-border-0 tw-border-b tw-pb-4 tw-border-solid tw-border-[#E5E5EA] tw-justify-between">
      <div className="tw-flex tw-gap-5">
        <Avatar
          style={{ background: "#fff", border: "1px solid #E5E5EA" }}
          src={program.photo_url}
        />
        <div className="tw-flex tw-flex-col">
          <span className="tw-text-sm tw-font-bold tw-text-[#222222]">
            {program.name}
          </span>
          <span className="tw-text-sm tw-pt-1">
            <Tag color={program.is_active ? "blue" : null}>
              {program.is_active ? "Active" : "Inactive"}
            </Tag>
          </span>
        </div>
      </div>
      <div className="tw-flex tw-items-center tw-mr-4">
        {!isActive && program.is_active && (
          <IconButton
            icon={<RxEnter />}
            onClick={() => (window.location.href = program.program_url)}
            className="tw-text-[#428AC9] tw-text-xl"
          />
        )}
      </div>
    </div>
  );
};

const ProgramSwitcher = ({ open = false, onClose = () => {} }) => {
  const { user } = useUser();
  const { activeProgram, otherPrograms, search, setSearch } = usePrograms();
  const { baseUrl } = useNavbarContext();

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header>
        <Modal.Title className="tw-font-medium">My Programs</Modal.Title>
      </Modal.Header>

      <Divider className="tw-absolute tw-w-full tw-left-0 tw-mt-4 tw-bg-gray-300" />

      <Modal.Body className="tw-my-5 no-scrollbar">
        <div className="tw-text-sm tw-py-2">You’re currently at</div>
        <ProgramItem program={activeProgram} activeId={activeProgram?.id} />
        <Input
          disabled={otherPrograms.length === 0 && !search}
          placeholder="Search program name"
          value={search}
          onChange={(val) => setSearch(val)}
          className="tw-my-4"
        />

        {otherPrograms.length > 0 && (
          <>
            <div className="tw-flex tw-items-center tw-justify-center">
              <span>
                Programs for{" "}
                <span className="tw-font-medium">{user?.email}</span>
              </span>
            </div>

            <div className="tw-flex tw-my-4 tw-flex-col tw-gap-4">
              {otherPrograms.map((program) => (
                <ProgramItem
                  key={program.id}
                  program={program}
                  activeId={activeProgram?.id}
                />
              ))}
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="sm:tw-pb-2">
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
          <span>Not seeing your program?</span>
          <span
            onClick={() =>
              fetch(baseUrl.logout, {
                method: "DELETE",
              }).then(() => (window.location.href = "/"))
            }
            className="tw-text-[#428AC9] tw-underline tw-cursor-pointer"
          >
            Change account
          </span>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ProgramSwitcher;
