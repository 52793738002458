// React
import React from "react";

// UI
import { FaFrown, FaMeh, FaSmileBeam } from "react-icons/fa";
import { Tooltip, Whisper } from "rsuite";

const HealthGraph = ({ message, icon }) => (
  <Whisper placement="top" speaker={<Tooltip className="tw-text-center">{message}</Tooltip>}>
    <div>{icon}</div>
  </Whisper>
);

export const returnHealthGraph = (status) => {
  if (status === "1") {
    return (
      <HealthGraph
        icon={<FaSmileBeam color="#00d146" size={20}/>}
        message="Highly engaged mentor-mentee relationship, effective communication and making good progress towards goals."
      />
    );
  } else if (status === "2") {
    return (
      <HealthGraph
        icon={<FaMeh color="#e8db2f" size={20}/>}
        message="Functional relationship but needs more engagement and clearer communication between mentor and mentee."
      />
    );
  } else {
    return (
      <HealthGraph
        icon={<FaFrown color="#e62727" size={20}/>}
        message="Unproductive relationship, needs significant improvements to benefit mentor and mentee."
      />
    );
  }
};