import React from 'react';
import { Button, Divider, Message, Modal, toaster } from 'rsuite';
import { withUser } from '../../contexts/user';
import { FutureLabApi } from '../../util/api';
import translateText from '../../util/translateText';

const EditMentorModal = ({ user, convertText }) => {
  const [showModal, setShowModal] = React.useState(
    !user.mentor_profile_status.mentorship
  );
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const onSubmit = (closeBtn = false) => {
    setIsSubmitting(true);
    FutureLabApi({
      method: 'post',
      path: '/users/current',
      options: { user: { close_edit_mentor_profile_popup: true } },
      success: ({ data }) => {
        if (!closeBtn) {
          window.location.href = `/users/${user.id}/edit_mentor`;
        }
      },
      error: (err) => {
        const { error, messages } = err.response.data;
        setIsSubmitting(false);
        console.log(messages);
        toaster.push(
          <Message showIcon type="error">
            {error}
          </Message>
        );
      },
    });
  };

  return (
    <Modal
      open={showModal}
      onClose={() => {
        setShowModal(false);
        onSubmit(true);
      }}
      className="tw-mt-32"
      size="sm"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title className="tw-text-xl tw-font-medium">
          {translateText(convertText, "Launch your new Mentor Profile today!")}
        </Modal.Title>
        <Divider className="tw-absolute tw-w-full tw-left-0 tw-mt-4 tw-bg-gray-300" />
      </Modal.Header>
      <Modal.Body>
        <div className="tw-px-20 tw-py-10 tw-text-lg tw-text-center">
          {translateText(convertText, "Let's complete your profile information and start helping more Mentees!")}
        </div>
      </Modal.Body>
      <Divider className="tw-absolute tw-w-full tw-left-0 tw-m-0 tw-bg-gray-300" />
      <Modal.Footer className="tw-pt-5 tw-text-center">
        <Button
          appearance="primary"
          size="lg"
          onClick={() => onSubmit()}
          loading={isSubmitting}
        >
          {translateText(convertText, "Go to edit profile")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default withUser(EditMentorModal);
