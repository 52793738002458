import React from 'react';
import { Form, Radio, RadioGroup } from "rsuite";
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import FieldError from "../ui/FieldError";
import translateText from '../../util/translateText';

const CanOfferJob = ({ convertText, control, errors }) => {

  return (
    <Form fluid className="tw-mt-6">
      <Form.Group>
        <Form.ControlLabel className="tw-text-base">
          {translateText(convertText, "Are you looking to hire for your organisation?")}
          <span className="tw-text-red-500">*</span>
        </Form.ControlLabel>
        <Controller
          name="info.can_offer_job"
          control={control}
          render={({ field: { onChange, value } }) => (
            <RadioGroup onChange={onChange} value={value.toString()}>
              <Radio value="true">{translateText(convertText, "Yes")}</Radio>
              <Radio value="false">{translateText(convertText, "No")}</Radio>
            </RadioGroup>
          )}
        />
        <ErrorMessage
          errors={errors}
          name="info.can_offer_job"
          as={FieldError}
        />
      </Form.Group>
    </Form>
  );
};

export default CanOfferJob;
