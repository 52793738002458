import React from 'react';
import { Form } from 'rsuite';
import toSentenceCase from '../../util/toSentenceCase';


const FieldError = (props) => {
  const { children } = props;

  return (
    <Form.HelpText className="tw-text-sm tw-pt-2" style={{ color: 'red'}}>
      {toSentenceCase(children)}.
    </Form.HelpText>
  );
};

export default FieldError;