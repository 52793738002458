// React
import React from "react";

// FutureLab
import MentortshipStarterPackBanner from "../../../images/starter-pack-banner.png";

// UI
import { Button, Col, FlexboxGrid } from "rsuite";

// 3rd Party
import mixpanel from "mixpanel-browser";

export default function StarterPackBanner({
  currentUser,
  setShowStarterPackModal,
}) {

  const trackStarterPackBanner = () => {
    window.open("/subscriptions", "_self")

    mixpanel.track("Clicked Starter Pack Banner", {
      "Name": `${currentUser?.firstname} ${currentUser?.lastname}`,
      "User Type": currentUser?.user_type,
    });
  }

  return (
    <FlexboxGrid.Item colspan={24}>
      {/* For Mobile */}
      <FlexboxGrid
        justify="center"
        className="tw-hidden xs:tw-flex"
        onClick={trackStarterPackBanner}
      >
        <FlexboxGrid.Item
          as={Col}
          xs={22}
          className="tw-bg-gray-100 tw-rounded-xl tw-border tw-border-solid tw-border-[#428ac9] tw-mb-6 tw-px-0 tw-py-3 tw-block"
        >
          <div className="tw-block tw-px-4 tw-mb-2">
            <img
              src={MentortshipStarterPackBanner}
              className="tw-rounded-xl tw-w-full "
            />
          </div>
          <div>
            <h6 className="tw-mb-0 tw-text-center tw-mr-0 ">
              <span className="tw-font-medium">Get Started with </span>
              Starter Pack!
            </h6>
            <p className="tw-mb-0 tw-text-center tw-text-sm tw-underline tw-text-[#428ac9]">
              Find out more
            </p>
          </div>
        </FlexboxGrid.Item>
      </FlexboxGrid>

      {/* For Desktop */}
      <FlexboxGrid justify="center" className="xs:tw-hidden">
        <FlexboxGrid.Item
          as={Col}
          xs={24}
          md={24}
          lg={24}
          xl={22}
          xxl={21}
          className="tw-bg-gray-100 tw-rounded-xl tw-border tw-border-solid tw-border-[#428ac9] tw-mb-6 tw-px-0 sm:tw-py-3 tw-flex"
        >
          <div className="tw-inline tw-basis-1/3 sm:tw-hidden">
            <img
              src={MentortshipStarterPackBanner}
              className="tw-rounded-xl tw-w-full "
            />
          </div>
          <div className="tw-inline-flex tw-justify-center tw-flex-grow tw-self-center">
            <h6 className="tw-mb-0 tw-self-center tw-mr-3">
              <span className="tw-font-medium">Get Started with </span>
              Starter Pack!
            </h6>
            <div className="tw-inline">
              {/* {currentUser && (
                <Button
                  onClick={() => setShowStarterPackModal(true)}
                  appearance="ghost"
                  className="tw-mr-3"
                >
                  More details
                </Button>
              )} */}
              <Button appearance="primary" onClick={trackStarterPackBanner}>
                Click here
              </Button>
            </div>
          </div>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </FlexboxGrid.Item>
  );
}
