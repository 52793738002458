import React from "react";
import { MdOutlineCloudUpload } from "react-icons/md";
import { Button } from "rsuite";
import { useSetupVariable } from "../../../../contexts/portal/setup/setupVariables";
import { useNavigate } from "react-router-dom";

const BottomNavCTA = ({ handleSubmit, loading, handleSaveDraft }) => {
  const { formStep, handleStep, isSetupComplete } = useSetupVariable();

  const lastFormStep = formStep === "impact_tracking";
  const firstFormStep = formStep === "design";
  const setupNotComplete = isSetupComplete === false;
  const setupComplete = isSetupComplete === true;

  const navigate = useNavigate();

  return (
    <div
      className="tw-absolute tw-w-full tw-flex tw-justify-end tw-py-4"
      style={{ borderTop: "1px solid #cacaca" }}
    >
      {/* KIV - FUT-1506: Removed Temp. To be confirmed */}
      {/* <Button
        appearance="link"
        className="tw-mr-4"
        type="submit"
        onClick={handleSaveDraft}
      >
        <MdOutlineCloudUpload className="tw-mr-2 tw-text-xl" />
        Save as draft
      </Button>

      {firstFormStep ? (
        <Button
          appearance="ghost"
          className="tw-mr-2"
          onClick={() => navigate("/dashboard")}
        >
          Cancel
        </Button>
      ) : (
        <Button
          appearance="ghost"
          className="tw-mr-2"
          onClick={() => handleStep("previous")}
        >
          Previous
        </Button>
      )}
      */}

      <Button
        appearance="ghost"
        className="tw-mr-2"
        onClick={() => navigate("/dashboard")}
      >
        Cancel
      </Button>

      <Button loading={loading} appearance="primary" onClick={handleSubmit}>
        Create
        {/* KIV - FUT-1506: Removed Temp. To be confirmed */}
        {/* {lastFormStep && setupNotComplete
          ? "Create"
          : lastFormStep && setupComplete
          ? "Done"
          : "Next"} */}
      </Button>
    </div>
  );
};

export default BottomNavCTA;
