import React from "react";
import { Container, Header, Content, Navbar, Nav } from "rsuite";

import { NavLink } from "../../chat_management/ChatManagement";

import EnterpriseSideNav from "../components/navigation/EnterpriseSideNav";
import EnterpriseTopNav from "../components/navigation/EnterpriseTopNav";
import useScreenSize from "../../../util/useScreenSize";
import { useNavbarContext } from "contexts/navbarContext";

const DashboardLayout = ({ children }) => {
  const { isDesktop, isMobile } = useScreenSize();
  const { isExpanded } = useNavbarContext();

  return (
    <Container className="tw-h-screen">
      {/*Left SideNav */}
      {/* TODO: Do a drawer for mobile */}
      {isDesktop && <EnterpriseSideNav />}

      {/* Right Content Container */}
      <Container
        style={{
          paddingLeft: isMobile ? "0px" : isExpanded ? "260px" : "56px",
          transition: "padding-left 0.18s ease-in-out",
        }}
      >
        <div className="tw-relative">
          <Header className="tw-w-full">
            <EnterpriseTopNav />
          </Header>

          {/* Content */}
          <Content className="tw-px-20 tw-py-10 sm:tw-px-2 tw-w-full tw-box-border tw-overflow-y-auto tw-h-[calc(100svh-56px)]">
            {children}
          </Content>
        </div>
      </Container>
    </Container>
  );
};

export default DashboardLayout;
