import React from "react";
import { Divider } from "rsuite";
import truncateString from "../../../util/truncateString";
import avatarPlaceholder from "../../../images/avatar_placeholder.png";
import EventMentorFollowButton from "../../EventMentorFollowButton";
import { useUser } from "../../../contexts/user";

const EventSpeakers = ({ speakers }) => {
  const { user } = useUser();

  return (
    <div className="tw-my-6 tw-py-2">
      <h2 className="tw-text-base">Speakers</h2>
      <Divider className="tw-mt-0" />
      <div className="tw-w-full">
        {speakers.map((speaker) => (
          <div
            key={speaker.user_id}
            className="tw-flex tw-justify-between tw-mb-2 tw-items-center"
          >
            <div>
              <img
                src={speaker.profile_photo || avatarPlaceholder}
                style={{
                  maxHeight: 40,
                  borderRadius: 6,
                  marginRight: 10,
                }}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = avatarPlaceholder;
                }}
              />
              <a
                href={`/mentor_profile/${speaker?.slug}`}
                className="montserrat tw-font-medium"
              >
                {truncateString(speaker.full_name, 17)}
              </a>
            </div>
            {/* TODO: Event handler and useEffect for Follow */}
            {user && speaker.user_id != user.id && (
              <EventMentorFollowButton user={user} mentor={speaker} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default EventSpeakers;
