import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Stack, Uploader } from "rsuite";

import { MdOutlineImage } from "react-icons/md";
import { usePortalSetup } from "../../../contexts/portal/setup";
import { Controller } from "react-hook-form";
import BannerGuideModal from "../../form/BannerGuideModal";
import PortalImageGuideModal from "./component/PortalImageGuideModal";

import FieldError from "../../form/FieldError";
import { ErrorMessage } from "@hookform/error-message";
import { usePortal } from "../../../contexts/portal";

const PortalBannerUploaderV2 = ({
  name,
  imageUrl,
  control,
  errors,
  setValue,
}) => {
  const [fileInfo, setFileInfo] = useState(imageUrl ? imageUrl : undefined);
  const [fileError, setFileError] = useState(false);

  const uploaderRef = useRef(null);

  useEffect(() => {
    if (imageUrl) {
      setFileInfo(imageUrl);
    }
  }, [imageUrl]);

  const previewFile = (file, callback) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleUpload = (file, name) => {
    // File size doesn't exceed 2MB - 2097152
    if (file?.blobFile.size > 2 * 1024 * 1024) {
      setFileError(true);
      return;
    }

    setFileError(false);
    setValue(name, file.blobFile);
    previewFile(file.blobFile, (value) => {
      setFileInfo(value);
    });
  };

  return (
    <>
      <Stack spacing={8} alignItems="flex-end">
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <>
              <Uploader
                accept=".jpg, .png"
                fileListVisible={false}
                listType="picture"
                onUpload={(file) => {
                  handleUpload(file, name);
                }}
                action=""
              >
                <button style={{ width: 300, height: 100 }} ref={uploaderRef}>
                  {fileInfo?.includes("https") ? (
                    <img src={fileInfo} width="100%" height="100%" />
                  ) : fileInfo ? (
                    <img src={fileInfo} width="100%" height="100%" />
                  ) : (
                    <MdOutlineImage className="tw-text-4xl" />
                  )}
                </button>
              </Uploader>
              <ErrorMessage errors={errors} name={name} as={FieldError} />
            </>
          )}
        />
        <Stack
          className="tw-mb-2"
          direction="column"
          alignItems="flex-start"
          spacing={8}
        >
          <Button
            appearance="primary"
            className="tw-font-medium"
            onClick={() => uploaderRef.current.click()}
          >
            Upload
          </Button>
          <PortalImageGuideModal isBanner />
        </Stack>
      </Stack>
      {fileError && <FieldError>File exceeds 2MB</FieldError>}
    </>
  );
};

export default PortalBannerUploaderV2;
