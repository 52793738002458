// React
import React from "react";

// FutureLab
import UserCards from "../dashboard/users/UserCards";

// UI
import { Placeholder, Stack } from "rsuite";

const SearchResult = (props) => {
  const { mentors, currentUser, loading, intersectionRef, setMentors, program } = props;

  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      {!mentors.length && !loading ? (
        <div
          className="card"
          style={{
            borderRadius: 10,
            minHeight: 150,
          }}
        >
          <div className="m-auto">
            <span className="font-size-4 font-weight-bold text-center d-block">
              Sorry we can't find the mentor you're looking for.
            </span>
            <span className="font-size-4 font-weight-medium text-center d-block">
              Try refining your search with another keyword.
            </span>
          </div>
        </div>
      ) : (
        <Stack
          className="tw-overflow-x-auto no-scrollbar"
          wrap
          justifyContent="center"
          alignItems="stretch"
        >
          {mentors.map((mentor, i) => (
            <UserCards
              program={program}
              user={mentor}
              setUsers={setMentors}
              currentUser={currentUser}
              ref={i === mentors.length - 1 ? intersectionRef : null}
              key={mentor.id}
              location={program ? 'Program Discovery Page' : 'FL Discovery Page'}
            />
          ))}
        </Stack>
      )}
      {loading && (
        <div className="tw-text-center">
          <Placeholder.Paragraph
            style={{ height: 275, marginTop: 30 }}
            rows={10}
            graph="image"
            active
          />
        </div>
      )}
    </div>
  );
};

export default SearchResult;
