import React, { useEffect, useState } from "react";
import { Col, FlexboxGrid } from "rsuite";

const PaymentSuccess = ({ redirectTo }) => {
  const [count, setCount] = useState(5);

  useEffect(() => {
    const timer = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);

    // Redirect when count reaches 0
    if (count === 0) {
      clearInterval(timer);
      window.location.href = redirectTo;
    }

    // Cleanup function to clear interval when component unmounts
    return () => clearInterval(timer);
  }, [count, redirectTo]);

  return (
    <FlexboxGrid
      justify="center"
      className="tw-justify-center tw-items-center"
      style={{ height: "100dvh" }}
    >
      <FlexboxGrid.Item
        as={Col}
        colspan={24}
        sm={24}
        md={8}
        className="tw-flex tw-flex-col tw-text-center"
      >
        <h1 className="tw-mb-4">Thank you for your purchase!</h1>

        <p className="montserrat tw-text-center">
          Success! Your credits are in for chatting with our experts. Check your
          email for a receipt. Feel free to explore our mentors and book
          sessions when it suits you!
        </p>

        <p className="montserrat tw-text-center">
          <b>
            {count > 0
              ? `You will be automatically redirected in ${count} seconds.`
              : `You are currently being redirected.`}
          </b>{" "}
        </p>
        <p className="montserrat tw-text-center">
          Click{" "}
          <a className="tw-underline" href={""}>
            here
          </a>{" "}
          if the page does not redirect automatically.
        </p>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

export default PaymentSuccess;
