import {
  checkUserOnboarding,
  createUserOnboarding,
  getAllOnboardingRecords,
} from "api/onboarding/onboardingApi";
import React, { createContext, useState, useContext } from "react";

const OnboardingContext = createContext(undefined);

export const OnboardingProvider = ({ children }) => {
  const [onboardingRecords, setOnboardingRecords] = useState([]);
  const [isUserOnboarded, setIsUserOnboarded] = useState(null);
  const [error, setError] = useState(null);

  const fetchOnboardingRecords = async () => {
    try {
      const response = await getAllOnboardingRecords();
      setOnboardingRecords(response.data);
    } catch (error) {
      setError(error);
    }
  };

  const checkOnboardingStatus = async (onboardingType, userId = null) => {
    try {
      const response = await checkUserOnboarding(onboardingType, userId);
      setIsUserOnboarded(response.data);
    } catch (error) {
      setError(error);
    }
  };

  const handleCreateOnboarding = async (onboardingType, userId = null) => {
    try {
      await createUserOnboarding(onboardingType, userId);
    } catch (error) {
      setError(error);
    }
  };

  return (
    <OnboardingContext.Provider
      value={{
        onboardingRecords,
        isUserOnboarded,
        error,
        fetchOnboardingRecords,
        checkOnboardingStatus,
        handleCreateOnboarding,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

// Custom hook for using the context
export const useOnboarding = () => useContext(OnboardingContext);
