// React
import React from "react";

// FutureLab
import useDesktopOrMobile from "../../hooks/useDesktopOrMobile";
import FLMobileHeaderNav from "./fl/FLMobileHeaderNav";
import FLMobileFooterNav from "./fl/FLMobileFooterNav";
import FLDesktopNav from "./fl/FLDesktopNav";
import PortalMobileHeaderNav from "./portal/PortalMobileHeaderNav";
import PortalMobileFooterNav from "./portal/PortalMobileFooterNav";
import { useUser } from "../../contexts/user";
import NavbarBanner from "./NavbarBanner";
import { useNavbarContext } from "../../contexts/navbarContext";

/* FUT-1151: To hide mobile footer nav, set @show_footer == false controller#action */

const approvedNavbarPromoBannerLocation = [
  "/main/dashboard",
  "/mentor_profile",
  "/discover/mentors",
  "/main/events",
];

const NavbarContent = () => {
  const isDesktop = useDesktopOrMobile();
  const { user } = useUser();
  const { onPortal, showFooter } = useNavbarContext();
  const currentURL = window.location.pathname;

  const MobileHeaderNav = onPortal ? PortalMobileHeaderNav : FLMobileHeaderNav;
  const MobileFooterNav = onPortal ? PortalMobileFooterNav : FLMobileFooterNav;
  // const DesktopNav = onPortal ? PortalDesktopNav : FLDesktopNav;
  const DesktopNav = FLDesktopNav;

  function checkCurrentURL() {
    return approvedNavbarPromoBannerLocation.some((approvedURL) => {
      // Check if the current URL starts with the approved URL
      return currentURL.startsWith(approvedURL);
    });
  }

  return (
    <nav>
      {isDesktop ? (
        <>
          {checkCurrentURL() && <NavbarBanner userType={user?.user_type} />}
          <DesktopNav />
        </>
      ) : (
        <>
          {checkCurrentURL() && <NavbarBanner userType={user?.user_type}/>}
          <MobileHeaderNav />
          {showFooter !== false && <MobileFooterNav />}
        </>
      )}
    </nav>
  );
};
export default NavbarContent;
