import React, { useState } from "react";

// UI
import { MdOutlineDashboard } from "react-icons/md"; // Example icons
import SideNav from "../../../shared/navigation/SideNav";
import Navigation from "../../../shared/navigation/Navigation";
import { useNavigate } from "react-router-dom";
import { findNavByEventKey } from "components/portal/admin/components/utils/navRouteMatcher";

// TODO: Setup for Mobile drawer
// useMediaQuery

const navItems = [
  {
    eventKey: "1",
    label: "Dashboard",
    path: "/dashboard",
    icon: MdOutlineDashboard,
  },
  // Sample Nav.Menu
  // {
  //   eventKey: "2",
  //   label: "Management",
  //   icon: MdSettings,
  //   children: [
  //     {
  //       eventKey: "2-1",
  //       label: "Users",
  //       path: "/management/users",
  //       icon: MdOutlineDashboard,
  //     },
  //     {
  //       eventKey: "2-2",
  //       label: "Roles",
  //       path: "/management/roles",
  //       icon: MdSettings,
  //     },
  //   ],
  // },
];

const EnterpriseSideNav = () => {
  const [activeKey, setActiveKey] = useState("1");
  const [openKey, setOpenKey] = useState([]);
  const navigate = useNavigate();

  const handleSelect = (eventKey) => {
    const selectedItem = findNavByEventKey(eventKey, navItems);
    if (selectedItem && selectedItem.path) {
      navigate(`.${selectedItem.path}`);
      setActiveKey(eventKey);
    }
  };

  const handleOpen = (key) => setOpenKey(key);

  return (
    <SideNav currentOpen={openKey} handleOpen={handleOpen}>
      <Navigation
        navItems={navItems}
        activeKey={activeKey}
        handleSelect={handleSelect}
      />
    </SideNav>
  );
};

export default EnterpriseSideNav;
