//@ts-nocheck
//hack to disable typescript checking for this file need to patch after
import React, { forwardRef } from "react";
// import enterpriseBanner from "../../../images/enterprise_poster.png";
import enterpriseBanner2 from "../../../images/enterprise_generic_banner.png";
import FlLogo from "../../../images/FL_logo_main.svg";
import FlLogo2 from "../../../images/fl-blue.svg";
import { Link } from "react-router-dom";
import { Input, Button, InputPicker, InputGroup, Divider } from "rsuite";

export const withContainer = (Component) => {
  return (props) => (
    <div className="tw-grid tw-w-full tw-h-screen tw-overflow-y-none tw-grid-cols-12">
      <div
        className="tw-bg-cover tw-bg-center tw-bg-no-repeat tw-bg-scroll tw-col-span-4 md:tw-hidden lg:tw-col-span-5"
        style={{
          background: `url(${enterpriseBanner2})`,
          height: "100dvh",
        }}
      ></div>
      <div className="no-scrollbar tw-overflow-y-auto tw-col-span-8 tw-flex tw-flex-col tw-items-center tw-w-full tw-min-h-screen tw-py-10 md:tw-col-span-12 lg:tw-col-span-7">
        <Component {...props} />
      </div>
    </div>
  );
};

export const FormWrapper = ({
  children,
  footerText = "Already have an account?",
  footerLink = { title: "Sign in.", to: "/login" },
  title = "Sign up for free",
}) => {
  return (
    <div className="tw-max-w-4xl tw-w-full tw-px-20 xs:tw-px-8 sm:tw-px-20 md:tw-px-20">
      <img src={FlLogo2} alt="logo" className="tw-mb-6" />
      <h1 className="tw-mb-5 tw-text-[#222] tw-text-2xl tw-font-bold">
        {title}
      </h1>
      {children}
      <Divider />
      <div className="tw-flex tw-justify-center tw-items-center tw-w-full">
        <span className="tw-text-sm">
          {footerText} <Link to={footerLink.to}>{footerLink.title}</Link>
        </span>
      </div>
    </div>
  );
};

export const FormInput = forwardRef<any, any>(
  (
    { label = "", type = "text", InputComponent = Input, error, ...props },
    ref
  ) => {
    const {
      onChange: handleChange,
      onBlur,
      name,
      placeholder,
      AddOn,
      ...rest
    } = props;

    const onChange = (value) => {
      let v = {
        target: {
          name: name,
          value: value,
        },
      };
      handleChange(v);
    };

    return (
      <div className="tw-flex tw-flex-col tw-gap-1 tw-mb-5">
        <label className="tw-text-md tw-mb-2 tw-font-medium tw-text-[#222]">
          {label}:
        </label>
        <InputGroup>
          {AddOn && <InputGroup.Addon>{AddOn}</InputGroup.Addon>}
          <InputComponent
            size="lg"
            onChange={onChange}
            ref={ref}
            name={name}
            type={type}
            placeholder={placeholder}
            {...rest}
            className="tw-w-full"
          />
        </InputGroup>
        {error && (
          <p className="tw-text-red-500 tw-text-sm">
            {formatErrorMessage({ message: error.message, name, label })}
          </p>
        )}
      </div>
    );
  }
);

const formatErrorMessage = ({ message, name, label }) => {
  //replace name with label
  return message.replace(name, label);
};
