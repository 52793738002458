// React
import React, { useEffect, useState } from "react";

// FutureLab
import { ApplicationFormQuestion } from "../types";

// UI
import { Form, Input, Radio, RadioGroup, Slider } from "rsuite";

// 3rd Party
import { Controller } from "react-hook-form";

export default function FormContent({
  application,
  register,
  errors,
  control,
}) {
  return application.questions.map((question, index) => (
    <RenderFormContent
      key={question.id}
      question={question}
      index={index}
      register={register}
      control={control}
      errors={errors}
    />
  ));
}

const RenderFormContent = ({ question, index, register, errors, control }) => {
  const {
    id,
    title,
    description,
    application_id,
    required,
  }: ApplicationFormQuestion = question;
  const [inputElement, setInputElement] = useState<React.ReactNode>(null);

  useEffect(() => {
    setInputElement(renderInputOption(index, control, question));
  }, [question]);

  return (
    <div className="tw-my-8" key={id}>
      <Form.Control
        {...register(`answers.${index}.question_id`)}
        defaultValue={id}
        className="tw-hidden"
      />
      <Form.Control
        {...register(`answers.${index}.application_id`)}
        defaultValue={application_id}
        className="tw-hidden"
      />
      <Form.Control
        {...register(`answers.${index}.required`)}
        defaultValue={`${required}`}
        className="tw-hidden"
      />
      <Form.ControlLabel className="tw-text-base tw-font-medium tw-text-gray-700 tw-mb-2">
        {index + 1}. {title}
        {""}
        {required && <span className="tw-text-base tw-text-red-500"> *</span>}
      </Form.ControlLabel>
      <Form.ControlLabel className="tw-text-gray-600">
        {description}
      </Form.ControlLabel>
      {inputElement}
      {errors.answers?.[index]?.answer && (
        <p className="tw-pt-1 tw-mb-2 tw-text-xs tw-text-red-500">
          {" "}
          {errors.answers?.[index].answer.message}{" "}
        </p>
      )}
    </div>
  );
};

export function renderInputOption(index, control, question): React.ReactNode {
  if (!question) return null;

  return (
    {
      text: (
        <Controller
          name={`answers.${index}.answer`}
          control={control}
          defaultValue={question?.answer || ""}
          render={({ field }) => (
            <Input
              {...field}
              as="textarea"
              rows={3}
              placeholder="Your Answer"
            />
          )}
        />
      ),
      options: (
        <Controller
          name={`answers.${index}.answer`}
          defaultValue={question?.answer || null}
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              {question.options.map((option, index) => {
                return (
                  <Radio key={index} value={option}>
                    {option}
                  </Radio>
                );
              })}
            </RadioGroup>
          )}
        />
      ),
      scale: (
        <Controller
          name={`answers.${index}.answer`}
          control={control}
          defaultValue={Number(question?.answer) || null}
          render={({ field }) => (
            <div className="tw-flex tw-mt-4 tw-px-2">
              <Slider
                className="tw-w-full"
                {...field}
                max={question.scale_end}
                min={question.scale_start}
                progress
                step={question.scale_start}
                graduated
                renderMark={(mark) => {
                  return mark;
                }}
              />
            </div>
          )}
        />
      ),
    }?.[question.question_type] || null
  );
}
