// React
import React from "react";

// FutureLab
import UnconcludedBooking from "./UnconcludedBooking";
import { checkCurrentURL } from "../../util/urlChecker";
import NetPromoterScore from "../NetPromoterScore";

// 3rd Party
import Cookies from "js-cookie";

export default function NotificationContent({
  portalId,
  currentUser,
  onPortal,
  token,
  pwaUrl,
  modalInfo,
}) {
  const preventSameModal = Cookies.get(`no_show_modal`);
  const currentUrl = window.location.pathname;

  const isApprovedUrl = checkCurrentURL(
    modalInfo?.approved_location,
    currentUrl
  );

  const isMultipleSession = modalInfo?.session_type === "multiple";

  // Due to failed/lapsed booking session not having a specific status to update the booking status (status remain upcoming). This code serve as a checker to prevent the same booking modal popup to be shown
  const isSameModal = modalInfo?.booking_id == preventSameModal;

  const isNpsModalSkipped = Cookies.get(`${modalInfo.details.type}`);

  return (
    <>
      {modalInfo?.module === "booking" &&
        isApprovedUrl &&
        (!isSameModal || isMultipleSession) && (
          <UnconcludedBooking
            token={token}
            modalInfo={modalInfo}
            currentUser={currentUser}
            onPortal={onPortal}
            pwaUrl={pwaUrl}
          />
        )}
      {modalInfo?.module === "nps" && isApprovedUrl && !isNpsModalSkipped && (
        <NetPromoterScore
          portalId={portalId}
          onPortal={onPortal}
          userNpsDetails={modalInfo.details}
        />
      )}
    </>
  );
}
