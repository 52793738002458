// React
import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";

// FutureLab
import { IMentorContext } from "../../../../../contexts/mentor_profile";
import translateText from "../../../../../util/translateText";

// UI
import { Button, Col, Divider, FlexboxGrid, Modal } from "rsuite";

// 3rd Party
import { DayPickerSingleDateController } from "react-dates";
import moment from "moment";

const AvailabilityModal = ({
  open,
  handleClose,
  daySize = 50,
  convertText,
  initialDate,
  initialTime,
  setInitialDate,
  setInitialTime,
  handleBookMentor,
}) => {
  const {
    mentor: { available_dates, timeslots },
  }: any = useOutletContext<IMentorContext>();
  const [currentStep, setCurrentStep] = useState(0);
  const [focused, setFocused] = useState(true);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className="tw-w-[500px]"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title className="tw-text-xl tw-font-medium">
          {translateText(convertText, "Book Session")}
        </Modal.Title>
        <Divider className="tw-absolute tw-w-full tw-left-0 tw-mt-4 tw-bg-gray-300" />
      </Modal.Header>
      {currentStep === 0 && (
        <DateStep
          date={initialDate}
          setDate={setInitialDate}
          focused={focused}
          setFocused={setFocused}
          available_dates={available_dates}
          daySize={daySize}
          setCurrentStep={setCurrentStep}
          handleBookMentor={handleBookMentor}
        />
      )}
      {currentStep === 1 && (
        <TimeStep
          date={initialDate}
          setTime={setInitialTime}
          timeslots={timeslots}
          handleBookMentor={handleBookMentor}
        />
      )}
    </Modal>
  );
};

const TimeStep = ({ date, setTime, timeslots, handleBookMentor }) => {
  const [active, setActive] = React.useState(null);
  const activeClass = "tw-border-[#1675E0] tw-font-bold";

  return (
    <>
      <BodyWrapper onBooking={handleBookMentor}>
        <div>
          <h6 className="tw-mb-9 tw-text-xl">
            {date.format("dddd, DD MMMM YYYY")}
          </h6>
          <FlexboxGrid>
            {timeslots[date.format("DD/MM/YYYY")].map((time, index) => (
              <FlexboxGrid.Item as={Col} colspan={24} xs={6} key={time}>
                <a
                  onClick={() => {
                    setTime(time);
                    setActive(index);
                  }}
                >
                  <div
                    className={
                      "tw-border tw-border-solid tw-border-[#979797] tw-mb-2 tw-rounded-md tw-py-2 tw-text-sm" +
                      (active === index ? activeClass : "")
                    }
                  >
                    {time}
                  </div>
                </a>
              </FlexboxGrid.Item>
            ))}
          </FlexboxGrid>
        </div>
      </BodyWrapper>
    </>
  );
};

const DateStep = ({
  date,
  setDate,
  focused,
  setFocused,
  available_dates,
  daySize,
  setCurrentStep,
  handleBookMentor,
}) => {
  return (
    <>
      <BodyWrapper onBooking={handleBookMentor}>
        <DayPickerSingleDateController
          initialVisibleMonth={() => moment(date)}
          date={date}
          onDateChange={(date) => {
            setDate(date);
            setCurrentStep(1);
          }}
          focused={focused}
          onFocusChange={({ focused }) => setFocused(focused)}
          numberOfMonths={1}
          noBorder
          hideKeyboardShortcutsPanel
          daySize={daySize}
          isOutsideRange={(day) =>
            !available_dates.includes(day.format("DD/MM/YYYY"))
          }
        />
      </BodyWrapper>
    </>
  );
};

const BodyWrapper = ({ children, onBooking }) => (
  <>
    <Modal.Body className="tw-pt-5">
      <div className="tw-my-2">
        <div className="tw-text-center">{children}</div>
      </div>
    </Modal.Body>
    <Divider className="tw-absolute tw-w-full tw-left-0 tw-m-0 tw-bg-gray-300" />
    <BasicFooter onBooking={onBooking} />
  </>
);

const BasicFooter = ({ onBooking = () => {}, disable = false }) => (
  <Modal.Footer className="tw-pt-5">
    <Button onClick={onBooking} appearance="primary" disabled={disable}>
      Book Mentor
    </Button>
  </Modal.Footer>
);

export default AvailabilityModal;
