function getWindowSize() {
  const windowWidth = window.innerWidth;

  if (windowWidth < 481) {
    return 'small';
  } else if (windowWidth < 768) {
    return 'medium';
  } else {
    return 'large';
  }
}

export default getWindowSize;