import React from "react";
import { Message } from "rsuite";
import { useEnterprise } from "../../../contexts/enterpriseContext";
import { resendConfirmation } from "../api";

const styles = {
  padding: 0,
  position: "relative",
} as any;

export const ConfirmationWarning = () => {
  const { state, toaster } = useEnterprise();
  const confirmed = state.admin && state?.admin?.confirmed;

  const handleResend = async () => {
    const email = state.admin?.email;
    try {
      const res = await resendConfirmation({ email });
      toaster({
        type: "success",
        msg: "Confirmation email sent",
      });
    } catch (error) {
      console.log(error);
      toaster({
        type: "error",
        msg: "Oops, something went wrong!",
      });
    }
  };

  if (confirmed) {
    return null;
  }

  return (
    <div style={styles}>
      <Message showIcon type="warning">
        You have yet to confirm your email account. Click{" "}
        <a onClick={handleResend}>here</a> to receive a confirmation email
      </Message>
    </div>
  );
};
