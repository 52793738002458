// React
import React, { useState } from "react";

// FutureLab
import { handleUserFollow } from "../../../../../util/handleUserFollow";
import translateText from "../../../../../util/translateText";

// UI
import { Message, toaster } from "rsuite";
import { FaShareAlt, FaPlus } from "react-icons/fa";

// 3rd Party
import { CopyToClipboard } from "react-copy-to-clipboard";
import mixpanel from "mixpanel-browser";

export default function ShareAndFollowUserButton({
  user,
  currentUser,
  convertText,
  isLoggedIn,
}) {
  const [isFollowing, setIsFollowing] = useState(user?.is_following);

  const toggleFollow = async () => {
    try {
      await handleUserFollow({
        mentor: user,
        setIsFollowing,
        onSuccess: (data) => {
          toaster.push(
            !data ? (
              <Message showIcon type="error">
                {`${translateText(
                  convertText,
                  "You are no longer following this mentor"
                )}.`}
              </Message>
            ) : (
              <Message showIcon type="success">
                {`${translateText(
                  convertText,
                  "You are following this mentor"
                )}.`}
              </Message>
            )
          );
          mixpanel.track("Clicked Follow Button", {
            Name: `${currentUser?.firstname} ${currentUser?.lastname}`,
            "User Type": currentUser?.user_type,
            Action: isFollowing ? "Unfollowing" : "Following",
            "Mentor Name": user.fullname,
            "Mentor Profession": user.info,
            Location: location,
          });
        },
        onFailure: () => {
          toaster.push(
            <Message showIcon type="error">
              An error occurred
            </Message>
          );
        },
      });
    } catch (error) {
      console.error("Error toggling follow:", error);
    }
  };

  return (
    <div className="tw-text-base tw-text-[#1675E0] tw-flex tw-gap-5 sm:tw-gap-2 tw-items-center tw-flex-row sm:tw-flex-col sm:tw-items-start sm:tw-justify-start">
      {isLoggedIn && (
        <a
          onClick={toggleFollow}
          className="mentor_follow_toggle tw-flex tw-items-center tw-gap-1"
        >
          {!!!isFollowing && <FaPlus />}
          <span className="sm:tw-hidden tw-inline-block">
            {translateText(
              convertText,
              !!isFollowing ? "Unfollow" : "Follow Mentor"
            )}
          </span>
          <span className="tw-hidden sm:tw-inline-block">
            {translateText(convertText, !!isFollowing ? "Unfollow" : "Follow")}
          </span>
        </a>
      )}

      <CopyToClipboard
        text={window.location.href}
        onCopy={() =>
          toaster.push(
            <Message showIcon type="success">
              {translateText(convertText, "Copied to clipboard")}
            </Message>
          )
        }
      >
        <a className="tw-flex tw-items-center tw-gap-1">
          <FaShareAlt />
          <span>{translateText(convertText, "Share")}</span>
        </a>
      </CopyToClipboard>
    </div>
  );
}
