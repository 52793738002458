// Main
import React, { useEffect, useRef, useState } from "react";

// 3rd Party
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

// FL
import GenericMsgLoader from "../../shared/GenericMsgLoader";
import { FutureLabApi } from "../../../util/api";
import { FlAdminApi } from "../../../util/fladmin_api";

// UI
import FieldError from "../../ui/FieldError";
import { Divider, Form, Message, TagPicker } from "rsuite";

const EventNotificationForm = ({
  skills,
  industries,
  companies,
  countries,
}) => {
  const [reachCount, setReachCount] = useState(0);
  const [reachLoading, setReachLoading] = useState(false);
  const isFirstLoad = useRef(true);

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const getTagReach = () => {
    setReachLoading(true);
    FlAdminApi({
      method: "get",
      path: "/events/tag_reach",
      options: eventTags,
      success: ({ data }) => {
        console.log("Reach", data);
        setReachLoading(false);
        setReachCount(data);
      },

      error: () => {},
    });
  };

  const eventTags = useWatch({ control, name: "event_tags" });

  useEffect(() => {
    if (!isFirstLoad.current) {
      getTagReach();
    } else {
      isFirstLoad.current = false;
    }
  }, [eventTags]);

  return (
    <>
      <Message showIcon type="warning">
        Before confirming, please ensure you're certain about your selection.
      </Message>
      <Divider />
      <h6>Select categories</h6>

      {isFirstLoad.current === false && (
        <Message showIcon type="info">
          {reachLoading ? (
            <GenericMsgLoader center />
          ) : (
            <span>
              Your selection will be sent to <b>{reachCount}</b> user(s).
            </span>
          )}
        </Message>
      )}

      <Form.Group className="tw-my-2">
        <Form.ControlLabel>Skills</Form.ControlLabel>
        <Controller
          name={"event_tags.skill_tags"}
          control={control}
          render={({ field }) => (
            <>
              <TagPicker
                {...field}
                size="lg"
                placeholder="Select your options"
                className="tw-w-full"
                placement="autoVerticalStart"
                // onChange={(value) => handleTagChange("skill_tags", value)}
                data={skills}
                creatable={false}
              />
              <ErrorMessage
                errors={errors}
                name={"event_tags.skill_tags"}
                as={FieldError}
              />
            </>
          )}
        />
      </Form.Group>
      <Form.Group className="tw-my-2">
        <Form.ControlLabel>Industries</Form.ControlLabel>
        <Controller
          name={"event_tags.industry_tags"}
          control={control}
          render={({ field }) => (
            <>
              <TagPicker
                {...field}
                size="lg"
                placeholder="Select your options"
                className="tw-w-full"
                placement="autoVerticalStart"
                data={industries}
                creatable={false}
              />
              <ErrorMessage
                errors={errors}
                name={"event_tags.industry_tags"}
                as={FieldError}
              />
            </>
          )}
        />
      </Form.Group>
      <Form.Group className="tw-my-2">
        <Form.ControlLabel>Companies</Form.ControlLabel>
        <Controller
          name={"event_tags.company_tags"}
          control={control}
          render={({ field }) => (
            <>
              <TagPicker
                {...field}
                size="lg"
                placeholder="Select your options"
                className="tw-w-full"
                placement="autoVerticalStart"
                data={companies}
                virtualized
                creatable={false}
              />
              <ErrorMessage
                errors={errors}
                name={"event_tags.company_tags"}
                as={FieldError}
              />
            </>
          )}
        />
      </Form.Group>
      <Form.Group className="tw-my-2">
        <Form.ControlLabel>Countries</Form.ControlLabel>
        <Controller
          name={"event_tags.country_tags"}
          control={control}
          render={({ field }) => (
            <>
              <TagPicker
                {...field}
                size="lg"
                placeholder="Select your options"
                className="tw-w-full"
                placement="autoVerticalStart"
                data={countries}
                creatable={false}
              />
              <ErrorMessage
                errors={errors}
                name={"event_tags.country_tags"}
                as={FieldError}
              />
            </>
          )}
        />
      </Form.Group>
    </>
  );
};

export default EventNotificationForm;
