import React from "react";

import { MdOutlineBusinessCenter } from "react-icons/md";
import { Button, Col, Divider, FlexboxGrid, Stack } from "rsuite";
import avatarPlaceholder from "../../../../images/avatar_placeholder.png";

import truncateString from "../../../../util/truncateString";
import useDrag from "../../../../util/useDrag";
// import { LeftArrow, RightArrow } from "../../ui/ScrollArrow";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import profileNotComplete from "../../../../images/profile_not_complete.png";

import translateText from "../../../../util/translateText";
import mixpanel from "mixpanel-browser";
import CustomTag from "../../../ui/CustomTag";

const PortalUserCards = (props) => {
  const {
    user,
    forMentee,
    forMentor,
    currentUser,
    convertText,
    activeKey,
    emptyText,
  } = props;
  const { dragStart, dragStop, dragMove, dragging } = useDrag();
  const handleDrag =
    ({ scrollContainer }) =>
    (ev: React.MouseEvent) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  const openInbox = (chat_link) =>
    chat_link && window.open(chat_link, "_blank");

  return (
    <>
      {user.length > 0 ? (
        <div onMouseLeave={dragStop}>
          <ScrollMenu
            // LeftArrow={LeftArrow}
            // RightArrow={RightArrow}
            onMouseDown={() => dragStart}
            onMouseUp={() => dragStop}
            onMouseMove={handleDrag}
          >
            <Stack
              className="tw-overflow-x-auto no-scrollbar"
              alignItems="stretch"
            >
              {user?.map((user) => (
                <Stack.Item key={user.id} className="user-wrapper">
                  <div>
                    <div className="tw-p-3">
                      <div
                        className="tw-relative"
                        style={{ borderRadius: "10px", overflow: "hidden" }}
                      >
                        <img
                          src={user.photo || avatarPlaceholder}
                          alt=""
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = avatarPlaceholder;
                          }}
                        />
                      </div>
                    </div>
                    <div className="tw-w-full tw-px-3 tw-pb-2">
                      <div className="tw-flex tw-justify-between tw-items-center">
                        <h6 className="tw-text-base tw-m-0 tw-truncate">
                          {truncateString(`${user.fullname}`, 14)}
                        </h6>
                      </div>
                      <div className="tw-flex tw-mb-2">
                        <span className="tw-shrink-0" style={{ width: 30 }}>
                          <MdOutlineBusinessCenter className="font-size-9" />
                        </span>
                        <span className="montserrat tw-text-sm tw-mt-1 tw-overflow-hidden">
                          {(user.info.job_title && user.info.company.length) > 0
                            ? `${user.info.job_title} at ${user.info.company}`
                            : "-"}
                        </span>
                      </div>

                      {forMentor && (
                        <>
                          <Divider className="tw-my-4" />
                          {user.goals.length > 0 && (
                            <div className="mb-2">
                              <p className="font-size-4 tw-m-0 tw-font-medium montserrat">
                                How I can help
                              </p>

                              {user.goals.map((goal) => (
                                <span
                                  key={goal.id}
                                  className={`font-size-4 tw-mr-1 tw-my-1 tw-border tw-border-solid tw-rounded-md tw-px-2 tw-py-1 tw-inline-block`}
                                  style={{
                                    color: "#1675E0",
                                    borderColor: "#1675E0",
                                  }}
                                >
                                  {goal.name}
                                </span>
                              ))}
                            </div>
                          )}
                          {user.ratings_count != 0 &&
                            user.sessions_count != 0 && (
                              <div className="tw-flex tw-justify-between">
                                <div className="tw-w-1/2">
                                  <p className="font-size-4 tw-m-0 tw-font-medium montserrat">
                                    Ratings
                                  </p>
                                  <p className="tw-text-sm montserrat">
                                    {user.ratings_count
                                      ? `${user.ratings_count} Received`
                                      : "-"}
                                  </p>
                                </div>
                                <div className="tw-w-1/2">
                                  <p className="font-size-4 tw-m-0 tw-font-medium montserrat">
                                    Sessions
                                  </p>
                                  <p className="tw-text-sm montserrat">
                                    {user.sessions_count
                                      ? `${user.sessions_count} Completed`
                                      : "-"}
                                  </p>
                                </div>
                              </div>
                            )}
                        </>
                      )}
                    </div>
                  </div>

                  <div
                    className="tw-full tw-px-3 tw-py-3"
                    style={{
                      borderTop: "1px solid #E5E5EA",
                      marginTop: "auto",
                    }}
                  >
                    {forMentee && (
                      <Button
                        appearance="primary"
                        className="tw-w-full"
                        onClick={() => openInbox(user.chat_link)}
                        // href={`/inbox?msg_id=${user.id}`}
                      >
                        Send message
                      </Button>
                    )}
                    {forMentor && (
                      <FlexboxGrid.Item colspan={24}>
                        <FlexboxGrid.Item as={Col} xs={24} className="tw-flex">
                          {activeKey === "my_mentors" && user.chat_link && (
                            <Button
                              appearance="ghost"
                              className="tw-w-1/2 tw-mr-2"
                              onClick={() => openInbox(user.chat_link)}
                            >
                              Chat
                            </Button>
                          )}

                          {/* Track this button to get the number of user viewing a mentor profile for the dashboard */}
                          <Button
                            appearance="primary"
                            className="tw-w-full"
                            href={user.profile_link}
                            // href={`/mentor_profile/${user.parameterize_firstname}/${user.hex_id}`}
                          >
                            View profile
                          </Button>
                        </FlexboxGrid.Item>
                      </FlexboxGrid.Item>
                    )}
                  </div>
                </Stack.Item>
              ))}
            </Stack>
          </ScrollMenu>
        </div>
      ) : (
        <div className="sm:tw-mb-4 tw-flex tw-flex-col tw-items-center tw-h-[235px] tw-justify-between tw-pt-10 tw-gap-2 sm:tw-h-auto">
          <img
            src={profileNotComplete}
            alt="profile not complete"
            className="tw-w-20 tw-h-20"
          />
          <p className="montserrat tw-text-center tw-mb-5">{emptyText}</p>
        </div>
      )}
    </>
  );
};
export default PortalUserCards;
