import React from 'react';
import { Button, Col, Form, Grid, Row } from 'rsuite';
import translateText from '../../util/translateText';
import PanelContainer from './PanelContainer';
import PreviewSaveButton from './PreviewSaveButton';
import { useVariables } from '../../contexts/variables';

const Availability = (props) => {
  const variables = useVariables()
  const { onPortal, enterpriseSlug, programSlug } = variables.variables
  
  return (
    <div>
      <PreviewSaveButton noSave />
      <PanelContainer>
        <Grid fluid>
          <Row gutter={24}>
            <Col xs={24} md={12}>
              <Form fluid>
                <Form.Group>
                  <Form.ControlLabel className="tw-text-base">
                    {translateText(
                      props.convertText,
                      "Set my upcoming availability"
                    )}
                    <Form.HelpText tooltip>
                      {translateText(
                        props.convertText,
                        "Upcoming availability is the date and time that you’re available for mentorship. You can setup up to 3 (three) different date & time."
                      )}
                    </Form.HelpText>
                  </Form.ControlLabel>
                  <Button
                    appearance="primary"
                    className="tw-w-56 tw-text-base"
                    href={onPortal ? `/app/${enterpriseSlug}/${programSlug}/availability` : "/main/availability"}
                  >
                    {translateText(props.convertText, "Set availability")}
                  </Button>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Grid>
      </PanelContainer>
    </div>
  );
};

export default Availability;
