import React, { useEffect, useState } from "react";
import { PortalApi } from "../../../util/portal_api";
import { Col, DateRangePicker, FlexboxGrid, Panel } from "rsuite";
import SessionCompletionBarChart from "./charts/SessionCompletionBarChart";

const SessionCompletionPanel = ({ progId }) => {
  const [bookingData, setBookingData] = useState({
    booking: 0,
    conducted: 0,
    reflected: 0,
  });

  // useEffect to update
  useEffect(() => {
    fetchSessionCompletion(progId);
  }, []);

  const fetchSessionCompletion = (progId, dateRange = null) => {
    PortalApi({
      method: "get",
      path: "/analytics/session_completion",
      options: { program_id: progId, date_range: dateRange },
      success: ({ data }) => {
        setBookingData(data);
      },
      error: (data) => {
        console.log("Error:", data);
      },
    });
  };

  const handleSessionRange = (dateRange) => {
    fetchSessionCompletion(progId, dateRange);
  };

  return (
    <>
      {/* Session Completion */}
      <FlexboxGrid.Item as={Col} colspan={24} sm={12} lg={24}>
        <Panel bordered className="tw-my-4">
          <div className="tw-flex tw-justify-between tw-items-center">
            <div>
              <p className="tw-text-lg tw-text-center montserrat tw-font-bold">
                Session Completion (%)
              </p>
            </div>

            <DateRangePicker
              format={"dd-MMM-yyyy"}
              placeholder="Filter Date Range"
              className="tw-w-1/4"
              placement="bottomEnd"
              onChange={(value) => handleSessionRange(value)}
            />
          </div>
          <div>
            <SessionCompletionBarChart data={bookingData} />
          </div>
        </Panel>
      </FlexboxGrid.Item>
    </>
  );
};

export default SessionCompletionPanel;
