// React
import React from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";

// FutureLab
import AppContainer from "./AppContainer";
import MenteeProfileContent from "./user_profile/mentee/MenteeProfileContent";
import { VariablesProvider } from "../contexts/variables";
import { UserProvider } from "../contexts/user";
import {
  useMenteeProfile,
  MenteeProfileProvider,
} from "../contexts/mentee_profile";

// UI
import { Loader } from "rsuite";

export default function MenteeProfile({ variables }) {
  return (
    <BrowserRouter>
      <AppContainer>
        <Routes>
          <Route
            path="/mentee_profile/:hexId"
            element={<MenteeProvider variables={variables} />}
          >
            <Route
              path="/mentee_profile/:hexId"
              element={
                <MenteeProfileContent
                  convertText={variables.convertText}
                  currentUser={variables.currentUser}
                />
              }
            />
          </Route>
        </Routes>
      </AppContainer>
    </BrowserRouter>
  );
}

function MenteeProvider({ variables }) {
  return (
    <VariablesProvider variables={variables}>
      <UserProvider>
        <MenteeProfileProvider>
          <RenderMenteeProfile />
        </MenteeProfileProvider>
      </UserProvider>
    </VariablesProvider>
  );
}

function RenderMenteeProfile() {
  const { mentee, setMentee, refreshMentee, type } = useMenteeProfile();

  if (!mentee) return <Loader center content="loading" />;
  return (
    <Outlet
      context={{
        mentee,
        setMentee,
        refreshMentee,
        type,
      }}
    />
  );
}
