// PhotoUploader.tsx
import React, { useState, useRef, useEffect } from "react";

import PhotoCropper from "./PhotoCropper";
import { Button, Uploader } from "rsuite";
import { MdOutlineImage } from "react-icons/md";

import { usePortal } from "../../../../contexts/portal";
import "react-advanced-cropper/dist/style.css";
import Cookies from "js-cookie";

type ImageFile = {
  blob: Blob;
  name: string;
  type: string;
};

const authToken = Cookies.get(`pwa_${process.env.RAILS_ENV}_session`);

const previewFile = (file: File, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.onloadend = () => {
    callback(reader.result as string);
  };
  reader.readAsDataURL(file);
};

type PhotoUploaderProps = {
  imageUrl: string;
  target: string;
  name: string;
  aspectRatio?: number;
  setValue: (name: string, value: any) => void;
};

const PhotoUploader: React.FC<PhotoUploaderProps> = ({
  imageUrl,
  target,
  name,
  aspectRatio,
  setValue,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [imageBlob, setImageBlob] = useState<ImageFile | null>(null);
  const [fileError, setFileError] = useState<boolean>(false);
  const [fileInfo, setFileInfo] = useState<string | undefined>(imageUrl);

  const uploaderRef = useRef<HTMLButtonElement>(null);
  const { program } = usePortal();

  const onCrop = (file: ImageFile) => {
    setImageBlob(file);
  };

  const handleUpload = (file: any) => {
    if (file.blobFile.size > 2 * 1024 * 1024) {
      setFileError(true);
      return;
    }

    setIsEditing(true);
    setFileError(false);
    setValue(name, file.blobFile);

    previewFile(file.blobFile, (value) => {
      setFileInfo(value);
    });
  };

  return (

    // Function: Upload - Modal Open - Set Crop - Save - Close
    <div className="tw-grid tw-gap-4 tw-grid-cols-2">
      <Uploader
        accept="image/png, image/jpeg, image/jpg, image/gif"
        fileListVisible={false}
        listType="picture"
        // onUpload={handleUpload}
        action=""
      >
        <button style={{ width: 200, height: 300 }} ref={uploaderRef}>
          {program && fileInfo?.includes("https") ? (
            <img
              src={fileInfo}
              width="100%"
              height="100%"
              alt="Vertical Banner"
            />
          ) : fileInfo ? (
            <img
              src={fileInfo}
              width="100%"
              height="100%"
              alt="Vertical Banner"
            />
          ) : (
            <MdOutlineImage className="tw-text-4xl" />
          )}
        </button>
      </Uploader>

      <div>
        <Button className="tw-mr-2" onClick={() => setIsEditing(true)} appearance="primary">
          Upload
        </Button>
        <Button onClick={() => setIsEditing(true)}>Edit</Button>
      </div>

      {isEditing && (
        <PhotoCropper
          image={fileInfo}
          onCancel={() => setIsEditing(false)}
          onCrop={onCrop}
          aspectRatio={aspectRatio || 1}
          name={name}
          isEditing={isEditing}
        />
      )}
    </div>
  );
};

export default PhotoUploader;
