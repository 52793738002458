import React from "react";
import { Container } from "rsuite";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

const AppContainer = ({ children, className = null }) => {
  return <Container className={className}>{children}</Container>;
};

export default AppContainer;
