import React from "react";
import { Col, Form, Input, TagPicker } from "rsuite";
import FieldError from "../ui/FieldError";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import translateText from "../../util/translateText";

const certificateList = [
  "Certified HRDC Trainer",
  "Certified Professional Coach",
  "Association of Chartered Certified Accountant (ACCA)",
  "Chartered Financial Analyst (CFA)",
  "Project Management Professional (PMP)",
  "Certified Business Analysis Professional (CBAP)",
  "Other",
].map((item) => ({ label: item, value: item }));

const Certificates = ({ convertText, control, func, errors, watch }) => {
  const setValue = func;
  const watchProfessionalCertificate = watch;

  return (
    <Col xs={24} md={12}>
      <Form.Group className="tw-mb-6">
        <Form.ControlLabel className="tw-text-base">
          {translateText(convertText, "What professional certificates do you hold?")}
        </Form.ControlLabel>
        <Controller
          name="info.professional_certificate"
          control={control}
          render={({ field }) => (
            <TagPicker
              data={certificateList}
              style={{ width: "100%" }}
              placement="autoVerticalStart"
              onChange={(value) => {
                setValue(field.name, value);
              }}
              placeholder={translateText(convertText, "Can select more than one")}
              size="lg"
              {...field}
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name="info.professional_certificate"
          as={FieldError}
        />
      </Form.Group>
      {watchProfessionalCertificate?.includes("Other") && (
        <Form.Group>
          <Form.ControlLabel className="tw-text-base">
            {translateText(convertText, "Others - Please specify")}
            <span className="tw-text-red-500">*</span>
          </Form.ControlLabel>
          <Controller
            name="info.others_certificate_detail"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                onChange={(value) => {
                  setValue(field.name, value);
                }}
              />
            )}
          />
        </Form.Group>
      )}
    </Col>
  );
};

export default Certificates;
