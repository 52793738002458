import React from "react";
import { Uploader, Container, Content, Notification, useToaster } from "rsuite";
import { useTable } from "react-table";
import axios from "axios";

const message = (message, type) => {
  return (
    <Notification type={type} header={type} closable>
      {message}
    </Notification>
  );
};

const BulkDelete = () => {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const toaster = useToaster();

  const handleUpload = async ([file]) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", file.blobFile);
      const res = await axios.post(
        "/main/admins/bulk_delete/delete_users",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
          //60s timeout
          timeout: 60000,
        }
      );
      setData(res.data);
      toaster.push(message(res.data.message, "success"), {
        duration: 1000,
      });
    } catch (error) {
      console.log(error);
      toaster.push(message("Error deleting users", "error"), {
        duration: 1000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-5">
      <Container>
        <Content>
          <UploaderComponent handleUpload={handleUpload} loading={loading} />
        </Content>
      </Container>
    </div>
  );
};

const UploaderComponent = ({ handleUpload, loading }) => {
  return (
    <div className="p-5">
      <h4>Uploader</h4>
      <Uploader
        action="//jsonplaceholder.typicode.com/posts/"
        draggable
        onChange={handleUpload}
        disabled={loading}
        multiple={false}
        fileList={[]}
      >
        <div
          style={{
            height: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span>Click or Drag files to this area to upload</span>
        </div>
      </Uploader>
    </div>
  );
};

export default BulkDelete;
