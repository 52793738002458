// React
import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";

// FutureLab
import BookingModal from "../futurelab/BookingModal";
import AvailabilityModal from "../futurelab/AvailabilityModal";
import {
  IMentorContext,
  PlatformType,
} from "../../../../../contexts/mentor_profile";
import { useUser } from "../../../../../contexts/user";
import translateText from "../../../../../util/translateText";
import PortalBookingButtons from "../../../shared/PortalBookingButtons";
import AvailableDatePreview from "../futurelab/AvailableDatePreview";
import BookingButtons from "../futurelab/BookingButtons";

// Ui
import { Divider, Panel } from "rsuite";

// 3rd Party
import moment from "moment";

const MentorActionCard = ({
  bordered = true,
  daySize = 50,
  convertText,
  callBack,
}) => {
  const { user: currentUser } = useUser();
  const { mentor, type } = useOutletContext<any>();
  const { available_dates: availableDates } = mentor as any;
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [showAvailabilityModal, setShowAvailabilityModal] = useState(false);
  const [initialDate, setInitialDate] = useState(
    availableDates?.[0] ? moment(availableDates?.[0], "DD/MM/YYYY") : moment()
  );
  const [initialTime, setInitialTime] = useState();
  const handleOpen = () => setShowBookingModal(true);
  const handleClose = () => setShowBookingModal(false);

  const handleBookMentor = () => {
    setShowAvailabilityModal(false);
    handleOpen();
  };

  const handleClick = () => {
    const modal = document.getElementById("new_bookings_modal");
    if (modal) {
      modal.style.display = "block";
      callBack();
    }
  };

  return (
    <>
      <Panel
        bordered={bordered}
        style={{ background: "#fefefe" }}
        className="xs:tw-p-0"
      >
        {type === PlatformType.FutureLab ? (
          <>
            {availableDates?.length > 0 ? (
              <>
                <AvailableDatePreview
                  mentor={mentor}
                  setInitialDate={setInitialDate}
                  setInitialTime={setInitialTime}
                  currentUser={currentUser}
                  convertText={convertText}
                  handleOpen={handleOpen}
                />
                <Divider>
                  <span className="tw-text-sm">
                    {translateText(convertText, "or")}
                  </span>
                </Divider>
                <BookingButtons
                  currentUserId={currentUser?.id}
                  mentorId={mentor?.id}
                  setShowAvailabilityModal={setShowAvailabilityModal}
                  handleOpen={handleOpen}
                  setInitialTime={setInitialTime}
                  convertText={convertText}
                />
              </>
            ) : (
              <>
                <div className="tw-text-center tw-mb-5 tw-rounded-lg tw-py-9 tw-text-sm tw-font-bold tw-bg-gray-200 tw-px-4">
                  {translateText(convertText, "I'm currently not available.")}
                </div>
              </>
            )}
          </>
        ) : (
          <PortalBookingButtons
            handleClick={handleClick}
            convertText={convertText}
            user={mentor}
          />
        )}
      </Panel>
      {showBookingModal && type === PlatformType.FutureLab && (
        <BookingModal
          open={showBookingModal}
          handleClose={handleClose}
          initialDate={initialDate}
          initialTime={initialTime}
          daySize={daySize}
          convertText={convertText}
        />
      )}
      {showAvailabilityModal && type === PlatformType.FutureLab && (
        <AvailabilityModal
          open={showAvailabilityModal}
          handleClose={() => setShowAvailabilityModal(false)}
          daySize={daySize}
          convertText={convertText}
          initialDate={initialDate}
          initialTime={initialTime}
          setInitialDate={setInitialDate}
          setInitialTime={setInitialTime}
          handleBookMentor={handleBookMentor}
        />
      )}
    </>
  );
};

export default MentorActionCard;
