import React, { createContext, useState, useContext, useEffect } from "react";
import { Loader } from "rsuite";
import axios from "axios";

const ChatContext = createContext(undefined);
const urls = {
  development: "http://lvh.me:3003/api/",
  production: "https://apiauth.futurelab.my/api/",
  staging: "https://apiauth.futurelab.global/api/",
};
const AUTH_URL = urls[process.env.RAILS_ENV];

export const ChatProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    accessToken: null,
    refreshToken: null,
  });

  const getAuth = async () => {
    try {
      const { data } = await axios.get(`${AUTH_URL}auth/transfer-auth`, {
        withCredentials: true,
      });
      setAuth(data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAuth();
  }, []);

  return <ChatContext.Provider value={auth}>{children}</ChatContext.Provider>;
};

export const useChatAuth = () => {
  const { accessToken, refreshToken } = useContext(ChatContext);
  return [accessToken, refreshToken];
};

export default ChatProvider;
