// React
import { lazy, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

// FutureLab
import { findNavByActiveRoutePath } from "./utils/navRouteMatcher";
import { usePortal } from "contexts/portal";
import Dashboard from "../../../portal/admin/pages/Dashboard";

// UI
import {
  MdOutlineDashboard,
  MdOutlineGroups,
  MdOutlineAnalytics,
  MdOutlineAssignment,
  MdOutlineHelpCenter,
  MdOutlineInterests,
  MdOutlineSettingsSuggest,
  MdOutlineVolunteerActivism,
} from "react-icons/md";

export const PortalSettings = lazy(
  () => import("../../../portal/admin/pages/PortalSettings")
);
export const PortalAppearance = lazy(
  () => import("../../../portal/admin/pages/PortalAppearance")
);
export const PortalAnalytics = lazy(() => import("../../../PortalAnalytics"));
export const PortalInvitation = lazy(() => import("../../../PortalInvitation"));
export const LegacyLoader = lazy(
  () => import("../../../portal/admin/pages/LegacyLoader")
);
export const ApplicationList = lazy(
  () => import("../../../portal/application/ApplicationList")
);
export const ApplicationForm = lazy(
  () => import("../../../portal/application/form/ApplicationForm")
);
export const ApplicationDetails = lazy(
  () => import("../../../portal/application/ApplicationDetails")
);
export const SessionManagement = lazy(
  () => import("../../../portal/session_management/SessionManagement")
);

const getNavItems = (surveyUrl) => {
  return [
    {
      eventKey: "1",
      label: "Dashboard",
      path: "/",
      icon: MdOutlineDashboard,
    },
    {
      eventKey: "2",
      label: "Program Settings",
      icon: MdOutlineSettingsSuggest,
      children: [
        {
          eventKey: "2-1",
          parentKey: "2",
          label: "General Settings",
          path: "/settings",
        },
        {
          eventKey: "2-2",
          parentKey: "2",
          label: "Appearance",
          path: "/appearance",
        },
        {
          eventKey: "2-3",
          parentKey: "2",
          label: "Agenda",
          path: "/program_overview_settings",
        },
        {
          eventKey: "2-4",
          parentKey: "2",
          label: "Resources",
          path: "/dashboard_settings",
        },
      ],
    },
    {
      eventKey: "3",
      label: "Users",
      // path: "/dashboard",
      icon: MdOutlineGroups,
      children: [
        {
          eventKey: "3-1",
          parentKey: "3",
          label: "Users Management",
          path: "/user_management",
        },
        {
          eventKey: "3-2",
          parentKey: "3",
          label: "Applications",
          path: "/applications",
        },
        {
          eventKey: "3-3",
          parentKey: "3",
          label: "Invitations",
          path: "/user-invites",
        },
        {
          eventKey: "3-4",
          parentKey: "3",
          label: "Availability Management",
          path: "/availability",
        },
      ],
    },
    {
      eventKey: "4",
      label: "Community",
      // path: "/dashboard",
      icon: MdOutlineInterests,
      children: [
        {
          eventKey: "4-1",
          parentKey: "4",
          label: "Event Management",
          path: "/events",
        },
        {
          eventKey: "4-2",
          parentKey: "4",
          label: "Discussions",
          path: "/newsfeed",
        },
      ],
    },
    {
      eventKey: "5",
      label: "Mentorship",
      // path: "/dashboard",
      icon: MdOutlineVolunteerActivism,
      children: [
        {
          eventKey: "5-1",
          parentKey: "5",
          label: "Pairing Management",
          path: "/group_and_pairings_management",
          group: "group",
        },
        {
          eventKey: "5-2",
          parentKey: "5",
          label: "Pairing Health",
          path: "/pairing-health",
        },
        {
          eventKey: "5-3",
          parentKey: "5",
          label: "Bookings",
          path: "/bookings/bookings_list",
        },
        {
          eventKey: "5-4",
          parentKey: "5",
          label: "Ratings",
          path: "/mentorship_ratings",
        },
        {
          eventKey: "5-5",
          parentKey: "5",
          label: "Chat Management",
          path: "/chat_management",
        },
      ],
    },
    {
      eventKey: "6",
      label: "Analytics",
      path: "/dashboard",
      icon: MdOutlineAnalytics,
    },
    {
      eventKey: "7",
      label: "Surveys",
      remote: true,
      path: surveyUrl,
      icon: MdOutlineAssignment,
    },
    // {
    //   eventKey: "8",
    //   label: "Help and Guide",
    //   path: "/app/:enterprise_id/:program_id/admins/newdashboard",
    //   icon: MdOutlineHelpCenter,
    // },
  ];
};

const getSurveyUrl = (environment, enterprise, program) => {
  const baseUrls = {
    production: "https://app.futurelab.my",
    staging: "https://app.futurelab.global",
    development: "http://lvh.me:4000",
  };

  const baseUrl = baseUrls[environment];
  return `${baseUrl}/${enterprise?.slug}/${program?.slug}/admins/surveys`;
};

const useAdminRoute = () => {
  const { program, enterprise } = usePortal();
  const url = `/app/${enterprise?.slug}/${program?.slug}/admin`;
  const { pathname } = useLocation();
  const activePath = pathname.replace(url, "");

  const surveyUrl = useMemo(
    () => getSurveyUrl(process.env.RAILS_ENV, enterprise, program),
    [enterprise, program]
  );

  const navItems = useMemo(() => getNavItems(surveyUrl), [surveyUrl]);

  const activeRoute = useMemo(
    () => findNavByActiveRoutePath(activePath, navItems),
    [activePath, navItems]
  );

  return {
    activeRoute,
    navItems,
  };
};

export default useAdminRoute;
