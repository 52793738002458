import React, { useState } from "react";
import { MdClose, MdSearch } from "react-icons/md";
import { Input, InputGroup } from "rsuite";
import useDesktopOrMobile from "../../../hooks/useDesktopOrMobile";

const NavSearchForm = ({ setSearchActive = null }) => {
  const [searchValues, setSearchValues] = useState("");
  const isDesktop = useDesktopOrMobile();

  const handleSearch = (event) => {
    event.preventDefault();
    window.location.replace(`/discover/mentors?search=${searchValues}`);
  };

  return (
    <>
      <div className="tw-w-full">
        <form onSubmit={handleSearch}>
          <InputGroup inside>
            <Input
              name="search"
              placeholder="Search"
              value={searchValues}
              onChange={(value) => setSearchValues(value)}
            />
            <InputGroup.Button
              onClick={() =>
                setSearchActive ? setSearchActive(false) : setSearchValues("")
              }
            >
              {!isDesktop || searchValues.length > 0 ? (
                <MdClose />
              ) : (
                <MdSearch color="#CCCCCC" />
              )}
            </InputGroup.Button>
          </InputGroup>
        </form>
      </div>
    </>
  );
};

export default NavSearchForm;
