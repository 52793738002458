import React, { useState } from "react";
import { useFieldArray } from "react-hook-form";
import { Button, Tag } from "rsuite";
import PortalTaskFormModal from "../form/PortalTaskFormModal";
import { debounce } from "lodash";

interface LearningModuleTask {
  id: string | number | undefined;
  name: string;
  description: string;
  category: string;
  link_name: string;
  link_url: string;
  _destroy: boolean;
}

const ModuleTaskComponent = (props) => {
  const { control, moduleIndex, setValue, getValues } = props;
  const [task, setTask] = useState(undefined);
  const [taskIndex, setTaskIndex] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const {
    fields: taskFields,
    append: appendTask,
    remove: removeTask,
    update: updateTask,
  } = useFieldArray({
    control,
    name: `learning_modules.${moduleIndex}.learning_module_tasks_attributes`,
    keyName: "idx",
  });

  const handleEditTask = (task, taskIndex) => {
    setOpenModal(true);
    setTask(task);
    setTaskIndex(taskIndex);
  };

  const handleCloseModalForm = () => {
    setOpenModal(false);

    // Create delay in updating state to accomodate Modal Close transition
    const debounceTaskState = debounce(() => {
      setTask(undefined);
      setTaskIndex(null);
    }, 500);

    debounceTaskState();
  };

  const tagDisplay = (category) => {
    const tags = {
      assignment: { color: "yellow", text: "Assignment" },
      session: { color: "blue", text: "Mentorship" },
      mentor_class: { color: "violet", text: "Event" },
      survey: { color: "green", text: "Survey" },
    };

    const { color, text } = tags[category];

    return <Tag color={color}>{text}</Tag>;
  };

  return (
    <>
      <div className="tw-flex tw-flex-row tw-w-full tw-justify-between">
        <h6>Tasks</h6>
        <span className="tw-text-sm tw-ml-2">
          {taskFields.length || 0} tasks assigned
        </span>
      </div>
      <div>
        {taskFields.map((task, taskIndex) => (
          <div
            key={taskIndex}
            className={`tw-flex tw-justify-between ${
              getValues(
                `learning_modules.${moduleIndex}.learning_module_tasks_attributes.${taskIndex}._destroy`
              ) && "tw-hidden"
            }`}
          >
            <div className="tw-inline-flex">
              <div style={{ width: "100px" }}>{tagDisplay(task.category)}</div>
              <div>
                <span className="tw-text-sm">{task.name}</span>
              </div>
            </div>

            <div>
              <Button
                appearance="link"
                onClick={() => {
                  handleEditTask(task, taskIndex);
                }}
              >
                Edit
              </Button>
              <Button
                appearance="link"
                onClick={() => {
                  if (task.id !== undefined) {
                    setValue(
                      `learning_modules.${moduleIndex}.learning_module_tasks_attributes.${taskIndex}._destroy`,
                      true
                    );
                  } else {
                    removeTask(taskIndex);
                  }
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        ))}
      </div>
      <PortalTaskFormModal
        appendTask={appendTask}
        updateTask={updateTask}
        task={task}
        taskIndex={taskIndex}
        openModal={openModal}
        handleCloseModalForm={() => handleCloseModalForm()}
      />
      <Button appearance="link" onClick={() => setOpenModal(true)}>
        + Add Task
      </Button>
    </>
  );
};

export default ModuleTaskComponent;
