import React from "react";
import { Button, Panel } from "rsuite";

const SessionsPanel = ({ pairsCount, groupsCount }) => {
  return (
    <>
      <Panel bordered>
        <p className="tw-text-lg tw-text-center montserrat tw-font-bold">
          Sessions
        </p>
        <div className="tw-flex tw-justify-items-center">
          <div className="tw-flex tw-w-1/2 tw-flex-col tw-text-center">
            <span className="tw-text-3xl tw-font-medium">
              {pairsCount || 0}
            </span>
            <span className="tw-text-sm">Paired</span>
          </div>
          <div className="tw-flex tw-w-1/2 tw-flex-col tw-text-center">
            <span className="tw-text-3xl tw-font-medium">
              {groupsCount || 0}
            </span>
            <span className="tw-text-sm">Group</span>
          </div>
        </div>
        <div className="tw-text-center tw-mt-4">
          <Button
            appearance="primary"
            href={`group_and_pairings_management`}
            target="_self"
          >
            View details
          </Button>
        </div>
      </Panel>
    </>
  );
};

export default SessionsPanel;
