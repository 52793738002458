import React from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { DateRangePicker, IconButton, Tooltip, Whisper } from "rsuite";
import { DateRange } from "rsuite/esm/DateRangePicker";

const tooltip = <Tooltip>Filter value will applied on date created</Tooltip>;

// const ProgramFilterHint = () => {
//   return (
//     <Whisper
//       placement="bottom"
//       controlId="control-id-hover"
//       trigger="hover"
//       speaker={tooltip}
//     >
//       <IconButton
//         icon={<AiOutlineQuestionCircle />}
//         size="xs"
//         appearance="subtle"
//       ></IconButton>
//     </Whisper>
//   );
// };

const ProgramFilter = ({ handleDateChange, date }) => {
  const value = Object.values(date) as DateRange;

  return (
    <div className="tw-flex tw-gap-4 tw-items-center">
      <div className="tw-font-medium tw-text-sm tw-flex tw-items-center tw-gap-1">
        Filter Dates
        {/* <ProgramFilterHint /> */}
      </div>
      <DateRangePicker
        format="yyyy-MM-dd"
        onChange={handleDateChange}
        character=" ~ "
        placeholder="Select Date Range"
        placement="bottomEnd"
      />
    </div>
  );
};

export default ProgramFilter;
