// React
import React from "react";

// FutureLab
import { RichTextEditorToHTML } from "../../../ui/RichTextEditor";
import FormContent from "./ContentSection";
import FormCredentials from "./CredentialSection";
import ApplicantProgressBar from "./ProgressBar";
import DefaultBannerImage from "../assets/default-program-banner.png";

// UI
import { Button, Col, FlexboxGrid, Form } from "rsuite";

// 3rd Party
import _ from "lodash";

export default function ApplicationForm({
  currentUser,
  program,
  application,
  register,
  errors,
  watch,
  handleSubmit,
  onSubmit,
  control,
  setValue,
}) {
  const isRequiredQuestionPresent = application.questions.some(
    (question) => question.required
  );

  return (
    <FlexboxGrid className="tw-mt-10 tw-mb-[64px]" justify="center">
      <FlexboxGrid.Item colspan={24}>
        <FlexboxGrid justify="center">
          <FlexboxGrid.Item
            colspan={24}
            as={Col}
            xs={20}
            md={17}
            lg={16}
            xl={12}
          >
            <img
              src={
                program?.white_label.newsfeed_banner.high_quality.url ||
                DefaultBannerImage
              }
              className="tw-rounded-lg tw-mb-3"
            />
            {isRequiredQuestionPresent && (
              <ApplicantProgressBar
                watch={watch}
                questions={application?.questions}
                programColor={program?.white_label.dashboard_primary_colour}
              />
            )}
            <FlexboxGrid.Item className="tw-font-semibold tw-text-2xl tw-text-center tw-pb-4 tw-text-gray-800">
              {application?.name}
            </FlexboxGrid.Item>
            <RichTextEditorToHTML content={application?.description} />

            <div className="tw-mt-3 tw-text-sm tw-text-red-500">
              * Indicates required question
            </div>
            <Form fluid className="tw-my-5">
              <FormCredentials currentUser={currentUser} />
              <FormContent
                application={application}
                register={register}
                control={control}
                errors={errors}
              />
              <div className="tw-fixed tw-bottom-0 tw-left-0 tw-w-full tw-py-3 tw-px-8 tw-text-center tw-shadow-lg tw-bg-[#fefefe] xs:tw-border xs:tw-border-solid xs:tw-border-gray-300 xs:tw-rounded-t-md">
                <Button
                  className="tw-text-base tw-mr-3"
                  onClick={() => {
                    setValue("applicant_status", "draft");
                    handleSubmit(onSubmit)();
                  }}
                  appearance="ghost"
                >
                  Save Progress
                </Button>
                <Button
                  className="tw-text-base"
                  appearance="primary"
                  onClick={() => {
                    setValue("applicant_status", "applied");
                    handleSubmit(onSubmit)();
                  }}
                >
                  Submit
                </Button>
              </div>
            </Form>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}
