import React from "react";
import { FormProvider, useForm } from "react-hook-form";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";

const schema = yup.object().shape({
  event: yup.object().shape({
    event_type: yup.string().nullable().required("Type of event is required"),
    title: yup.string().required("Title is required"),
    privacy: yup.string().required("Privacy is required"),
    start_time: yup.date().required("Start time is required"),
    end_time: yup.date().required("End time is required"),
    timezone: yup.string().required("Timezone is required"),
    webinar: yup.string().nullable(),
    webinar_password: yup.string().nullable(),
    venue_title: yup.string().nullable(),
    geocode: yup.string().nullable(),
    description: yup.string().required("Description is required"),
    organizer_name: yup
      .string()
      .nullable()
      .required("Organizer name is required"),
    inquiry_email: yup
      .string()
      .nullable()
      .required("Organizer email is required"),
    inquiry_number: yup.number().nullable().label("Organizer contact"),
    event_speakers: yup
      .array()
      .max(5, "You've exceeded the number of speakers allowed"),
  }),
  tickets: yup.array().of(
    yup.object().shape({
      name: yup.string().required().label("Ticket name"),
      ticket_type: yup.string().required().label("Ticket type"),
      price: yup
        .number()
        .typeError("Price must be a number")
        .required()
        .label("Price"),
      max_amount: yup
        .number()
        .typeError("Quantity must be a number")
        .required()
        .label("Amount"),
    })
  ),
  extra_fields: yup.array().of(
    yup.object().shape({
      id: yup.number(),
      title: yup.string(),
      active: yup.boolean(),
    })
  ),
});

// Validation array for react-hook-form triggers func
export const infoValidation = [
  "event.event_type",
  "event.title",
  "event.privacy",
  "event.start_time",
  "event.end_time",
  "event.timezone",
  "event.webinar",
  "event.webinar_password",
  "event.venue_title",
  "event.geocode",
];

export const detailValidation = [
  "event.description",
  "event.organizer_name",
  "event.inquiry_email",
  "event.inquiry_number",
  "event.event_speakers",
];

export const ticketValidation = ["tickets"];
export const extraFieldsValidation = ["extra_fields"];
// End

const EventFormProvider = ({ children }) => {
  const roundedDateTime = moment()
    .startOf("hour")
    .add(Math.round(moment().minutes() / 15) * 15, "minutes")
    .toDate();

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      event: {
        event_type: "webinar",
        title: "",
        privacy: "is_public",
        timezone: "Asia/Kuala_Lumpur",
        start_time: roundedDateTime,
        end_time: moment(roundedDateTime).add(1, "hours").toDate(),
        webinar: "",
        webinar_password: "",
        venue_title: "",
        geocode: "",
        description: "",
        organizer_name: "FutureLab",
        inquiry_email: "info@futurelab.my",
        inquiry_number: null,
        event_speakers: [],
      },
      tickets: [],
      extra_fields: [],
    },
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};

export default EventFormProvider;
