import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";

const LottieAnimation = ({
  animationData,
  loop = false,
  autoplay = true,
  width = 400,
  height = 400,
}) => {
  const lottieRef = useRef(null);

  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: lottieRef.current,
      renderer: "svg",
      loop: loop,
      autoplay: autoplay,
      animationData: animationData,
    });

    return () => {
      animation.destroy();
    };
  }, []);

  return <div ref={lottieRef} style={{ width: width, height: height }}></div>;
};

export default LottieAnimation;
