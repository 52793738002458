// React
import React from "react";

// FutureLab
import PortalBookingButtons from "../../../shared/PortalBookingButtons";
import { useOutletContext } from "react-router-dom";

// UI
import { Panel } from "rsuite";

export default function MenteeActionCard({ bordered = true, convertText }) {
  const { mentee, type } = useOutletContext<any>();

  return (
      <Panel
        bordered={bordered}
        style={{ background: "#fefefe" }}
        className="xs:tw-p-0"
      >
        <PortalBookingButtons
          handleClick={() => {}}
          convertText={convertText}
          user={mentee}
        />
      </Panel>
  );
}
