import React from "react";
import { UserProvider } from "../contexts/user";
import AppContainer from "./AppContainer";
import DashboardContent from "./dashboard/DashboardContent";

const Dashboard = (props) => {
  return (
    <AppContainer>
      <UserProvider>
        <DashboardContent showSurvey={props} convertText={props.convertText} />
      </UserProvider>
    </AppContainer>
  );
};

export default Dashboard;
