import React, { useState } from "react";
import { Col, FlexboxGrid, Nav } from "rsuite";
import { usePortal } from "../../../../contexts/portal";

import AppearanceForm from "../forms/appearance/AppearanceForm";
import PageCustomizationForm from "../forms/appearance/PageCustomizationForm";
import AppearanceFormProvider from "../forms/appearance/context/appearanceFormContext";

const PortalAppearance = () => {
  const { program } = usePortal();
  const [activeKey, setActiveKey] = useState("1");

  const onSelect = (key) => {
    setActiveKey(key);
  };

  // console.log(program.white_label);

  const renderForm = (key, program) => {
    const form = {
      "1": <AppearanceForm />,
      "2": program?.paid_access ? (
        <PageCustomizationForm />
      ) : (
        <PageCustomizationForm />
      ),
    };

    return form[key];
  };

  return (
    <AppearanceFormProvider>
      <FlexboxGrid justify="center">
        <FlexboxGrid.Item as={Col} colspan={24} md={18} lg={12}>
        <h1 className="tw-text-xl">Appearance</h1>
          <p className="montserrat">
            Personalize the visual aspects of your portal. Update logos, cover
            photos, and colors to create a cohesive and branded user experience.
          </p>
          {/* Navigation */}
          <Nav
            activeKey={activeKey}
            onSelect={onSelect}
            appearance="subtle"
            justified
            style={{ marginBottom: 50 }}
          >
            <Nav.Item className="tw-justify-center" eventKey="1">
              Appearance
            </Nav.Item>
            <Nav.Item className="tw-justify-center" eventKey="2">
              Page Customization
            </Nav.Item>
          </Nav>
          {renderForm(activeKey, program)}
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </AppearanceFormProvider>
  );
};

export default PortalAppearance;
