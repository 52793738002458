import React, { useState, useEffect, useRef } from "react";
import { HexColorPicker } from "react-colorful";
import { Controller } from "react-hook-form";
import { Input } from "rsuite";
import { ErrorMessage } from "@hookform/error-message";
import FieldError from "../../form/FieldError";

const PortalColorSelect = ({
  name,
  control,
  errors,
  colour = null,
  setValue,
  ...restProps
}) => {
  const [color, setColor] = useState("#428ac9");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const pickerRef = useRef(null);

  useEffect(() => {
    if (colour) {
      setColor(colour);
    }
  }, [colour]);

  const toggleColorPicker = () => {
    setShowColorPicker(!showColorPicker);
  };

  const handleColorChange = (name, newValue) => {
    setColor(newValue);
    setValue(name, newValue);
  };

  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setShowColorPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="tw-flex tw-items-center tw-relative" ref={pickerRef}>
      <button className="tw-mr-2" onClick={toggleColorPicker}>
        <div
          style={{
            width: "100px",
            height: "40px",
            borderRadius: "6px",
            border: "1px solid #f1f1f1",
            backgroundColor: color,
          }}
        />
      </button>
      <Controller
        name={name}
        control={control}
        render={({ field: { name, value = color } }) => (
          <>
            <Input
              value={value}
              onChange={(e) => handleColorChange(name, e)}
              className="tw-w-1/6"
              {...restProps}
            />
            <ErrorMessage errors={errors} name={name} as={FieldError} />
          </>
        )}
      />

      {showColorPicker && (
        <HexColorPicker
          color={color}
          onChange={(e) => handleColorChange(name, e)}
          className="tw-absolute"
          style={{ bottom: "42px", zIndex: 6 }}
        />
      )}
    </div>
  );
};

export default PortalColorSelect;
