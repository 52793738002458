// React
import React from "react";

// Futurelab
import multipleUnconcludedBookingIcon from "../../../images/multiple_unconcluded_booking_icon.png";

// UI
import { Button, Modal } from "rsuite";

export default function MultipleUnconcludedBookingModal({ open, close }) {
  return (
    <Modal open={open} className="mt-16 sm:w-full" size="xs" onClose={close}>
      <Modal.Header>
        <Modal.Title className="tw-font-semibold">
          Multiple Overdue Sessions
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="tw-pb-0">
        <div className="tw-flex tw-justify-center">
          <img src={multipleUnconcludedBookingIcon} className="tw-w-32" />
        </div>
        <p className="tw-text-center montserrat tw-text-sm tw-font-medium tw-pb-1">
          Hi there! You have multiple sessions that appear unconcluded. Mark
          them as completed and share your reflections.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button appearance="ghost" onClick={close}>
          Skip
        </Button>
        <Button
          appearance="primary"
          href="bookings"
          target="_self"
          onClick={close}
        >
          View Sessions
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
