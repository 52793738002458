// React
import React from "react";

// FutureLab
import flagOfMalaysia from "../images/flag-of-malaya.png";
import desktopSubscriptionBanner from "../images/subscription-page-banner.png";
import reviewerOne from "../images/subscription-page-reviewer-1.png";
import reviewerTwo from "../images/subscription-page-reviewer-2.png";

// UI
import { Button, Tag } from "rsuite";

export default function TopUpCredit() {
  return (
    <div className="tw-mb-8">
      <div className="tw-mb-6 tw-mt-8 tw-bg-[#428AC9] tw-py-7 tw-px-4 tw-rounded-xl xs:tw-block tw-hidden">
        <h3 className="tw-text-white tw-font-semibold text-shadow tw-mb-4 xs:tw-text-xl">
          Take control of your career and speak to a mentor today!
        </h3>
        <p className="tw-text-[#428AC9] tw-inline tw-italic tw-bg-[#D4EAF7] tw-font-semibold tw-py-2 tw-px-3 tw-rounded-3xl xs:tw-text-xs montserrat">
          1 credit = 1 mentorship session
        </p>
      </div>
      <img
        src={desktopSubscriptionBanner}
        className="tw-block xs:tw-hidden tw-mt-10 tw-rounded-xl"
      />
      <h4 className="tw-mt-8 tw-mb-6 tw-font-semibold tw-text-center">
        Choose your credit package
      </h4>

      <div className="tw-flex tw-justify-center xs:tw-flex-wrap">
        <div className="tw-basis-2/5 sm:tw-basis-1/2 xs:tw-basis-full tw-p-5 tw-rounded-xl tw-border-solid tw-border tw-mr-8 xs:tw-mr-0 tw-shadow-md hover:tw-border-[#428ac9]">
          <p className="tw-font-semibold tw-text-xl tw-text-[#428AC9] montserrat">
            Purchase Single Session
          </p>
          <p className="tw-mb-4 montserrat">
            <span className="tw-font-semibold tw-text-2xl sm:tw-text-lg tw-text-black">
              RM35.00
            </span>{" "}
            / session
          </p>
          <p className="tw-min-h-[51.20px] montserrat">
            Each credit is valid for 3 months after your top-up
          </p>
          <div className="tw-text-right">
            <Button
              appearance="ghost"
              className="tw-font-medium"
              href="/package/10/purchase_page"
            >
              Choose Plan
            </Button>
          </div>
        </div>
        <div className="tw-basis-2/5 sm:tw-basis-1/2 xs:tw-basis-full tw-p-5 tw-rounded-xl tw-border-solid tw-border xs:tw-mt-8 tw-shadow-md hover:tw-border-[#428ac9]">
          <div className="tw-flex tw-justify-between tw-align-center tw-mb-4">
            <span className="tw-font-semibold tw-text-xl tw-inline tw-text-[#428AC9]">
              Starter Pack
            </span>
            <Tag color="red" className="tw-h-6 tw-font-medium">Recommended</Tag>
          </div>

          <p className="tw-mb-4 montserrat">
            <span className="tw-font-semibold tw-text-2xl sm:tw-text-lg tw-text-black">
              RM60.00
            </span>{" "}
            for 3 sessions
          </p>
          <p className="montserrat">
            Enjoy 3 one-on-one sessions with any mentor of your choice, valid
            for 6 months after your top-up
          </p>
          <div className="tw-text-right tw-self-end">
            <Button
              appearance="primary"
              className="tw-font-medium"
              href="/package/12/purchase_page"
            >
              Choose Plan
            </Button>
          </div>
        </div>
      </div>
      <h4 className="tw-mt-10 tw-mb-6 tw-font-semibold tw-text-center">
        Hear the success stories from our mentees
      </h4>
      <div className="tw-flex xs:tw-flex-wrap tw-justify-center">
        <div className="user-review-wrapper tw-basis-2/5 xs:tw-basis-full sm:tw-basis-1/2 tw-mr-8 xs:tw-mr-0">
          <div className="tw-flex tw-flex-row">
            <div className="tw-basis-2/12">
              <img src={reviewerOne} className="tw-w-full" />
            </div>
            <div className="tw-flex tw-flex-col tw-font-semibold  tw-text-base sm:tw-text-sm tw-mb-4 tw-mx-3 tw-basis-7/12">
              <span>Syahidatul Faizzah</span> <span>Tax Assistant</span>
              <span>Deloitte </span>
            </div>
            <div className="tw-basis-2/12">
              <img src={flagOfMalaysia} />
            </div>
          </div>
          <p className="tw-text-base xs:tw-text-sm tw-font-medium tw-mt-4 montserrat">
            Starting as a research assistant, my job didn't satisfy me,
            especially after getting married. Switching roles was tough, and
            dealing with mental health issues made it harder. Luckily, I
            connected with my mentor, Jay.
            <div className="tw-my-4" />
            With his guidance, I left auditing, overcame mental hurdles, and
            eventually landed a role at Deloitte. Thanks to FutureLab and Jay's
            support.
          </p>
        </div>
        <div className="user-review-wrapper tw-basis-2/5 sm:tw-basis-1/2 xs:tw-basis-full xs:tw-mt-6">
          <div className="tw-flex tw-flex-row">
            <div className="tw-basis-2/12">
              <img src={reviewerTwo} className="tw-w-full" />
            </div>
            <div className="tw-flex tw-flex-col tw-font-semibold tw-text-base sm:tw-text-sm tw-mb-4 tw-mx-3 tw-basis-7/12">
              <span>Najmia Zulkarnain</span> <span>Founder</span>
              <span>SHOP UNPLUG</span>
            </div>
            <div className="tw-basis-2/12">
              <img src={flagOfMalaysia} />
            </div>
          </div>
          <p className="tw-text-base xs:tw-text-sm tw-font-medium tw-mt-4 montserrat">
            The mentorship session has been really helpful, it came at the right
            time when business is starting fresh, post-lockdown. Access to
            experienced mentors across diverse fields like marketing, funding,
            and strategy has been invaluable for my small team.
            <div className="tw-my-4" />
            Shasi, my mentor, played a crucial role in shaping our business
            strategy. Without his guidance, we would've been lost. The sincerity
            throughout the mentorship experience is truly heartening.
          </p>
        </div>
      </div>
    </div>
  );
}
