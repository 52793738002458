import React from "react";
import ContentLoader from "react-content-loader";

const UserCardsSkeleton = (props) => {
  return (
    <ContentLoader
      width={300}
      height={400}
      viewBox="0 0 300 400"
      backgroundColor="#f0f0f0"
      foregroundColor="#dedede"
      className="tw-mr-4"
      {...props}
    >
      {/* Picture and Name */}
      <rect x="0" y="10" rx="6" ry="6" width="300" height="217" />
      <rect x="0" y="240" rx="2" ry="2" width="271" height="10" />
      <rect x="0" y="260" rx="3" ry="3" width="119" height="10" />

      {/* Divider */}
      <rect x="0" y="290" rx="2" ry="2" width="300" height="1" />

      {/* Title */}
      <rect x="0" y="300" rx="2" ry="2" width="100" height="10" />

      {/* Goal Tiles */}
      <rect x="0" y="320" rx="2" ry="2" width="160" height="20" />
      <rect x="170" y="320" rx="2" ry="2" width="129" height="20" />
      <rect x="0" y="350" rx="2" ry="2" width="150" height="20" />
      <rect x="160" y="350" rx="2" ry="2" width="139" height="20" />
    </ContentLoader>
  );
};

const UserCardsSkeletonWrapper = (props) => {
  return (
    <div>
      <UserCardsSkeleton />
      <UserCardsSkeleton />
      <UserCardsSkeleton />
    </div>
  );
};

export default UserCardsSkeletonWrapper;
