import React from 'react';

const PanelContainer = ({ children }) => {
  return (
    <>
      <div className="tw-rounded-xl tw-border tw-border-solid tw-border-gray-300 tw-p-5 tw-mt-10 sm:tw-mt-14 sm:tw-border-none sm:tw-p-0">
        {children}
      </div>
    </>
  );
};

export default PanelContainer;
