import LottieAnimation from "components/shared/LottieAnimation";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "components/shared/modal/BaseModal";
import React, { useState } from "react";
import { Button, Modal } from "rsuite";

// @ts-ignore
import welcomeAnimation from "../../../../../assets/animations/enterprise-welcome.json";
import { useOnboarding } from "contexts/onboardingContext";

const WelcomeModal = () => {
  const [open, setOpen] = useState(true);
  const { handleCreateOnboarding } = useOnboarding();

  const handleCloseOnboardingModal = () => {
    setOpen(false);
    handleCreateOnboarding("enterprise_welcome_modal");
  };

  return (
    <Modal open={open} onClose={handleCloseOnboardingModal}>
      <ModalHeader>Welcome to FutureLab!</ModalHeader>
      <ModalBody className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-center">
        <LottieAnimation animationData={welcomeAnimation} loop={true} />
        <p className="montserrat tw-text-center">
          Welcome! Build impactful mentorship programs with ease. Our platform
          empowers your team to grow, engage, and succeed. Let's launch your
          mentoring program together!
        </p>
      </ModalBody>
      <ModalFooter className="tw-text-center tw-pt-5">
        <Button onClick={handleCloseOnboardingModal} appearance="primary">
          Start Building Your Program
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default WelcomeModal;
