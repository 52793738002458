import React, { createContext, useState, useContext, useEffect } from "react";
import { Loader } from "rsuite";
import { PortalApi } from "../../util/portal_api";

const PortalSetupContext = createContext(undefined);

export const PortalSetupProvider = ({
  children,
  programId = null,
  enterpriseSlug,
}) => {
  const [program, setProgram] = useState();
  const [enterprise, setEnterprise] = useState();
  const [setupStatus, setSetupStatus] = useState();

  useEffect(() => {
    if (programId) {
      PortalApi({
        method: "get",
        path: `/programs/setup/${programId}`,
        success: ({ data }) => {
          setProgram(data);
        },
      });
    }
  }, [programId]);

  useEffect(() => {
    if (program) {
      PortalApi({
        method: "get",
        path: "/programs/setup/status_check",
        options: { program_id: program?.id },
        success: ({ data }) => {
          setSetupStatus(data);
        },
      });
    }
  }, [program]);

  useEffect(() => {
    if (enterpriseSlug) {
      PortalApi({
        method: "get",
        path: `/enterprises/${enterpriseSlug}`,
        success: ({ data }) => {
          setEnterprise(data);
        },
      });
    }
  }, [enterpriseSlug]);

  return (
    <PortalSetupContext.Provider
      value={{
        program,
        setProgram,
        enterprise,
        setEnterprise,
        setupStatus,
      }}
    >
      {children}
    </PortalSetupContext.Provider>
  );
};

export const usePortalSetup = () => useContext(PortalSetupContext);

export function withPortal(WrappedComponent) {
  const Component = (props) => {
    const { program, setProgram, enterprise, setupStatus } = usePortalSetup();

    if (!program || !enterprise) return <Loader center content="loading" />;

    return (
      <WrappedComponent
        program={program}
        setProgram={setProgram}
        enterprise={enterprise}
        setupStatus={setupStatus}
        {...props}
      />
    );
  };

  return Component;
}
