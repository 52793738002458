import React from "react";
import { Col, Form, SelectPicker } from "rsuite";
import FieldError from "../ui/FieldError";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { map } from "lodash";
import { useUser } from "../../contexts/user";
import toSentenceCase from "../../util/toSentenceCase";
import translateText from "../../util/translateText";

const AverageTotalPeopleHire = ({ convertText, control, func, errors }) => {
  const setValue = func;
  const { user } = useUser();

  return (
    <Form fluid className="tw-mt-6">
      <Form.Group className="tw-mb-6">
        <Form.ControlLabel className="tw-text-base">
          {translateText(convertText, "How many people do you hire in a year on average?")}
          <span className="tw-text-red-500">*</span>
        </Form.ControlLabel>
        <Controller
          name="info.average_total_people_hire"
          control={control}
          render={({ field }) => (
            <SelectPicker
              data={map(
                user.info.average_total_people_hire_list,
                (value, key) => ({
                  label: toSentenceCase(key),
                  value,
                })
              )}
              style={{ width: "100%" }}
              onChange={(value) => {
                setValue(field.name, value);
              }}
              placeholder={translateText(convertText, "Please select")}
              placement={"topStart"}
              size="lg"
              {...field}
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name="info.average_total_people_hire"
          as={FieldError}
        />
      </Form.Group>
    </Form>
  );
};

export default AverageTotalPeopleHire;
