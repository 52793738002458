// React
import React from "react";

// FutureLab
import {
  ArrayCell,
  BooleanCell,
  NumberCell,
  TimeCell,
} from "./data_table_component/DataTableCellType";
import DataTable from "./DataTable";

const programmeBookingsColumns = [
  {
    Header: "Booking ID",
    accessor: "bookingId",
    Cell: NumberCell,
  },
  {
    Header: "Booking Date and Time",
    accessor: "bookingTime",
    Cell: TimeCell,
  },
  {
    Header: "Booking Creation Date",
    accessor: "bookingCreationTime",
    Cell: TimeCell,
  },
  {
    Header: "Enterprise Programme Name",
    accessor: "enterpriseName",
  },
  {
    Header: "Programme Name",
    accessor: "programmeName",
  },
  {
    Header: "Mentor Name",
    accessor: "mentorName",
    Cell: ArrayCell,
  },
  {
    Header: "Mentee(s) Name",
    accessor: "menteeName",
    Cell: ArrayCell,
  },
  {
    Header: "Booking Confirmed?",
    accessor: "bookingConfirmed",
    Cell: BooleanCell,
  },
  {
    Header: "Mentor Entered Room",
    accessor: "mentorEnterRoom",
    Cell: ArrayCell,
  },
  {
    Header: "Mentee(s) Entered Room",
    accessor: "menteeEnterRoom",
    Cell: ArrayCell,
  },
  {
    Header: "Booking Completed?",
    accessor: "bookingCompleted",
    Cell: BooleanCell,
  },
  {
    Header: "Mentor Rated?",
    accessor: "mentorBookingRated",
    Cell: ArrayCell,
  },
  {
    Header: "Mentee(s) Rated?",
    accessor: "menteeBookingRated",
    Cell: ArrayCell,
  },
  {
    Header: "Booking Cancelled?",
    accessor: "bookingCancelled",
    Cell: BooleanCell,
  },
  {
    Header: "Learning Objectives",
    accessor: "learningObjectives",
    Cell: ArrayCell,
  },
  {
    Header: "Mentor Services",
    accessor: "mentorServices",
  },
  {
    Header: "Attachment Submitted",
    accessor: "bookingAttachment",
    Cell: ArrayCell,
  },
];

const ProgrammeBookingData = ({ programmeBooking = [], maxPages, fields }) => {
  return (
    <DataTable
      initialData={programmeBooking}
      maxPages={maxPages}
      title="Programme Booking Data"
      column={programmeBookingsColumns as any}
      fields={fields}
    />
  );
};

export default ProgrammeBookingData;
