import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Col,
  DatePicker,
  Form,
  Grid,
  Input,
  Loader,
  Message,
  Row,
  SelectPicker,
  toaster,
} from 'rsuite';
import { useUser } from '../../contexts/user';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ErrorMessage } from '@hookform/error-message';
import FieldError from '../ui/FieldError';
import { FutureLabApi } from '../../util/api';
import PreviewSaveButton from './PreviewSaveButton';
import MobileNav from './MobileNav';
import PanelContainer from './PanelContainer';
import translateText from "../../util/translateText";

type UserInput = {
  user: {
    firstname: string;
    lastname: string;
    email: string;
    phone_no: string;
    timezone: string;
  };
  info: {
    gender: number;
    birthday: Date;
    country_id: number;
    city_state_id: number;
  };
};

const schema = yup.object().shape({
  user: yup.object().shape({
    firstname: yup.string().required().label('First name'),
    email: yup.string().required().label('Email'),
    lastname: yup.string().required().label('Last name'),
    phone_no: yup.string().required().label('Phone number'),
    timezone: yup.string().required().label('Timezone').nullable(),
  }),
  info: yup.object().shape({
    birthday: yup.string().required().label('Date of birth').nullable(),
    country_id: yup.string().required().label('Country').nullable(),
    city_state_id: yup.string().required().label('State').nullable(),
    gender: yup.string().required().label('Gender').nullable(),
  }),
});

const PersonalDetails = (props) => {
  const { user, refreshUser } = useUser();
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<UserInput>({
    defaultValues: {
      user: {
        firstname: "",
        lastname: "",
        email: "",
        phone_no: "",
      },
    },
    resolver: yupResolver(schema),
  });
  const [countries, setCountries] = React.useState([]);
  const [cityStates, setCityStates] = React.useState([]);
  const [timezones, setTimezones] = React.useState([]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const watchCountry = watch("info.country_id");

  let defaultBirthday = new Date();
  defaultBirthday.setFullYear(defaultBirthday.getFullYear() - 18);

  React.useEffect(() => {
    if (user) {
      reset({
        user: {
          firstname: user.firstname,
          lastname: user.lastname,
          email: user.email,
          phone_no: user.phone_no,
          timezone: user.timezone,
        },
        info: {
          gender: user.info.gender,
          birthday: user.info.birthday
            ? new Date(user.info.birthday)
            : defaultBirthday,
          country_id: user.info.country_id,
          city_state_id: user.info.city_state_id,
        },
      });
    }
  }, [user]);

  React.useEffect(() => {
    FutureLabApi({
      method: "get",
      path: "/countries",
      success: ({ data }) => {
        setCountries(data);
      },
    });

    FutureLabApi({
      method: "get",
      path: "/timezones",
      success: ({ data }) => {
        setTimezones(data);
      },
    });
  }, []);

  React.useEffect(() => {
    getCityStates(watchCountry);
  }, [watchCountry]);

  const getCityStates = (country_id) => {
    if (country_id)
      FutureLabApi({
        method: "get",
        path: "/city_states",
        options: {
          country_id,
        },
        success: ({ data }) => {
          setCityStates(data);
        },
      });
  };

  const onSubmit = (data) => {
    setIsSubmitting(true);
    FutureLabApi({
      method: "post",
      path: "/users/current",
      options: data,
      success: ({ data }) => {
        refreshUser();
        setIsSubmitting(false);
        toaster.push(
          <Message showIcon type="success">
            {translateText(props.convertText, "Successfully saved.")}
          </Message>
        );
      },
      error: (err) => {
        const { error, messages } = err.response.data;
        setIsSubmitting(false);
        toaster.push(
          <Message showIcon type="error">
            {error}
          </Message>
        );
      },
    });
  };

  if (!user) return <Loader center content="loading" />;

  return (
    <div>
      <PreviewSaveButton
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
      />
      <PanelContainer>
        <Form fluid>
          <Grid fluid>
            <Row gutter={24}>
              <Col xs={24} md={12}>
                <Form.Group className="tw-mb-6">
                  <Form.ControlLabel className="tw-text-base">
                    {translateText(props.convertText, "First name")}
                    <span className="tw-text-red-500">*</span>
                  </Form.ControlLabel>
                  <Controller
                    name="user.firstname"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder={translateText(
                          props.convertText,
                          "First name"
                        )}
                        size="lg"
                        {...field}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="user.firstname"
                    as={FieldError}
                  />
                </Form.Group>
              </Col>
              <Col xs={24} md={12}>
                <Form.Group className="tw-mb-6">
                  <Form.ControlLabel className="tw-text-base">
                    {translateText(props.convertText, "Last name")}
                    <span className="tw-text-red-500">*</span>
                  </Form.ControlLabel>
                  <Controller
                    name="user.lastname"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder={translateText(
                          props.convertText,
                          "Last name"
                        )}
                        size="lg"
                        {...field}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="user.lastname"
                    as={FieldError}
                  />
                </Form.Group>
              </Col>
              <Col xs={24} md={12}>
                <Form.Group className="tw-mb-6">
                  <Form.ControlLabel className="tw-text-base">
                    {translateText(props.convertText, "Email address")}
                    <span className="tw-text-red-500">*</span>
                  </Form.ControlLabel>
                  <Controller
                    name="user.email"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder={translateText(
                          props.convertText,
                          "Email address"
                        )}
                        size="lg"
                        {...field}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="user.email"
                    as={FieldError}
                  />
                </Form.Group>
              </Col>
              <Col xs={24} md={12}>
                <Form.Group className="tw-mb-6">
                  <Form.ControlLabel className="tw-text-base">
                    {translateText(props.convertText, "Phone number")}
                    <span className="tw-text-red-500">*</span>
                  </Form.ControlLabel>
                  <Controller
                    name="user.phone_no"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder={translateText(
                          props.convertText,
                          "Phone number"
                        )}
                        size="lg"
                        {...field}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="user.phone_no"
                    as={FieldError}
                  />
                </Form.Group>
              </Col>
              <Col xs={24} md={12}>
                <Form.Group className="tw-mb-6">
                  <Form.ControlLabel className="tw-text-base">
                    {translateText(props.convertText, "Gender")}
                    <span className="tw-text-red-500">*</span>
                  </Form.ControlLabel>
                  <Controller
                    name="info.gender"
                    control={control}
                    render={({ field }) => (
                      <SelectPicker
                        placeholder={translateText(
                          props.convertText,
                          "Please select"
                        )}
                        size="lg"
                        {...field}
                        data={[
                          {
                            value: translateText(props.convertText, "male"),
                            label: translateText(props.convertText, "Male"),
                          },
                          {
                            value: translateText(props.convertText, "female"),
                            label: translateText(props.convertText, "Female"),
                          },
                          {
                            value: translateText(props.convertText, "others"),
                            label: translateText(props.convertText, "Others"),
                          },
                        ]}
                        className="tw-w-full"
                        searchable={false}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="info.gender"
                    as={FieldError}
                  />
                </Form.Group>
              </Col>
              <Col xs={24} md={12}>
                <Form.Group className="tw-mb-6">
                  <Form.ControlLabel className="tw-text-base">
                    {translateText(props.convertText, "Birthday")}
                    <span className="tw-text-red-500">*</span>
                  </Form.ControlLabel>
                  <Controller
                    name="info.birthday"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        className="tw-w-full"
                        oneTap
                        format="dd-MM-yy"
                        onChange={(value) => setValue(field.name, value)}
                        size="lg"
                        {...field}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="info.birthday"
                    as={FieldError}
                  />
                </Form.Group>
              </Col>
              <Col xs={24} md={12}>
                <Form.Group className="tw-mb-6">
                  <Form.ControlLabel className="tw-text-base">
                    {translateText(props.convertText, "Country")}
                    <span className="tw-text-red-500">*</span>
                  </Form.ControlLabel>
                  <Controller
                    name="info.country_id"
                    control={control}
                    render={({ field }) => (
                      <SelectPicker
                        data={countries}
                        style={{ width: "100%" }}
                        onChange={(value) => {
                          setValue(field.name, value);
                          getCityStates(value);
                        }}
                        placeholder={translateText(
                          props.convertText,
                          "Please select"
                        )}
                        size="lg"
                        {...field}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="info.country_id"
                    as={FieldError}
                  />
                </Form.Group>
              </Col>
              <Col xs={24} md={12}>
                <Form.Group className="tw-mb-6">
                  <Form.ControlLabel className="tw-text-base">
                    {translateText(props.convertText, "State")}
                    <span className="tw-text-red-500">*</span>
                  </Form.ControlLabel>
                  <Controller
                    name="info.city_state_id"
                    control={control}
                    render={({ field }) => (
                      <SelectPicker
                        data={cityStates}
                        style={{ width: "100%" }}
                        onChange={(value) => {
                          setValue(field.name, value);
                        }}
                        placeholder={translateText(
                          props.convertText,
                          "Please select"
                        )}
                        size="lg"
                        {...field}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="info.city_state_id"
                    as={FieldError}
                  />
                </Form.Group>
              </Col>
              <Col xs={24} md={12} className='sm-tw-mb-4'>
                <Form.Group>
                  <Form.ControlLabel className="tw-text-base">
                    {translateText(props.convertText, "Timezone")}
                    <span className="tw-text-red-500">*</span>
                  </Form.ControlLabel>
                  <Controller
                    name="user.timezone"
                    control={control}
                    render={({ field }) => (
                      <SelectPicker
                        data={timezones}
                        style={{ width: "100%" }}
                        onChange={(value) => {
                          setValue(field.name, value);
                        }}
                        placeholder={translateText(
                          props.convertText,
                          "Please select"
                        )}
                        size="lg"
                        {...field}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="user.timezone"
                    as={FieldError}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Grid>
        </Form>
      </PanelContainer>
    </div>
  );
};

export default PersonalDetails;
