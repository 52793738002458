import React from "react";
import { Col, Divider, FlexboxGrid, Panel, Placeholder, Row } from "rsuite";

const UserStats = (props) => {
  const {
    userStats: { activation, countries, industries, total_users, universities },
    userType,
  } = props;

  const data = {
    activation: { data: activation, header: "Total Activation" },
    countries: { data: countries, header: "Top Countries" },
    industries: { data: industries, header: "Top Industries" },
    universities: { data: universities, header: "Top Universities" },
    total_users: { data: total_users },
  };

  const statsDisplay = (user) => {
    const key =
      user === "mentee"
        ? [
            "total_users",
            "industries",
            "universities",
            "countries",
            "activation",
          ]
        : ["total_users", "industries", "countries", "activation"];

    return key.map((e) => {
      const obj = data[e];

      return e == "total_users" ? (
        <PanelTotalCards data={obj.data} user={userType} />
      ) : (
        <PanelTopCards data={obj.data} header={obj.header} />
      );
    });
  };

  const PanelTotalCards = ({ data, user }) => (
    <Col md={4} sm={12}>
      <Panel
        bordered
        header={
          <div>
            <span className="tw-font-bold">
              {user == "mentee" ? "Total Mentees" : "Total Mentors"}
            </span>
            <Divider className="tw-mb-0" />
          </div>
        }
      >
        {data > 0 ? (
          <h3 className="tw-font-bold">{data}</h3>
        ) : (
          <Placeholder.Paragraph />
        )}
      </Panel>
    </Col>
  );

  const PanelTopCards = ({ data, header }) => (
    <Col md={5} sm={12}>
      <Panel
        bordered
        header={
          <div>
            <span className="tw-font-bold">{header}</span>
            <Divider className="tw-mb-0" />
          </div>
        }
      >
        {data && data?.length > 0 ? (
          data.map((x, idx) => (
            <div key={idx} className="tw-flex tw-justify-between">
              <p className="tw-text-sm montserrat">{x[0]}</p>
              <p className="tw-text-sm montserrat tw-font-bold">{x[1]}</p>
            </div>
          ))
        ) : (
          <Placeholder.Paragraph />
        )}
      </Panel>
    </Col>
  );

  return (
    <FlexboxGrid.Item as={Col} colspan={22} xs={24} md={20} lg={22}>
      <Row>{statsDisplay(userType)}</Row>
    </FlexboxGrid.Item>
  );
};

export default UserStats;