import axios from "axios";
import { getRootUrl } from "api/config";
import {
  CheckUserOnboardingResponse,
  CreateUserOnboardingRequest,
  OnboardingRecord,
} from "./onboardingApi.types";
import Cookies from "js-cookie";

// Base URL for the API
const BASE_URL = getRootUrl();
const api = axios.create({ baseURL: BASE_URL, withCredentials: false });

// TODO: Add comments
export const getAllOnboardingRecords = async () => {
  return await api.get<OnboardingRecord[]>(
    `${BASE_URL}/user_onboarding/all_records`
  );
};

export const checkUserOnboarding = async (
  onboardingType: string,
  userId?: number,
) => {
  return await api.get<CheckUserOnboardingResponse>(
    `${BASE_URL}/user_onboarding/check_user_onboarding`,
    {
      params: { user_id: userId, onboarding_type: onboardingType }
    }
  );
};

export const createUserOnboarding = async (
  onboardingType: string,
  userId?: number,
) => {
  return await api.post<CreateUserOnboardingRequest>(
    `${BASE_URL}/user_onboarding/create_user_onboarding`,
    {
      user_id: userId,
      onboarding_type: onboardingType
    }
  );
};

api.interceptors.request.use((config) => {
  const token =
    Cookies.get("pwa_token") ||
    Cookies.get(`pwa_${process.env.RAILS_ENV}_session`);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
