// React
import React from "react";

// UI
import { Button } from "rsuite";

export default function RenderCharacter(value: number, index: number) {
  return (
    <Button
      value={value}
      appearance="ghost"
      className="tw-font-semibold tw-text-base xs:tw-text-sm xs:tw-font-medium xs:tw-w-6"
    >
      {index + 1}
    </Button>
  );
}
