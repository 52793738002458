// React
import React, { useMemo } from "react";

// FutureLab
import { getHeaderAndCellData } from "../callbacks/csvHeaderAndData";

// UI
import { IconButton } from "rsuite";
import { FaDownload } from "react-icons/fa";

export default function DownloadButton(props) {
  const { data, fileName, activeKey } = props;

  const csvData = useMemo(() => {
    const { headerData, cellData } = getHeaderAndCellData(data, activeKey);
    return [headerData, ...cellData];
  }, [data, activeKey]);

  const handleDownloadCSV = () => {
    // Create a data URI for the CSV content
    const csvContent = encodeURIComponent(
      csvData.map((row) => row.join(",")).join("\n")
    );
    const csvURL = `data:text/csv;charset=utf-8,${csvContent}`;

    // Create a temporary <a> element to trigger the download
    const link = document.createElement("a");
    link.setAttribute("href", csvURL);
    link.setAttribute("download", `${fileName}`);
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  return (
    <IconButton
      icon={<FaDownload size={16} />}
      appearance="ghost"
      className="tw-mb-1 tw-mr-2"
      onClick={handleDownloadCSV}
    />
  );
}
