import React, { useEffect, useState } from "react";

import { Navbar } from "rsuite";

import { MdChatBubbleOutline, MdOutlineSearch } from "react-icons/md";
import flCircleLogo from "../../../images/fl-circle-logo-border.png";
import NavSearchForm from "../component/NavSearchForm";
import NavItemNotificationButton from "../component/NavItemNotificationButton";
import { NavItemButton } from "../common";
import { usePortal } from "../../../contexts/portal";
import { useNavbarContext } from "../../../contexts/navbarContext";

const PortalMobileHeaderNav = (props) => {
  const { onPortal } = useNavbarContext();
  const { program } = usePortal();

  const chatUrls = {
    development: "http://localhost:3005/chat",
    production: "https://newapp.futurelab.my",
    staging: "https://newapp.futurelab.global",
  };

  useEffect(() => {
    if (program) {
      bgColorGenerator(program);
    }
  }, [onPortal, program]);

  const bgColorGenerator = (program) => {
    let bgColour = "#428AC9";

    if (onPortal && program) {
      bgColour = program?.white_label?.dashboard_primary_colour || "#428AC9";
    }

    return bgColour;
  };

  const chathref = chatUrls?.[process.env.RAILS_ENV] || chatUrls["development"];

  return (
    <>
      <Navbar
        appearance="subtle"
        className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-px-4"
        style={{
          backgroundColor: bgColorGenerator(program),
          color: "#ffffff",
          height: 60,
        }}
      >
        <div className="tw-grow">
          <img
            src={!onPortal ? flCircleLogo : program?.photo_url || flCircleLogo}
            alt=""
            style={{ maxHeight: 35 }}
            onClick={() =>
              window.location.replace(
                `/app/${program?.enterprise.slug}/${program?.slug}/overviews`
              )
            }
          />
        </div>
        <div>
          <NavItemButton
            icon={<MdChatBubbleOutline />}
            href={`${chathref}?originURL=${program?.enterprise?.slug}/${program?.slug}`}
            target="_self"
          />
          <NavItemNotificationButton />
        </div>
      </Navbar>
    </>
  );
};

export default PortalMobileHeaderNav;
