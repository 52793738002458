// React
import React, { useEffect, useState } from "react";

// FutureLab
import UserCards from "../dashboard/users/UserCards";
import HorizontalScroller from "../ui/HorizontalScroller";
import thankYouImage from "../../images/thank-you-check.png";
import { FutureLabApi } from "../../util/api";

// UI
import { Col, Divider, FlexboxGrid, Stack, Button } from "rsuite";

const EventThankYou = ({ event, redirect_url }) => {
  const [user, setUser] = useState([]);
  const [mentors, setMentors] = useState([]);
  const [recall, setRecall] = useState(false);

  useEffect(() => {
    FutureLabApi({
      method: "get",
      path: "/users/current",
      success: ({ data }) => {
        setUser(data);
      },
    });
    return () => {
      setUser([]);
    };
  }, [event]);

  useEffect(() => {
    if (event.program_id == null) {
      FutureLabApi({
        method: "get",
        path: "/dashboard/users/mentors",
        success: ({ data }) => {
          setMentors(data.suggested);
        },
      });
      // if (mentors) {
      //   setRecall(false);
      // }
    }
  }, [/* recall */]);

  return (
    <FlexboxGrid justify="center">
      <FlexboxGrid.Item as={Col} colspan={24} lg={20} sm={24}>
        <div className="tw-text-center">
          <img
            src={thankYouImage}
            alt=""
            className="tw-my-4 tw-py-2"
            style={{ maxHeight: 200 }}
          />
          <div className="tw-text-center">
            <h2 className="tw-text-lg">
              Thanks for reserving a spot on this event!
            </h2>
            <p className="montserrat tw-text-center">
              We've sent an email containing your ticket to your inbox.
            </p>

            {event.program_id == null && (
              <p className="montserrat tw-text-center grey-primary">
                Looking forward to seeing you soon! In the meantime, check out
                some mentors that you might be interested in speaking to.
              </p>
            )}
          </div>
        </div>
        <div className="tw-flex tw-justify-center">
          <Button
            style={{ width: 100 }}
            className="tw-text-center"
            appearance="primary"
            href={redirect_url}
          >
            Ok
          </Button>
        </div>

        {event.program_id == null && (
          <>
            <Divider />
            <div className="tw-my-2 tw-py-2">
              <div className="tw-mb-5">
                <p className="section-header montserrat">
                  Mentors matching your interest
                </p>
              </div>
              <HorizontalScroller>
                <Stack
                  className="tw-overflow-x-auto no-scrollbar"
                  alignItems="stretch"
                >
                  {mentors.map((user) => (
                    <UserCards
                      // setRecall={setRecall}
                      user={user}
                      setUsers={setMentors}
                      forMentor
                      currentUser={user}
                      location={"Event - Thank You"}
                    />
                  ))}
                </Stack>
              </HorizontalScroller>
            </div>
          </>
        )}
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

export default EventThankYou;
