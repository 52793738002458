import React from "react";
import { Container, Header, Content } from "rsuite";
import useScreenSize from "../../../../util/useScreenSize";
import PortalAdminSideNav from "../components/navigation/PortalAdminSideNav";
import PortalAdminTopNav from "../../../navbar/portal/PortalAdminTopNav";
import { useNavbarContext } from "../../../../contexts/navbarContext";

const DashboardLayout = ({ children }) => {
  const { isDesktop, isMobile } = useScreenSize();
  const { isExpanded } = useNavbarContext();

  return (
    <Container className="tw-h-screen">
      {/*Left SideNav */}
      {/* TODO: Do a drawer for mobile */}
      {isDesktop && <PortalAdminSideNav />}

      {/* Right Content Container */}
      <Container
        style={{
          paddingLeft: isMobile ? "0px" : isExpanded ? "260px" : "56px",
          transition: "padding-left 0.18s ease-in-out",
        }}
      >
        <div className="tw-relative">
          <Header className="tw-w-full">
            <PortalAdminTopNav />
          </Header>

          {/* Content */}
          <Content className="tw-px-20 tw-py-10 sm:tw-px-2 tw-w-full tw-box-border tw-overflow-y-auto tw-h-[calc(100svh-56px)]">
            {children}
          </Content>
        </div>
      </Container>
    </Container>
  );
};

export default DashboardLayout;
