import React, { useMemo, useState } from "react";

// FL
import { useEnterprise } from "../../../../contexts/enterpriseContext";
import { registerEnterprise } from "../../api";
import useCountries from "../../../../hooks/useCountries";

// UI
import { Button, Divider, Form } from "rsuite";
import {
  FormInputPicker,
  FormInputText,
  FormInputTextAddOn,
} from "../../../form/default/FormInputs";
import { FormWrapper } from "../Container";
import Toaster from "../../../shared/Toaster";

// 3rd party
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const enterpriseSchema = yup.object().shape({
  name: yup.string().required("Company name is required").nullable(),
  program_type: yup.string().required("Program type is required").nullable(),
  estimated_participants: yup
    .string()
    .required("Estimated participants is required")
    .nullable(),
});

const schema = yup.object().shape({
  firstname: yup.string().required("Firstname is required").nullable(),
  lastname: yup.string().required("Lastname is required").nullable(),
  email: yup.string().email().required("Email is required").nullable(),
  country_id: yup.number().required("Country is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8)
    .nullable()
    .test({
      name: "password",
      message:
        "Password must contain at least 8 characters, 1 uppercase, 1 number and 1 special character",
      test: (value) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/;
        return regex.test(value);
      },
    }),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required")
    .nullable(),
  phone_no: yup
    .string()
    .required("Phone no is required")
    .nullable()
    .test({
      name: "phone_no",
      //
      message: "Phone number must be in the format 123456789",
      test: (value) => {
        const regex = /[0-9]/;
        return regex.test(value);
      },
    }),
  enterprise: enterpriseSchema,
});

type FormValue = yup.InferType<typeof schema>;

const typeOfProgramOptions = [
  "Company wide",
  "Employee resource group or diversity",
  "Department or function specific",
  "Career-milestone base",
];

const typeOfEstimatedParticipants = [
  "1 - 50",
  "51 - 100",
  "101 - 200",
  "201 - 300",
  "More than 300",
];

const RegistrationForm = () => {
  const [showPassword, setShowPassword] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    setValue,
    watch,
  } = useForm<FormValue>({
    resolver: yupResolver(schema),
  });
  const { loginAdmin } = useEnterprise();
  const handleShowPassword = () => setShowPassword(!showPassword);

  const countries = useCountries((value) =>
    setValue("country_id" as never, value as never)
  );

  const countryOptions = useMemo(
    () =>
      countries.map((v) => ({
        value: v.id,
        label: v.name,
      })),
    [countries]
  );

  const countryId = watch("country_id" as never);
  const currentCountry = useMemo(
    () => countries.find((v) => v.id === countryId),
    [countries, countryId]
  );

  const onSubmit = async (data: FormValue) => {
    try {
      const response = await registerEnterprise(data);
      loginAdmin(response.data);
      Toaster({
        type: "success",
        msg: "Registration successful",
      });
    } catch (error) {
      const data = error.response.data;

      Toaster({
        type: "error",
        msg: data?.errors || "Oops, something went wrong!",
      });
    }
  };

  return (
    <FormWrapper>
      <Form>
        <div className="tw-grid tw-gap-4 tw-grid-cols-2 sm:tw-grid-cols-1 sm:tw-gap-0 sm:tw-mb-6">
          <Form.Group>
            <FormInputText
              name={"firstname"}
              label={"First name"}
              placeholder={"First name"}
              control={control}
              errors={errors}
            />
          </Form.Group>
          <Form.Group>
            <FormInputText
              name={"lastname"}
              label={"Last name"}
              placeholder={"Last name"}
              control={control}
              errors={errors}
            />
          </Form.Group>
        </div>
        <div className="tw-grid tw-gap-4 tw-grid-cols-2 sm:tw-grid-cols-1 sm:tw-gap-0 sm:tw-mb-6">
          <Form.Group>
            <FormInputPicker
              name={"country_id"}
              label={"Country"}
              control={control}
              placeholder={"Select country"}
              errors={errors}
              data={countryOptions}
              cleanable={false}
              block
            />
          </Form.Group>
          {/* TODO: Set prefix as part of value */}
          <Form.Group>
            <FormInputTextAddOn
              type={"tel"}
              name={"phone_no"}
              label={"Phone number"}
              control={control}
              placeholder={"123456789"}
              errors={errors}
              addonLabel={`+${currentCountry?.prefix || ""}`}
              block
            />
          </Form.Group>
        </div>

        <div className="tw-grid tw-gap-4 tw-mb-6 tw-grid-cols-2 sm:tw-grid-cols-1 sm:tw-gap-0 sm:tw-mb-6">
          <Form.Group>
            <FormInputText
              name={"email"}
              label={"Email"}
              placeholder={"email@example.com"}
              control={control}
              errors={errors}
            />
          </Form.Group>
        </div>

        <div className="tw-grid tw-gap-4 tw-grid-cols-2 sm:tw-grid-cols-1 sm:tw-gap-0 sm:tw-mb-6">
          <Form.Group>
            <div className="tw-mb-1 tw-flex tw-justify-between">
              <span
                className="tw-text-sm tw-font-medium tw-text-[#0a0a0a] tw-leading-[23px]"
              >
                Password
              </span>
              <span
                onClick={handleShowPassword}
                style={{ userSelect: "none" }}
                className="tw-text-sm tw-cursor-pointer tw-flex tw-items-center"
              >
                {showPassword ? (
                  <>
                    <FaEyeSlash className="tw-mr-2" /> Hide
                  </>
                ) : (
                  <>
                    <FaEye className="tw-mr-2" /> Show
                  </>
                )}
              </span>
            </div>
            <FormInputText
              type={showPassword ? "text" : "password"}
              name={"password"}
              placeholder={"Password"}
              control={control}
              errors={errors}
            />
          </Form.Group>
          <Form.Group>
            <FormInputText
              type={"password"}
              name={"password_confirmation"}
              label={"Confirm password"}
              placeholder={"Confirm password"}
              control={control}
              errors={errors}
            />
          </Form.Group>
        </div>
        <Divider />

        <Form.Group>
          <FormInputText
            name={"enterprise.name"}
            label={"Company name"}
            placeholder={"Company name"}
            control={control}
            errors={errors}
          />
        </Form.Group>

        <div className="tw-grid tw-gap-4 tw-grid-cols-2 sm:tw-grid-cols-1 sm:tw-gap-0 sm:tw-mb-6">
          <Form.Group>
            <FormInputPicker
              name={"enterprise.program_type"}
              control={control}
              label={"Program type"}
              placeholder={"Select type of program"}
              errors={errors}
              data={typeOfProgramOptions.map((v) => ({ value: v, label: v }))}
              cleanable={false}
              block
            />
          </Form.Group>
          {/* TODO: Convert estimated participants attr tointeger data type - to string  */}
          <Form.Group>
            <FormInputPicker
              name={"enterprise.estimated_participants"}
              control={control}
              label={"Estimated participants"}
              placeholder={"Select estimated participants"}
              errors={errors}
              data={typeOfEstimatedParticipants.map((v) => ({
                value: v,
                label: v,
              }))}
              cleanable={false}
              block
            />
          </Form.Group>
        </div>
        <div className="tw-text-center">
          <Button
            loading={isSubmitting}
            onClick={handleSubmit(onSubmit)}
            className="tw-mt-4 tw-w-2/5 sm:tw-w-full tw-mx-auto"
            appearance="primary"
            type="submit"
          >
            Continue
          </Button>
        </div>
        <p className="montserrat tw-text-center tw-mt-5 tw-text-sm tw-text-[#222]">
          By signing up, you agree with FutureLab's{" "}
          <a href="https://futurelab.my/terms">Terms & Conditions</a> and{" "}
          <a href="https://www.futurelab.my/privacy">Privacy Policy</a>.
        </p>
      </Form>
    </FormWrapper>
  );
};

export default RegistrationForm;
