import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const SessionCompletionBarChart = ({ data }) => {
  const series = [
    {
      data: [
        {
          x: "Booked (%)",
          y: data?.booked,
        },
        {
          x: "Conducted (%)",
          y: data?.conducted,
        },
        {
          x: "Reflected (%)",
          y: data?.reflected,
        },
      ],
    },
  ];

  const options: ApexOptions = {
    plotOptions: {
      bar: {
        columnWidth: "40%",
        borderRadius: 6,
        dataLabels: {
          position: "top",
        },
      },
    },
    legend: {
      show: true,
      position: "bottom",
      markers: {
        radius: 6,
      },
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="bar"
      height={"350"}
    />
  );
};

export default SessionCompletionBarChart;
