// React
import React, { useEffect, useState } from "react";

// UI
import { Progress } from "rsuite";

const calculateProgressPercentage = (answers, requiredQuestionCount) => {
  let answeredRequiredQuestionCount = 0;

  // Iterate through required questions and count answered ones
  answers.forEach((item) => {
    const { answer, required } = item;
    if (
      [true, "true"].includes(required) &&
      answer !== null &&
      answer !== undefined &&
      answer.toString().trim() !== ""
    ) {
      answeredRequiredQuestionCount++;
    }
  });

  const percentage =
    (answeredRequiredQuestionCount / requiredQuestionCount) * 100;
  return parseFloat(percentage.toFixed(0));
};

export default function ApplicantProgressBar({
  watch,
  questions,
  programColor,
}) {
  const [percentage, setPercentage] = useState(0);

  const requiredQuestionCount = questions.filter((question) =>
    [true, "true"].includes(question.required)
  ).length;

  useEffect(() => {
    const eventWatch = watch((d) => {
      const percentage = calculateProgressPercentage(
        d.answers,
        requiredQuestionCount
      );
      setPercentage((p) => percentage);
    });

    return () => {
      eventWatch.unsubscribe();
    };
  }, [requiredQuestionCount]);

  return (
    <>
      <div className="tw-sticky tw-top-0 tw-h-[10px] tw-bg-[#fefefe]"></div>
      <div className="tw-sticky tw-top-2 tw-mb-4 tw-bg-slate-100 tw-rounded-md tw-shadow-md">
        <Progress.Line
          percent={percentage}
          strokeWidth={12}
          strokeColor={percentage === 100 ? "#4caf50" : `${programColor}`}
          status={percentage === 100 ? "success" : "active"}
        />
      </div>
    </>
  );
}
