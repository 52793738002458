// React
import React from "react";
import { useNavigate } from "react-router-dom";

// UI
import { Col, Panel, Row } from "rsuite";

export const DashboardAnalytics = ({ analytics }) => {
  const { bookings_count, pairings_count, participants_count } = analytics;
  const navigate = useNavigate();
  return (
    <>
      <Row className="tw-my-5">
        <Col className="tw-min-w-64">
          <Panel
            bordered
            header="Participants"
            className="hover:tw-shadow-md hover:tw-cursor-pointer"
            onClick={() => navigate("./user_management")}
          >
            <div className="tw-flex tw-justify-around">
              <div className="tw-flex tw-flex-col tw-text-center">
                <span className="tw-text-xl tw-font-semibold">
                  {participants_count?.mentees}
                </span>
                <span>Mentees</span>
              </div>
              <div className="tw-flex tw-flex-col tw-text-center">
                <span className="tw-text-xl tw-font-semibold">
                  {participants_count?.mentors}
                </span>
                <span>Mentors</span>
              </div>
            </div>
          </Panel>
        </Col>
        <Col className="tw-min-w-64">
          <Panel
            bordered
            header="Pairing"
            className="hover:tw-shadow-md hover:tw-cursor-pointer"
            onClick={() => navigate("./group_and_pairings_management")}
          >
            <div className="tw-flex tw-justify-around">
              <div className="tw-flex tw-flex-col tw-text-center">
                <span className="tw-text-xl tw-font-semibold">
                  {pairings_count?.pairs}
                </span>
                <span>Pairs</span>
              </div>
              <div className="tw-flex tw-flex-col tw-text-center">
                <span className="tw-text-xl tw-font-semibold">
                  {pairings_count?.groups}
                </span>
                <span>Groups</span>
              </div>
            </div>
          </Panel>
        </Col>
        <Col className="tw-min-w-64">
          <Panel
            bordered
            header="Bookings"
            className="hover:tw-shadow-md hover:tw-cursor-pointer"
            onClick={() => navigate("./bookings/bookings_list")}
          >
            <div className="tw-flex tw-justify-around">
              <div className="tw-flex tw-flex-col tw-text-center">
                <span className="tw-text-xl tw-font-semibold">
                  {bookings_count?.created}
                </span>
                <span>Created</span>
              </div>

              <div className="tw-flex tw-flex-col tw-text-center">
                <span className="tw-text-xl tw-font-semibold">
                  {bookings_count?.completed}
                </span>
                <span>Completed</span>
              </div>
            </div>
          </Panel>
        </Col>
      </Row>
    </>
  );
};
