import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Divider,
  Form,
  Message,
  Modal,
  SelectPicker,
  toaster,
} from "rsuite";
import { FutureLabApi } from "../../util/api";
import FieldError from "../ui/FieldError";
import LinkButton from "../ui/LinkButton";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUser } from "../../contexts/user";
import { FaExclamationTriangle } from "react-icons/fa";
import { last, map } from "lodash";
import { ValueType } from "rsuite/esm/Checkbox";
import { useVariables } from "../../contexts/variables";
import translateText from "../../util/translateText";

type UserInput = {
  reason_for_leaving: string[];
};

const schema = yup.object().shape({
  reason_for_leaving: yup
    .array()
    .min(1, "Reasons is a required field")
    .required()
    .label("Reasons"),
});

const DeleteAccountModal = (props) => {
  const { variables } = useVariables();
  const { user, setUser } = useUser();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<UserInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      reason_for_leaving: [],
    },
  });

  const reasonOptions = [
    translateText(props.convertText, "It doesn't seem useful"),
    translateText(props.convertText, "I have privacy issues"),
    translateText(props.convertText, "I have found something better"),
    translateText(props.convertText, "Other"),
  ];

  const onSubmit = (data) => {
    data.reason_for_leaving = data.reason_for_leaving.join(";");

    setIsSubmitting(true);
    FutureLabApi({
      method: "post",
      path: "/users/current/delete_account",
      options: data,
      success: ({ data }) => {
        setUser(data);
        setIsSubmitting(false);
        toaster.push(
          <Message showIcon type="success">
            {translateText(props.convertText, "Successfully deleted.")}
          </Message>
        );
        window.location.href = `${variables?.PWA_URL}/users/logout`;
      },
      error: (err) => {
        const { error, messages } = err.response.data;
        setIsSubmitting(false);
        console.log(messages);
        toaster.push(
          <Message showIcon type="error">
            {error}
          </Message>
        );
      },
    });
  };

  return (
    <>
      <Button
        className="tw-text-base"
        style={{ backgroundColor: "#D12F1D" }}
        appearance="primary"
        onClick={() => setShowDeleteConfirm(true)}
      >
        {/* {translateText(props.convertText, "Delete My Account")} */}
        Delete My Account
      </Button>
      <Modal
        open={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        className="tw-mt-16"
        size="sm"
      >
        <Modal.Header>
          <Modal.Title className="tw-text-xl tw-font-medium">
            {translateText(props.convertText, "Delete Account")}
          </Modal.Title>
          <Divider className="tw-absolute tw-w-full tw-left-0 tw-mt-4 tw-bg-gray-300" />
        </Modal.Header>
        <Modal.Body>
          <Form fluid className="tw-pt-7">
            <Form.Group>
              <Form.ControlLabel>
                <h6>
                  {/* {translateText(
                    props.convertText,
                    "We sad you want to leave us"
                  )} */}
                  We're sorry to see you go..
                </h6>
                <div className="tw-text-base">
                  {/* {translateText(
                    props.convertText,
                    "If you're sure that you would like to proceed, we hope you can tell us why you are leaving."
                  )} */}
                  <p className="montserrat">
                    Deleting your account will mean that you are removing all of
                    your personal information from our database. After {" "}
                    <b>
                      21 days, all data associated with this account will be
                      permanently deleted and cannot be undone
                    </b>.{" "}
                    In the mean time, your account will be deactivated.
                  </p>
                  <p className="montserrat">
                    If you're sure that you would like to proceed, we hope you
                    can tell us why you are leaving.
                  </p>
                </div>
              </Form.ControlLabel>
              <Controller
                name="reason_for_leaving"
                control={control}
                render={({ field }) => (
                  <CheckboxGroup name="reason_for_leaving" {...field}>
                    {map(reasonOptions, (reason) => (
                      <Checkbox key={reason} {...field} value={reason}>
                        <span className="tw-text-base">{reason}</span>
                      </Checkbox>
                    ))}
                  </CheckboxGroup>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="reason_for_leaving"
                as={FieldError}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Divider className="tw-absolute tw-w-full tw-left-0 tw-m-0 tw-bg-gray-300" />
        <Modal.Footer className="tw-pt-5">
          <Button
            appearance="ghost"
            onClick={() => setShowDeleteConfirm(false)}
          >
            {translateText(props.convertText, "Cancel")}
          </Button>
          <Button
            appearance="primary"
            onClick={handleSubmit(onSubmit)}
            loading={isSubmitting}
          >
            {translateText(props.convertText, "Proceed")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteAccountModal;
