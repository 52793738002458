import React, { useState, useRef, useEffect } from "react";
import { CropperRef, Cropper } from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";

type ImageFile = {
  blob: Blob;
  name: string;
  type: string;
};

const fileToUrl = (file: File, setter: (e) => void) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    setter(e.target.result as string);
  };
  reader.readAsDataURL(file);
};

const PhotoUploaderParent = ({
  imageUrl,
  target,
  name,
  aspectRatio,
}: {
  imageUrl: string;
  target: string;
  name: string;
  aspectRatio?: number;
}) => {
  const [image, setImage] = useState<string>(imageUrl);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [imageBlob, setImageBlob] = useState<ImageFile>(null);
  const fileRef = useRef<HTMLInputElement>(null);

  const handleUpload = () => {
    fileRef.current?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files?.length > 0) {
      fileToUrl(files[0], setImage);
      setIsEditing(true);
    }
  };

  const onCrop = ({ blob, name, type }) => {
    setImageBlob({ blob, name, type });
  };

  useEffect(() => {
    const { blob } = imageBlob || { blob: null };

    if (blob) {
      const targetEl = document.getElementById(target) as HTMLInputElement;
      //set file to the target element
      const newFile = new File([imageBlob.blob], imageBlob.name, {
        type: imageBlob.type,
      });
      const container = new DataTransfer();
      container.items.add(newFile);
      if (targetEl) {
        targetEl.files = container.files;
      }
      fileToUrl(newFile, setImage);
      setIsEditing(false);
    }
  }, [imageBlob]);

  return (
    <div className="tw-grid tw-gap-4 tw-grid-cols-2">
      <div>
        <input
          type="file"
          className="tw-hidden"
          ref={fileRef}
          onChange={handleFileChange}
        />
        <button
          onClick={handleUpload}
          type="button"
          className="btn btn-blue"
        >
          Upload
        </button>
      </div>
      {isEditing ? (
        <PhotoUploader
          target={target}
          image={image}
          onCancel={() => setIsEditing(false)}
          onCrop={onCrop}
          aspectRatio={aspectRatio || 1}
          name={name}
        />
      ) : (
        <div className="tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-center tw-relative">
          <img src={image} className="tw-h-auto tw-w-full" />
          <button
            onClick={() => setIsEditing(true)}
            className="btn btn-blue tw-absolute tw-h-10 tw-w-20 tw-bottom-4 tw-left-4 tw-rounded-md tw-opacity-80"
          >
            Edit
          </button>
        </div>
      )}
    </div>
  );
};

export const PhotoUploader = ({
  image,
  aspectRatio,
  onCancel,
  onCrop,
  name,
}: {
  image: string;
  target: string;
  onCancel?: () => void;
  onCrop?: (blob: ImageFile) => void;
  aspectRatio?: number;
  name: string;
}) => {
  const cropperRef = useRef<CropperRef>(null);

  const setCroppeddImage = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    let result = {
      blob: null as Blob,
      name,
      type: "image/png",
    };
    cropperRef.current?.getCanvas().toBlob((blob) => {
      result.blob = blob;
      onCrop(result);
    });
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-center tw-relative">
      <Cropper
        src={image}
        stencilProps={{
          aspectRatio,
        }}
        ref={cropperRef}
        className="tw-h-auto tw-w-full"
      />
      <button
        className="btn btn-blue tw-absolute tw-h-10 tw-w-20 tw-bottom-4 tw-left-4 tw-rounded-md tw-opacity-80"
        onClick={setCroppeddImage}
      >
        Crop
      </button>
      <button
        className="btn btn-secondary tw-absolute tw-h-10 tw-w-20 tw-bottom-4 tw-right-4 tw-rounded-md tw-opacity-80"
        onClick={onCancel}
      >
        Cancel
      </button>
    </div>
  );
};

export default PhotoUploaderParent;
