// React
import React, { createContext, useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

// FutureLab
import { FutureLabApi } from "../util/api";
import { PortalApi } from "../util/portal_api";
import { usePortal } from "./portal";
import { PlatformType } from "./mentor_profile";

// UI
import { Loader } from "rsuite";

export interface IMenteeContext {
  mentee: object;
  setMentee: (mentee: object) => void;
  refreshMentee: () => void;
  type: PlatformType;
  program?: object | null;
}

const MenteeProfileContext = createContext<IMenteeContext>(undefined);

const useMenteeProvider: () => IMenteeContext = () => {
  const [mentee, setMentee] = useState();
  const { hexId } = useParams();
  const type = PlatformType.FutureLab;

  useEffect(() => {
    setMentee(undefined);
    getMentee();
  }, [hexId]);

  const getMentee = () => {
    FutureLabApi({
      method: "get",
      path: `/mentee_profile/${hexId}`,
      success: ({ data }) => {
        setMentee(data);
      },
    });
  };

  const refreshMentee = () => {
    getMentee();
  };

  return { mentee, setMentee, refreshMentee, type };
};

const usePortalMenteeProvider: () => IMenteeContext = () => {
  const [mentee, setMentee] = useState();
  const { menteeId } = useParams();
  const { program } = usePortal();
  const type = PlatformType.Portal;

  const refreshMentee = () => {
    PortalApi({
      method: "get",
      path: `/mentee_profile/${menteeId}`,
      options: { program_id: program?.id },
      success: ({ data }) => {
        setMentee(data);
      },
    });
  };

  useEffect(() => {
    if (program && menteeId) {
      refreshMentee();
    }
  }, [menteeId, program]);

  return { mentee, setMentee, refreshMentee, type };
};

export const PortalMenteeProfileProvider = ({ children }) => {
  const { mentee, setMentee, refreshMentee, type } = usePortalMenteeProvider();
  const { program } = usePortal();

  return (
    <MenteeProfileContext.Provider
      value={{
        mentee,
        setMentee,
        refreshMentee,
        type,
        program,
      }}
    >
      {children}
    </MenteeProfileContext.Provider>
  );
};

export const usePortalMenteeProfile = () => useContext(MenteeProfileContext);

export const MenteeProfileProvider = ({ children }) => {
  const { mentee, setMentee, refreshMentee, type } = useMenteeProvider();

  return (
    <MenteeProfileContext.Provider
      value={{
        mentee,
        setMentee,
        refreshMentee,
        type,
        program: null,
      }}
    >
      {children}
    </MenteeProfileContext.Provider>
  );
};

export const useMenteeProfile = () => useContext(MenteeProfileContext);

export function withMenteeProfile(WrappedComponent) {
  const Component = (props) => {
    const { mentee, setMentee, refreshMentee } = useMenteeProfile();

    if (!mentee) return <Loader center content="loading" />;

    return (
      <WrappedComponent
        mentee={mentee}
        setMentee={setMentee}
        refreshMentee={refreshMentee}
        {...props}
      />
    );
  };

  return Component;
}
