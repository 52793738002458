// React
import React from "react";

// UI
import { Rate } from "rsuite";

export function FeedbackList({ feedback, idx }) {
  const list = {
    stars: (
      <div key={`stars-${idx}`} className="tw-my-6">
        <h6>{feedback.title}</h6>
        <p className="montserrat tw-text-sm">{feedback.description}</p>
        <Rate readOnly value={feedback.value} />
      </div>
    ),
    text: (
      <div key={`text-${idx}`} className="tw-my-6">
        <h6>{feedback.title}</h6>
        <p className="montserrat tw-text-sm">{feedback.description}</p>
        <p className="montserrat">{feedback.text}</p>
      </div>
    ),
    options: (
      <div key={`options-${idx}`} className="tw-my-6">
        <h6>{feedback.title}</h6>
        <p className="montserrat tw-text-sm">{feedback.description}</p>
        <p className="montserrat">{feedback.text}</p>
      </div>
    ),
    checkboxes: (
      <div key={`checkboxes-${idx}`} className="tw-my-6">
        <h6>{feedback.title}</h6>
        <p className="montserrat tw-text-sm">{feedback.description}</p>
        <ul>
          {feedback.text &&
            feedback.text?.split(",").map((text) => <li><p className="montserrat m-0">{text}</p></li>)}
        </ul>
      </div>
    ),
  };

  return list[feedback.category];
}
