// React
import React, { useEffect, useState } from "react";

// FutureLab
import SideNav from "../../../../shared/navigation/SideNav";
import Navigation from "../../../../shared/navigation/Navigation";
import useAdminRoute from "../useAdminRoute";
import { useNavigate } from "react-router-dom";
import { findNavByEventKey } from "../utils/navRouteMatcher";
import { usePortal } from "contexts/portal";

const PortalAdminSideNav = () => {
  const [activeKey, setActiveKey] = useState("1");
  const [openKey, setOpenKey] = useState([]);
  const navigate = useNavigate();
  const { activeRoute, navItems } = useAdminRoute();
  const currentActive = activeRoute?.eventKey;
  const { program } = usePortal();

  useEffect(() => {
    if (currentActive) {
      activeRoute?.parentKey && setOpenKey([activeRoute?.parentKey]);
      setActiveKey(activeRoute?.eventKey);
    }
  }, [activeRoute]);

  const handleSelect = (eventKey) => {
    const selectedItem = findNavByEventKey(eventKey, navItems);

    if (selectedItem?.remote) {
      window.location.href = selectedItem.path;
    } else if (selectedItem?.path) {
      navigate(`.${selectedItem.path}`);
      setActiveKey(eventKey);
    }
  };

  const handleOpen = (key) => setOpenKey(key);

  return (
    <SideNav program={program} currentOpen={openKey} handleOpen={handleOpen}>
      <Navigation
        navItems={navItems}
        handleSelect={handleSelect}
        activeKey={activeKey}
      />
    </SideNav>
  );
};

export default PortalAdminSideNav;
