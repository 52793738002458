import React from "react";
import UserDonutChart from "./charts/UserDonutChart";
import { Button, Panel } from "rsuite";

const TotalUsersPanel = ({
  totalMentees,
  totalMentors,
  totalAdmins,
  totalUsers,
}) => {
  return (
    <>
      <Panel bordered className="tw-w-1/3">
        <div className="tw-px-4 tw-flex tw-flex-col">
          <div className="tw-text-center">
            <p className="tw-text-lg tw-text-center montserrat tw-font-bold">
              Users
            </p>
            <div className="tw-relative">
              <UserDonutChart
                totalMentees={totalMentees}
                totalMentors={totalMentors}
                totalAdmins={totalAdmins}
              />
              <div
                className="tw-flex tw-flex-col tw-absolute"
                style={{ top: "35%", right: "37%" }}
              >
                <span className="tw-font-bold">{totalUsers}</span>
                <span className="tw-text-sm">Total Users</span>
              </div>
            </div>
          </div>
        </div>
        <div className="tw-text-center tw-mt-4">
          <Button
            appearance="primary"
            href={`user_management`}
            target="_self"
          >
            View Details
          </Button>
        </div>
      </Panel>
    </>
  );
};

export default TotalUsersPanel;
