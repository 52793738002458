// React
import React, { forwardRef, useEffect, useMemo } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

// FutureLab
import { MemberList } from "./GroupList";

// UI
import { Grid, Row, Col, Button, Toggle, Form, Input } from "rsuite";
import { FaArrowLeftLong } from "react-icons/fa6";

const EditGroup = () => {
  const {
    instance,
    selectedGroup,
    admins,
    mentors,
    mentees,
    addUsers,
    deleteUsers,
  } = useOutletContext() as any;
  const recipients = instance.watch("recipientUserIds");
  const navigate = useNavigate();

  const users = useMemo(
    () => [admins, ...mentors, ...mentees],
    [mentors, mentees, admins]
  );

  const selectedParticipants = useMemo(() => {
    if (!selectedGroup) return [];
    const ids = selectedGroup?.participants?.map(
      (participant) => participant.userId
    );
    return users.filter((user) => ids.includes(user.id));
  }, [selectedGroup, users]);

  const handleDelete = async (id) => {
    await deleteUsers(selectedGroup.id, [id]);
  };

  useEffect(() => {
    instance.reset({
      ...instance.getValues(),
      groupChatName: selectedGroup?.name,
      groupChatDescription: selectedGroup?.description,
      groupChatId: selectedGroup?.id,
      recipientUserIds: selectedGroup?.participants?.map((v) => v.userId),
    });
  }, [selectedGroup]);

  useEffect(() => {
    if (!recipients || recipients.length === 0) return;
    const mentorIds = recipients.filter((id) =>
      mentors.map((m) => m.id).includes(id)
    );
    instance.setValue("groupChatAdmins", mentorIds);
  }, [recipients]);

  return (
    <div>
      <div className="tw-flex tw-items-center tw-justify-between tw-gap-2 tw-mb-5 tw-border-0 tw-border-b tw-pb-5 tw-border-solid tw-border-[#EAEAEA]">
        <div className="tw-flex">
          <FaArrowLeftLong
            size={24}
            className="tw-mr-3 hover:tw-cursor-pointer"
            onClick={() => navigate("..")}
          />
          <span>Group Settings</span>
        </div>

        <div className="tw-flex tw-gap-11 tw-items-center">
          <div className="tw-flex tw-gap-3 tw-items-center">
            <Toggle readOnly defaultChecked size="md" />
            <label className="tw-uppercase">Active</label>
          </div>
          <Button
            type="submit"
            form="create-form"
            appearance="primary"
            size="sm"
          >
            Save changes
          </Button>
        </div>
      </div>
      <Grid fluid>
        <Row>
          <Col className="tw-px-0" sm={12}>
            <CreateForm instance={instance} />
          </Col>
          <Col sm={4} />
          <Col sm={8}>
            <MemberList
              selectedParticipants={selectedParticipants}
              selectedGroup={selectedGroup}
              deleteUsers={handleDelete}
              addUsers={addUsers}
              from={"/edit-group"}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

const controls = [
  {
    name: "groupChatName",
    label: "Group Name",
    required: true,
    disabled: false,
    type: "control",
  },
  {
    name: "groupChatDescription",
    label: "Group Description",
    required: false,
    disabled: false,
    type: "textarea",
  },
  {
    name: "programName",
    label: "Program Name",
    required: false,
    disabled: true,
    type: "control",
  },
];

const CreateForm = ({ instance }) => {
  const { register, editHandler } = instance;
  return (
    <Form id="create-form" fluid onSubmit={editHandler}>
      {controls.map((control) => (
        <FormField control={control} register={register} key={control.name} />
      ))}
    </Form>
  );
};

const Textarea = forwardRef<any>((props, ref) => (
  <Input
    {...props}
    className="tw-h-[105px] tw-resize-none"
    as="textarea"
    ref={ref}
  />
));

const FormField = ({ control, register }) => {
  const { Group, Control, ControlLabel } = Form;
  const { onChange, onBlur, name, ref } = register(control.name);
  const addOnProps = control.type === "textarea" ? { accepter: Textarea } : {};

  return (
    <Group controlId={control.name} key={control.name}>
      <ControlLabel className="tw-font-bold tw-text-[#575757]">
        {control.label}{" "}
        {control.required && <span className="tw-text-[#FB1313]">*</span>}
      </ControlLabel>
      <Control
        className="tw-font-bold tw-text-[#575757]"
        disabled={control.disabled}
        name={name}
        ref={ref}
        onChange={(e) => {
          onChange({
            target: {
              name,
              value: e,
            },
          });
        }}
        onBlur={onBlur}
        {...addOnProps}
      />
    </Group>
  );
};

export default EditGroup;
