// Main
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Divider, Form, Loader, RadioTile, RadioTileGroup } from "rsuite";
import { FormInputText } from "../../../form/default/FormInputs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import PortalColorSelect from "../PortalColorSelect";
import BottomNavCTA from "../component/BottomNavCTA";
import { PortalApi } from "../../../../util/portal_api";
import { usePortalSetup } from "../../../../contexts/portal/setup";
import { FormRichTextInput } from "../../../ui/RichTextEditor";
import PortalLogoUploaderV2 from "../PortalLogoUploaderV2";
import PortalBannerUploaderV2 from "../PortalBannerUploaderV2";
import { useEnterprise } from "../../../../contexts/enterpriseContext";
import { useSetupVariable } from "../../../../contexts/portal/setup/setupVariables";
import {
  MdOutlineAdminPanelSettings,
  MdOutlineCached,
  MdOutlinePeopleAlt,
} from "react-icons/md";
import FieldError from "components/form/FieldError";
import { ErrorMessage } from "@hookform/error-message";

const schema = yup.object().shape({
  program: yup.object().shape({
    name: yup
      .string()
      .max(35, "Exceeded max characters (max 35)")
      .matches(/^[a-zA-Z0-9\s]*$/, "Name can't contain special characters")
      .required("Program name is required"),
    description: yup
      .string()
      .max(300, "Exceeded max characters (max 300)")
      .required("Program description is required"),
    pairing_method: yup.string().required("Pairing method is required"),
  }),
  white_label: yup.object().shape({
    dashboard_primary_colour: yup
      .string()
      .required("Primary color is required"),
  }),
});

const PortalDesignForm = (props) => {
  const { handleStep, isEditing } = useSetupVariable();
  const { program, setProgram } = usePortalSetup();
  const { state } = useEnterprise();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const enterpriseId = useMemo(
    () => state?.enterprises?.[0]?.id || undefined,
    [state]
  );

  const {
    reset,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      program: {
        name: "",
        description: "",
        pairing_method: "",
      },
      white_label: {
        dashboard_primary_colour: "#428ac9",
      },
    },
  });

  const onSubmit = (data, saveDraft = false) => {
    // setIsSubmitting(true);

    if (program) {
      data.program_id = program.id;
    }

    if (enterpriseId) {
      data.enterprise_id = enterpriseId;
    }

    const form = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (typeof value === "object") {
        Object.entries(value).forEach(([subKey, subValue]) => {
          if (
            (subKey === "photo" || subKey === "banner") &&
            subValue instanceof File
          ) {
            form.append(`${key}[${subKey}]`, subValue);
          }

          if (typeof subValue !== "undefined")
            form.append(`${key}[${subKey}]`, subValue);
        });
      } else {
        form.append(key, value as any);
      }
    });

    PortalApi({
      method: "post",
      path: "/programs",
      options: form,
      config: {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      success: ({ data }) => {
        setIsSubmitting(false);
        setProgram(data);

        // FUT:1506: Replace path to new program rather than save as draft
        window.location.replace(`/enter-program/${data.id}`);
        // handleStep(saveDraft ? "save_draft" : "");
      },
      error: ({ data }) => {
        setIsSubmitting(false);
        console.log(data);
      },
    });
  };

  useEffect(() => {
    if (program) {
      reset({
        program: { name: program.name, description: program.description },
      });
    }
  }, [program]);

  if (!enterpriseId)
    return <Loader className="tw-my-10" center content="loading" />;

  if (isEditing && !program)
    return <Loader className="tw-my-10" center content="loading" />;

  return (
    <>
      <Form>
        {/* Program Name */}
        <Form.Group>
          <FormInputText
            name="program.name"
            control={control}
            errors={errors}
            label={"Name"}
            placeholder={"Name"}
            max_length={35}
          />
        </Form.Group>

        {/* Program Description */}
        {/* FUT-1241: Change to RichTextInput if required. Need to do max length func as below. */}
        {/* <Form.Group>
          <FormRichTextInput
            name="program.description"
            defaultValue={program?.description || ""}
            control={control}
            errors={errors}
            label={"Program Description"}
            placeholder={"Program Description"}
          />
        </Form.Group> */}
        <Form.Group>
          <FormInputText
            name="program.description"
            control={control}
            errors={errors}
            label={"Description"}
            placeholder={"Description"}
            as="textarea"
            rows={4}
            max_length={300}
          />
        </Form.Group>
        <Divider />

        <div className="tw-w-full tw-flex">
          {/* Program Photo */}
          <Form.Group className="tw-w-1/2">
            <PortalLogoUploaderV2
              control={control}
              errors={errors}
              setValue={setValue}
              name="program.photo"
              imageUrl={program?.logo_url}
            />
          </Form.Group>

          {/* Program Banner */}
          <Form.Group className="tw-w-1/2">
            <PortalBannerUploaderV2
              control={control}
              errors={errors}
              setValue={setValue}
              name="white_label.newsfeed_banner"
              imageUrl={program?.white_label?.newsfeed_banner_url}
            />
          </Form.Group>
        </div>

        {/* Program Primary Color */}
        <Form.Group>
          <Form.ControlLabel>Primary Color</Form.ControlLabel>
          <PortalColorSelect
            control={control}
            errors={errors}
            setValue={setValue}
            name="white_label.dashboard_primary_colour"
          />
        </Form.Group>

        <Divider />

        <h6>Mentorship Pairing Type</h6>
        <p className="montserrat tw-text-sm">
          By default, mentees have the freedom to book any available mentors
          within your portal. Alternatively, you can modify this setting to give
          mentees the choice to select their own mentors, or you can manually
          pair them yourself.
        </p>

        <Form.Group>
          <Controller
            name="program.pairing_method"
            control={control}
            render={({ field }) => (
              <>
                <RadioTileGroup
                  {...field}
                  inline
                  aria-label="Mentorship Pairing type"
                  className="tw-flex tw-w-full tw-justify-between"
                >
                  <RadioTile
                    className="tw-w-1/2"
                    icon={
                      <MdOutlinePeopleAlt
                        className="tw-mx-2"
                        style={{ color: "#696969" }}
                      />
                    }
                    label="Traditional"
                    value={"mentee_picks"}
                  >
                    Mentee picks mentor.
                  </RadioTile>

                  <RadioTile
                    className="tw-w-1/2"
                    icon={
                      <MdOutlineAdminPanelSettings
                        className="tw-mx-2"
                        style={{ color: "#696969" }}
                      />
                    }
                    label="Admin Paired"
                    value={"admin_pairs"}
                  >
                    Participant paired by admins.
                  </RadioTile>
                  {/* <RadioTile
                    className="tw-w-1/3"
                    icon={
                      <MdOutlineCached
                        className="tw-mx-2"
                        style={{ color: "#696969" }}
                      />
                    }
                    label="Reverse"
                    value={"mentor_picks"}
                  >
                    Mentor picks mentee.
                  </RadioTile> */}
                </RadioTileGroup>
                <ErrorMessage
                  errors={errors}
                  name={"program.pairing_method"}
                  as={FieldError}
                />
              </>
            )}
          />
        </Form.Group>

        <BottomNavCTA
          // Second onSubmit argument is SaveDraft
          handleSubmit={handleSubmit((data) => onSubmit(data, false))}
          handleSaveDraft={handleSubmit((data) => onSubmit(data, true))}
          loading={isSubmitting}
        />
      </Form>
    </>
  );
};

export default PortalDesignForm;
