import React, { useEffect } from "react";
import { Form } from "rsuite";
import { FormInputText } from "../../form/default/FormInputs";
import { useUser } from "../../../contexts/user";

const EventQuestionForm = ({ questions, control, errors, reset }) => {
  const { user } = useUser();

  useEffect(() => {
    if (user) {
      reset({
        name: user.full_name,
        email: user.email,
        phone_no: user.phone_no,
      });
    }
  }, [user]);

  return (
    <>
      <Form>
        <Form.Group>
          <FormInputText
            name="name"
            defaultValue={""}
            control={control}
            errors={errors}
            label={"Name"}
            placeholder={"Name"}
          />
        </Form.Group>
        <Form.Group>
          <FormInputText
            name="email"
            defaultValue={""}
            control={control}
            errors={errors}
            label={"Email"}
            placeholder={"Email"}
          />
        </Form.Group>
        <Form.Group>
          <FormInputText
            name="phone_no"
            defaultValue={60123456789}
            control={control}
            errors={errors}
            label={"Phone Number"}
            placeholder={"E.g. 60123456789"}
            type="tel"
          />
        </Form.Group>
        {questions.length > 0 &&
          questions.map((question, index) => (
            <Form.Group key={question.id}>
              <FormInputText
                name={`answers.${index}.event_extra_field_id`}
                defaultValue={question.id}
                control={control}
                errors={errors}
                className="tw-hidden"
              />
              <FormInputText
                name={`answers.${index}.answer`}
                defaultValue={""}
                control={control}
                errors={errors}
                label={question.title}
                placeholder={question.title}
              />
            </Form.Group>
          ))}
      </Form>
    </>
  );
};

export default EventQuestionForm;
