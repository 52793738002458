// React
import React from "react";

// FutureLab
import avatarPlaceholder from "../../../images/avatar_placeholder.png";
import { FutureLabApi } from "../../../util/api";

// UI
import { Button, Col, FlexboxGrid, Modal } from "rsuite";
import { FaRegCalendar, FaRegClock } from "react-icons/fa6";
import { MdOutlineBusiness, MdOutlineBusinessCenter } from "react-icons/md";

export default function SingleUnconcludedBookingModal({
  booking,
  bookingParticipant,
  setBookingModalType,
  open,
  close,
}) {
  const completeBooking = () => {
    FutureLabApi({
      method: "post",
      path: `/bookings/${booking.id}/complete`,
      success: () => {
        setBookingModalType("confirmed");
      },
    });
  };

  return (
    <Modal
      open={open}
      onClose={close}
      backdrop="static"
      className="tw-mt-8 tw-mb-16 tw-w-[340px]"
      size="xs"
    >
      <Modal.Header>
        <Modal.Title className="tw-font-semibold">Overdue Session</Modal.Title>
      </Modal.Header>
      <Modal.Body className="tw-pb-0">
        <FlexboxGrid>
          <FlexboxGrid.Item colspan={24} className="tw-mb-4">
            <p className="tw-text-center montserrat tw-text-[#222222] tw-text-sm tw-font-medium">
              Did you have your session?
            </p>
            <FlexboxGrid.Item as={Col} colspan={9} className="tw-pr-3 tw-pl-0">
              <div>
                <img
                  src={
                    bookingParticipant?.user?.profile_photo_url ||
                    avatarPlaceholder
                  }
                  className="tw-w-36 tw-rounded-xl"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = avatarPlaceholder;
                  }}
                />
              </div>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} colspan={15} className="tw-px-0">
              <h6 className="tw-truncate tw-text-[#222222]">
                {bookingParticipant?.user?.full_name}
              </h6>
              <div className="tw-flex tw-my-2">
                <MdOutlineBusinessCenter
                  size={22}
                  className="tw-mr-2 tw-overflow-visible"
                />
                <span className="tw-line-clamp-2 tw-text-sm tw-font-medium">
                  {bookingParticipant?.user?.info?.job_title}
                </span>
              </div>
              <div className="tw-flex tw-mt-2">
                <MdOutlineBusiness
                  size={22}
                  className="tw-mr-2 tw-overflow-visible"
                />
                <span className="tw-truncate tw-text-sm tw-font-medium">
                  {bookingParticipant?.user?.info?.company}
                </span>
              </div>
            </FlexboxGrid.Item>
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <div>
          <p className="tw-font-medium tw-mb-2 montserrat">
            Session date and time:-
          </p>
          <div className="tw-flex tw-my-3">
            <FaRegCalendar size={20} className="tw-mr-2 tw-overflow-visible" />
            <span className="tw-truncate tw-text-sm tw-font-medium">
              {booking?.booking_date}
            </span>
          </div>
          <div className="tw-flex tw-my-3">
            <FaRegClock size={19} className="tw-mr-2 tw-overflow-visible" />
            <span className="tw-truncate tw-text-sm tw-font-medium">
              {`${booking?.booking_start_time} - ${booking?.booking_end_time}`}
            </span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          appearance="ghost"
          onClick={() => setBookingModalType("cancellation")}
        >
          No
        </Button>
        <Button appearance="primary" onClick={completeBooking}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
