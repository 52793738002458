// React
import React, { useMemo, useEffect, useState, useRef } from "react";
import { useTable } from "react-table";

// FutureLab
import { DataTableFilter } from "./data_table_component/DataTableFilter";
import { ShowHideColumn } from "./data_table_component/ShowHideColumn";
import { CsvButton } from "./data_table_component/DownloadButton";

// UI
import { Pagination, Loader } from "rsuite";

// 3rd Party
import axios from "axios";

type getMenteesProps = {
  page?: number;
  query?: string;
  field?: string;
  date?: {
    date_from: string;
    date_to: string;
  };
};

const getData: (getMenteesProps) => any = async ({
  page = 1,
  query,
  date,
  field,
}) => {
  const currentUrl = new URL(window.location.href);
  return await axios.get(currentUrl.pathname + ".json", {
    params: {
      page: page,
      query: query || "*",
      date_from: date?.date_from,
      date_to: date?.date_to,
      field: field || null,
    },
  });
};

const DataTable = ({
  initialData = [],
  maxPages,
  column,
  title,
  fields = [],
}) => {
  const [dataTable, setDataTable] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(maxPages);
  const columns = useMemo(() => column, [column]);
  const data = useMemo(() => dataTable, [dataTable]);
  const [query, setQuery] = useState(null);
  const [field, setField] = useState(null);
  const [date, setDate] = useState({
    date_from: null,
    date_to: null,
  });
  const el = useRef<any>(null);
  const firstRender = useRef(true);
  const options = useMemo(
    () => [
      { label: "Any", value: null },
      ...fields.map((el) => ({ label: el, value: el })),
    ],
    [fields]
  );

  const tableInstance = useTable({ columns, data });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const handleChangePage = async (curPage = page) => {
    if (curPage < 1 || curPage > maxPage) return;
    try {
      setLoading(true);
      const response = await getData({ page: curPage, query, date, field });
      setDataTable(response.data.data || []);
      setMaxPage((page) => response.data?.max_pages || page);
      setPage(curPage);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onFieldChange = (value) => {
    setField(value);
  };

  useEffect(() => {
    if (firstRender.current) return;
    if (query !== null) {
      handleChangePage(1);
    }
  }, [query, field]);

  useEffect(() => {
    if (firstRender.current) return;
    handleChangePage(1);
  }, [date]);

  const handleKeyDown = (e) => {
    el.current = document.querySelector(".w-vw-100");
    if (!el.current) return;
    if (e.key === "ArrowLeft") {
      el.current.scrollTo({
        left: el.current.scrollLeft - 800,
        behavior: "smooth",
      });
    }
    if (e.key === "ArrowRight") {
      el.current.scrollTo({
        left: el.current.scrollLeft + 800,
        behavior: "smooth",
      });
    }
    if (e.key === "ArrowUp") {
      el.current.scrollTo({
        top: el.current.scrollTop - 800,
        behavior: "smooth",
      });
    }
    if (e.key === "ArrowDown") {
      el.current.scrollTo({
        top: el.current.scrollTop + 800,
        behavior: "smooth",
      });
    }
    //if key shift + arrow left/right trigger change page
    if (e.shiftKey && e.key === "ArrowLeft") {
      handleChangePage(page - 1);
    }
    if (e.shiftKey && e.key === "ArrowRight") {
      handleChangePage(page + 1);
    }
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    }
    //disable body scroll
    document.body.style.overflow = "visible";
    //add listener to sroll horizontally with keyboard
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      //enable body scroll
      document.body.style.overflow = "auto";
      //remove listener
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <table className="mentee-table" {...getTableProps()}>
      {loading && <Loader backdrop center content="loading..." />}
      <thead>
        <tr>
          <th className="top-th">
            <CsvButton title={title} />
          </th>
          <th className="top-th" colSpan={5}>
            <Pagination
              disabled={loading}
              prev
              next
              first
              last
              size="lg"
              total={maxPage}
              limit={100}
              activePage={page}
              onChangePage={(page) => handleChangePage(page)}
              layout={["total", "|", "pager", "skip"]}
              maxButtons={10}
            />
          </th>
          <th className="top-th">
            <DataTableFilter
              options={options}
              date={date}
              setDate={setDate}
              loading={loading}
              query={query}
              setQuery={setQuery}
              onFieldChange={onFieldChange}
            />
          </th>
          <th className="top-th">
            <ShowHideColumn {...tableInstance} column={column} />
          </th>
          <th className="top-th" colSpan={column.length - 10} />
        </tr>
      </thead>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th className="tw-whitespace-nowrap" {...column.getHeaderProps()}>
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td
                    className="tw-whitespace-nowrap tw-pb-5 tw-capitalize"
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default DataTable;
