import React from 'react';
import { Col, Form, Grid, Message, Row, Stack, toaster, Toggle } from 'rsuite';
import { useUser } from '../../contexts/user';
import { FutureLabApi } from '../../util/api';
import translateText from '../../util/translateText';
import PanelContainer from './PanelContainer';
import PreviewSaveButton from './PreviewSaveButton';

const Notifications = (props) => {
  const { user, setUser } = useUser();

  const onSubmit = (value) => {
    FutureLabApi({
      method: 'post',
      path: '/users/current',
      options: { user: { whatsapp_notification: value } },
      success: ({ data }) => {
        setUser(data);
        toaster.push(
          <Message showIcon type="success">
            {translateText(props.convertText, "Successfully saved.")}
          </Message>
        );
      },
      error: (err) => {
        const { error, messages } = err.response.data;
        toaster.push(
          <Message showIcon type="error">
            {error}
          </Message>
        );
      },
    });
  };

  return (
    <div>
      <PreviewSaveButton noSave />
      <PanelContainer>
        <Grid fluid>
          <Row gutter={24}>
            <Col xs={24} md={12}>
              <Form fluid>
                <Form.Group>
                  <Stack justifyContent="space-between">
                    <Form.ControlLabel className="tw-text-base">
                      {translateText(
                        props.convertText,
                        "Whatsapp Notification"
                      )}
                      <Form.HelpText tooltip>
                        {translateText(
                          props.convertText,
                          "Get you mobile number verified to activate this feature"
                        )}
                      </Form.HelpText>
                    </Form.ControlLabel>
                    <Stack spacing={4}>
                      <span>
                        {user.whatsapp_notification ? translateText(
                          props.convertText, "Active"
                        ) : translateText(
                          props.convertText, "Inactive"
                        )}
                      </span>
                      <Toggle
                        checked={user.whatsapp_notification}
                        onChange={onSubmit}
                      />
                    </Stack>
                  </Stack>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Grid>
      </PanelContainer>
    </div>
  );
};

export default Notifications;
