// React
import React from "react";

// UI
import { Form, Input } from "rsuite";

export default function FormCredentials({ currentUser }) {
  return (
    <>
      <Form.Group className="tw-mb-5">
        <Form.ControlLabel className="tw-text-base tw-font-medium tw-text-gray-700 tw-mb-2">
          Email Address
        </Form.ControlLabel>
        <Input name="email" defaultValue={currentUser?.email} readOnly />
      </Form.Group>
      <Form.Group className="tw-inline-flex tw-justify-between tw-w-full xs:tw-block">
        <Form.Group className="tw-w-1/2 tw-pr-3 xs:tw-w-full xs:tw-pr-0 tw-mb-5">
          <Form.ControlLabel className="tw-text-base tw-font-medium tw-text-gray-700 tw-mb-2">
            First Name
          </Form.ControlLabel>
          <Input
            name="firstname"
            defaultValue={currentUser?.firstname}
            readOnly
          />
        </Form.Group>
        <Form.Group className=" tw-pl-3 tw-w-1/2 xs:tw-w-full xs:tw-pl-0 tw-mb-5">
          <Form.ControlLabel className="tw-text-base tw-font-medium tw-text-gray-700 tw-mb-2">
            Last Name
          </Form.ControlLabel>
          <Input
            name="lastname"
            defaultValue={currentUser?.lastname}
            readOnly
          />
        </Form.Group>
      </Form.Group>
    </>
  );
}
