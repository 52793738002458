import React from "react";
import { getCountries } from "../components/enterprise/api";

const useCountries = (cb = (e) => {}) => {
  const [countries, setCountries] = React.useState([]);

  React.useEffect(() => {
    getCountries()
      .then((response) => {
        setCountries(response.data);
        const defaultCountry = response.data.find(
          (country) => country.isocode === "MY"
        );
        cb(defaultCountry.id);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return countries;
};

export default useCountries;
