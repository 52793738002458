// PhotoCropper.tsx
import React, { useRef } from "react";
import { Cropper, CropperRef } from "react-advanced-cropper";
import { Button, Modal } from "rsuite";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../shared/modal/BaseModal";

type ImageFile = {
  blob: Blob;
  name: string;
  type: string;
};

type PhotoCropperProps = {
  image: string;
  aspectRatio?: number;
  onCancel?: () => void;
  onCrop?: (file: ImageFile) => void;
  name: string;
isEditing: boolean;
};

const PhotoCropper: React.FC<PhotoCropperProps> = ({
  image,
  aspectRatio,
  onCancel,
  onCrop,
  name,
  isEditing,
}) => {
  const cropperRef = useRef<CropperRef>(null);

  const setCroppedImage = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    cropperRef.current?.getCanvas().toBlob((blob) => {
      if (blob) {
        onCrop?.({ blob, name, type: "image/png" });
      }
    });
  };

  return (
    <Modal open={isEditing} onClose={onCancel} size="xs">
      <ModalHeader>Edit</ModalHeader>
      <ModalBody>
        <Cropper
          src={image}
          stencilProps={{ aspectRatio }}
          ref={cropperRef}
          className="tw-h-auto tw-w-full"
        />
      </ModalBody>
      <ModalFooter className="tw-pt-4 tw-flex tw-gap-2">
        <Button appearance="ghost" onClick={onCancel} block>
          Cancel
        </Button>
        <Button appearance="primary" onClick={setCroppedImage} block>
          Crop
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PhotoCropper;
