import React, { forwardRef } from "react";

// UI
import ProgramTableEmptyState from "./ProgramTableEmptyState";
import { Avatar, Dropdown, IconButton, Popover, Table, Whisper } from "rsuite";
import { MdOutlineMoreHoriz } from "react-icons/md";

// 3rd Party
import { useNavigate } from "react-router-dom";

const { Column, HeaderCell, Cell } = Table;

interface MenuPopoverProps {
  onClose: () => void;
  right?: number;
  top?: number;
  className?: string;
  rowData: { id: number };
}

const ImageCell = ({ rowData, dataKey, ...props }) => (
  <Cell {...props} style={{ padding: 0 }}>
    <Avatar
      style={{ background: "##428ac9" }}
      alt={`${rowData.name.charAt(0).toUpperCase()}` || ""}
      src={rowData.logo}
    />
  </Cell>
);

const handleEnterProgram = (id: number, param?: string) => {
  if (param === "settings") {
    return (window.location.href = `/enter-program/${id}?settings=true`);
  }

  window.location.href = `/enter-program/${id}`;
};

const MenuPopover = forwardRef<HTMLDivElement, MenuPopoverProps>(
  ({ onClose, right, top, className, rowData }, ref) => {
    const handleSelect = (eventKey) => {
      onClose();

      if (eventKey === 1) {
        handleEnterProgram(rowData.id, "settings");
      } else if (eventKey === 2) {
        handleEnterProgram(rowData.id);
      }
    };

    return (
      <Popover ref={ref} className={className} style={{ right, top }} full>
        <Dropdown.Menu onSelect={handleSelect}>
          <Dropdown.Item eventKey={1}>
            <span className="montserrat">Edit</span>
          </Dropdown.Item>
          <Dropdown.Item eventKey={2}>
            <span className="montserrat">Enter Program</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Popover>
    );
  }
);

const ActionCell = ({ rowData, dataKey, ...props }) => {
  return (
    <Cell {...props} className="link-group">
      <Whisper
        placement="autoVerticalEnd"
        trigger="click"
        speaker={(props, ref) => (
          <MenuPopover {...props} ref={ref} rowData={rowData} />
        )}
      >
        <IconButton appearance="subtle" icon={<MdOutlineMoreHoriz />} />
      </Whisper>
    </Cell>
  );
};

export const EmptyState = () => {
  return (
    <>
      <h6>No programmes available</h6>
      <p className="montserrat tw-text-center">
        It looks like you haven’t created any programs.
        <br /> Start by creating your first program.
      </p>
    </>
  );
};

const DesktopProgramTable = ({ tableData, loading }) => {
  const data = React.useMemo(() => tableData, [tableData]);

  return (
    <Table
      data={data}
      autoHeight
      rowHeight={60}
      className="tw-my-6 tw-min-h-full"
      affixHorizontalScrollbar
      // headerHeight={60}
      // rowClassName="tw-text-sm"
      loading={loading}
      renderEmpty={() => (
        <ProgramTableEmptyState>
          <EmptyState />
        </ProgramTableEmptyState>
      )}
    >
      <Column verticalAlign="middle" align="center" minWidth={100}>
        <HeaderCell> </HeaderCell>
        <ImageCell dataKey={"logo"} rowData={undefined} />
      </Column>
      <Column verticalAlign="middle" align="left" flexGrow={2}>
        <HeaderCell>Program Name</HeaderCell>
        <Cell>
          {(rowData) => (
            <span
              onClick={() => handleEnterProgram(rowData.id)}
              className="tw-text-[#428ac9] tw-cursor-pointer"
            >
              {rowData.name}
            </span>
          )}
        </Cell>
      </Column>
      <Column verticalAlign="middle" align="center" flexGrow={1}>
        <HeaderCell>Pairing Method</HeaderCell>
        <Cell dataKey={"pairing_method"} />
      </Column>
      {/* TODO: Disable temporarily */}

      {/* <Column verticalAlign="middle" align="center" flexGrow={1}>
        <HeaderCell>Created at</HeaderCell>
        <Cell dataKey={"created_at"} />
      </Column>

      <Column verticalAlign="middle" align="center" flexGrow={1}>
        <HeaderCell>Start date</HeaderCell>
        <Cell dataKey={"from_date" || "-"} />
      </Column>
      <Column verticalAlign="middle" align="center" flexGrow={1}>
        <HeaderCell>Invitation type</HeaderCell>
        <Cell dataKey={"invitation_type"} />
      </Column>
      */}
      <Column verticalAlign="middle" align="center" flexGrow={1}>
        <HeaderCell>Total Users</HeaderCell>
        <Cell dataKey={"total_users"} />
      </Column>
      <Column verticalAlign="middle" align="center" flexGrow={1}>
        <HeaderCell>Admins</HeaderCell>
        <Cell dataKey={"admins"} />
      </Column>
      <Column verticalAlign="middle" align="center" flexGrow={1}>
        <HeaderCell>Mentees</HeaderCell>
        <Cell dataKey={"mentees"} />
      </Column>
      <Column verticalAlign="middle" align="center" flexGrow={1}>
        <HeaderCell>Mentors</HeaderCell>
        <Cell dataKey={"mentors"} />
      </Column>
      {/* <Column verticalAlign="middle" align="center" flexGrow={1}>
        <HeaderCell>Status</HeaderCell>
        <Cell dataKey={"status"} />
      </Column> */}
      <Column verticalAlign="middle" align="center" flexGrow={1} fixed="right">
        <HeaderCell> </HeaderCell>
        <ActionCell dataKey={"id"} rowData={undefined} />
      </Column>
    </Table>
  );
};

export default DesktopProgramTable;
