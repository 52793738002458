// React
import React, { useEffect, useState } from "react";

// FutureLab
import RatingOverviewModal from "./mentorship_rating/RatingOverviewModal";
import { PortalApi } from "../../util/portal_api";
import avatarPlaceholder from "../../images/avatar_placeholder.png";

// UI
import { FaFrown, FaMeh, FaSmileBeam } from "react-icons/fa";
import {
  Button,
  Loader,
  Message,
  Modal,
  Popover,
  Table,
  Whisper,
} from "rsuite";

const { Column, HeaderCell, Cell } = Table;

export const UserInfo = ({ user }) => (
  <div>
    <img
      src={user?.profile_photo || avatarPlaceholder}
      style={{ width: 40 }}
      className="tw-rounded-xl tw-mr-2"
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = avatarPlaceholder;
      }}
    />
    <span className="tw-text-sm">{user?.full_name || "Deleted User"}</span>
  </div>
);

export const scoreCalculator = (score) => {
  if (score > 3) {
    return (
      <span
        className="tw-inline-flex tw-items-center"
        style={{ color: "#28A745" }}
      >
        {score}
        <FaSmileBeam className="tw-ml-2" />
      </span>
    );
  } else if (score == 3) {
    return (
      <span
        className="tw-inline-flex tw-items-center"
        style={{ color: "#DB7D37" }}
      >
        {score}
        <FaMeh className="tw-ml-2" />
      </span>
    );
  } else {
    return (
      <span
        className="tw-inline-flex tw-items-center"
        style={{ color: "#DB6057" }}
      >
        {score}
        <FaFrown className="tw-ml-2" />
      </span>
    );
  }
};

const PairingTable = ({ program_id, page }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [group, setGroup] = useState([]);
  const [groupId, setGroupId] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    PortalApi({
      method: "get",
      path: `/mentorship_ratings/main`,
      options: { program_id: program_id },
      success: ({ data }) => {
        setLoading(false);
        setData(data);
      },
    });
  }, []);

  const handleRatingModal = (group) => {
    setOpen(true);
    setGroup(group);
    setGroupId(group.group_id);
  };

  const closeModal = () => {
    setOpen(false);
    setGroupId(null);
  };

  if (!data) return <Loader center content="loading" />;

  return (
    <>
      <Message type="info">
        New Update: This page now tracks overall satisfaction and performance
        between mentors and mentees, based on their feedback.
      </Message>

      <Table
        data={data}
        autoHeight
        rowHeight={66}
        headerHeight={60}
        virtualized
        loading={loading}
      >
        <Column verticalAlign="middle" flexGrow={2}>
          <HeaderCell>Mentee</HeaderCell>
          <Cell>{(rowData) => <UserInfo user={rowData.mentee} />}</Cell>
        </Column>

        <Column verticalAlign="middle" flexGrow={2}>
          <HeaderCell>Mentor</HeaderCell>
          <Cell>{(rowData) => <UserInfo user={rowData.mentor} />}</Cell>
        </Column>

        <Column verticalAlign="middle" flexGrow={2} align="center">
          <HeaderCell>
            <div>Average Rating by Mentee</div>
            <div>(Out of 5)</div>
          </HeaderCell>
          <Cell>
            {(rowData) =>
              rowData.mentee.score ? (
                scoreCalculator(rowData.mentee.score)
              ) : (
                <span className="tw-text-sm">N/A</span>
              )
            }
          </Cell>
        </Column>

        <Column verticalAlign="middle" flexGrow={2} align="center">
          <HeaderCell>
            <div>Average Rating by Mentor</div>
            <div>(Out of 5)</div>
          </HeaderCell>
          <Cell>
            {(rowData) =>
              rowData.mentor.score ? (
                scoreCalculator(rowData.mentor.score)
              ) : (
                <span className="tw-text-sm">N/A</span>
              )
            }
          </Cell>
        </Column>

        <Column
          width={80}
          fixed="right"
          verticalAlign="middle"
          flexGrow={1}
          align="center"
        >
          <HeaderCell>...</HeaderCell>
          <Cell>
            {(rowData) => (
              <a
                onClick={() => handleRatingModal(rowData)}
                className="tw-text-sm"
              >
                View details
              </a>
            )}
          </Cell>
        </Column>
      </Table>
      <RatingOverviewModal
        show={open}
        group={group}
        id={groupId}
        closeModal={closeModal}
      />
    </>
  );
};

export default PairingTable;
