// React
import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";

// FutureLab
import UserProfileHeader from "../shared/UserProfileHeader";
import MenteeProfileBody from "./component/shared/MenteeProfileBody";
import ActionDrawer from "../shared/ActionDrawer";
import MobileFooterButton from "../shared/MobileFooterButton";
import { PlatformType } from "../../../contexts/mentor_profile";

export default function MenteeProfileContent({ convertText, currentUser }) {
  const { mentee, type } = useOutletContext<any>();
  const [openBookingDrawer, setOpenBookingDrawer] = useState<boolean>(false);

  return (
    <>
      <UserProfileHeader
        user={mentee}
        convertText={convertText}
        currentUser={currentUser}
        isLoggedIn={!!currentUser || null}
        type={type}
      />
      <MenteeProfileBody convertText={convertText} currentUser={currentUser} />
      {type === PlatformType.Portal && currentUser?.id !== mentee?.id && (
        <div className="tw-hidden sm:tw-block">
          <MobileFooterButton
            convertText={convertText}
            setOpenBookingDrawer={setOpenBookingDrawer}
            user={mentee}
          />
        </div>
      )}
      {openBookingDrawer && (
        <ActionDrawer
          convertText={convertText}
          setOpenBookingDrawer={setOpenBookingDrawer}
          openBookingDrawer={openBookingDrawer}
          user={mentee}
        />
      )}
    </>
  );
}
