import React, { useEffect, useState } from "react";
import { Button, CheckPicker, Form } from "rsuite";
import { FutureLabApi } from "../util/api";

const DiscoverSearchBox = (props) => {
  const { skillIds, industryIds } = props;
  const [industries, setIndustries] = useState([]);
  const [skills, setSkills] = useState([]);
  const [searchIndustries, setSearchIndustries] = useState("");
  const [searchSkill, setSearchSkill] = useState("");
  const [loading, setLoading] = React.useState(false);
  const [industryValues, setIndustryValues] = useState([]);
  const [skillValues, setSkillValues] = useState([]);

  useEffect(() => {
    FutureLabApi({
      method: "get",
      path: "/industries",
      success: ({ data }) => {
        setIndustries(data);
        if (industryIds?.length > 0) {
          setIndustryValues(industryIds);
        }
      },
    });

    FutureLabApi({
      method: "get",
      path: "/skills",
      success: ({ data }) => {
        setSkills(data);
        if (skillIds?.length > 0) {
          setSkillValues(skillIds);
        }
      },
    });
  }, []);

  const returnData = (dataSet, targetSetKeys, searchKey) => {
    // apply toUpperCase method in search string and filter dataSet to perform case insensitive comparison
    const UpperCasedSearchKey = searchKey.toUpperCase();
    const dataSetListForRendering = dataSet
      .filter((ele) => ele.name.toUpperCase().includes(UpperCasedSearchKey))
      .slice(0, 100);

    const targetKeysToAppend = targetSetKeys.map((ele) =>
      dataSet.find((dataSetItem) => dataSetItem.id === ele)
    );

    targetKeysToAppend.map((ele) => {
      if (dataSetListForRendering.indexOf(ele) === -1) {
        dataSetListForRendering.push(ele);
      }
    });

    return dataSetListForRendering;
  };

  const onSubmit = () => {
    function queryString(string) {
      let newString = encodeURI(string);
      return `${newString}`;
    }

    const skillQuery = skillValues.map(queryString);
    const indQuery = industryValues.map(queryString);

    window.location.href = `/main/discover/result?ind=${indQuery}&ski=${skillQuery}`;
  };

  return (
    <Form layout="inline" className="discover-form tw-w-full tw-mb-6">
      <CheckPicker
        className="discover-search-input tw-mr-2"
        sticky
        size="lg"
        data={returnData(industries, industryValues, searchIndustries)}
        labelKey="name"
        valueKey="id"
        value={industryValues}
        defaultValue={industryValues}
        menuStyle={{ maxWidth: 500 }}
        onSearch={setSearchIndustries}
        onChange={setIndustryValues}
        placeholder={"Industry"}
        renderMenu={(menu) => {
          if (loading) {
            return (
              <p style={{ padding: 4, color: "#999", textAlign: "center" }}>
                Loading...
              </p>
            );
          }
          return menu;
        }}
      />
      <CheckPicker
        className="discover-search-input tw-mr-2"
        sticky
        size="lg"
        data={returnData(skills, skillValues, searchSkill)}
        labelKey="name"
        valueKey="id"
        value={skillValues}
        defaultValue={skillValues}
        onSearch={setSearchSkill}
        onChange={setSkillValues}
        placeholder={"Skill"}
        renderMenu={(menu) => {
          if (loading) {
            return (
              <p style={{ padding: 4, color: "#999", textAlign: "center" }}>
                Loading...
              </p>
            );
          }
          return menu;
        }}
      />
      <Button
        className="discover-button tw-mr-0"
        appearance="primary"
        size="lg"
        onClick={onSubmit}
      >
        Search
      </Button>
    </Form>
  );
};

export default DiscoverSearchBox;
