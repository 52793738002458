import React from "react";
import { UserProvider } from "../contexts/user";
import EventCreateComponent from "./event/new/EventCreateComponent";
import EventFormProvider from "./event/new/EventFormProvider";

const EventNew = ({ eventId }) => {
  return (
    <UserProvider>
      <EventFormProvider>
        <EventCreateComponent eventId={eventId} />
      </EventFormProvider>
    </UserProvider>
  );
};

export default EventNew;
