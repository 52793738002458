import React from "react";
import { UserProvider } from "../contexts/user";
import { VariablesProvider } from "../contexts/variables";
import AppContainer from "./AppContainer";
import EditMentorProfileContent from "./edit_mentor_profile/EditMentorProfileContent";

const EditMentorProfile = ({ variables }) => {
  return (
    <VariablesProvider variables={variables}>
      <UserProvider>
        <AppContainer>
          <EditMentorProfileContent convertText={variables.convertText} />
        </AppContainer>
      </UserProvider>
    </VariablesProvider>
  );
};

export default EditMentorProfile;
