// React
import React from "react";

// FutureLab
import avatarPlaceholder from "../../../images/avatar_placeholder.png";
import defaultBg from "../../../images/mentor_profile_default_bg.png";
import SmartText from "../../ui/SmartText";
import translateText from "../../../util/translateText";
import ShareAndFollowUserButton from "../mentor/component/futurelab/ShareAndFollowUserButton";
import { PlatformType, UserType } from "../../../contexts/mentor_profile";

// UI
import { Col, FlexboxGrid } from "rsuite";
import { FaStar } from "react-icons/fa";
import {
  MdOutlineBusiness,
  MdOutlineBusinessCenter,
  MdOutlineVerified,
} from "react-icons/md";

// 3rd Party
import { DateTime, Interval } from "luxon";
import * as humanizeDuration from "humanize-duration";

export default function UserProfileHeader({
  user,
  convertText,
  currentUser,
  isLoggedIn,
  type,
}) {
  return (
    <>
      <div className="tw-absolute tw-h-56 tw-w-full">
        <img src={user.banner?.url || defaultBg} className="tw-h-56 tw-w-full" />
      </div>
      <FlexboxGrid justify="center" className="tw-mt-20">
        <FlexboxGrid.Item as={Col} colspan={22} xs={22} md={20} lg={18}>
          <div className="tw-flex sm:tw-block">
            <div className="tw-mr-3 tw-w-60 sm:tw-w-full sm:tw-text-center sm:tw-mb-4">
              <img
                src={user.profile_photo?.url || avatarPlaceholder}
                className="tw-w-full sm:tw-w-60 tw-rounded-xl"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = avatarPlaceholder;
                }}
              />
            </div>

            <div
              className="tw-rounded-xl tw-border-x tw-border-y tw-border-solid tw-border-gray-200 tw-px-5 tw-py-3"
              style={{ flex: 3, background: "#fefefe" }}
            >
              <div className="tw-mb-4 tw-relative tw-flex tw-justify-between">
                <div className="tw-flex tw-gap-2 tw-items-center tw-flex-row sm:tw-flex-col sm:tw-items-start sm:tw-justify-center">
                  <h1 className="tw-text-3xl sm:tw-text-xl tw-font-bold p-0 m-0">
                    {user.firstname} {user.lastname}
                  </h1>
                  <div>
                    {user?.fl_average_rating >= 4 && (
                      <span
                        className="tw-text-2xl tw-font-bold tw-ml-4 sm:tw-ml-0 sm:tw-text-base"
                        style={{ color: "#1675E0" }}
                      >
                        <FaStar className="tw-text-2xl tw-mb-1 sm:tw-text-base" />{" "}
                        {user.fl_average_rating.toFixed(1)}
                      </span>
                    )}
                  </div>
                </div>

                {user.user_type === UserType.Mentor &&
                  type === PlatformType.FutureLab && (
                    <ShareAndFollowUserButton
                    user={user}
                    currentUser={currentUser}
                      convertText={convertText}
                      isLoggedIn={isLoggedIn}
                    />
                  )}
              </div>
              <div className="tw-mb-6">
                <div className="tw-flex tw-mb-2">
                  <MdOutlineBusinessCenter size={22} className="tw-mr-2 tw-overflow-visible" />

                  <span className="tw-text-base sm:tw-text-sm  tw-font-medium">
                    {user?.info?.job_title}
                  </span>
                </div>
                <div className="tw-flex tw-mb-2">
                  <MdOutlineBusiness size={22} className="tw-mr-2 tw-overflow-visible" />

                  <span className="tw-text-base sm:tw-text-sm tw-font-medium">
                    {user.info?.company}
                  </span>
                </div>
                {user.user_type === UserType.Mentor && (
                  <div className="tw-flex">
                    <MdOutlineVerified size={22} className="tw-mr-2 tw-overflow-visible" />

                    <span className="tw-text-base sm:tw-text-sm  tw-font-medium">
                      {translateText(convertText, "Mentor since ")}
                      {DateTime.fromISO(user.created_at).year} (
                      {humanizeDuration(
                        Interval.fromDateTimes(
                          DateTime.fromISO(user.created_at),
                          DateTime.local()
                        )
                          .toDuration()
                          .valueOf(),
                        { largest: 2, round: true }
                      )}
                      )
                    </span>
                  </div>
                )}
              </div>
              <SmartText
                text={user.profile_intro}
                length={300}
                convertText={convertText}
              />
            </div>
          </div>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
}
