import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { Button, Divider, Modal, Steps } from 'rsuite';
import bannerGuide from '../../images/banner_guide.png';

const BannerGuideModal = ({ open, handleClose }) => {
  return (
    <Modal open={open} onClose={handleClose} className="tw-mt-16" size="xs">
      <Modal.Header>
        <Modal.Title className="tw-text-xl tw-font-medium">
          Banner image guide
        </Modal.Title>
        <Divider className="tw-absolute tw-w-full tw-left-0 tw-mt-4 tw-bg-gray-300" />
      </Modal.Header>
      <Modal.Body>
        <div className="tw-text-center tw-py-7">
          <img src={bannerGuide} alt="" className="tw-w-72" />
          <div className="tw-text-green-600 tw-italic tw-mt-4 tw-text-xs">
            <FaCheckCircle className="tw-mr-2" />
            Perfect!
          </div>
        </div>
        <div>
          <h6>QUICK TIPS!</h6>
          <ul>
            <li>Recommended resolution: 1440 x 230</li>
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} appearance="primary" block>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BannerGuideModal;
