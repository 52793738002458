// React
import React from "react";

// UI
import { ButtonToolbar, IconButton, Tooltip, Whisper } from "rsuite";
import {
  MdPersonRemove,
  MdChatBubbleOutline,
  MdModeEditOutline,
} from "react-icons/md";

export default function ActionButtons({
  rowData,
  portalUrl,
  program_id,
  handleRemoveUser,
}) {
  return (
    <ButtonToolbar className="tw-justify-center tw-flex">
      <Whisper
        placement="top"
        speaker={<Tooltip className="tw-text-center">Chat</Tooltip>}
      >
        <IconButton
          icon={<MdChatBubbleOutline size={23} />}
          href={rowData.chat_link}
          target="_blank"
          circle
          appearance="link"
        />
      </Whisper>
      <Whisper
        placement="top"
        speaker={<Tooltip className="tw-text-center">Edit</Tooltip>}
      >
        <IconButton
          icon={<MdModeEditOutline size={23} />}
          circle
          href={`${portalUrl}users/${rowData.id}/edit?program_edit=${program_id}`}
          target="_blank"
          appearance="link"
        />
      </Whisper>
      <Whisper
        placement="top"
        speaker={<Tooltip className="tw-text-center">Remove</Tooltip>}
      >
        <IconButton
          icon={<MdPersonRemove size={25} color="F44336" />}
          circle
          onClick={() => handleRemoveUser(rowData)}
          appearance="link"
        />
      </Whisper>
    </ButtonToolbar>
  );
}
