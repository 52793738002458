// React
import React, { useState } from "react";

// FutureLab
import translateText from "../../util/translateText";

const SmartText = ({
  text,
  length = 100,
  showReadMore = true,
  convertText,
}) => {
  const [showLess, setShowLess] = useState(true);

  if (!text) return null;

  if (text.length < length) {
    return (
      <p className="montserrat tw-mb-0 sm:tw-text-sm text-muted">{text}</p>
    );
  }

  return (
    <div>
      <p className="tw-mb-0 montserrat sm:tw-text-sm text-muted">
        {showLess ? `${text.slice(0, length)}...` : text}{" "}
        {showReadMore && (
          <a
            style={{ color: "#1675E0" }}
            className="tw-underline"
            onClick={() => setShowLess(!showLess)}
          >
            &nbsp;{translateText(convertText, "Read")}{" "}
            {showLess
              ? translateText(convertText, "more")
              : translateText(convertText, "less")}
          </a>
        )}
      </p>
    </div>
  );
};

export default SmartText;
