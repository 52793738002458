// React
import React from "react";

// FutureLab
import {
  BooleanCell,
  NumberCell,
  TimeCell,
} from "./data_table_component/DataTableCellType";
import DataTable from "./DataTable";

const programmeEventColumns = [
  {
    Header: "Attendee ID",
    accessor: "attendeeId",
    Cell: NumberCell,
  },
  {
    Header: "First Name",
    accessor: "attendeeFirstName",
  },
  {
    Header: "Last Name",
    accessor: "attendeeLastName",
  },
  {
    Header: "Email Address",
    accessor: "attendeeEmail",
  },
  {
    Header: "Enterprise Programme Name",
    accessor: "enterpriseName",
  },
  {
    Header: "Programme Name",
    accessor: "programmeName",
  },
  {
    Header: "Programme User Type",
    accessor: "programmeAttendeeUserType",
  },
  {
    Header: "Event ID",
    accessor: "eventId",
    Cell: NumberCell,
  },
  {
    Header: "Event Name",
    accessor: "eventName",
  },
  {
    Header: "Event Ticket Name",
    accessor: "eventTicketName",
  },
  {
    Header: "Ticket Quantity Purchased",
    accessor: "ticketQuantityPurchased",
    Cell: NumberCell,
  },
  {
    Header: "Date of Purchase",
    accessor: "dateOfPurchased",
    Cell: TimeCell
  },
  {
    Header: "Ticket Price (RM)",
    accessor: "ticketPrice",
    Cell: NumberCell,
  },
  {
    Header: "Total Payment (RM)",
    accessor: "totalPurchased",
    Cell: NumberCell,
  },
  {
    Header: "Service Tax",
    accessor: "serviceTax",
    Cell: NumberCell,
  },
  {
    Header: "First Time Buyer?",
    accessor: "firstTimeBuyer",
    Cell: BooleanCell
  },
];

const ProgrammeEventData = ({ programmeEvent = [], maxPages, fields }) => {
  
  return (
    <DataTable
      initialData={programmeEvent}
      maxPages={maxPages}
      title="Programme Event Data"
      column={programmeEventColumns as any}
      fields={fields}
    />
  );
};
export default ProgrammeEventData;
