import React from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Col,
  Form,
  Grid,
  Input,
  InputGroup,
  Message,
  Row,
  Stack,
  toaster,
} from "rsuite";
import UserBannerUploader from "../form/UserBannerUploader";
import UserProfilePhotoUploader from "../form/UserProfilePhotoUploader";
import * as yup from "yup";
import { ErrorMessage } from "@hookform/error-message";
import FieldError from "../ui/FieldError";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUser } from "../../contexts/user";
import { FutureLabApi } from "../../util/api";
import instagramIcon from "../../images/instagram_icon.png";
import linkedinIcon from "../../images/linkedin_icon.png";
import WorkExperiences from "../form/WorkExperiences";
import Educations from "../form/Educations";
import PreviewSaveButton from "./PreviewSaveButton";
import PanelContainer from "./PanelContainer";
import translateText from "../../util/translateText";
import Departments from "../form/Departments";
import Certificates from "../form/Certificates";

type UserInput = {
  user: {
    profile_intro: string;
  };
  info: {
    linkedin_url: string;
    instagram_url: string;
    // department: Array<string>;
    professional_certificate: Array<string>;
    others_certificate_detail: string;
    others_department_detail: string;
  };
};

const schema = yup.object().shape({
  user: yup.object().shape({
    profile_intro: yup.string().required().label("About me"),
  }),
  info: yup.object().shape({
    linkedin_url: yup.string().label("LinkedIn URL"),
    instagram_url: yup.string().label("Instagram URL"),
    // department: yup.string().required().label("Departments").nullable(),
    professional_certificate: yup
      .array()
      .label("Professional certificate")
      .nullable(),
    others_certificate_detail: yup.string().label("Professional Certificate"),
    others_department_detail: yup.string().label("Department"),
  }),
});
const ProfileDetails = (props) => {
  const { user, setUser } = useUser();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm<UserInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      user: {
        profile_intro: user?.profile_intro || "",
      },
      info: {
        linkedin_url: "",
        instagram_url: "",
        // department: [""],
        professional_certificate: [""],
        others_certificate_detail: "",
        others_department_detail: "",
      },
    },
  });
  // const maxLength = 300;
  // const [inputLength, setInputLength] = React.useState(0);

  const watchProfessionalCertificate = watch(
    "info.professional_certificate",
    []
  );

  // const watchDepartment = watch("info.department", []);

  React.useEffect(() => {
    if (user) {
      reset({
        user: {
          profile_intro: user.profile_intro || "",
        },
        info: {
          linkedin_url: user.info.linkedin_url?.replace(
            "https://www.linkedin.com/in/",
            ""
          ),
          instagram_url: user.info.instagram_url?.replace(
            "https://www.instagram.com/",
            ""
          ),
          // department: user.info.department,
          professional_certificate: user.info?.professional_certificate,
          others_certificate_detail: user.info?.others_certificate_detail || "",
          others_department_detail: user.info?.others_department_detail || "",
        },
      });

      // setInputLength(user.profile_intro?.length || 0);
    }
  }, [user]);

  const onSubmit = (data) => {
    setIsSubmitting(true);
    if (data.info.linkedin_url)
      data.info.linkedin_url = `https://www.linkedin.com/in/${data.info.linkedin_url}`;
    if (data.info.instagram_url)
      data.info.instagram_url = `https://www.instagram.com/${data.info.instagram_url}`;

    FutureLabApi({
      method: "post",
      path: "/users/current",
      options: data,
      success: ({ data }) => {
        setUser(data);
        setIsSubmitting(false);
        toaster.push(
          <Message showIcon type="success">
            {translateText(props.convertText, "Successfully saved.")}
          </Message>
        );
      },
      error: (err) => {
        const { error, messages } = err.response.data;
        setIsSubmitting(false);
        toaster.push(
          <Message showIcon type="error">
            {error}
          </Message>
        );
      },
    });
  };

  return (
    <div>
      <PreviewSaveButton
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
      />
      <PanelContainer>
        <Grid fluid>
          <Row gutter={24}>
            <Col xs={24} md={10}>
              <Form fluid>
                <Form.Group className="tw-mb-6">
                  <Form.ControlLabel className="tw-text-base">
                    {translateText(props.convertText, "Profile image")}
                    <span className="tw-text-red-500">*</span>
                  </Form.ControlLabel>
                  <UserProfilePhotoUploader convertText={props.convertText} />
                </Form.Group>
              </Form>
            </Col>
            <Col xs={24} md={14}>
              <Form fluid>
                <Form.Group>
                  <Form.ControlLabel className="tw-text-base">
                    {translateText(props.convertText, "Banner image")}
                  </Form.ControlLabel>
                  <UserBannerUploader convertText={props.convertText} />
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24}>
              <Form fluid className="tw-mt-7">
                <Form.Group>
                  <Form.ControlLabel className="tw-text-base">
                    {translateText(props.convertText, "About me")}
                    <span className="tw-text-red-500">*</span>
                  </Form.ControlLabel>
                  <Controller
                    name="user.profile_intro"
                    control={control}
                    render={({ field }) => (
                      <Input
                        rows={3}
                        as="textarea"
                        size="lg"
                        // maxLength={maxLength}
                        {...field}
                        onChange={(value) => {
                          // setInputLength(value.length);
                          setValue(field.name, value);
                        }}
                      />
                    )}
                  />
                  {/* <span
                    className={`${
                      inputLength > 300
                        ? 'tw-text-red-500'
                        : 'tw-text-green-600'
                    } tw-my-1 tw-text-right tw-float-right`}
                  >
                    {maxLength - inputLength}/300
                  </span> */}
                  <ErrorMessage
                    errors={errors}
                    name="user.profile_intro"
                    as={FieldError}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel className="tw-text-base">
                    {translateText(props.convertText, "Social media")}
                  </Form.ControlLabel>
                  <Stack spacing={8}>
                    <img src={linkedinIcon} alt="" />
                    <InputGroup size="lg" className="tw-w-full">
                      <InputGroup.Addon>www.linkedin.com/</InputGroup.Addon>
                      <Controller
                        name="info.linkedin_url"
                        control={control}
                        render={({ field }) => <Input {...field} />}
                      />
                    </InputGroup>
                  </Stack>
                  <ErrorMessage
                    errors={errors}
                    name="info.linkedin_url"
                    as={FieldError}
                  />
                </Form.Group>
                <Form.Group>
                  <Stack spacing={8}>
                    <img src={instagramIcon} alt="" />
                    <InputGroup size="lg" className="tw-w-full">
                      <InputGroup.Addon>www.instagram.com/</InputGroup.Addon>
                      <Controller
                        name="info.instagram_url"
                        control={control}
                        render={({ field }) => <Input {...field} />}
                      />
                    </InputGroup>
                  </Stack>
                  <ErrorMessage
                    errors={errors}
                    name="info.instagram_url"
                    as={FieldError}
                  />
                </Form.Group>
                <WorkExperiences user={user} />
                <Educations user={user} />
                {/* <Departments
                  convertText={props.convertText}
                  control={control}
                  func={setValue}
                  errors={errors}
                  watch={watchDepartment}
                  user={user}
                /> */}
                <Certificates
                  convertText={props.convertText}
                  control={control}
                  func={setValue}
                  errors={errors}
                  watch={watchProfessionalCertificate}
                />
              </Form>
            </Col>
          </Row>
        </Grid>
      </PanelContainer>
    </div>
  );
};

export default ProfileDetails;
