import React from "react";

import NoProgramme from "../../../../images/no_programme.png";

const ProgramTableEmptyState = ({ children }) => {
  return (
    <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full">
      {children}
    </div>
  );
};

export default ProgramTableEmptyState;
