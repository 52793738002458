type HandleFollowToggleProps = {
  userId: string;
  isFollowing: boolean;
  setUsers: React.Dispatch<React.SetStateAction<any>>;
};

export const handleFollowToggle = ({
  userId,
  isFollowing,
  setUsers,
}: HandleFollowToggleProps) => {
  setUsers((prevUsers) =>
    prevUsers.map((user) =>
      user.id === userId ? { ...user, is_following: isFollowing } : user
    )
  );
};