// React
import React from "react";

// FutureLab
import avatarPlaceholder from "../../../../images/avatar_placeholder.png";

// UI
import { Nav } from "rsuite";

function Avatar({ src }) {
  const handleError = (event) => {
    event.target.onerror = null;
    event.target.src = avatarPlaceholder;
  };

  return (
    <img
      src={src || avatarPlaceholder}
      style={{ width: 40 }}
      className="tw-rounded-xl tw-mr-2"
      onError={handleError}
      alt=""
    />
  );
}

function UserOption({ user, index, setOpenRating, openRating }) {
  return (
    <Nav.Item
      key={index}
      eventKey={`${index}`}
      onSelect={() => setOpenRating(!openRating)}
    >
      <div className="tw-my-2">
        <Avatar src={user?.profile_photo} />
        {user?.full_name || "Deleted User"}
      </div>
    </Nav.Item>
  );
}

export function GroupModalOption(list, setOpenRating, openRating, data) {
  const isGroupRating = data[0]?.is_group_rating;

  if (isGroupRating) {
    return (
      <Nav.Item
        key={0}
        eventKey={`${0}`}
        onSelect={() => setOpenRating(!openRating)}
      >
        <div className="tw-my-2">
          <Avatar src={undefined} />
          Group Rating
        </div>
      </Nav.Item>
    );
  } else {
    return list.map((user, index) => (
      <UserOption
        key={index}
        user={user}
        index={index}
        setOpenRating={setOpenRating}
        openRating={openRating}
      />
    ));
  }
}
