import React from 'react';
import { FaCircle } from 'react-icons/fa';
import { Nav } from 'rsuite';
import { useUser } from '../../contexts/user';

const MobileNav = ({ activeKey, setActiveKey }) => {
  const { user } = useUser();

  return (
    <Nav
      activeKey={activeKey}
      onSelect={setActiveKey}
      className="tw-overflow-x-scroll no-scrollbar"
    >
      <Nav.Item eventKey="1" className="tw-p-2">
        Personal Details{' '}
        {!user.mentor_profile_status?.['personal_details'] && (
          <FaCircle
            className="tw-text-red-600 tw-ml-4"
            style={{ fontSize: '8px' }}
          />
        )}
      </Nav.Item>
      <Nav.Item eventKey="2" className="tw-p-2">
        Profile Details
        {!user.mentor_profile_status?.['profile_details'] && (
          <FaCircle
            className="tw-text-red-600 tw-ml-4"
            style={{ fontSize: '8px' }}
          />
        )}
      </Nav.Item>
      <Nav.Item eventKey="3" className="tw-p-2">
        Mentorship
        {!user.mentor_profile_status?.['mentorship'] && (
          <FaCircle
            className="tw-text-red-600 tw-ml-4"
            style={{ fontSize: '8px' }}
          />
        )}
      </Nav.Item>
      <Nav.Item eventKey="4" className="tw-p-2">
        Account & Security
      </Nav.Item>
      <Nav.Item eventKey="5" className="tw-p-2">
        Notifications
      </Nav.Item>
      <Nav.Item eventKey="6" className="tw-p-2">
        Availability
        {!user.mentor_profile_status?.['availability'] && (
          <FaCircle
            className="tw-text-red-600 tw-ml-4"
            style={{ fontSize: '8px' }}
          />
        )}
      </Nav.Item>
    </Nav>
  );
};

export default MobileNav;
