import React from "react";
import { Modal } from "rsuite";

const DashboardBannerModal = ({ openBannerModal, setOpenBannerModal }) => {
  const source = "https://www.youtube.com/embed/8hAcZCDHOeo";
  const url =
    openBannerModal == true ? `${source + "?autoplay=1"}` : `${source}`;

  const handleClose = () => setOpenBannerModal(false);

  return (
    <Modal
      size={'md'}
      backdrop={"static"}
      open={openBannerModal}
      onClose={handleClose}
    >
      <Modal.Header>
        <Modal.Title>Get Started to FutureLab | Tutorial Video</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <iframe
          className="tw-w-full"
          // width="300"
          height="400"
          src={url}
          title="Get Started to FutureLab | Tutorial Video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Modal.Body>
    </Modal>
  );
};

export default DashboardBannerModal;