// React
import React from "react";

// FutureLab
import CustomNavHeader from "./CustomNavHeader";
import { useNavbarContext } from "../../../contexts/navbarContext";

// UI
import { Sidebar, Sidenav } from "rsuite";

const SideNav = ({ children, program = null, currentOpen, handleOpen }) => {
  const { isExpanded, toggleNavbar } = useNavbarContext();

  return (
    <Sidebar
      className="tw-fixed tw-flex tw-z-10 tw-flex-col tw-h-screen"
      style={{
        borderRight: "1px solid #E5E5E5",
      }}
      width={isExpanded ? 260 : 56}
      collapsible
    >
      <Sidenav
        expanded={isExpanded}
        openKeys={currentOpen}
        onOpenChange={(openKeys) => handleOpen(openKeys)}
        appearance="subtle"
        className="tw-flex tw-flex-col tw-h-screen"
      >
        <Sidenav.Header
          style={{ height: "56px" }}
          className="tw-flex tw-items-center tw-justify-start"
        >
          <CustomNavHeader isExpanded={isExpanded} program={program} />
        </Sidenav.Header>
        <Sidenav.Body
          className={`tw-py-5 ${
            isExpanded && "no-scrollbar tw-overflow-y-auto"
          }`}
        >
          {children}
        </Sidenav.Body>
        <Sidenav.Toggle onToggle={toggleNavbar} className="tw-shrink-0" />
      </Sidenav>
    </Sidebar>
  );
};

export default SideNav;
