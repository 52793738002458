import React from "react";
import { FaCircle } from "react-icons/fa";
import { Col, FlexboxGrid, Loader, Nav, Sidenav } from "rsuite";
import { useUser } from "../../contexts/user";
import AccountSecurity from "./AccountSecurity";
import Availability from "./Availability";
import Mentorship from "./Mentorship";
import MobileNav from "./MobileNav";
import Notifications from "./Notifications";
import PersonalDetails from "./PersonalDetails";
import ProfileDetails from "./ProfileDetails";
import translateText from "../../util/translateText";

const forms = (activeKey, convertText) => {
  return {
    1: <PersonalDetails convertText={convertText} />,
    2: <ProfileDetails convertText={convertText} />,
    3: <Mentorship convertText={convertText} />,
    4: <AccountSecurity convertText={convertText} />,
    5: <Notifications convertText={convertText} />,
    6: <Availability convertText={convertText} />,
  }[activeKey];
};

const EditMentorProfileContent = (props) => {
  const { user } = useUser();
  const [activeKey, setActiveKey] = React.useState("1");

  if (!user) return <Loader center content="loading" />;

  return (
    <FlexboxGrid justify="center" className="tw-mt-0 tw-mb-8 sm:tw-mb-16">
      <FlexboxGrid.Item
        as={Col}
        colspan={22}
        xs={5}
        lg={4}
        className="sm:tw-hidden"
      >
        <div className="tw-text-xl tw-font-medium tw-pl-4 tw-mb-10">
          {translateText(props.convertText, "Edit Profile")}
        </div>
        <Sidenav appearance="subtle">
          <Sidenav.Body>
            <Nav activeKey={activeKey} onSelect={setActiveKey}>
              <Nav.Item eventKey="1" className="tw-pl-4 tw-py-3">
                {translateText(props.convertText, "Personal Details")}
                {!user.mentor_profile_status?.["personal_details"] && (
                  <FaCircle
                    className="tw-text-red-600 tw-ml-4"
                    style={{ fontSize: "8px" }}
                  />
                )}
              </Nav.Item>
              <Nav.Item eventKey="2" className="tw-pl-4 tw-py-3">
                {translateText(props.convertText, "Profile Details")}
                {!user.mentor_profile_status?.["profile_details"] && (
                  <FaCircle
                    className="tw-text-red-600 tw-ml-4"
                    style={{ fontSize: "8px" }}
                  />
                )}
              </Nav.Item>
              <Nav.Item eventKey="3" className="tw-pl-4 tw-py-3">
                {translateText(props.convertText, "Mentorship")}
                {!user.mentor_profile_status?.["mentorship"] && (
                  <FaCircle
                    className="tw-text-red-600 tw-ml-4"
                    style={{ fontSize: "8px" }}
                  />
                )}
              </Nav.Item>
              <Nav.Item eventKey="4" className="tw-pl-4 tw-py-3">
                {translateText(props.convertText, "Account and Security")}
              </Nav.Item>
              <Nav.Item eventKey="5" className="tw-pl-4 tw-py-3">
                {translateText(props.convertText, "Notifications")}
              </Nav.Item>
              <Nav.Item eventKey="6" className="tw-pl-4 tw-py-3">
                {translateText(props.convertText, "Availability")}
                {!user.mentor_profile_status?.["availability"] && (
                  <FaCircle
                    className="tw-text-red-600 tw-ml-4"
                    style={{ fontSize: "8px" }}
                  />
                )}
              </Nav.Item>
            </Nav>
          </Sidenav.Body>
        </Sidenav>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} colspan={22} xs={22} md={17} lg={16}>
        <div className="tw-hidden sm:tw-block tw-absolute tw-top-[44px] tw-left-0 tw-w-full">
          <MobileNav activeKey={activeKey} setActiveKey={setActiveKey} />
        </div>
        <div>{forms(activeKey, props.convertText)}</div>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

export default EditMentorProfileContent;
