import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import {
  Registration,
  Login,
  Success,
  Dashboard,
  DashboardLayout,
  ForgotPassword,
  ResetPassword,
  ConfirmationWarning,
} from "./enterprise";
import {
  EnterpriseProvider,
  useEnterprise,
} from "../contexts/enterpriseContext";
import PortalSetup from "./PortalSetup";
import { NavbarProvider } from "contexts/navbarContext";
import { OnboardingProvider } from "contexts/onboardingContext";

const EnterpriseSetup = () => {
  return (
    <Router>
      <EnterpriseProvider>
        <NavbarProvider>
          <OnboardingProvider>
            <Routes>
              <Route element={<PublicAccess />}>
                <Route path="/register" element={<Registration />} />
                <Route path="/login" element={<Login />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="*" element={<Navigate to="/login" />} />
              </Route>
              {/* <Route path="/confirm_success" element={<Success />} /> */}
              <Route element={<PrivateAccess />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/create" element={<PortalSetup />} />
                <Route
                  path="/edit/:programId"
                  element={<PortalSetup editMode={true} />}
                />
              </Route>
            </Routes>
          </OnboardingProvider>
        </NavbarProvider>
      </EnterpriseProvider>
    </Router>
  );
};

const PublicAccess = () => {
  const { pathname, search } = useLocation();
  const {
    state: { jwt_token },
  } = useEnterprise();

  const isResetPassword = pathname.includes("reset-password");

  //check if url contains double slash and reset password

  if (isResetPassword && pathname.includes("//")) {
    return (
      <Navigate
        replace
        to={{
          pathname: "/reset-password",
          search: search,
        }}
      />
    );
  }

  if (jwt_token && !isResetPassword) {
    return <Navigate to="/dashboard" />;
  }

  return <Outlet />;
};

const PrivateAccess = () => {
  const {
    state: { jwt_token },
  } = useEnterprise();

  if (!jwt_token) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      {/* TODO: need to consider this height  */}
      {/* <ConfirmationWarning /> */}
      <DashboardLayout>
        <Outlet />
      </DashboardLayout>
    </>
  );
};

export default EnterpriseSetup;
