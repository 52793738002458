import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import * as yup from "yup";

import { Divider, Form, Toggle } from "rsuite";
import FormSubmitButton from "../appearance/FormSubmitButton";

const NotificationsForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <Form>
        <Form.Group>
          <Form.ControlLabel>WhatsApp Notifications</Form.ControlLabel>
          <span className="tw-text-sm">
            Manage portal notifications via WhatsApp for reminders and updates.
          </span>
          <Controller
            name={"program.whatsapp_notification"}
            control={control}
            render={({ field }) => (
              <div className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-my-6">
                <div>
                  <span>
                    Portal will {field.value === true ? "" : "not"} send
                    notifications via WhatsApp to your participants.
                  </span>
                </div>
                <div>
                  <Toggle
                    {...field}
                    checkedChildren="Enabled"
                    unCheckedChildren="Disabled"
                    checked={field.value}
                  />
                </div>
              </div>
            )}
          />
        </Form.Group>
      </Form>
      <Divider />
      <div className="tw-flex tw-flex-row-reverse">
        <FormSubmitButton />
      </div>
    </div>
  );
};

export default NotificationsForm;
