// React
import React from "react";

// UI
import { Button, Modal } from "rsuite";

export default function ConfirmedBookingModal({
  pwaUrl,
  bookingId,
  onPortal,
  setBookingModalType,
  participantId,
  open,
  close,
}) {
  const baseFeedbackUrl = `bookings/${bookingId}/participants/${participantId}/feedback`;

  const feedbackUrl = onPortal
    ? `${pwaUrl}/${onPortal?.enterpriseSlug}/${onPortal?.programSlug}/${baseFeedbackUrl}`
    : `${pwaUrl}/${baseFeedbackUrl}`;

  const goToReflectionPage = () => {
    setBookingModalType("clear");
    window.open(feedbackUrl, "_self");
  };

  return (
    <Modal
      open={open}
      backdrop="static"
      className="tw-mt-8 tw-mb-16 tw-w-[340px]"
      size="xs"
      onClose={close}
    >
      <Modal.Header>
        <Modal.Title className="tw-font-semibold">
          Thank you for confirming!
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="tw-pb-1">
        <p className="montserrat tw-text-sm tw-font-medium">
          We're glad to hear the session was carried out smoothly.
        </p>
        <p className="montserrat tw-text-sm tw-font-medium">
          Now, the final step is to rate and share your reflection! Your input
          is invaluable for tracking your personal growth and progress with your
          mentor.
        </p>
        <p className="montserrat tw-text-sm tw-font-medium">
          It'll only take 5 minutes, we promise!
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button appearance="ghost" onClick={close}>
          I'll Rate Later
        </Button>
        <Button appearance="primary" onClick={goToReflectionPage}>
          Rate now
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
