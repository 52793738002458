import React from "react";
import { FaQuestionCircle } from "react-icons/fa";
import { Tooltip, Whisper } from "rsuite";

const TooltipHelper = ({ tip }) => {
  return (
    <>
      <Whisper
        speaker={<Tooltip>{tip}</Tooltip>}
        trigger="hover"
        placement="autoVerticalStart"
      >
        <span className={"tw-ml-2"}>
          <FaQuestionCircle style={{ color: "#C4C4C4" }} className={"tw-m-0"} />
        </span>
      </Whisper>
    </>
  );
};

export default TooltipHelper;
