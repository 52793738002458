// React
import React, { useState } from "react";

// FutureLab
import CollegeModal from "./CollegeModal";
import Api from "../../util/api";

// UI
import { Divider, Form, Stack } from "rsuite";
import { FaUserGraduate } from "react-icons/fa";

// 3rd Party
import { DateTime } from "luxon";
import LinkButton from "../ui/LinkButton";

export interface CollegeInput {
  program_name: string;
  university_name: string;
  start_date: Date;
  end_date: Date;
  level: string;
  study_status: number;
  cgpa: string;
  is_current: boolean;
}

const levels = {
  not_specified: "Not specified",
  high_school: "High School Graduate",
  pre_university: "Pre University Graduate",
  diploma: "Diploma",
  bachelors: "Bachelors",
  masters: "Masters",
  phd: "PhD",
  postdoctarate: "Postdoctorate",
  other: "Others",
};

const fetchUniverisities = async (query = "") => {
  return await Api.get("/universities", { search: query });
};

const Educations = ({ user }) => {
  const [universities, setUniversities] = useState<[]>();
  const [college, setCollege] = useState<CollegeInput>();
  const [loading, setLoading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const handleSearch = async (query) => {
    setLoading(true);
    try {
      const { data } = await fetchUniverisities(query);
      setUniversities(data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const initializedCollegeModal = async (selectedCollege) => {
    await handleSearch(selectedCollege?.university?.name);
    setCollege(selectedCollege);
    setShowEditModal(true);
  };

  return (
    <Form.Group>
      <Stack justifyContent="space-between">
        <Form.ControlLabel className="tw-text-base">
          Education
        </Form.ControlLabel>
        <LinkButton
          className="tw-no-underline"
          onClick={() => initializedCollegeModal({})}
        >
          Add new
        </LinkButton>
      </Stack>
      <div className="tw-h-36 tw-w-full tw-border tw-border-solid tw-border-gray-300 tw-p-3 tw-overflow-y-auto">
        {user?.colleges.map((college, idx) => (
          <div key={college.id}>
            <Stack justifyContent="space-between" alignItems="flex-start">
              <Stack spacing={8} alignItems="flex-start">
                {college.university.photo.url ? (
                  <img
                    src={college.university.photo.url}
                    alt=""
                    className="tw-w-24"
                  />
                ) : (
                  <div className="tw-w-24 tw-h-24 tw-text-center tw-bg-gray-200 tw-align-middle tw-flex">
                    <FaUserGraduate className="tw-text-5xl tw-text-gray-400 tw-m-auto" />
                  </div>
                )}
                <div>
                  <h6 className="tw-mb-1">
                    {levels[college.level]}
                    {college.level && college.program_name && " of "}
                    {college.program_name}
                  </h6>
                  <p className="tw-mb-1">{college.university.name}</p>
                  {college.cgpa && (
                    <p className="tw-mb-1">Grade: {college.cgpa}</p>
                  )}
                  <p className="tw-mb-1">
                    {DateTime.fromISO(college.start_date).toFormat("MMM yyyy")}{" "}
                    -{" "}
                    {college.end_date
                      ? DateTime.fromISO(college.end_date).toFormat("MMM yyyy")
                      : "Present"}
                  </p>
                </div>
              </Stack>
              <LinkButton
                className="tw-no-underline"
                onClick={() => initializedCollegeModal(college)}
              >
                Edit
              </LinkButton>
            </Stack>
            {idx !== user.colleges.length - 1 && (
              <Divider className="tw-my-2" />
            )}
          </div>
        ))}
      </div>
      {showEditModal && (
        <CollegeModal
          user={user}
          college={college}
          universities={universities}
          handleSearch={handleSearch}
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          loading={loading}
        />
      )}
    </Form.Group>
  );
};

export default Educations;
