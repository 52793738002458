// React
import React from "react";

// UI
import { Checkbox, Panel } from "rsuite";

export function CheckboxTile({
  label,
  image,
  value,
  disabled = false,
  onChange,
  isChecked,
}) {
  const panelStyle = {
    border: isChecked ? "2px solid #428ac9" : "1px solid #d9d9d9",
    height: 146,
  };

  return (
    <Checkbox
      checked={isChecked}
      disabled={disabled}
      onChange={onChange}
      value={value}
      className="checkbox-tile"
    >
      <Panel
        bordered
        bodyFill
        style={panelStyle}
        className="justify-center text-center w-32 py-4 px-2 m-3 rounded-xl cursor-pointer hover:shadow-md sm:m-0 sm:my-1"
      >
        {image && (
          <img src={image} alt="Checkbox Image" className="w-12 mb-3 sm:w-12" />
        )}
        <span className="block">{label}</span>
      </Panel>
    </Checkbox>
  );
}
