import React, { useEffect } from "react";
import styled from "styled-components";

// FL
import useEnterpriseDashboard from "../../../hooks/useEnterpriseDashboard";

// UI
import { Button } from "rsuite";
import { AiOutlinePlus } from "react-icons/ai";

// 3rd Party
import { Link } from "react-router-dom";

import useScreenSize from "../../../util/useScreenSize";
import ProgramFilter from "../components/dashboard/ProgramFilter";
import DesktopProgramTable from "../components/dashboard/DesktopProgramTable";
import MobileProgramTable from "../components/dashboard/MobileProgramTable";
import WelcomeModal from "../components/onboarding/WelcomeModal";
import { useOnboarding } from "contexts/onboardingContext";

type DateRange1 = {
  from_date: Date | null;
  to_date: Date | null;
};

export const Dashboard = () => {
  const [date, setDate] = React.useState<DateRange1>({
    from_date: undefined,
    to_date: undefined,
  });
  const { programs, loading } = useEnterpriseDashboard(date);
  const { isDesktop } = useScreenSize();

  const { checkOnboardingStatus, isUserOnboarded } = useOnboarding();

  const handleDateChange = (e) => {
    if (!e)
      return setDate({
        from_date: undefined,
        to_date: undefined,
      });

    const [from_date, to_date] = e;

    setDate({
      from_date,
      to_date,
    });
  };

  useEffect(() => {
    checkOnboardingStatus("enterprise_welcome_modal");
  }, []);

  return (
    <div className="tw-flex tw-flex-col tw-gap-8 sm:tw-px-2">
      <div className="tw-flex tw-justify-between sm:tw-flex-col">
        <h1 className="tw-text-lg sm:tw-text-xl">Mentoring Programs</h1>
        <div className="tw-flex tw-gap-4 xs:tw-flex-col">
          {isDesktop && (
            <ProgramFilter date={date} handleDateChange={handleDateChange} />
          )}
          <Button as={Link} to="/create" appearance="primary">
            <AiOutlinePlus className="tw-mr-1" />
            {"Create New Program"}
          </Button>
        </div>
      </div>
      {!isUserOnboarded && <WelcomeModal />}
      {isDesktop ? (
        <DesktopProgramTable tableData={programs} loading={loading} />
      ) : (
        <MobileProgramTable tableData={programs} loading={loading} />
      )}
    </div>
  );
};
function checkOnboardingStatus() {
  throw new Error("Function not implemented.");
}
