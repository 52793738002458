// React
import React from "react";

// FutureLab
import { FutureLabApi } from "../../util/api";

// UI
import { FaGift } from "react-icons/fa";
import { Message } from "rsuite";

// 3rd Party
import _ from "lodash";

export function DashboardPromo(props) {
  const { user } = props;

  const handleClose = (promo) => {
    // Logic to update DB to user confirmation receipt
    let currentCount =
      parseInt(localStorage.getItem("PromoReminderCount")) || 0;
    let updateCount = (currentCount + 1).toString();
    localStorage.setItem("PromoReminderCount", updateCount);

    if (currentCount >= 2) {
      FutureLabApi({
        method: "post",
        path: "/users/current/confirmation_receipt",
        options: { promo_code_id: promo.promo_code_id },
      });
      localStorage.setItem("PromoReminderCount", "0");
    }
  };

  const handleClick = (promoCode: string) => {
    window.open(`/package/10/purchase_page?promo_code=${promoCode}`, "_self");
  };

  return user.promo_trackers
    .filter((promo) => !promo.recipient_confirmed)
    .slice(0, 1)
    .map((promo, index) => {
      return (
        <Message
          closable
          onClose={() => handleClose(promo)}
          className="promo-banner"
          key={index}
        >
          <div
            onClick={() => handleClick(promo.promo.code)}
            className="tw-flex tw-py-4 xs:tw-text-sm"
          >
            <span className="tw-pr-5">
              <FaGift size={24} />
            </span>
            <span className="tw-leading-7">
              <span>Congratulations! Use promo code</span>
              <span className="tw-font-semibold"> {promo.promo.code} </span> to
              get{" "}
              <span className="tw-font-semibold">
                {promo.promo.discount[0][1]}% off!{" "}
              </span>
            </span>
          </div>
        </Message>
      );
    });
}
