import React, { useEffect, useState } from "react";
import { Button, Divider, Message, toaster, Toggle } from "rsuite";
import { FutureLabApi } from "../../util/api";
import TooltipHelper from "../ui/TooltipHelper";
import translateText from "../../util/translateText";

const DashboardAvailability = ({ convertText }) => {
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    FutureLabApi({
      method: "get",
      path: "/users/current/dnd_status",
      success: ({ data }) => {
        setToggle(data.active);
      },
    });
  }, [toggle]);

  const toggleDnd = () => {
    FutureLabApi({
      method: "post",
      path: "/users/current/toggle_dnd",
      success: ({ data }) => {
        setToggle(data.active);
        toaster.push(
          <Message showIcon type="success">
            {translateText(convertText, "Successfully updated.")}
          </Message>
        );
      },
      error: (err) => {
        const { error } = err.response.data;
        toaster.push(
          <Message showIcon type="error">
            {error}
          </Message>
        );
      },
    });
  };

  return (
    <>
      <div className="tw-w-1/2 sm:tw-w-full">
        <div className="tw-flex tw-w-full tw-mb-3 tw-items-center">
          <p className="section-header montserrat">
            {translateText(convertText, "My availability")}
          </p>
          <TooltipHelper
            tip={
              "Let mentees know when they can schedule a session with you by setting your availability"
            }
          />
        </div>
        <div className="tw-px-3 tw-py-4 tw-border-solid tw-border tw-border-gray-300 tw-rounded-lg">
          <p className="montserrat">
            {translateText(convertText, "Your status is")}{" "}
            {toggle == true ? (
              <b style={{ color: "#f94c4c" }}>
                {translateText(convertText, "unavailable")}
              </b>
            ) : (
              <b style={{ color: "#0ccd39" }}>
                {translateText(convertText, "available")}
              </b>
            )}
            .
          </p>
          <Button
            appearance="primary"
            className="tw-mb-3"
            href="/main/availability"
            block
          >
            {translateText(convertText, "Manage my availability")}
          </Button>

          <Divider />
          <div className="tw-flex tw-justify-start tw-items-center">
            <Toggle onChange={toggleDnd} checked={toggle} className="mr-5" />
            <p className="montserrat mb-0 mr-1">
              {translateText(convertText, "Do not disturb")}
            </p>
            <TooltipHelper
              tip={translateText(
                convertText,
                "Want to take a break from mentoring? Turn this on so mentees can't book your time. Turn it off any time to resume mentoring when you are ready"
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardAvailability;
