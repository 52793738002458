// React
import React from "react";

// Futurelab
import MenteeNavbarBanner from "./component/MenteeNavbarBanner";
import MentorNavbarBanner from "./component/MentorNavbarBanner";

export default function NavbarBanner({ userType }) {
  let isMentee = userType === "mentee";
  let isMentor = userType === "mentor";

  return (
    <>
      {isMentee && <MenteeNavbarBanner />}
      {isMentor && <MentorNavbarBanner />}
    </>
  );
}
