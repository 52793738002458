// react
import React from "react";

// FutureLab
import translateText from "../../../util/translateText";
import { UserType } from "../../../contexts/mentor_profile";

// UI
import { Col, FlexboxGrid } from "rsuite";

export default function Goals({ convertText, user }) {
  const { goals, user_type } = user;

  const text =
    user_type === UserType.Mentee
      ? "I'm looking help for"
      : "What can I help with";

  return (
    <div>
      <p className="tw-font-bold tw-text-base montserrat">
        {translateText(convertText, text)}
      </p>
      <FlexboxGrid>
        {goals?.map((goal) => (
          <FlexboxGrid.Item
            as={Col}
            colspan={24}
            xs={24}
            sm={12}
            className="tw-mb-3"
            key={goal.id}
          >
            <img src={goal.image_url} className="tw-w-8 tw-mr-3" />
            {goal.mentor_label}
          </FlexboxGrid.Item>
        ))}
      </FlexboxGrid>
    </div>
  );
}
