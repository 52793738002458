import React from "react";
import { Avatar } from "rsuite";

import AppLogo from "../../../images/fl-circle-logo.png";

//@ts-ignore
import FLLogo from "../../../images/fl-blue.svg";
import { usePortal } from "contexts/portal";

const CustomNavHeader = ({ isExpanded, program = null }) => {
  if (program) {
    const { photo_url, name } = program || {};

    return (
      <div className="tw-flex tw-items-center tw-gap-2 tw-px-2">
        <Avatar
          src={photo_url}
          alt={name?.charAt(0) || ""}
          className="tw-shrink-0"
        />
        {isExpanded && <span className="tw-ml-2 tw-font-medium">{name}</span>}
      </div>
    );
  }

  const logoSrc = false ? FLLogo : AppLogo;

  return (
    <img
      src={logoSrc}
      className="tw-px-0 tw-py-3 tw-h-[56px] tw-w-full tw-object-contain"
    />
  );
};

export default CustomNavHeader;
