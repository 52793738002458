import React, { useEffect, useRef, useState } from "react";
import { FlexboxGrid, Col, Pagination } from "rsuite";
import { debounce } from "lodash";
import { FlAdminApi } from "../../util/fladmin_api";

import UserTable from "./UserTable";
import UserStats from "./UserStats";
import UserSearchFilter from "./UserSearchFilter";

const MenteeManagement = ({ user_type }) => {
  const userType = user_type;

  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(30);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [users, setUsers] = useState([]);
  const [userStats, setUserStats] = useState([]);

  const getUsers = ({
    page = 1,
    search = "",
    searchCountry = "",
    searchDates = [],
  } = {}) => {
    setLoading(true);
    FlAdminApi({
      method: "get",
      path: "/users",
      options: {
        user_type: user_type,
        page,
        per_page: limit,
        search_name: search,
        dates: searchDates,
        search_country: searchCountry,
      },
      success: ({ data }) => {
        setLoading(false);
        setUsers(data.users);
        setUserStats(data.stats);
        setTotal(data.total);
        setPage(data.current_page);
      },
    });
    return () => {
      setUsers([]);
    };
  };

  const handleChangeLimit = (limit) => {
    setLimit(limit);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleSearch = debounce(
    ({ searchName = "", searchDates = [], searchCountry = "", page = 1 }) => {
      const search = searchName.toLowerCase();

      getUsers({ search, searchDates, searchCountry, page });
    },
    1500
  );

  return (
    <FlexboxGrid justify="center">
      <FlexboxGrid.Item as={Col} colspan={22} xs={24} md={20} lg={22}>
        <div className="tw-my-6">
          <h2 className="tw-text-left tw-text-lg">
            {userType == "mentee" ? "Mentee Engagement" : "Mentor Engagement"}
          </h2>
        </div>
      </FlexboxGrid.Item>

      <UserStats userStats={userStats} userType={userType} />

      <UserSearchFilter handleSearch={handleSearch} users={users} page={page} />

      <UserTable users={users} loading={loading} userType={userType} />

      <FlexboxGrid.Item as={Col} colspan={22} xs={24} md={20} lg={22}>
        <div style={{ padding: 10 }}>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="xs"
            layout={["total", "-", "limit", "|", "pager", "skip"]}
            total={total}
            limitOptions={[20, 30, 50, 100]}
            limit={limit}
            activePage={page}
            onChangePage={handleChangePage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

export default MenteeManagement;
