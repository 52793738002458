// React
import React, { useEffect, useState } from "react";

// FutureLab
import { FutureLabApi } from "../../util/api";
import SingleUnconcludedBookingModal from "./component/SingleUnconcludedBookingModal";
import ConfirmedBookingModal from "./component/ConfirmedBookingModal";
import { getPairParticipant } from "../../util/getBookingParticipants";
import MultipleUnconcludedBookingModal from "./component/MultipleUnconcludedBookingModal";
import FailedBookingModal from "./component/FailedBookingModal";

// 3rd Party
import Cookies from "js-cookie";

export type Booking = {
  id: number;
  booking_participants: Array<{
    user_id: number;
    user: {
      profile_photo_url: string;
      info: {
        job_title: string;
        company: string;
      };
    };
  }>;
  booking_date: Date;
  booking_start_time: Date;
  booking_end_time: Date;
};

export default function UnconcludedBooking({
  modalInfo,
  currentUser,
  onPortal,
  token,
  pwaUrl,
}) {
  const [bookingModalType, setBookingModalType] = useState(
    modalInfo.session_type
  );
  const [booking, setBooking] = useState<Booking>();

  useEffect(() => {
    if (modalInfo.session_type === "single") {
      FutureLabApi({
        method: "get",
        path: `/bookings/${modalInfo.booking_id}`,
        success: ({ data }) => {
          setBooking(data);
        },
        error: (error) => {
          console.error("Error fetching booking:", error);
          // Handle error (e.g., show an error message)
        },
      });
    }
  }, []);

  useEffect(() => {
    if (bookingModalType === "clear") {
      Cookies.remove("modal_to_show");
      Cookies.set("unconcluded_modal_break", "", { expires: 2 });
    }
  }, [bookingModalType]);

  let bookingParticipant = getPairParticipant(booking, currentUser.id);

  return (
    <>
      {bookingModalType === "single" && booking && (
        <SingleUnconcludedBookingModal
          booking={booking}
          bookingParticipant={bookingParticipant}
          setBookingModalType={setBookingModalType}
          open={modalInfo.session_type === "single"}
          close={() => setBookingModalType("clear")}
        />
      )}
      {bookingModalType === "multiple" && (
        <MultipleUnconcludedBookingModal
          open={modalInfo.session_type === "multiple"}
          close={() => setBookingModalType("clear")}
        />
      )}
      {bookingModalType === "confirmed" && (
        <ConfirmedBookingModal
          pwaUrl={pwaUrl}
          bookingId={booking.id}
          onPortal={onPortal}
          setBookingModalType={setBookingModalType}
          participantId={bookingParticipant.id}
          open={bookingModalType === "confirmed"}
          close={() => setBookingModalType("clear")}
        />
      )}
      {bookingModalType === "cancellation" && (
        <FailedBookingModal
          token={token}
          bookingId={booking.id}
          setBookingModalType={setBookingModalType}
          bookingParticipant={bookingParticipant}
          open={bookingModalType === "cancellation"}
          close={() => setBookingModalType(modalInfo.session_type)}
        />
      )}
    </>
  );
}
