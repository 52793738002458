import React from "react";
import { useParams } from "react-router";
import AppContainer from "./AppContainer";

import PortalSetupContent from "./portal/setup/PortalSetupContent";
import { PortalSetupProvider } from "../contexts/portal/setup";
import { useEnterprise } from "../contexts/enterpriseContext";
import { SetupVariablesProvider } from "../contexts/portal/setup/setupVariables";

const PortalSetup = ({ editMode = false }) => {
  const { programId } = useParams();
  const { state } = useEnterprise();

  const enterpriseSlug = state?.admin?.enterprise?.slug || "";

  return (
    <AppContainer>
      <PortalSetupProvider
        enterpriseSlug={enterpriseSlug}
        programId={+programId || null}
      >
        <SetupVariablesProvider editMode={editMode}>
          <PortalSetupContent />
        </SetupVariablesProvider>
      </PortalSetupProvider>
    </AppContainer>
  );
};

export default PortalSetup;
