import React from "react";
import { Button, Divider, Modal } from "rsuite";
import translateText from "../../util/translateText";

const DashboardFeatureModal = ({
  handleFeatureModalClose,
  openFeatureModal,
  handleFeatureModalClick,
  convertText,
}) => {
  return (
    <>
      <Modal
        size="xs"
        backdrop={"static"}
        keyboard={false}
        open={openFeatureModal}
        onClose={handleFeatureModalClose}
      >
        <Modal.Header>
          <Modal.Title className="tw-text-xl tw-font-medium">
            {translateText(convertText, "What's New")}!
          </Modal.Title>
        </Modal.Header>
        <Divider className="tw-absolute tw-w-full tw-left-0 tw-mt-4 tw-bg-gray-300" />
        <Modal.Body className="tw-py-5 tw-text-center">
          <img alt="lang-banner" className="lang-banner" />
          <h6>{translateText(convertText, "Choose your language")}!</h6>
          {translateText(convertText, "FutureLab is now available in")}:
          <div>
            <ul className="tw-inline-block tw-text-left tw-mt-2">
              <li>{translateText(convertText, "English")}</li>
              <li>{translateText(convertText, "Bahasa Melayu")}</li>
              <li>{translateText(convertText, "Bahasa Indonesia")}</li>
            </ul>
          </div>
          {translateText(convertText, "Check it out")}!
        </Modal.Body>
        <Divider className="tw-absolute tw-w-full tw-left-0 tw-m-0 tw-bg-gray-300" />
        <Modal.Footer className="tw-pt-5">
          <Button
            className="tw-px-6"
            onClick={handleFeatureModalClick}
            appearance="primary"
          >
            {translateText(convertText, "Ok")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DashboardFeatureModal;