import React from "react";
import { withContainer } from "../components";

export const NeedConfirmation = withContainer(() => {
  return (
    <>
      <h1>Need Confirmation</h1>
      <p>
        Thank you for registering! Before you can start using your account, we
        need to confirm your email address. Please check your inbox for a
        confirmation email from us and click the link inside to complete the
        registration process. If you don't see the email, don't forget to check
        your spam folder. Once you've confirmed your email, you'll be all set to
        explore our website and enjoy our services. If you encounter any issues,
        please don't hesitate to contact our support team. Thank you for
        choosing us!
      </p>
    </>
  );
});
