import React from "react";

// FL
import { forgotPassword } from "../../api";
import Toaster from "../../../shared/Toaster";

// UI
import { FormInputText } from "../../../form/default/FormInputs";
import { Button, Form } from "rsuite";

// 3rd party
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

const schema = yup.object().shape({
  email: yup.string().email().required("Your email is required").nullable(),
});

const ForgotPasswordForm = () => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      await forgotPassword(data);
      navigate("/login");
      Toaster({
        type: "success",
        msg: "Password reset link sent to your email",
      });
    } catch (error) {
      const data = error.response.data;

      Toaster({
        type: "error",
        msg: data.message ? data.message : "Oops, something went wrong!",
      });
    }
  };

  return (
    <>
      <Form>
        <Form.Group>
          <FormInputText
            name={"email"}
            label={"Your email"}
            placeholder={"example@email.com"}
            control={control}
            errors={errors}
          />
        </Form.Group>
      </Form>

      <Button
        loading={isSubmitting}
        onClick={handleSubmit(onSubmit)}
        className="tw-mt-10"
        appearance="primary"
        block
        type="submit"
      >
        Reset password
      </Button>
    </>
  );
};

export default ForgotPasswordForm;
