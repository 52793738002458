import _ from "lodash";
import React from "react";
import {
  FormInputNumber,
  FormInputText,
  FormTagPicker,
} from "../../form/default/FormInputs";

const getQuestionField = (props) => {
  const {
    control,
    index,
    question,
    errors,
    name,
    primaryQuestion = null,
  } = props;

  return {
    stars: (
      <FormInputNumber
        name={`${name}.${index}.stars`}
        defaultValue={5}
        readOnly={primaryQuestion}
        label={"No of stars"}
        placeholder={"No of stars"}
        control={control}
        errors={errors}
        max={5}
        min={0}
        style={{ width: "100px" }}
      />
    ),
    scale: (
      <div className="tw-inline-flex tw-w-full tw-align-middle">
        <div>
          <FormInputText
            name={`${name}.${index}.scale_start`}
            control={control}
            errors={errors}
            placeholder={"Start"}
            defaultValue={1}
            className="tw-w-12"
          />
        </div>
        <div className="tw-p-2">to</div>
        <div>
          <FormInputText
            name={`${name}.${index}.scale_end`}
            control={control}
            errors={errors}
            placeholder={"End"}
            defaultValue={5}
            className="tw-w-12"
          />
        </div>
      </div>
    ),
    options: (
      <FormTagPicker
        name={`${name}.${index}.options`}
        control={control}
        errors={errors}
        placeholder={"Start typing to create your options"}
        data={_.map(question?.options, (option) => {
          return { label: option, value: option };
        })}
        className="tw-flex tw-w-full"
        creatable
      />
    ),
  }[question.question_type || question.category];
};

export default getQuestionField;
