import React from "react";

import { withContainer, FormWrapper } from "../components";
import LoginForm from "../components/forms/LoginForm";

export const Login = withContainer(() => {
  return (
    <div className="tw-flex tw-items-center tw-h-screen tw-w-1/2 sm:tw-w-full lg:tw-w-2/3">
      <FormWrapper
        title="Log in"
        footerText="Need an account?"
        footerLink={{
          title: "Create an Enterprise Account",
          to: "/register",
        }}
      >
        <LoginForm />
      </FormWrapper>
    </div>
  );
});
