import React from "react";
import { Controller, useFieldArray } from "react-hook-form";
import {
  Button,
  DateRangePicker,
  Divider,
  Form,
  Input,
  List,
  Toggle,
  Tooltip,
  Whisper,
} from "rsuite";
import {
  FormDateRangePicker,
  FormInputText,
} from "../../../form/default/FormInputs";
import { FormRichTextInput } from "../../../ui/RichTextEditor";
import { ErrorMessage } from "@hookform/error-message";
import FieldError from "../../../form/FieldError";
import ModuleTaskComponent from "../component/ModuleTaskComponent";
import { MdContentCopy, MdOutlineDelete } from "react-icons/md";
import _ from "lodash";

interface LearningModule {
  idx: string;
  id: string | number | undefined;
  name: string;
  description: string;
  order: number;
  start_date: Date;
  end_date: Date;
  _destroy: boolean;
  // Define other properties as needed
}

const LearningModuleForm = ({
  control,
  getValues,
  setValue,
  errors,
  watch,
}) => {
  const { allowedRange } = DateRangePicker;
  const watchStartDate = watch("program.start_date");
  const watchEndDate = watch("program.end_date");

  const {
    fields: moduleFields,
    append: appendModule,
    remove: removeModule,
  } = useFieldArray({
    control,
    name: "learning_modules",
    keyName: "idx",
  });

  // Value setter for learning module start_date and end_date from learning_module.duration_range
  const handleDateRangeChange = (moduleIndex, dates) => {
    setValue(`learning_modules.${moduleIndex}.duration_range`, dates);
    setValue(`learning_modules.${moduleIndex}.start_date`, dates[0]);
    setValue(`learning_modules.${moduleIndex}.end_date`, dates[1]);
  };

  return (
    <>
      {/* Programme Structure */}
      <div className="tw-flex tw-flex-col tw-my-4">
        <h6>Programme Module</h6>

        <span className="montserrat tw-w-full tw-text-sm">
          Create a well-organized module outline for your users by adding
          multiple content elements or use a template from above.
        </span>
      </div>

      {/* FUT-1241: Learning Modules Form */}
      {/* Suggest adding swapping capabilities with useFieldArray's swap - to interchange learning_modules.duration & order */}
      <List>
        {moduleFields &&
          moduleFields.map((field: LearningModule, moduleIndex) => (
            <List.Item
              key={field.idx}
              className={`tw-p-4 tw-border-solid tw-border tw-border-gray-200 tw-rounded-lg tw-my-4 ${
                watch(`learning_modules.${moduleIndex}._destroy`) && "tw-hidden"
              }`}
            >
              <div>
                <Controller
                  name={`learning_modules.${moduleIndex}.id`}
                  control={control}
                  defaultValue={field.id || undefined}
                  render={({ field }) => (
                    <Input {...field} className="tw-hidden" />
                  )}
                />

                <Controller
                  name={`learning_modules.${moduleIndex}._destroy`}
                  control={control}
                  defaultValue={field._destroy || false}
                  render={({ field: { name, value } }) => (
                    <Toggle
                      defaultChecked={value}
                      onChange={(value) => setValue(name, value)}
                      className="tw-hidden"
                    />
                  )}
                />

                <Controller
                  name={`learning_modules.${moduleIndex}.order`}
                  control={control}
                  defaultValue={moduleIndex}
                  render={({ field }) => (
                    <Input
                      {...field}
                      value={moduleIndex}
                      className="tw-hidden"
                    />
                  )}
                />

                <Form.Group>
                  <FormInputText
                    name={`learning_modules.${moduleIndex}.name`}
                    control={control}
                    errors={errors}
                    placeholder={`Module Title ${moduleIndex + 1}`}
                    className="tw-font-medium"
                  />
                </Form.Group>

                <Form.Group>
                  <FormRichTextInput
                    name={`learning_modules.${moduleIndex}.description`}
                    control={control}
                    errors={errors}
                    label={"Module Description"}
                  />
                </Form.Group>

                <Form.Group>
                  <FormDateRangePicker
                    name={`learning_modules.${moduleIndex}.duration_range`}
                    control={control}
                    errors={errors}
                    format={"dd-MMM-yyyy"}
                    label={"Module Duration"}
                    placeholder={"Select module duration"}
                    defaultValue={
                      field.start_date && field.end_date
                        ? [new Date(field.start_date), new Date(field.end_date)]
                        : []
                    }
                    onChange={(value) =>
                      handleDateRangeChange(moduleIndex, value)
                    }
                    className="tw-w-1/2"
                    placement="autoVerticalStart"
                    shouldDisableDate={allowedRange(
                      watchStartDate,
                      watchEndDate
                    )}
                    ranges={[]}
                  />
                  {/* Error messages for start_date and end_date, value is set from handleDateRangeChange() */}
                  <ErrorMessage
                    name={`learning_modules.${moduleIndex}.start_date`}
                    errors={errors}
                    as={FieldError}
                  />
                  <ErrorMessage
                    name={`learning_modules.${moduleIndex}.end_date`}
                    errors={errors}
                    as={FieldError}
                  />
                </Form.Group>

                <ModuleTaskComponent
                  control={control}
                  moduleIndex={moduleIndex}
                  setValue={setValue}
                  getValues={getValues}
                />
              </div>

              <Divider />

              <div className="tw-flex tw-flex-row-reverse">
                <>
                  <div>
                    <Whisper
                      placement="top"
                      trigger="hover"
                      speaker={<Tooltip>Copy Module</Tooltip>}
                    >
                      <a
                        onClick={() => {
                          let values = getValues("learning_modules");
                          appendModule(
                            _.omit(values[moduleIndex], ["idx", "id"])
                          );
                        }}
                        className="tw-px-2 tw-pr-4 tw-cursor-pointer"
                      >
                        <MdContentCopy />
                      </a>
                    </Whisper>

                    <Whisper
                      placement="top"
                      trigger="hover"
                      speaker={<Tooltip>Delete Module</Tooltip>}
                    >
                      <a
                        onClick={() => {
                          if (field.id !== undefined) {
                            setValue(
                              `learning_modules.${moduleIndex}._destroy`,
                              true
                            );
                          } else {
                            removeModule(moduleIndex);
                          }
                        }}
                      >
                        <MdOutlineDelete
                          style={{ fontSize: "18px", color: "red" }}
                        />
                      </a>
                    </Whisper>
                  </div>
                </>
              </div>
            </List.Item>
          ))}
      </List>
      <Button
        appearance="ghost"
        className="tw-my-4"
        // TODO: FUT-1241: Start Date - getValue(current index).duration and set for next append
        // TODO: FUT-1241: Set Order - getValue(current index).order for next append
        onClick={() => {
          let learningModuleValues = getValues("learning_modules");

          appendModule({
            name: `Module Title`,
            id: undefined,
            order:
              learningModuleValues?.length === 0
                ? 0
                : learningModuleValues[learningModuleValues.length - 1]?.order +
                  1,
            _destroy: false,
            duration_range: [],
            description: "",
            learning_module_tasks_attributes: [],
          });
        }}
      >
        + {"Add module"}
      </Button>
    </>
  );
};

export default LearningModuleForm;
