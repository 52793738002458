import React from "react";
import { GoTriangleRight, GoTriangleDown } from "react-icons/go";
import { Avatar, Modal } from "rsuite";
import { useUser } from "../../../contexts/user";
import { createPortal } from "react-dom";
import NavProfileMenu from "../component/NavProfileMenu";
import avatarPlaceholder from "../../../images/avatar_placeholder.png";
import ProgramSwitcher from "./ProgramSwitcher";

const Dialog = ({ children }) => {
  const el = document.getElementById("profile-portal");

  return createPortal(children, el || document.body);
};

const ProfileMenu = ({ handleClose = () => {}, toggleSwitcher = () => {} }) => {
  const dropRef = React.useRef(null);
  //if click outside of the dropdown, close it
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropRef.current && !dropRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener("mouseup", handleClickOutside);

    return () => document.removeEventListener("mouseup", handleClickOutside);
  }, []);

  return (
    <div
      className="tw-absolute tw-bg-white tw-w-[287px] tw-rounded-xl tw-top-0"
      style={{ zIndex: 99999, right: 0 }}
      ref={dropRef}
    >
      <NavProfileMenu toggleSwitcher={toggleSwitcher} />
    </div>
  );
};

const AvatarMenu = () => {
  const { user } = useUser();
  const photo = user?.profile_photo_url;
  const [expanded, setExpanded] = React.useState(false);
  const [switchOn, setSwitchOn] = React.useState(false);

  const handleClose = () => {
    setExpanded(false);
  };

  return (
    <div className="tw-flex tw-items-center tw-gap-3 tw-px-6">
      <div id="profile-portal" className="tw-relative">
        <Avatar
          src={photo || avatarPlaceholder}
          circle
          size="md"
          className="tw-cursor-pointer"
          onClick={() => setExpanded(!expanded)}
          imgProps={{
            onError: ({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = avatarPlaceholder;
            },
          }}
        />
      </div>
      {expanded ? (
        <GoTriangleDown
          onClick={() => setExpanded(!expanded)}
          className="tw-cursor-pointer tw-text-xl"
        />
      ) : (
        <GoTriangleRight
          onClick={() => setExpanded(!expanded)}
          className="tw-cursor-pointer tw-text-xl"
        />
      )}
      <Modal
        open={expanded}
        dialogAs={Dialog}
      >
        <ProfileMenu
          toggleSwitcher={() => {
            setSwitchOn(true);
            setExpanded(false);
          }}
          handleClose={handleClose}
        />
      </Modal>
      <ProgramSwitcher open={switchOn} onClose={() => setSwitchOn(false)} />
    </div>
  );
};

export default AvatarMenu;
