import React from "react";
import { Tooltip, Whisper } from "rsuite";

export const headerTooltipMsg = (tipKey) => {
  const message = {
    paired: "Pairing health indicates the level of engagement and productivity of a mentoring relationship.",
    open: "Pairing health indicates the level of engagement and productivity of a mentoring relationship.",
    avg_score: "This indicates the average performance of this mentor and mentee pairing, based on the feedback they submit for each other.",
  };
  return message[tipKey];
};


function HeaderTooltip({ headerText, tooltipMessage }) {
  return (
    <Whisper placement="bottom" speaker={<Tooltip className="tw-text-center">{tooltipMessage}</Tooltip>}>
      <span>{headerText}</span>
    </Whisper>
  );
}

export default HeaderTooltip;