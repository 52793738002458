import React, { useState } from "react";
import { Button, Form } from "rsuite";
import { FormInputText } from "../../../form/default/FormInputs";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEnterprise } from "../../../../contexts/enterpriseContext";
import { resetPassword } from "../../api";
import * as yup from "yup";
import Toaster from "../../../shared/Toaster";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const schema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .nullable()
    .test({
      name: "password",
      message:
        "Password must contain at least 8 characters, 1 uppercase, 1 number and 1 special character",
      test: (value) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/;
        return regex.test(value);
      },
    }),
  password_confirmation: yup
    .string()
    .required("Password confirmation is required")
    .nullable()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const ResetPasswordForm = () => {
  const [showPassword, setShowPassword] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const [params, _] = useSearchParams();
  const resetToken = params.get("reset_password_token");
  const handleShowPassword = () => setShowPassword(!showPassword);

  const onSubmit = async (data) => {
    try {
      data.reset_password_token = resetToken;
      await resetPassword(data);
      navigate("/login");
      Toaster({
        type: "success",
        msg: "Password has been reset",
      });
    } catch (error) {
      const data = error.response.data;
      Toaster({
        type: "error",
        msg: data.message ? data.message : "Oops, something went wrong!",
      });
    }
  };

  return (
    <>
      <Form>
        <Form.Group>
          <div className="tw-mb-1 tw-flex tw-justify-between">
            <span
              style={{
                fontSize: "0.8rem",
                lineHeight: "1.8",
                color: "#0a0a0a",
              }}
            >
              New password
            </span>
            <span
              onClick={handleShowPassword}
              style={{ userSelect: "none" }}
              className="tw-text-sm tw-cursor-pointer tw-flex tw-items-center"
            >
              {showPassword ? (
                <>
                  <FaEyeSlash className="tw-mr-2" /> Hide
                </>
              ) : (
                <>
                  <FaEye className="tw-mr-2" /> Show
                </>
              )}
            </span>
          </div>
          <FormInputText
            type={showPassword ? "text" : "password"}
            name={"password"}
            placeholder={"Enter new password"}
            control={control}
            errors={errors}
          />
        </Form.Group>
        <Form.Group>
          <FormInputText
            type={"password"}
            name={"password_confirmation"}
            label={"Confirm new password"}
            placeholder={"Confirm new password"}
            control={control}
            errors={errors}
          />
        </Form.Group>
        <Button
          loading={isSubmitting}
          onClick={handleSubmit(onSubmit)}
          className="tw-mt-10"
          appearance="primary"
          block
          type="submit"
        >
          Reset Password
        </Button>
      </Form>
    </>
  );
};

export default ResetPasswordForm;
