// React
import React from "react";
import {
  Route,
  Routes,
  BrowserRouter,
  Outlet,
  useParams,
} from "react-router-dom";

// FutureLab
import {
  PortalMentorProfileProvider,
  usePortalMentorProfile,
  IMentorContext,
} from "../contexts/mentor_profile";
import { UserProvider } from "../contexts/user";
import { PortalProvider } from "../contexts/portal";
import { VariablesProvider } from "../contexts/variables";
import AppContainer from "./AppContainer";
import MentorProfileContent from "./user_profile/mentor/MentorProfileContent";

// UI
import { Loader } from "rsuite";

const MentorProfilePortal = ({ variables }) => {
  return (
    <BrowserRouter>
      <AppContainer>
        <Routes>
          <Route
            path="/app/:enterpriseSlug/:programSlug/members/:mentorId"
            element={<MentorProvider variables={variables} />}
          >
            <Route
              path="/app/:enterpriseSlug/:programSlug/members/:mentorId"
              element={
                <MentorProfileContent convertText={[]} showSurvey={false} />
              }
            />
          </Route>
        </Routes>
      </AppContainer>
    </BrowserRouter>
  );
};

function MentorProvider({ variables }) {
  const { enterpriseSlug, programSlug } = useParams();

  return (
    <VariablesProvider variables={variables}>
      <UserProvider>
        <PortalProvider
          enterpriseSlug={enterpriseSlug}
          programSlug={programSlug}
        >
          <PortalMentorProfileProvider>
            <RenderMentorProfile />
          </PortalMentorProfileProvider>
        </PortalProvider>
      </UserProvider>
    </VariablesProvider>
  );
}

function RenderMentorProfile() {
  const { mentor, setMentor, refreshMentor, type } = usePortalMentorProfile();

  if (!mentor) return <Loader center content="loading" />;
  return (
    <Outlet
      context={
        {
          mentor,
          setMentor,
          refreshMentor,
          type,
        } as IMentorContext
      }
    />
  );
}

export default MentorProfilePortal;
