// React
import React from "react";

// FutureLab
import useDrag from "../../util/useDrag";

// 3rd Party
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";

export default function HorizontalScroller({ children }) {
  const { dragStart, dragStop, dragMove, dragging } = useDrag();
  const handleDrag =
    ({ scrollContainer }) =>
    (ev: React.MouseEvent) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  return (
    <div onMouseLeave={dragStop}>
      <ScrollMenu
        // LeftArrow={LeftArrow}
        // RightArrow={RightArrow}
        onMouseDown={() => dragStart}
        onMouseUp={() => dragStop}
        onMouseMove={handleDrag}
      >
        {children}
      </ScrollMenu>
    </div>
  );
}
