import React from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { Button, Form, Input, Tag, Toggle, Tooltip, Whisper } from "rsuite";
import {
  FormInputText,
  FormSelectPicker,
} from "../../../form/default/FormInputs";

import getQuestionField from "../../application/getQuestionField";
import { MdContentCopy, MdInfoOutline, MdOutlineDelete } from "react-icons/md";
import _ from "lodash";

interface ImpactQuestions {
  idx: string;
  id: string | number | undefined;
  user_type: string;
  title: string;
  category: string;
  primary: boolean;
  primary_tag: string;
  stars: string;
  description: string;
  required: boolean;
  _destroy: boolean;
  // Define other properties as needed
}

const ImpactForms = (props) => {
  const { formProps, watchQuestions, activeKey } = props;

  const {
    control,
    getValues,
    setValue,
    register,
    formState: { errors },
  } = formProps;

  const {
    fields: impactFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "impact_questions",
    keyName: "idx",
  });

  // Custom Review Questions user type are inversed
  // E.g. In mentee's feedback view, we're showing question user_type for 'mentors'
  const userTypeQuestion = activeKey === "mentee" ? "mentor" : "mentee";

  return (
    <>
      <Form.Group>
        {impactFields.map((field: ImpactQuestions, index: number) => {
          const primaryQuestion = field?.primary === true ? true : false;

          if (field.user_type === userTypeQuestion) {
            return (
              <div
                className={`tw-border-solid tw-border tw-border-gray-200 tw-rounded-lg tw-p-4 tw-my-4 ${
                  getValues(`impact_questions.${index}._destroy`) && "hidden"
                }`}
                key={field.idx}
              >
                {primaryQuestion && (
                  <div className="tw-mb-4">
                    <Whisper
                      placement="top"
                      trigger="hover"
                      speaker={
                        <Tooltip>
                          This question is mandatory. The feedback will be
                          included in your analytics.
                        </Tooltip>
                      }
                    >
                      <Tag color="blue">
                        <div className="tw-flex tw-align-middle">
                          <MdInfoOutline
                            style={{ fontSize: "16px" }}
                            className="tw-mr-1 tw-my-1"
                          />
                          <span className="montserrat tw-text-xs tw-my-1 text-white">
                            Primary Question
                          </span>
                        </div>
                      </Tag>
                    </Whisper>
                  </div>
                )}
                <div>
                  <Controller
                    name={`impact_questions.${index}.id`}
                    control={control}
                    defaultValue={field.id || undefined}
                    render={({ field }) => (
                      <Input {...field} className="tw-hidden" />
                    )}
                  />

                  <Controller
                    name={`impact_questions.${index}._destroy`}
                    control={control}
                    defaultValue={field._destroy || false}
                    render={({ field: { name, value } }) => (
                      <Toggle
                        defaultChecked={value}
                        onChange={(value) => setValue(name, value)}
                        className="tw-hidden"
                      />
                    )}
                  />

                  <Controller
                    name={`impact_questions.${index}.user_type`}
                    control={control}
                    defaultValue={userTypeQuestion}
                    render={({ field }) => (
                      <Input {...field} className="tw-hidden" />
                    )}
                  />

                  <div className="tw-inline-flex tw-w-full tw-justify-between">
                    <Form.Group className="tw-w-full tw-mr-2">
                      <FormInputText
                        name={`impact_questions.${index}.title`}
                        plaintext={primaryQuestion}
                        errors={errors}
                        control={control}
                        placeholder={`Question Title`}
                        className="tw-font-medium tw-whitespace-nowrap"
                      />
                    </Form.Group>

                    {!primaryQuestion && (
                      <Form.Group>
                        <FormSelectPicker
                          name={`impact_questions.${index}.category`}
                          control={control}
                          errors={errors}
                          readOnly={primaryQuestion}
                          placeholder={"Question Type"}
                          style={{ width: "145px" }}
                          data={[
                            {
                              label: "Scale",
                              value: "scale",
                            },
                            {
                              label: "Stars",
                              value: "stars",
                            },
                            {
                              label: "Text",
                              value: "text",
                            },
                            {
                              label: "Options",
                              value: "options",
                            },
                          ]}
                          searchable={false}
                        />
                      </Form.Group>
                    )}
                  </div>

                  {!primaryQuestion && (
                    <>
                      <Form.Group>
                        <FormInputText
                          name={`impact_questions.${index}.description`}
                          control={control}
                          errors={errors}
                          defaultValue={""}
                          placeholder={"Description"}
                          label={"Description (Optional)"}
                          as="textarea"
                          rows={2}
                        />
                      </Form.Group>

                      <Form.Group>
                        {getQuestionField({
                          question: watchQuestions[index],
                          control,
                          register,
                          index,
                          field,
                          errors,
                          primaryQuestion,
                        })}
                      </Form.Group>

                      <div className="tw-inline-flex tw-w-full tw-justify-between tw-align-middle">
                        <div className="tw-inline-flex">
                          <Form.ControlLabel className="tw-pr-2">
                            Required question
                          </Form.ControlLabel>

                          <Controller
                            name={`impact_questions.${index}.required`}
                            control={control}
                            defaultValue={false}
                            render={({ field: { name, value } }) => (
                              <Toggle
                                checked={value}
                                onChange={(value) => setValue(name, value)}
                              />
                            )}
                          />
                        </div>

                        <div>
                          <Whisper
                            placement="top"
                            trigger="hover"
                            speaker={<Tooltip>Duplicate Question</Tooltip>}
                          >
                            <a
                              onClick={() => {
                                let values = getValues();
                                append(
                                  _.omit(values.impact_questions[index], [
                                    "idx",
                                    "id",
                                  ])
                                );
                              }}
                              className="tw-px-2 tw-pr-4 tw-cursor-pointer"
                            >
                              <MdContentCopy />
                            </a>
                          </Whisper>
                          <Whisper
                            placement="top"
                            trigger="hover"
                            speaker={<Tooltip>Delete Question</Tooltip>}
                          >
                            <a
                              onClick={() => {
                                // Keyname is required in useFieldArray() else it would generate an hash id.
                                if (field.id !== undefined) {
                                  setValue(
                                    `impact_questions.${index}._destroy`,
                                    true
                                  );
                                } else {
                                  remove(index);
                                }
                              }}
                              className="tw-px-2 tw-cursor-pointer"
                            >
                              <MdOutlineDelete
                                style={{ fontSize: "18px", color: "red" }}
                              />
                            </a>
                          </Whisper>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            );
          }
        })}
        <Button
          appearance="ghost"
          className="tw-my-4"
          onClick={() =>
            append({ category: "text", user_type: userTypeQuestion })
          }
        >
          + {"Add question"}
        </Button>
      </Form.Group>
    </>
  );
};

export default ImpactForms;
