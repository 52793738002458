// React
import React, { useEffect, useState } from "react";

// FutureLab
import { usePortal } from "contexts/portal";
import { StartingGuide } from "../components/StartingGuide";
import { DashboardAnalytics } from "../components/DashboardAnalytics";
import { DashboardAgenda } from "../components/DashboardAgenda";
import { PortalApi } from "util/portal_api";
import GenericMsgLoader from "components/shared/GenericMsgLoader";
import { DashboardOverviewProps } from "../types";

const Dashboard = () => {
  const { program } = usePortal();
  const [data, setData] = useState<DashboardOverviewProps>();

  useEffect(() => {
    if (program) {
      PortalApi({
        method: "get",
        path: "/dashboard/overview",
        options: { program_id: program?.id },
        success: ({ data }) => {
          setData(data);
        },
        error: (data) => {
          console.log("Error:", data);
        },
      });
    }
  }, [program]);

  if (!data)
    return (
      <div className="tw-flex tw-h-full tw-justify-center tw-items-center tw-w-full">
        <GenericMsgLoader vertical />
      </div>
    );

  return (
    <div className="tw-flex tw-justify-center">
      <div className="tw-max-w-4xl tw-min-w-[896px]">
        <h1 className="tw-text-xl">Welcome to {program?.name}</h1>

        {!data.setup_status.overall && (
          <StartingGuide setupStatus={data.setup_status} />
        )}
        {data?.analytics && <DashboardAnalytics analytics={data.analytics} />}
        <DashboardAgenda agendas={data.agendas} />
      </div>
    </div>
  );
};

export default Dashboard;
