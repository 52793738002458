import React from "react";
import { withContainer } from "../components";
import { Link } from "react-router-dom";

export const Success = withContainer(() => {
  return (
    <>
      <h1>Success</h1>
      <Link to="/login"> go to Login</Link>
    </>
  );
});
