import React, { useEffect, useState, useMemo } from "react";

import { Button, Nav } from "rsuite";
import TooltipHelper from "../ui/TooltipHelper";
import UserCards from "./users/UserCards";
import translateText from "../../util/translateText";

import profileNotComplete from "../../images/profile_not_complete.png";
import UserCardsSkeletonWrapper from "../shared/cards/skeleton/UserCardsSkeleton";
import { PortalApi } from "../../util/portal_api";
import PortalUserCards from "../portal/dashboard/users/PortalUserCards";

type Data = {
  suggested: any[];
  my_mentors: any[];
  following: any[];
};

const url = {
  production: "https://app.futurelab.my/profile/setup",
  staging: "https://app.futurelab.global/profile/setup",
  development: "http://localhost:4000/profile/setup",
};

const env = process.env.RAILS_ENV;
const linkUrl = url[env];

const PortalDashboardMentors = ({ convertText, currentUser, programId }) => {
  const [data, setData] = useState<Data>(undefined);
  const [isFetching, setIsFetching] = useState(true);
  const [activeKey, setActiveKey] = useState("suggested");

  const suggested = useMemo(() => data?.suggested || [], [data]);
  const mymentors = useMemo(() => data?.my_mentors || [], [data]);

  useEffect(() => {
    PortalApi({
      method: "get",
      path: `/dashboard/users/mentors`,
      options: { program_id: programId },
      success: ({ data: res }) => {
        setIsFetching(false);
        setData(res);
        if (res.suggested.length === 0) {
          setActiveKey("my_mentors");
        }
      },
    });
  }, []);

  const activeDisplay = (
    activeKey,
    data = {
      suggested: [],
      my_mentors: [],
    }
  ) => {
    const { suggested, my_mentors } = data;
    const obj = {
      suggested: {
        data: suggested,
        empty_text: "You do not have any suggested mentors at the moment.",
        button_link: "/discover/mentors",
      },
      my_mentors: {
        data: my_mentors,
        empty_text:
          "You are not currently connected with any mentors. Speak to your first mentor now!",
        button_link: "/discover/mentors",
      },
    };
    return obj[activeKey];
  };

  const dataitems = useMemo(
    () => activeDisplay(activeKey, data),
    [activeKey, data]
  );

  return (
    <>
      <div className="tw-w-full tw-block">
        <div className="sm:tw-w-full sm:tw-text-center sm:tw-mb-4 tw-mb-5 tw-flex tw-justify-between ">
          <div className="tw-inline-flex tw-items-center">
            <p className="section-header montserrat">Mentors</p>
            <TooltipHelper
              tip={"These are the mentors that you have connected with"}
            />
          </div>
        </div>
        <Nav
          appearance="subtle"
          activeKey={activeKey}
          onSelect={setActiveKey}
          className="tw-mb-5"
          justified
        >
          {suggested.length > 0 && (
            <Nav.Item eventKey="suggested" className="tw-text-center">
              {translateText(convertText, "Matched")} ({suggested?.length || 0})
            </Nav.Item>
          )}
          <Nav.Item eventKey="my_mentors" className="tw-text-center">
            {translateText(convertText, "Your Mentors")} (
            {mymentors?.length || 0})
          </Nav.Item>
        </Nav>
      </div>
      {isFetching ? (
        <UserCardsSkeletonWrapper />
      ) : (
        <PortalUserCards
          forMentor
          activeKey={activeKey}
          user={dataitems.data}
          currentUser={currentUser}
          convertText={convertText}
          emptyText={dataitems.empty_text}
        />
      )}
    </>
  );
};

export default PortalDashboardMentors;
