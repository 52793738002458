// React
import React, { useState } from "react";

// FutureLab
import Api from "../../util/api";
import JobModal from "./JobModal";

// UI
import { Divider, Form, Stack } from "rsuite";
import { FaBuilding } from "react-icons/fa";

// 3rd Party
import { DateTime } from "luxon";
import LinkButton from "../ui/LinkButton";

export interface JobInput {
  name: string;
  company_name: string;
  start_date: Date;
  end_date: Date;
  description: string;
  is_current: boolean;
}

const fetchCompanies = async (query = "") => {
  return await Api.get("/companies", { search: query });
};

const WorkExperiences = ({ user }) => {
  const [companies, setCompanies] = useState<[]>();
  const [job, setJob] = useState<JobInput>();
  const [loading, setLoading] = React.useState(false);
  const [showEditModal, setShowEditModal] = React.useState(false);

  const handleSearch = async (query) => {
    setLoading(true);
    try {
      const { data } = await fetchCompanies(query);
      setCompanies(data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const initializedJobModal = async (selectedJob) => {
    await handleSearch(selectedJob?.company?.name);
    setJob(selectedJob);
    setShowEditModal(true);
  };

  return (
    <Form.Group>
      <Stack justifyContent="space-between">
        <Form.ControlLabel className="tw-text-base">
          Current work experiences
        </Form.ControlLabel>
        <LinkButton
          className="tw-no-underline"
          onClick={() => initializedJobModal({})}
        >
          Add new
        </LinkButton>
      </Stack>
      <div className="tw-h-36 tw-w-full tw-border tw-border-solid tw-border-gray-300 tw-p-3 tw-overflow-y-auto">
        {user.jobs.map((job, idx) => (
          <div key={job.id}>
            <Stack justifyContent="space-between" alignItems="flex-start">
              <Stack spacing={8} alignItems="flex-start">
                {job.company.photo.url ? (
                  <img src={job.company.photo.url} alt="" className="tw-w-24" />
                ) : (
                  <div className="tw-w-24 tw-h-24 tw-text-center tw-bg-gray-200 tw-align-middle tw-flex">
                    <FaBuilding className="tw-text-5xl tw-text-gray-400 tw-m-auto" />
                  </div>
                )}
                <div>
                  <h6 className="tw-mb-1">{job.name}</h6>
                  <p className="tw-mb-1">{job.company.name}</p>
                  <p className="tw-mb-1">
                    {DateTime.fromISO(job.start_date).toFormat("MMM yyyy")} -{" "}
                    {job.end_date
                      ? DateTime.fromISO(job.end_date).toFormat("MMM yyyy")
                      : "Present"}
                  </p>
                  <p className="tw-mb-1">{job.description}</p>
                </div>
              </Stack>
              <LinkButton
                className="tw-no-underline"
                onClick={() => initializedJobModal(job)}
              >
                Edit
              </LinkButton>
            </Stack>
            {idx !== user.jobs.length - 1 && <Divider className="tw-my-2" />}
          </div>
        ))}
      </div>
      {showEditModal && (
        <JobModal
          user={user}
          job={job}
          companies={companies}
          handleSearch={handleSearch}
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          loading={loading}
        />
      )}
    </Form.Group>
  );
};

export default WorkExperiences;
