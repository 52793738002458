import React from "react";
import { Col, Form, Input, SelectPicker } from "rsuite";
import FieldError from "../ui/FieldError";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import translateText from "../../util/translateText";
import _ from "lodash";

const Departments = ({ convertText, control, func, errors, watch, user }) => {
  const setValue = func;
  const watchDepartment = watch;

  return (
    <Col xs={24} md={12}>
      <Form.Group className="tw-mb-6">
        <Form.ControlLabel className="tw-text-base">
          {translateText(convertText, "What departments are you in?")}
        </Form.ControlLabel>
        <Controller
          name="info.department"
          control={control}
          render={({ field }) => (
            <SelectPicker
              data={_.map(user.info.department_list, (value, key) => {
                return { label: value, value: value };
              })}
              style={{ width: "100%" }}
              placement="autoVerticalStart"
              onChange={(value) => {
                setValue(field.name, value);
              }}
              placeholder={translateText(convertText, "Please select")}
              size="lg"
              {...field}
            />
          )}
        />
        <ErrorMessage errors={errors} name="info.department" as={FieldError} />
      </Form.Group>
      {watchDepartment?.includes("Other") && (
        <Form.Group>
          <Form.ControlLabel className="tw-text-base">
            {translateText(convertText, "Others - Please specify")}
            <span className="tw-text-red-500">*</span>
          </Form.ControlLabel>
          <Controller
            name="info.others_department_detail"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                onChange={(value) => {
                  setValue(field.name, value);
                }}
              />
            )}
          />
        </Form.Group>
      )}
    </Col>
  );
};

export default Departments;
