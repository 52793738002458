import React from "react";
import {
  FaMapMarkerAlt,
  FaRegCalendar,
  FaRegClock,
  FaRegListAlt,
} from "react-icons/fa";
import { Button, Stack } from "rsuite";

import useDrag from "../../../util/useDrag";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";

import avatarPlaceholder from "../../../images/avatar_placeholder.png";
import translateText from "../../../util/translateText";

const EventCards = (props) => {
  const { events, convertText, activeKey } = props;
  const { dragStart, dragStop, dragMove, dragging } = useDrag();
  const handleDrag =
    ({ scrollContainer }) =>
    (ev: React.MouseEvent) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  return (
    <>
      {events?.length > 0 ? (
        <div onMouseLeave={dragStop}>
          <ScrollMenu
            // LeftArrow={LeftArrow}
            // RightArrow={RightArrow}
            onMouseDown={() => dragStart}
            onMouseUp={() => dragStop}
            onMouseMove={handleDrag}
          >
            <Stack
              className="tw-overflow-x-auto no-scrollbar"
              alignItems="stretch"
            >
              {events?.map((event) => (
                <div className="dash-event-card" key={event.id}>
                  <img src={event.photo} className="tw-w-full" />
                  <div className="tw-px-3 tw-pt-4 tw-pb-2">
                    <h6 className="tw-h-16 tw-mb-3 tw-overflow-hidden">
                      {event.title}
                    </h6>
                    <p
                      className="montserrat tw-font-bold tw-mb-2"
                      style={{ color: "#DB6057" }}
                    >
                      {event.date}
                    </p>

                    <div className="tw-flex tw-items-center montserrat mb-1">
                      <span className="tw-flex tw-items-center">
                        <FaRegClock />
                      </span>
                      <span className="tw-ml-3">
                        {event.start_time} - {event.end_time}
                      </span>
                    </div>
                    <div className="tw-flex tw-items-center montserrat">
                      <span className="tw-flex tw-items-center">
                        <FaMapMarkerAlt />
                      </span>
                      <span className="tw-ml-3 tw-truncate">
                        {event.event_type == "webinar"
                          ? "Online"
                          : event.event_type}
                      </span>
                    </div>
                  </div>
                  <div
                    className="tw-full tw-px-3 tw-pb-4 tw-pt-2"
                    style={{
                      marginTop: "auto",
                    }}
                  >
                    <Button href={event.link_path} appearance="ghost">
                      View more
                    </Button>
                  </div>
                </div>
              ))}
              {activeKey === "2" && (
                <a href="/main/events">
                  <div className="dash-event-see-all-card">
                    <span>
                      <FaRegListAlt
                        className="tw-w-12 tw-h-12"
                        color="#428AC9"
                      />
                    </span>
                    {translateText(convertText, "See all")}
                  </div>
                </a>
              )}
            </Stack>
          </ScrollMenu>
        </div>
      ) : (
        <div className="tw-w-full tw-text-center sm:tw-w-full sm:tw-text-center sm:tw-mb-4">
          <p className="montserrat tw-text-center tw-mt-10">
            You have not joined any upcoming events at the moment.
          </p>
          <Button appearance="primary">Join an event now</Button>
        </div>
      )}
    </>
  );
};

export default EventCards;
