// React
import React from "react";

// FutureLab
import {
  ArrayCell,
  NumberCell,
  TimeCell,
} from "./data_table_component/DataTableCellType";
import DataTable from "./DataTable";

const programmeMentorColumns = [
  {
    Header: "User ID",
    accessor: "userId",
    Cell: NumberCell,
  },
  {
    Header: "How did you find out about FutureLab?",
    accessor: "howDidYouFindOutAboutFutureLab",
    Cell: ArrayCell,
  },
  {
    Header: "First Name",
    accessor: "firstName",
  },
  {
    Header: "Last Name",
    accessor: "lastName",
  },
  {
    Header: "Email Address",
    accessor: "email",
  },
  {
    Header: "Signup Date",
    accessor: "signupDate",
    Cell: TimeCell,
  },
  {
    Header: "Last Login Date",
    accessor: "lastLoginDate",
    Cell: TimeCell,
  },
  {
    Header: "No. of Programme Joined",
    accessor: "noOfProgrammesJoined",
    Cell: NumberCell,
  },
  {
    Header: "Which Programme(s)",
    accessor: "programmesJoined",
    Cell: ArrayCell,
  },
  {
    Header: "Joined Through Programme Application",
    accessor: "joinedThroughProgrammeApplication",
  },
  {
    Header: "Date of birth",
    accessor: "dateOfBirth",
    Cell: TimeCell,
  },
  {
    Header: "Country",
    accessor: "country",
  },
  {
    Header: "Spoken Languages",
    accessor: "spokenLanguages",
    Cell: ArrayCell,
  },
  {
    Header: "Job Position",
    accessor: "job_title",
  },
  {
    Header: "Which company are you from?",
    accessor: "company",
  },
  {
    Header: "Which university are you from?",
    accessor: "university",
    Cell: ArrayCell,
  },
  {
    Header: "Who do you want to mentor?",
    accessor: "whoDoYouWantToMentor",
  },
  {
    Header: "What job role are you interested in?",
    accessor: "jobRole",
    Cell: ArrayCell,
  },
  {
    Header: "I can help in these areas",
    accessor: "helpAreas",
    Cell: ArrayCell,
  },
  {
    Header: "Linkedin Profile URL",
    accessor: "linkedinUrl",
  },
  {
    Header: "No. of FutureLab Bookings Received",
    accessor: "bookingsReceived",
    Cell: NumberCell,
  },
  {
    Header: "No. of FutureLab Bookings Requests Accepted as a Mentor",
    accessor: "bookingsAccepted",
    Cell: NumberCell,
  },
  {
    Header: "No. of FutureLab Events the Mentor has been tagged in",
    accessor: "eventsTaggedIn",
    Cell: NumberCell,
  },
];

const ProgrammeMentorSignupData = ({
  initialProgrammeMentors = [],
  maxPages,
  fields,
}) => {
  return (
    <DataTable
      initialData={initialProgrammeMentors}
      maxPages={maxPages}
      title="Programme Mentor Signup Data"
      column={programmeMentorColumns as any}
      fields={fields}
    />
  );
};

export default ProgrammeMentorSignupData;
