import React from "react";
import { Col, FlexboxGrid, Pagination, Table } from "rsuite";
const { Column, HeaderCell, Cell } = Table;
import avatarPlaceholder from "../../images/avatar_placeholder.png";

const UserTable = (props) => {
  const {
    users,
    loading,
    total,
    limit,
    handleChangeLimit,
    handleChangePage,
    page,
    userType,
  } = props;

  const menteeData = userType == "mentee";
  const mentorData = userType == "mentor";

  const userAvatar = (photo_url) => (
    <div>
      <img
        src={photo_url || avatarPlaceholder}
        alt="photo_url"
        style={{ width: 40 }}
        className="tw-rounded-xl tw-mr-2"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = avatarPlaceholder;
        }}
      />
    </div>
  );

  return (
    <FlexboxGrid.Item
      as={Col}
      colspan={22}
      xs={24}
      md={20}
      lg={22}
      className="tw-my-10"
    >
      <Table
        data={users}
        wordWrap
        autoHeight
        rowClassName="tw-text-sm"
        rowHeight={60}
        loading={loading}
        className="tw-my-6"
        renderRow={(children) => <div>{children}</div>}
      >
        <Column
          align="center"
          verticalAlign="middle"
          minWidth={100}
          flexGrow={1}
          fixed
        >
          <HeaderCell>ID</HeaderCell>
          <Cell dataKey="id" />
        </Column>

        <Column align="center" verticalAlign="middle" fixed>
          <HeaderCell>Profile pic</HeaderCell>
          <Cell>{(rowData) => userAvatar(rowData.profile_photo)}</Cell>
        </Column>

        <Column
          align="center"
          verticalAlign="middle"
          minWidth={200}
          flexGrow={1}
          fixed
        >
          <HeaderCell>First name</HeaderCell>
          <Cell dataKey="firstname" />
        </Column>

        <Column
          align="center"
          verticalAlign="middle"
          minWidth={200}
          flexGrow={1}
        >
          <HeaderCell>Last name</HeaderCell>
          <Cell dataKey="lastname" />
        </Column>

        <Column
          align="center"
          verticalAlign="middle"
          flexGrow={1}
          minWidth={350}
        >
          <HeaderCell>Email</HeaderCell>
          <Cell dataKey="email" />
        </Column>

        <Column
          align="center"
          verticalAlign="middle"
          minWidth={150}
          flexGrow={1}
        >
          <HeaderCell>Phone number</HeaderCell>
          <Cell dataKey="phone_no" />
        </Column>

        <Column
          align="center"
          verticalAlign="middle"
          minWidth={150}
          flexGrow={1}
        >
          <HeaderCell>Age</HeaderCell>
          <Cell dataKey="age" />
        </Column>

        <Column
          align="center"
          verticalAlign="middle"
          minWidth={300}
          flexGrow={1}
        >
          <HeaderCell>Country / State / Province</HeaderCell>
          <Cell dataKey="location" />
        </Column>

        {mentorData && (
          <>
            <Column
              align="center"
              verticalAlign="middle"
              minWidth={300}
              flexGrow={2}
            >
              <HeaderCell>Occupation</HeaderCell>
              <Cell dataKey="job_title" />
            </Column>

            <Column
              align="center"
              verticalAlign="middle"
              minWidth={300}
              flexGrow={2}
            >
              <HeaderCell>Company</HeaderCell>
              <Cell dataKey="company" />
            </Column>

            <Column
              align="center"
              verticalAlign="middle"
              minWidth={300}
              flexGrow={1}
            >
              <HeaderCell>Industry</HeaderCell>
              <Cell>
                {(rowData) => (
                  <ul className="tw-text-left">
                    {rowData.industries?.map((x, idx) => (
                      <li key={idx}>{x}</li>
                    ))}
                  </ul>
                )}
              </Cell>
            </Column>

            <Column
              align="center"
              verticalAlign="middle"
              minWidth={150}
              flexGrow={1}
            >
              <HeaderCell>Do Not Disturb</HeaderCell>
              <Cell>{(rowData) => (rowData.dnd == true ? "Yes" : "No")}</Cell>
            </Column>
          </>
        )}

        {menteeData && (
          <>
            <Column
              align="center"
              verticalAlign="middle"
              minWidth={200}
              flexGrow={1}
            >
              <HeaderCell>Employment Status</HeaderCell>
              <Cell dataKey="employment_status" />
            </Column>

            <Column
              align="center"
              verticalAlign="middle"
              minWidth={300}
              flexGrow={2}
            >
              <HeaderCell>University</HeaderCell>
              <Cell dataKey="university" />
            </Column>

            <Column
              align="center"
              verticalAlign="middle"
              minWidth={300}
              flexGrow={1}
            >
              <HeaderCell>Interested Industry</HeaderCell>
              <Cell>
                {(rowData) => (
                  <ul className="tw-text-left">
                    {rowData.interested_industries?.map((x, idx) => (
                      <li key={idx}>{x}</li>
                    ))}
                  </ul>
                )}
              </Cell>
            </Column>

            <Column
              align="center"
              verticalAlign="middle"
              minWidth={300}
              flexGrow={1}
            >
              <HeaderCell>Interested Company</HeaderCell>
              <Cell>
                {(rowData) => (
                  <ul className="tw-text-left">
                    {rowData.interested_companies?.map((x, idx) => (
                      <li key={idx}>{x}</li>
                    ))}
                  </ul>
                )}
              </Cell>
            </Column>
          </>
        )}

        <Column
          align="center"
          verticalAlign="middle"
          minWidth={200}
          flexGrow={1}
        >
          <HeaderCell>Signed up at</HeaderCell>
          <Cell dataKey="created_at" />
        </Column>

        <Column
          align="center"
          verticalAlign="middle"
          minWidth={200}
          flexGrow={1}
        >
          <HeaderCell>Last logged in</HeaderCell>
          <Cell dataKey="last_active" />
        </Column>

        <Column
          align="center"
          verticalAlign="middle"
          minWidth={200}
          flexGrow={1}
        >
          <HeaderCell>No. of booking</HeaderCell>
          <Cell dataKey="no_bookings" />
        </Column>

        <Column
          align="center"
          verticalAlign="middle"
          minWidth={200}
          flexGrow={1}
        >
          <HeaderCell>No. of events joined</HeaderCell>
          <Cell dataKey="no_events" />
        </Column>

        <Column
          align="center"
          verticalAlign="middle"
          minWidth={200}
          flexGrow={1}
        >
          <HeaderCell>No. of programmes</HeaderCell>
          <Cell dataKey="no_programs" />
        </Column>
      </Table>
    </FlexboxGrid.Item>
  );
};

export default UserTable;
