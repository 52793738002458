// React
import React, { useMemo, useState } from "react";
import { useOutletContext, useLocation, useNavigate } from "react-router-dom";

// FutureLab
import Table from "./Table";

// UI
import { Input, InputGroup, SelectPicker, Button, Modal } from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import { FaArrowLeftLong } from "react-icons/fa6";

const type = [
  {
    label: "Admin",
    value: "admin",
  },
  {
    label: "Mentor",
    value: "mentor",
  },
  {
    label: "Mentee",
    value: "mentee",
  },
];

const UserList = () => {
  const [open, setOpen] = useState(false);
  const { mentors, mentees, admins } = useOutletContext() as any;
  const { state } = useLocation();
  const navigate = useNavigate();
  const groupParticipants = state?.selectedParticipants || [];
  const chatId = state?.selectedGroup?.id || null;
  const [selected, setSelected] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [searchType, setSearchType] = useState(null);

  const onClose = () => setOpen(false);
  const handleAdd = async () => {
    const recipientUserIds = selected.map((user) => user.id);
    await state?.addUsers(chatId, recipientUserIds);
    navigate(state?.from || "/chat-management");
  };
  const users = useMemo(() => {
    const join =
      searchType === "admin"
        ? admins
        : searchType === "mentor"
        ? mentors
        : searchType === "mentee"
        ? mentees
        : [...admins, ...mentors, ...mentees];

    const unique = join.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id);
      if (!x) {
        acc.push(current);
      }
      return acc;
    }, []);
    const ids = groupParticipants.map((participant) => participant.id);
    const notInGroup = unique.filter((user) => !ids.includes(user.id));
    return notInGroup;
  }, [mentors, mentees, groupParticipants, admins, searchType]);

  const filteredUsers = useMemo(() => {
    if (!search) return users;
    //filter by name or email
    return users.filter(
      (user) =>
        user.name.toLowerCase().includes(search.toLowerCase()) ||
        user.email.toLowerCase().includes(search.toLowerCase())
    );
  }, [users, search]);

  return (
    <div>
      <InputHeader
        openModal={() => setOpen(true)}
        disabled={selected.length === 0}
        setSearchType={setSearchType}
        setSearch={setSearch}
        navigate={() => navigate(state?.from || "/chat-management")}
      />
      <Table setSelected={setSelected} data={filteredUsers} columns={[]} />
      <InviteModal open={open} onClose={onClose} handleAdd={handleAdd} />
    </div>
  );
};

const InviteModal = ({ open, onClose, handleAdd }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Add Member</Modal.Title>
      </Modal.Header>
      <Modal.Body className="tw-flex tw-justify-center tw-items-center">
        <span className="tw-bold">Are you sure you want to add this user?</span>
      </Modal.Body>
      <Modal.Footer className="tw-flex tw-justify-evenly tw-items-center">
        <Button onClick={onClose} appearance="ghost">
          Cancel
        </Button>
        <Button onClick={handleAdd} appearance="primary">
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const InputHeader = ({
  disabled,
  openModal,
  setSearchType,
  setSearch,
  navigate,
}) => {
  return (
    <div className="tw-flex tw-items-center tw-justify-between tw-gap-2 tw-mb-5">
      <div className="tw-flex">
        <FaArrowLeftLong
          size={24}
          className="tw-mr-3 hover:tw-cursor-pointer"
          onClick={navigate}
        />
        <span>User List</span>
      </div>
      <div className="tw-flex tw-gap-7">
        <InputGroup inside className="tw-w-80">
          <InputGroup.Button>
            <SearchIcon />
          </InputGroup.Button>
          <Input
            onChange={(e) => setSearch(e)}
            placeholder="Search name or email address"
          />
        </InputGroup>
        <div className="tw-flex tw-items-center tw-gap-3">
          <label>User Type</label>
          <SelectPicker
            data={type}
            appearance="default"
            placeholder="Select type"
            searchable={false}
            onChange={(value) => setSearchType(value)}
          />
        </div>
        <div>
          <Button
            onClick={openModal}
            disabled={disabled}
            appearance="primary"
            size="sm"
          >
            Invite to group
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserList;
