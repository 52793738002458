import React, { useEffect } from "react";
import { Navbar } from "rsuite";
//@ts-ignore
import FlLogo from "../../../images/FL_logo_main.svg";
//@ts-ignore
import divider from "../../../images/divider.svg";
import { MdChatBubbleOutline } from "react-icons/md";
import flCircleLogo from "../../../images/fl-circle-logo-border.png";

import { NavItemButton } from "../common";
import AvatarMenu from "../common/AvatarMenu";
import { usePortal } from "../../../contexts/portal";
import NavItemNotificationButton from "../component/NavItemNotificationButton";
import NavSearchForm from "../component/NavSearchForm";
import { useNavbarContext } from "../../../contexts/navbarContext";

const items = [
  // {
  //   icon: <MdOutlineSearch />,
  //   href: "/search",
  //   badge: false,
  // },
  {
    icon: <MdChatBubbleOutline />,
    href: "/messages",
    badge: false,
    redirect: "chat",
  },
  // {
  //   icon: <MdNotificationsNone />,
  //   href: "/notifications",
  //   badge: true,
  // },
];

const chatUrls = {
  development: "http://localhost:3005/chat",
  production: "https://newapp.futurelab.my/chat",
  staging: "https://newapp.futurelab.global/chat",
};

const chathref = chatUrls?.[process.env.RAILS_ENV] || chatUrls["development"];

const FLDesktopNav = () => {
  const { baseUrl, onPortal } = useNavbarContext();
  const { program } = usePortal();

  useEffect(() => {
    if (program) {
      bgColorGenerator(program);
    }
  }, [onPortal, program]);

  const bgColorGenerator = (program) => {
    let bgColour = "#428AC9";

    if (onPortal && program) {
      bgColour = program?.white_label?.dashboard_primary_colour || "#428AC9";
    }

    return bgColour;
  };

  return (
    <div
      className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-h-[60px] tw-text-white tw-px-[15px]"
      style={{
        backgroundColor: bgColorGenerator(program),
      }}
    >
      <div className="large-12 small-12 columns tw-px-[15px]">
        <div className="large-10 large-offset-1 small-12 columns tw-flex tw-items-center tw-justify-between">
          <Navbar.Brand
            href={
              onPortal
                ? `/app/${program?.enterprise?.slug}/${program?.slug}/overviews`
                : "/"
            }
            className="tw-flex tw-items-center"
          >
            <div id="portal_navbar_logo tw-relative">
              <img
                src={!onPortal ? FlLogo : program?.photo_url || flCircleLogo}
                alt="futurelab"
                id="program-logo"
                className="tw-max-h-[40px]"
              />
            </div>
            {onPortal && (
              <span className="tw-ml-2 tw-font-medium">{program?.name}</span>
            )}
          </Navbar.Brand>
          <div className="tw-flex tw-items-center">
            {!onPortal && (
              <div>
                <NavSearchForm />
              </div>
            )}
            <div className="tw-px-6">
              {items.map((item) => (
                <NavItemButton
                  key={item.href}
                  icon={item.icon}
                  badge={item.badge}
                  onClick={
                    item.redirect === "chat"
                      ? program
                        ? () =>
                            window.open(
                              `${chathref}?originURL=${program?.enterprise?.slug}/${program?.slug}`,
                              "_self"
                            )
                        : () => window.open(`${chathref}`, "_self")
                      : null
                  }
                />
              ))}
              <NavItemNotificationButton />
            </div>
            <img src={divider} alt="divider" />
            <AvatarMenu />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FLDesktopNav;
