import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Divider,
  Form,
  Message,
  Modal,
  SelectPicker,
  toaster,
} from "rsuite";
import { FutureLabApi } from "../../util/api";
import FieldError from "../ui/FieldError";
import LinkButton from "../ui/LinkButton";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUser } from "../../contexts/user";
import { FaExclamationTriangle } from "react-icons/fa";
import { last, map } from "lodash";
import { ValueType } from "rsuite/esm/Checkbox";
import translateText from "../../util/translateText";

type UserLanguageInput = {
  language_id: number;
  proficiency: ValueType[];
};

const schema = yup.object().shape({
  language_id: yup.string().required().label("Language").nullable(),
  proficiency: yup
    .array()
    .min(1, "Proficiency is a required field")
    .required()
    .label("Proficiency"),
});

const UserLanguageModal = ({
  userLanguage = undefined,
  languages,
  convertText,
}) => {
  const isEdit = !!userLanguage;
  const { refreshUser, user } = useUser();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);
  const [showEditModal, setShowEditModal] = React.useState(false);
  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm<UserLanguageInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      language_id: userLanguage?.language_id || "",
      proficiency: userLanguage?.proficiency ? [userLanguage?.proficiency] : [],
    },
  });

  const proficiencyOptions = [
    //:unspecified, :limited, :conversational, :professional, :native
    { label: translateText(convertText, "Unspecified"), value: "unspecified" },
    { label: translateText(convertText, "Limited"), value: "limited" },
    {
      label: translateText(convertText, "Conversational"),
      value: "conversational",
    },
    {
      label: translateText(convertText, "Professional"),
      value: "professional",
    },
    { label: translateText(convertText, "Native"), value: "native" },
  ];

  const onSubmit = (data) => {
    setIsSubmitting(true);
    if (!isEdit) data.user_id = user.id;
    data.proficiency = data.proficiency[0];

    FutureLabApi({
      method: isEdit ? "patch" : "post",
      path: isEdit ? `/user_languages/${userLanguage.id}` : `/user_languages`,
      options: data,
      success: ({ data }) => {
        setIsSubmitting(false);
        refreshUser();
        setShowEditModal(false);
        toaster.push(
          <Message showIcon type="success">
            {translateText(convertText, "Successfully saved.")}
          </Message>
        );
      },
      error: (err) => {
        const { error, messages } = err.response.data;
        setIsSubmitting(false);
        toaster.push(
          <Message showIcon type="error">
            {error}
          </Message>
        );
      },
    });
  };

  const deleteUserLanguage = () => {
    FutureLabApi({
      method: "delete",
      path: `/user_languages/${userLanguage.id}`,
      success: () => {
        refreshUser();
        setShowEditModal(false);
        toaster.push(
          <Message showIcon type="success">
            {translateText(convertText, "Successfully deleted.")}
          </Message>
        );
      },
    });
  };

  return (
    <>
      <LinkButton
        className="tw-no-underline"
        onClick={() => setShowEditModal(true)}
      >
        {isEdit
          ? translateText(convertText, "Edit")
          : translateText(convertText, "+ Add new")}
      </LinkButton>
      <Modal
        open={showEditModal}
        onClose={() => setShowEditModal(false)}
        className="tw-mt-16"
        size="sm"
      >
        <Modal.Header>
          <Modal.Title className="tw-text-xl tw-font-medium">
            {isEdit
              ? translateText(convertText, "Edit")
              : translateText(convertText, "Add")}{" "}
            {translateText(convertText, "language I can mentor")}
          </Modal.Title>
          <Divider className="tw-absolute tw-w-full tw-left-0 tw-mt-4 tw-bg-gray-300" />
        </Modal.Header>
        <Modal.Body>
          <Form fluid className="tw-pt-7">
            <Form.Group>
              <Form.ControlLabel className="tw-text-base">
                {translateText(convertText, "Language")}{" "}
                <span className="tw-text-red-500">*</span>
              </Form.ControlLabel>
              <Controller
                name="language_id"
                control={control}
                render={({ field }) => (
                  <SelectPicker
                    data={languages}
                    virtualized
                    block
                    placeholder={translateText(
                      convertText,
                      "Select a language"
                    )}
                    size="lg"
                    {...field}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="language_id"
                as={FieldError}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel className="tw-text-base">
                {translateText(convertText, "Select proficiency")}
                <span className="tw-text-red-500">*</span>
              </Form.ControlLabel>
              <Controller
                name="proficiency"
                control={control}
                render={({ field }) => (
                  <CheckboxGroup
                    name="proficiency"
                    {...field}
                    onChange={(value) => {
                      setValue("proficiency", [last(value)]);
                    }}
                  >
                    {map(proficiencyOptions, (proficiency) => (
                      <Checkbox
                        key={proficiency.value}
                        {...field}
                        value={proficiency.value}
                      >
                        <span className="tw-text-base">
                          {proficiency.label}
                        </span>
                      </Checkbox>
                    ))}
                  </CheckboxGroup>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="proficiency"
                as={FieldError}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Divider className="tw-absolute tw-w-full tw-left-0 tw-m-0 tw-bg-gray-300" />
        <Modal.Footer className="tw-pt-5">
          {isEdit && (
            <>
              <Button
                appearance="ghost"
                onClick={() => setShowDeleteConfirm(true)}
              >
                {translateText(convertText, "Remove")}
              </Button>
              <Modal
                backdrop="static"
                open={showDeleteConfirm}
                onClose={() => setShowDeleteConfirm(false)}
                size="xs"
              >
                <Modal.Body>
                  <FaExclamationTriangle className="tw-mr-4" />
                  {"  "}
                  {translateText(convertText, "Are you sure to delete it?")}
                </Modal.Body>
                <Modal.Footer className="text-center">
                  <Button
                    onClick={deleteUserLanguage}
                    appearance="primary"
                    color="red"
                  >
                    {translateText(convertText, "Confirm")}
                  </Button>
                  <Button
                    onClick={() => setShowDeleteConfirm(false)}
                    appearance="default"
                  >
                    {translateText(convertText, "Cancel")}
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          )}
          <Button
            appearance="primary"
            onClick={handleSubmit(onSubmit)}
            loading={isSubmitting}
          >
            {translateText(convertText, "Save")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserLanguageModal;
