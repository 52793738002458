import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Form,
  Loader,
  RadioTile,
  RadioTileGroup,
  TagInput,
  Toggle,
} from "rsuite";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  MdOutlineAdminPanelSettings,
  MdOutlineCached,
  MdOutlinePeopleAlt,
} from "react-icons/md";
import BottomNavCTA from "../component/BottomNavCTA";
import FieldError from "../../../form/FieldError";
import { ErrorMessage } from "@hookform/error-message";
import { PortalApi } from "../../../../util/portal_api";
import { usePortalSetup } from "../../../../contexts/portal/setup";
import {
  EmployeeEngagementProgramme,
  EntrepeneurshipProgramme,
  YouthEmployabilityProgramme,
} from "../templates/setup_templates";
import { useSetupVariable } from "../../../../contexts/portal/setup/setupVariables";

const schema = yup.object().shape({
  program: yup.object().shape({
    pairing_method: yup.string().required(),
    smart_matching: yup.bool(),
  }),
  program_goals: yup
    .array()
    .max(7, "You've exceeded a maximum of 7 options.")
    .required()
    .label("Mentoring Services"),
});

const PortalMentorshipSettingForm = (props) => {
  const { handleStep } = useSetupVariable();
  const { program, setProgram } = usePortalSetup();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const goalTemplateValues = (template_type) => {
    const template = {
      create_my_own: [
        "Career Guidance",
        "CV Reviews",
        "Mock Interview",
        "Business Mentoring",
      ],
      employee_engagement_programme: EmployeeEngagementProgramme.goals,
      youth_employability_programme: YouthEmployabilityProgramme.goals,
      entrepreneurship_programme: EntrepeneurshipProgramme.goals,
    };

    if (template_type) {
      return template[template_type];
    }

    return [];
  };

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      program: {
        pairing_method: program?.pairing_method || "",
      },
      // smart_matching: false,
      program_goals:
        program?.goals.length > 0
          ? program?.goals
          : goalTemplateValues(program?.template_type),
    },
  });

  const onSubmit = (data, saveDraft = false) => {
    setIsSubmitting(true);

    data.program_id = program.id;

    PortalApi({
      method: "post",
      path: "/programs",
      options: data,
      success: ({ data }) => {
        handleStep(saveDraft ? "save_draft" : "");
        setIsSubmitting(false);
        setProgram(data);
      },
      error: ({ data }) => {
        setIsSubmitting(false);
        console.log(data);
      },
    });
  };

  if (!program) return <Loader className="tw-my-10" center content="loading" />;

  return (
    <Form>
      <h6>Mentorship Pairing Type</h6>
      <p className="montserrat tw-text-sm">
        By default, mentees have the freedom to book any available mentors
        within your portal. Alternatively, you can modify this setting to give
        mentees the choice to select their own mentors, or you can manually pair
        them yourself.
      </p>

      {/* Mentorship Pairing Type */}
      <Form.Group>
        <Controller
          name="program.pairing_method"
          control={control}
          render={({ field }) => (
            <>
              <RadioTileGroup
                inline
                {...field}
                aria-label="Mentorship Pairing type"
                className="tw-flex tw-w-full tw-justify-between"
              >
                <RadioTile
                  className="tw-w-1/3"
                  icon={
                    <MdOutlinePeopleAlt
                      className="tw-mx-2"
                      style={{ color: "#696969" }}
                    />
                  }
                  label="Traditional"
                  value={"mentee_picks"}
                >
                  Mentee picks mentor.
                </RadioTile>
                <RadioTile
                  className="tw-w-1/3"
                  icon={
                    <MdOutlineCached
                      className="tw-mx-2"
                      style={{ color: "#696969" }}
                    />
                  }
                  label="Reverse"
                  value={"mentor_picks"}
                >
                  Mentor picks mentee.
                </RadioTile>
                <RadioTile
                  className="tw-w-1/3"
                  icon={
                    <MdOutlineAdminPanelSettings
                      className="tw-mx-2"
                      style={{ color: "#696969" }}
                    />
                  }
                  label="Admin Paired"
                  value={"admin_pairs"}
                >
                  Participant paired by admins.
                </RadioTile>
              </RadioTileGroup>
              <ErrorMessage
                errors={errors}
                name={"program.pairing_method"}
                as={FieldError}
              />
            </>
          )}
        />
      </Form.Group>

      {/* Smart Matching */}
      {/* FUT-1241: KIV - Suggest to relook when Smart Matching is fully built */}
      {/* <Form.Group>
        <Form.ControlLabel>Smart matching</Form.ControlLabel>
        <Controller
          name="smart_matching"
          control={control}
          render={({ field }) => <Toggle {...field} />}
        />
      </Form.Group> */}

      {/* Mentoring Services */}
      <h6>Mentoring Goals</h6>
      <p className="montserrat tw-text-sm">
        Define your programme's objectives here. When participants sign up,
        these goals will guide the matching process, ensuring a meaningful and
        effective mentorship experience.
      </p>

      <Form.Group>
        <p className="montserrat tw-text-sm">Choose a maximum of 7 options.</p>
        <Controller
          name={"program_goals"}
          control={control}
          render={({ field }) => (
            <>
              <TagInput
                {...field}
                size="lg"
                placeholder="Type your options"
                className="tw-w-full"
              />
              <ErrorMessage
                errors={errors}
                name={"program_goals"}
                as={FieldError}
              />
            </>
          )}
        />
      </Form.Group>
      <BottomNavCTA
        // Second onSubmit argument is SaveDraft
        handleSubmit={handleSubmit((data) => onSubmit(data, false))}
        handleSaveDraft={handleSubmit((data) => onSubmit(data, true))}
        loading={isSubmitting}
      />
    </Form>
  );
};

export default PortalMentorshipSettingForm;
