import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  MdOutlineAdminPanelSettings,
  MdOutlinePeopleAlt,
} from "react-icons/md";

import { Divider, Form, RadioTile, RadioTileGroup } from "rsuite";
import PairingMethodConfirmationModal from "./PairingMethodConfirmationModal";
import FormSubmitButton from "../appearance/FormSubmitButton";

const PairingMethodsForm = () => {
  const { control, getValues, setValue } = useFormContext();
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [currentOption, setCurrentOption] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setCurrentOption(getValues("program.pairing_method") || null);
  }, [currentOption]);

  // Handle the user clicking an option
  const handleSelectOption = (option: string) => {
    if (currentOption !== option) {
      setSelectedOption(option);
      setOpenModal(true);
    }
  };

  // Handle the modal response
  const handleModalResponse = () => {
    setValue("program.pairing_method", selectedOption);
    setCurrentOption(selectedOption);
    setOpenModal(false);
  };

  return (
    <div>
      <Form>
        <PairingMethodConfirmationModal
          optionSelected={selectedOption}
          openModal={openModal}
          setOpenModal={setOpenModal}
          handleModalResponse={handleModalResponse}
        />
        <Form.Group>
          <span className="tw-text-sm">
            By default, your mentees will be able to book any mentors in your
            portal. You can change the settings to either mentee choose their
            mentors or you can pair them yourself. We recommend not changing
            these settings as it would greatly affect your participant's
            mentorship experience.
          </span>
          <Controller
            name={"program.pairing_method"}
            control={control}
            render={({ field }) => (
              <RadioTileGroup value={currentOption} className="tw-mt-6">
                <RadioTile
                  label="Mentee pick mentor"
                  value={"mentee_picks"}
                  icon={
                    <MdOutlinePeopleAlt
                      className="tw-mx-2"
                      style={{ color: "#696969" }}
                    />
                  }
                  checked={currentOption === "mentee_picks"}
                  onClick={() => handleSelectOption("mentee_picks")}
                >
                  <span className="tw-text-sm">
                    This allows mentees to pick any mentors of their choice.
                    This setting is for open portals that do not have any set
                    pairings for mentor and mentee.
                  </span>
                </RadioTile>
                <RadioTile
                  label="Admin Pairing"
                  value={"admin_pairs"}
                  icon={
                    <MdOutlineAdminPanelSettings
                      className="tw-mx-2"
                      style={{ color: "#696969" }}
                    />
                  }
                  checked={currentOption === "admin_pairs"}
                  onClick={() => handleSelectOption("admin_pairs")}
                >
                  <span className="tw-text-sm">
                    Mentee and mentor will not be allowed to pair with each
                    other. This setting is for closed portal where the admin
                    sets the pairings. Once this setting is enabled, you will be
                    able to pair your mentors and mentees under Bookings
                    Overview.
                  </span>
                </RadioTile>
              </RadioTileGroup>
            )}
          />
        </Form.Group>
        <Divider />
        <div className="tw-flex tw-flex-row-reverse">
          <FormSubmitButton />
        </div>
      </Form>
    </div>
  );
};

export default PairingMethodsForm;
