import React from 'react';

const VariablesContext = React.createContext(undefined);

export const VariablesProvider = ({ children, variables }) => {
  return (
    <VariablesContext.Provider
      value={{
        variables,
      }}
    >
      {children}
    </VariablesContext.Provider>
  );
};

export const useVariables = () => React.useContext(VariablesContext);

export function withVariables(WrappedComponent) {
  const Component = (props) => {
    const { variables } = useVariables();

    return <WrappedComponent variables={variables} {...props} />;
  };

  return Component;
}
