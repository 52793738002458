import React from "react";
import { useFormContext } from "react-hook-form";

import { Divider, Form } from "rsuite";

import PortalColorSelect from "../../../setup/PortalColorSelect";
import PortalBannerUploaderV2 from "../../../setup/PortalBannerUploaderV2";
import PortalLogoUploaderV2 from "../../../setup/PortalLogoUploaderV2";

import FormSubmitButton from "./FormSubmitButton";
import { usePortal } from "../../../../../contexts/portal";

const DashboardStylingForm = () => {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const { program } = usePortal();

  return (
    <div>
      <Form>
        <p className="montserrat tw-text-sm">
          The selected color will be applied to your portal's top navigation
          bar.
        </p>
        <div className="tw-flex tw-flex-row tw-gap-6">
          <Form.Group className="tw-w-1/2">
            <Form.ControlLabel>Primary Color</Form.ControlLabel>
            <PortalColorSelect
              name={"white_label.dashboard_primary_colour"}
              colour={getValues("white_label.dashboard_primary_colour")}
              control={control}
              errors={errors}
              setValue={setValue}
              className="tw-w-1/2"
            />
          </Form.Group>
          <Form.Group className="tw-w-1/2">
            <Form.ControlLabel>Secondary Color</Form.ControlLabel>
            <PortalColorSelect
              name={"white_label.dashboard_secondary_colour"}
              colour={getValues("white_label.dashboard_secondary_colour")}
              control={control}
              errors={errors}
              setValue={setValue}
              className="tw-w-1/2"
            />
          </Form.Group>
        </div>
        <Divider />
        <p className="montserrat tw-text-sm">
          Your portal banner and logo help users quickly identify your portal
          throughout the platform.
        </p>
        <Form.Group>
          <Form.ControlLabel>Cover Photo</Form.ControlLabel>
          <PortalBannerUploaderV2
            name={"white_label.newsfeed_banner"}
            imageUrl={program?.white_label?.newsfeed_banner_url}
            control={control}
            errors={errors}
            setValue={setValue}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Logo</Form.ControlLabel>
          <PortalLogoUploaderV2
            name={"program.photo"}
            imageUrl={program?.photo_url}
            control={control}
            errors={errors}
            setValue={setValue}
          />
        </Form.Group>
        <Divider />
        <div className="tw-flex tw-flex-row-reverse">
          <FormSubmitButton />
        </div>
      </Form>
    </div>
  );
};

export default DashboardStylingForm;
