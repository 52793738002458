import React from "react";
import { Button, Loader, Message, Tag, toaster } from "rsuite";
import { RichTextEditorToHTML } from "../../ui/RichTextEditor";
import { FutureLabApi } from "../../../util/api";

const NavNotificationContent = ({ notifications, getNotifications }) => {
  const tagColorGenerator = (object_type) => {
    const obj = {
      Booking: "blue",
      Survey: "orange",
      Chat: "green",
      InstantBooking: "violet",
      Group: "cyan",
    };

    return obj[object_type];
  };

  const handleMarkRead = (notification_id) => {
    FutureLabApi({
      method: "post",
      path: "/notifications/mark_read",
      options: { notification_id: notification_id },
      success: () => {
        getNotifications();
        toaster.push(
          <Message showIcon type="success">
            Notification cleared.
          </Message>
        );
      },
    });
  };

  if (!notifications) return <Loader center content="loading" />;

  return (
    <>
      {notifications.length > 0 ? (
        notifications.map((notification) => (
          <div
            key={notification.id}
            className="tw-flex tw-flex-col tw-my-2"
            style={{ borderBottom: "1px solid #CCCCCC" }}
          >
            <a href={notification.link_path} style={{ color: "#696969" }}>
              <div className="tw-flex tw-justify-between tw-items-center tw-mb-2">
                <Tag color={tagColorGenerator(notification.object_type)}>
                  {notification.object_type}
                </Tag>
                <span className="tw-py-2 tw-text-sm">
                  {notification.time_elapsed}.
                </span>
              </div>
              <RichTextEditorToHTML content={notification.message} />
            </a>
            <div className="tw-flex tw-justify-between">
              <Button
                appearance="link"
                className="tw-py-2 tw-my-2 tw-pl-0 tw-text-sm"
                onClick={() => handleMarkRead(notification.id)}
              >
                Mark as read
              </Button>
            </div>
          </div>
        ))
      ) : (
        <div className="tw-flex tw-flex-grow">
          <div className="tw-justify-center tw-self-center tw-text-center">
            There are currently no new notifications to show.
          </div>
        </div>
      )}
    </>
  );
};

export default NavNotificationContent;
