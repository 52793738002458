// React
import React from "react";

// FutureLab
import avatarPlaceholder from "../../../../images/avatar_placeholder.png";
import { FeedbackList } from "./FeedbackList";

// UI
import { BsArrowLeft } from "react-icons/bs";
import { Button } from "rsuite";

// 3rd Party
import _ from "lodash";

const groupIndividualFeedback = (user, data, setOpenRating) => {
  return (
    <>
      {data.map((review, index) => (
        <div key={index}>
          <Button
            appearance="link"
            className="tw-my-2 tw-text-base"
            onClick={() => setOpenRating(false)}
          >
            <BsArrowLeft size={32} className="tw-mr-5" color="#979797" />
            <img
              src={user[index].profile_photo || avatarPlaceholder}
              style={{ width: 40 }}
              className="tw-rounded-xl tw-mr-4"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = avatarPlaceholder;
              }}
            />
            {user[index].full_name || "Deleted User"}
          </Button>
          {review.submitted && (
            <p className="montserrat tw-mb-6 tw-mt-3 tw-text-sm">
              Review submitted on {review.submitted}
            </p>
          )}
          <div className="tw-my-6">
            {review.feedback.length > 0
              ? review.feedback.map((feedback, idx) => (
                  <FeedbackList key={idx} feedback={feedback} idx={idx} />
                ))
              : NoRating}
          </div>
        </div>
      ))}
    </>
  );
};

const groupFeedback = (data, setOpenRating) => {
  const firstFeedback = data[0];
  const groupSubmitted = firstFeedback?.submitted;
  const groupFeedback = firstFeedback?.feedback;

  return (
    <div>
      <Button
        appearance="link"
        className="tw-my-2 tw-text-base"
        onClick={() => setOpenRating(false)}
      >
        <BsArrowLeft size={32} className="tw-mr-5" color="#979797" />
        <img
          src={avatarPlaceholder}
          style={{ width: 40 }}
          className="tw-rounded-xl tw-mr-4"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = avatarPlaceholder;
          }}
        />
        Group Rating
      </Button>
      {groupSubmitted && (
        <p className="montserrat tw-mb-6 tw-mt-3 tw-text-sm">
          Review submitted on {groupSubmitted}
        </p>
      )}
      <div className="tw-my-6">
        {groupFeedback
          ? groupFeedback.map((feedback, idx) => (
              <FeedbackList key={idx} feedback={feedback} idx={idx} />
            ))
          : NoRating}
      </div>
    </div>
  );
};

const NoRating = (
  <div className="tw-my-6">
    <p className="montserrat tw-py-6 tw-text-center">
      This user has not rated.
    </p>
  </div>
);

export function GroupFeedbackDisplay({ data, user, setOpenRating }) {
  return (
    <div className="tw-px-5">
      {!_.isEmpty(data) ? (
        <>
          {data[0]?.is_group_rating
            ? groupFeedback(data, setOpenRating)
            : groupIndividualFeedback(user, data, setOpenRating)}
        </>
      ) : (
        NoRating
      )}
    </div>
  );
}

export function OneOnOneFeedbackDisplay({ data }) {
  return (
    <div>
      {!_.isEmpty(data) ? (
        <>
          {data.map((review, index) => (
            <div key={index}>
              {review.submitted && (
                <p className="montserrat tw-my-6 tw-text-sm">
                  Review submitted on {review.submitted}
                </p>
              )}
              <div className="tw-my-6">
                {review.feedback.length > 0
                  ? review.feedback.map((feedback, idx) => (
                      <FeedbackList key={idx} feedback={feedback} idx={idx} />
                    ))
                  : NoRating}
              </div>
            </div>
          ))}
        </>
      ) : (
        NoRating
      )}
    </div>
  );
}
