// React
import React from "react";

// FutureLab
import avatarPlaceholder from "../../images/avatar_placeholder.png";

export default function UserPhoto({ user }) {
  return (
    <img
      src={user.profile_photo || avatarPlaceholder}
      className="tw-rounded-lg tw-w-14"
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = avatarPlaceholder;
      }}
    />
  );
}
