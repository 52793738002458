// React
import React from "react";

// FutureLab
import avatarPlaceholder from "../../images/avatar_placeholder.png";
import RecommendationButtons from "./recommendation/RecommendationButtons";

// UI
import { Carousel, Divider, Modal } from "rsuite";
import {
  MdOutlineBusiness,
  MdOutlineBusinessCenter,
  MdOutlineDesignServices,
  MdOutlineLocationOn,
} from "react-icons/md";

export default function UserRecommendationModal(props) {
  const { mentors, openModal, handleCloseModal, FLDashboard = null } = props;

  return (
    <div>
      {mentors && (
        <Modal
          open={openModal}
          onClose={() => handleCloseModal()}
          backdrop="static"
          className="tw-mt-8 tw-mb-16 xs:tw-w-[300px] tw-w-[320px]"
        >
          <Modal.Header>
            <Modal.Title className="tw-font-medium">
              Here's your Mentor Match!
            </Modal.Title>
            <Divider className="tw-absolute tw-w-full tw-left-0 tw-mt-4 tw-bg-gray-300" />
          </Modal.Header>
          <Carousel className="tw-h-full tw-pb-10">
            {mentors.map((mentor, mentorIndex) => (
              <div key={mentorIndex}>
                <Modal.Body className="no-scrollbar tw-mb-4">
                  <div className="tw-flex tw-justify-center tw-my-2 tw-px-6">
                    <img
                      src={mentor?.photo || avatarPlaceholder}
                      className="tw-rounded-xl"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = avatarPlaceholder;
                      }}
                    />
                  </div>
                  <div className="tw-w-full tw-h-[202px] tw-pt-2">
                    <div className="tw-mb-3">
                      <h6 className="tw-text-base tw-m-0 tw-text-center tw-truncate">
                        {mentor?.fullname}
                      </h6>
                    </div>
                    <div className="tw-text-sm">
                      <div className="tw-flex tw-my-3">
                        <MdOutlineBusinessCenter
                          size={20}
                          className="tw-mr-2 tw-overflow-visible"
                        />

                        <span className="tw-line-clamp-2 tw-mt-auto">
                          {mentor?.info?.job_title}
                        </span>
                      </div>
                      <div className="tw-flex tw-my-3">
                        <MdOutlineBusiness
                          size={20}
                          className="tw-mr-2 tw-overflow-visible"
                        />

                        <span className="tw-line-clamp-2 tw-mt-auto">
                          {mentor?.info?.company}
                        </span>
                      </div>
                      <div className="tw-flex tw-my-3">
                        <MdOutlineDesignServices
                          size={20}
                          className="tw-mr-2 tw-overflow-visible"
                        />

                        <span className="tw-line-clamp-2 tw-mt-auto">
                          {mentor?.goals.map((goal, index) => (
                            <span key={goal.id}>
                              {" "}
                              <span>{goal?.name}</span>
                              {index < mentor.goals.length - 1 && (
                                <span>, </span>
                              )}
                            </span>
                          ))}
                        </span>
                      </div>
                      <div className="tw-flex tw-mt-3">
                        <MdOutlineLocationOn
                          size={20}
                          className="tw-mr-2 tw-overflow-visible"
                        />

                        <span className="tw-truncate tw-mt-auto">
                          {mentor?.country?.name}
                        </span>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer className="tw-flex">
                  <RecommendationButtons
                    mentor={mentor}
                    FLDashboard={FLDashboard}
                    handleCloseModal={handleCloseModal}
                  />
                </Modal.Footer>
              </div>
            ))}
          </Carousel>
        </Modal>
      )}
    </div>
  );
}
