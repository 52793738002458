// React
import React, { useEffect, useState } from "react";

// React
import { FutureLabApi } from "../../util/api";
import TooltipHelper from "../ui/TooltipHelper";
import EventCards from "./events/EventCards";
import translateText from "../../util/translateText";

// UI
import { Button, Nav } from "rsuite";

const DashboardEvents = ({ convertText }) => {
  const [all, setAll] = useState([]);
  const [upcoming, setUpcoming] = useState([]);
  const [matched, setMatched] = useState([]);
  const [activeKey, setActiveKey] = useState("2");

  useEffect(() => {
    FutureLabApi({
      method: "get",
      path: `/dashboard/events/main`,
      success: ({ data }) => {
        setAll(data.all);
        setUpcoming(data.upcoming);
        setMatched(data.matched);
      },
    });
  }, []);

  const activeDisplay = (activeKey) => {
    const obj = {
      1: {
        data: upcoming,
        empty_text: `${translateText(
          convertText,
          "You have no upcoming events. Browse all events by clicking all events."
        )}`,
        button_link: "/main/events",
      },
      2: {
        data: all,
        empty_text: `${translateText(
          convertText,
          "There are no events to view right now. Stay tuned!"
        )}`,
        button_link: "/main/events",
      },
      3: {
        data: matched,
        empty_text: `${translateText(
          convertText,
          "Oh no! There are no events for you right now. Browse through all events and join one by clicking All Events."
        )}`,
        button_link: "/main/events",
      },
    };
    return obj[activeKey];
  };

  const dataitems = activeDisplay(activeKey);

  return (
    <>
      <div className="tw-w-full sm:tw-block sm:tw-mb-12">
        <div className="sm:tw-w-full sm:tw-text-center sm:tw-mb-4 tw-mb-5 tw-flex tw-justify-between">
          <div className="tw-inline-flex tw-items-center">
            <p className="section-header montserrat">
              {translateText(convertText, "MentorClass")}
            </p>
            <TooltipHelper
              tip={
                "Join these events specially curated based on your interested industries and skills"
              }
            />
          </div>
        </div>
        <Nav
          appearance="subtle"
          activeKey={activeKey}
          onSelect={setActiveKey}
          justified
          className="tw-mb-5 xs:tw-overflow-x-auto no-scrollbar"
        >
          <Nav.Item eventKey="1" className="tw-flex tw-justify-center tw-min-w-[140px]">
            {translateText(convertText, "Upcoming")} ({upcoming?.length})
          </Nav.Item>
          <Nav.Item eventKey="2" className="tw-flex tw-justify-center tw-min-w-[140px]">
            {translateText(convertText, "All MentorClasses")} ({all?.length})
          </Nav.Item>
          <Nav.Item eventKey="3" className="tw-flex tw-justify-center tw-min-w-[140px]">
            {translateText(convertText, "For you")} ({matched?.length})
          </Nav.Item>
        </Nav>

        {dataitems.data.length > 0 ? (
          <EventCards
            events={dataitems.data}
            convertText={convertText}
            activeKey={activeKey}
          />
        ) : (
          <>
            <div className="tw-w-full tw-my-20 tw-text-center sm:tw-w-full sm:tw-text-center sm:tw-mb-4">
              <p className="montserrat tw-text-center">
                {dataitems.empty_text}
              </p>
              <Button appearance="primary" href={dataitems.button_link}>
                {translateText(convertText, "All events")}
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DashboardEvents;
