import React, { useEffect, useState } from "react";
import DOMPurify from "dompurify";

const LegacyPageWrapper = ({ url }) => {
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await fetch(url, {
          headers: {
            "Content-Type": "text/html",
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const text = await response.text();
        const sanitizedContent = DOMPurify.sanitize(text);
        setContent(text);
      } catch (error) {
        setError(error);
        console.error("Error fetching legacy page content:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchContent();
  }, [url]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading content: {error.message}</div>;
  }

  return <div dangerouslySetInnerHTML={{ __html: content }} />;
};

export default LegacyPageWrapper;
