import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Col,
  Divider,
  Form,
  Grid,
  Input,
  Message,
  Row,
  TagPicker,
  toaster,
} from "rsuite";
import { useUser } from "../../contexts/user";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ErrorMessage } from "@hookform/error-message";
import FieldError from "../ui/FieldError";
import { FutureLabApi } from "../../util/api";
import { map } from "lodash";
import toSentenceCase from "../../util/toSentenceCase";
import MentorLanguages from "../form/MentorLanguages";
import PreviewSaveButton from "./PreviewSaveButton";
import PanelContainer from "./PanelContainer";
import translateText from "../../util/translateText";
import MentoringTime from "../form/MentoringTimes";
import CanOfferJob from "../form/CanOfferJob";
import HiringDepartments from "../form/HiringDepartments";
import HiringFrequency from "../form/HiringFrequency";
import AverageTotalPeopleHire from "../form/averageTotalPeopleHire";
import HiringExperienceLevel from "../form/HiringExperienceLevel";

type UserInput = {
  user_industries_attributes: Array<string | number>;
  user_skills_attributes: Array<string | number>;
  info: {
    who_to_mentor: Array<number>;
    impact_to_create: string;
    can_offer_job: boolean;
    roles_for_hiring: Array<string>;
    average_total_people_hire: number;
    hiring_frequency: number;
    hiring_experience_level: Array<string>;
    total_mentoring_hour: number;
    others_hiring_role: string;
  };
  goals_attributes: any;
};

const schema = yup.object().shape({
  user_industries_attributes: yup
    .array()
    .required()
    .label("Industries")
    .nullable(),
  user_skills_attributes: yup.array().required().label("Skills").nullable(),
  info: yup.object().shape({
    who_to_mentor: yup.array().required().label("Ideal mentees").nullable(),
    impact_to_create: yup.string().required().label("Impact"),
    can_offer_job: yup.boolean().required().label("Can offer job").nullable(),
    roles_for_hiring: yup
      .array()
      .when("can_offer_job", {
        is: true,
        then: yup
          .array()
          .min(1, "Roles for hiring is required")
          .typeError("Roles for hiring is required"),
      })
      .nullable(),
    average_total_people_hire: yup
      .number()
      .when("can_offer_job", {
        is: true,
        then: yup
          .number()
          .required()
          .typeError("Average total people hiring is required"),
      })
      // transform empty string into null so yup can validate it
      .transform((currentValue, originalValue) => {
        return originalValue === "" ? null : currentValue;
      })
      .nullable(),
    hiring_frequency: yup
      .number()
      .when("can_offer_job", {
        is: true,
        then: yup.number().required().typeError("Hiring frequency is required"),
      })
      // transform empty string into null so yup can validate it
      .transform((currentValue, originalValue) => {
        return originalValue === "" ? null : currentValue;
      })
      .nullable(),
    hiring_experience_level: yup
      .array()
      .when("can_offer_job", {
        is: true,
        then: yup.array().min(1, "Hiring experience is required"),
      })
      .nullable(),
    others_hiring_role: yup.string().label("Hiring Department"),
  }),
  goals_attributes: yup.array().required().label("How can I help").nullable(),
});

// 25/6/23: FUT-1137: Limit the industry selection list
// List is based on production DB IDs
const approvedIndustryList = [
  585, 1186, 231, 3772, 1999, 167, 58, 223, 685, 2079,
];

const Mentorship = (props) => {
  const { user, refreshUser } = useUser();
  const {
    handleSubmit,
    setValue,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm<UserInput>({
    defaultValues: {
      user_industries_attributes: map(user.industries, (value) => value.id),
      user_skills_attributes: map(user.skills, (value) => value.id),
      info: {
        who_to_mentor: user.info.who_to_mentor,
        impact_to_create: user.info.impact_to_create || "",
        can_offer_job: user.info.can_offer_job,
        roles_for_hiring: user.info.roles_for_hiring,
        average_total_people_hire: user.info.average_total_people_hire,
        hiring_frequency: user.info.hiring_frequency,
        hiring_experience_level: user.info.hiring_experience_level,
        total_mentoring_hour: user.info.total_mentoring_hour || "",
        others_hiring_role: "",
      },
      goals_attributes: map(user.goals, (value) => value.id),
    },
    resolver: yupResolver(schema),
  });
  const [industries, setIndustries] = useState([]);
  const [skills, setSkills] = useState([]);
  const [goals, setGoals] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [disabledValues, setDisabledValues] = useState([]);

  const watchHiringDepartment = watch("info.roles_for_hiring");
  const canOfferJob = watch("info.can_offer_job");

  useEffect(() => {
    if (user) {
      reset({
        info: {
          can_offer_job: user.info?.can_offer_job,
          roles_for_hiring: user.info.roles_for_hiring || "",
          average_total_people_hire: user.info.average_total_people_hire || "",
          hiring_frequency: user.info.hiring_frequency || "",
          hiring_experience_level: user.info.hiring_experience_level || "",
          total_mentoring_hour: user.info.total_mentoring_hour || "",
          others_hiring_role: user.info?.others_hiring_role || "",
        },
      });
    }
  }, [user]);

  useEffect(() => {
    FutureLabApi({
      method: "get",
      path: "/industries",
      success: ({ data }) => {
        let filteredIndustries = data
          .filter((obj) => approvedIndustryList.includes(obj.id))
          .sort((a, b) => a.name.localeCompare(b.name));
        setIndustries(filteredIndustries);
      },
    });

    FutureLabApi({
      method: "get",
      path: "/skills",
      success: ({ data }) => {
        setSkills(data);
      },
    });

    FutureLabApi({
      method: "get",
      path: "/goals",
      success: ({ data }) => {
        setGoals(data);
      },
    });
  }, []);

  const onSubmit = (data) => {
    setIsSubmitting(true);
    console.log("data:", data);

    FutureLabApi({
      method: "post",
      path: "/users/current",
      options: data,
      success: ({ data }) => {
        refreshUser();
        setIsSubmitting(false);
        toaster.push(
          <Message showIcon type="success">
            {translateText(props.convertText, "Successfully saved.")}
          </Message>
        );
      },
      error: (err) => {
        const { error, messages } = err.response.data;
        setIsSubmitting(false);
        toaster.push(
          <Message showIcon type="error">
            {error}
          </Message>
        );
      },
    });
  };

  const handleMaxLimit = (selected, data) => {
    let filteredData = [];

    if (selected.length === 3) {
      filteredData = data
        .filter((obj) => !selected.includes(obj.id))
        .map((obj) => obj.id);
    }
    setDisabledValues(selected.length === 3 ? filteredData : []);
  };

  return (
    <div>
      <PreviewSaveButton
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
      />
      <PanelContainer>
        <Grid fluid>
          <Row gutter={24}>
            <Col xs={24} md={12}>
              <Form fluid>
                <Form.Group>
                  <Form.ControlLabel className="tw-text-base">
                    {translateText(props.convertText, "Job roles I can cover")}
                    <span className="tw-text-red-500">*</span>
                  </Form.ControlLabel>
                  <Controller
                    name="user_industries_attributes"
                    control={control}
                    render={({ field }) => (
                      <TagPicker
                        disabledItemValues={disabledValues}
                        onClean={() => setDisabledValues([])}
                        onSelect={(value) => {
                          handleMaxLimit(value, industries);
                        }}
                        data={industries}
                        block
                        placeholder={translateText(
                          props.convertText,
                          "Please select"
                        )}
                        size="lg"
                        {...field}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="user_industries_attributes"
                    as={FieldError}
                  />
                </Form.Group>
                <Form.Group className="tw-mb-6">
                  <Form.ControlLabel className="tw-text-base">
                    {translateText(props.convertText, "Ideal mentees")}
                    <span className="tw-text-red-500">*</span>
                  </Form.ControlLabel>
                  <Controller
                    name="info.who_to_mentor"
                    control={control}
                    render={({ field }) => (
                      <TagPicker
                        cleanable={false}
                        data={map(
                          user.info.who_to_mentor_list,
                          (value, key) => ({
                            label: toSentenceCase(key),
                            value,
                          })
                        )}
                        block
                        placeholder={translateText(
                          props.convertText,
                          "Please select"
                        )}
                        size="lg"
                        {...field}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="info.who_to_mentor"
                    as={FieldError}
                  />
                </Form.Group>
              </Form>
            </Col>
            <Col xs={24} md={12}>
              <Form fluid>
                <Form.Group>
                  <Form.ControlLabel className="tw-text-base">
                    {translateText(props.convertText, "Skills I can help with")}
                    <span className="tw-text-red-500">*</span>
                  </Form.ControlLabel>
                  <Controller
                    name="user_skills_attributes"
                    control={control}
                    render={({ field }) => (
                      <TagPicker
                        cleanable={false}
                        data={skills}
                        block
                        placeholder={translateText(
                          props.convertText,
                          "Please select"
                        )}
                        size="lg"
                        {...field}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="user_skills_attributes"
                    as={FieldError}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel className="tw-text-base">
                    {translateText(
                      props.convertText,
                      "How I can help a mentee"
                    )}
                    <span className="tw-text-red-500">*</span>
                  </Form.ControlLabel>
                  <Controller
                    name="goals_attributes"
                    control={control}
                    render={({ field }) => (
                      <TagPicker
                        cleanable={false}
                        data={goals}
                        block
                        placeholder={translateText(
                          props.convertText,
                          "Please select"
                        )}
                        size="lg"
                        {...field}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="goals_attributes"
                    as={FieldError}
                  />
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24}>
              <Form fluid className="tw-mt-6">
                <Form.Group>
                  <Form.ControlLabel className="tw-text-base">
                    {translateText(
                      props.convertText,
                      "The impact I want to make"
                    )}
                    <span className="tw-text-red-500">*</span>
                  </Form.ControlLabel>
                  <Controller
                    name="info.impact_to_create"
                    control={control}
                    render={({ field }) => (
                      <Input rows={4} as="textarea" size="lg" {...field} />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="info.impact_to_create"
                    as={FieldError}
                  />
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} md={12}>
              <MentorLanguages convertText={props.convertText} />
            </Col>
          </Row>
          <Divider />
          <CanOfferJob
            convertText={props.convertText}
            control={control}
            errors={errors}
          />
          {/* if can offer job, display question below */}
          {canOfferJob && (
            <Row gutter={24}>
              <Col xs={24} md={12}>
                <HiringDepartments
                  convertText={props.convertText}
                  control={control}
                  func={setValue}
                  errors={errors}
                  watch={watchHiringDepartment}
                />
                <HiringExperienceLevel
                  convertText={props.convertText}
                  control={control}
                  func={setValue}
                  errors={errors}
                />
              </Col>
              <Col xs={24} md={12}>
                <HiringFrequency
                  convertText={props.convertText}
                  control={control}
                  func={setValue}
                  errors={errors}
                />
                <AverageTotalPeopleHire
                  convertText={props.convertText}
                  control={control}
                  func={setValue}
                  errors={errors}
                />
              </Col>
            </Row>
          )}
        </Grid>
      </PanelContainer>
    </div>
  );
};

export default Mentorship;
