// React
import React from "react";

// FutureLab
import avatarPlaceholder from "../../../../images/avatar_placeholder.png";

// UI
import { Button, Popover, Whisper } from "rsuite";

export function GroupInfo({ users, enterpriseSlug, programSlug }) {
  const speaker = (
    <Popover>
      {users.map((user, index) => (
        <div key={index} className="tw-mb-3 tw-my-1">
          <img
            src={user?.profile_photo || avatarPlaceholder}
            style={{ width: 40 }}
            className="tw-rounded-xl tw-mr-2"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = avatarPlaceholder;
            }}
          />
          <Button
            appearance="link"
            key={index}
            href={`/app/${enterpriseSlug}/${programSlug}/members/${user.user_id}?lang=en`}
            target="_blank"
          >
            {user?.full_name || "Deleted User"}
          </Button>
        </div>
      ))}
    </Popover>
  );

  return (
    <Whisper placement="top" speaker={speaker} trigger="click">
      <Button appearance="link">{`${users.length} mentees`}</Button>
    </Whisper>
  );
}
