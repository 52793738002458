import React, { useState } from "react";
import {
  MdMenu,
  MdOutlineCalendarMonth,
  MdOutlineChromeReaderMode,
  MdOutlineHome,
  MdOutlineSupervisorAccount,
} from "react-icons/md";

import { Drawer } from "rsuite";
import NavProfileMenu from "../component/NavProfileMenu";
import ProgramSwitcher from "../common/ProgramSwitcher";

const handleNavigation = (url) => {
  window.location.replace(url);
};

const MobileItem = ({ icon, label, link, active = null, ...restProps }) => (
  <div
    className="tw-w-1/5 tw-inline-flex tw-flex-col tw-items-center"
    onClick={() => handleNavigation(link)}
    {...restProps}
    style={active ? { borderTop: "2px solid #428ac9", color: "#428AC9" } : {}}
  >
    {icon}
    <span className="tw-text-xs tw-font-medium tw-mt-0.5">{label}</span>
  </div>
);

const FLMobileFooterNav = (props) => {
  const [open, setOpen] = useState(false);
  const [openSwitcher, setOpenSwitcher] = useState(false);

  const getActiveItem = (link) => {
    const currentPath = window.location.pathname;
    return currentPath === link;
  };

  return (
    <>
      <div
        className="tw-flex tw-flex-row tw-fixed tw-justify-between tw-bottom-0 tw-w-full"
        style={{ height: 60, zIndex: 9999, backgroundColor: "#ffffff" }}
      >
        <MobileItem
          icon={<MdOutlineHome size={36} className="tw-pt-2"/>}
          label="Home"
          link="/main/dashboard"
          active={getActiveItem("/main/dashboard")}
        />
        <MobileItem
          icon={<MdOutlineSupervisorAccount size={36} className="tw-pt-2"/>}
          label="Mentors"
          link="/discover/mentors"
          active={getActiveItem("/discover/mentors")}
        />
        <MobileItem
          icon={<MdOutlineChromeReaderMode size={36} className="tw-pt-2"/>}
          label="Events"
          link="/main/events"
          active={getActiveItem("/main/events")}
        />
        <MobileItem
          icon={<MdOutlineCalendarMonth size={36} className="tw-pt-2"/>}
          label="Bookings"
          link="/main/bookings"
          active={getActiveItem("/main/bookings")}
        />
        <MobileItem
          icon={<MdMenu  size={36} className="tw-pt-2"/>}
          label="Menu"
          onClick={() => setOpen(!open)}
          link=""
        />
      </div>

      <Drawer
        size="full"
        placement="bottom"
        open={open}
        dialogClassName="tw-rounded-t-md"
        onClose={() => setOpen(false)}
      >
        <div className="tw-px-6">
          <NavProfileMenu toggleSwitcher={() => setOpenSwitcher(true)} />
        </div>
      </Drawer>

      <ProgramSwitcher open={openSwitcher} onClose={() => setOpenSwitcher(false)} />
    </>
  );
};

export default FLMobileFooterNav;
