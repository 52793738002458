import React from "react";
import { Col, Form, Input, TagPicker } from "rsuite";
import FieldError from "../ui/FieldError";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useUser } from "../../contexts/user";
import { map } from "lodash";
import translateText from "../../util/translateText";

const HiringDepartments = ({ convertText, control, func, errors, watch }) => {
  const { user } = useUser();
  const setValue = func;
  const watchHiringDepartment = watch;

  return (
    <Form fluid className="tw-mt-6">
      <Form.Group className="tw-mb-6">
        <Form.ControlLabel className="tw-text-base">
          {translateText(convertText, "What roles are you looking for?")}
          <span className="tw-text-red-500">*</span>
        </Form.ControlLabel>
        <Controller
          name="info.roles_for_hiring"
          control={control}
          render={({ field }) => (
            <TagPicker
              data={map(user.info.roles_for_hiring_list, (value, key) => ({
                label: value,
                value,
              }))}
              style={{ width: "100%" }}
              onChange={(value) => {
                setValue(field.name, value);
              }}
              placeholder={translateText(convertText, "Please select")}
              size="lg"
              {...field}
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name="info.roles_for_hiring"
          as={FieldError}
        />
      </Form.Group>
      {watchHiringDepartment?.includes("Other") && (
        <Form.Group>
          <Form.ControlLabel className="tw-text-base">
            {translateText(convertText, "Others - Please specify")}
            <span className="tw-text-red-500">*</span>
          </Form.ControlLabel>
          <Controller
            name="info.others_hiring_role"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                onChange={(value) => {
                  setValue(field.name, value);
                }}
              />
            )}
          />
        </Form.Group>
      )}
    </Form>
  );
};

export default HiringDepartments;
