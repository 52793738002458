// React
import React from "react";

// UI
import { MdOutlineInfo } from "react-icons/md";
import { Tooltip, Whisper } from "rsuite";

export function GroupSessionSummary(summary) {
  let totalCount = summary.mentee_rated + summary.mentor_rated;

  return (
    <Whisper
      placement="top"
      speaker={
        <Tooltip className="tw-text-left">
          <>
            {`${totalCount} user has rated`}
            <br />
            {`- ${summary.mentor_rated} mentor`}
            <br />
            {`- ${summary.mentee_rated} mentee`}
          </>
        </Tooltip>
      }
    >
      <div>
        <MdOutlineInfo size={24} color="#979797" />
      </div>
    </Whisper>
  );
}
