import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import {
  Button,
  Col,
  DateRangePicker,
  FlexboxGrid,
  Input,
  InputGroup,
  SelectPicker,
} from "rsuite";

import { FlAdminApi } from "../../util/fladmin_api";
import { CSVLink } from "react-csv";

const UserSearchFilter = ({ handleSearch, users, page }) => {
  const [countries, setCountries] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [searchCountry, setSearchCountry] = useState(null);
  const [searchDates, setSearchDates] = useState([]);

  useEffect(() => {
    handleSearch({
      searchName: searchName,
      searchCountry: searchCountry,
      searchDates: searchDates,
      page: page,
    });
  }, [searchName, searchCountry, searchDates, page]);

  useEffect(() => {
    getCountries();
    return () => {
      setCountries([]);
    };
  }, []);

  const getCountries = () => {
    FlAdminApi({
      method: "get",
      path: "/countries",
      success: ({ data }) => {
        setCountries(data);
      },
    });
  };

  return (
    <FlexboxGrid
      as={Col}
      justify="space-between"
      xs={24}
      md={20}
      lg={22}
      className="tw-mt-10"
    >
      {/* Search */}
      <FlexboxGrid.Item as={Col} colspan={6} className="">
        <label>Search user</label>
        <InputGroup inside className="tw-inline-flex tw-w-full">
          <Input
            placeholder="User name"
            onChange={(value) => setSearchName(value)}
          />
          <InputGroup.Button onClick={() => handleSearch(searchName)}>
            <FaSearch />
          </InputGroup.Button>
        </InputGroup>
      </FlexboxGrid.Item>

      <FlexboxGrid.Item as={Col} colspan={10} className="">
        <div className="tw-inline-block tw-pr-2 tw-w-2/5">
          <label>Filter by country</label>
          <SelectPicker
            menuClassName="tw-w-1/6"
            className="tw-w-full tw-relative"
            placeholder="Select country"
            onChange={(value) => setSearchCountry(value)}
            data={countries}
          />
        </div>
        <div className="tw-inline-block tw-pr-2 tw-w-2/5">
          <label>Filter by Sign up date</label>
          <DateRangePicker
            onChange={(value) => setSearchDates(value)}
            className="tw-w-full"
            placeholder="Select date range"
            placement="auto"
          />
        </div>
        <div className="tw-inline-block tw-w-1/5">
          <Button appearance="ghost" block>
            <CSVLink data={users} filename={"user_info.csv"}>
              Download CSV
            </CSVLink>
          </Button>
        </div>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

export default UserSearchFilter;