import React, { useState, useEffect } from "react";
import { getPrograms } from "../components/enterprise/api";
import { useEnterprise } from "../contexts/enterpriseContext";
import moment from "moment";

const useEnterpriseDashboard = ({ from_date, to_date }) => {
  const [programs, setPrograms] = useState([]);
  const [loading, setLoading] = useState(false);
  const { logoutAdmin } = useEnterprise();

  const getProgramList = async () => {
    setLoading(true);

    try {
      const { data } = await getPrograms(from_date, to_date);
      setPrograms(data);
    } catch (error) {
      console.log(error);
      //check if error 401 and logout
      if (error?.response?.status === 401) {
        logoutAdmin();
      }
    } finally {
      setLoading(false);
    }
  };

  const filteredPrograms = React.useMemo(() => {
    if (!from_date || !to_date) return programs;
    return programs.filter((program) => {
      const programDate = moment(program.created_at);
      return programDate.isBetween(from_date, to_date, undefined, "[]");
    });
  }, [programs, from_date, to_date]);

  useEffect(() => {
    getProgramList();
  }, []);

  return { programs: filteredPrograms, loading: loading };
};

export default useEnterpriseDashboard;
