// React
import React, { useCallback, useState } from "react";

// FutureLab
import { FutureLabApi } from "../../util/api";
import FieldError from "../ui/FieldError";
import { useUser } from "../../contexts/user";
import { JobInput } from "./WorkExperiences";

// UI
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  InputPicker,
  Message,
  Modal,
  toaster,
} from "rsuite";
import SpinnerIcon from "@rsuite/icons/legacy/Spinner";
import { FaExclamationTriangle } from "react-icons/fa";

// 3rd Party
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { DateTime } from "luxon";
import _, { debounce } from "lodash";

const schema = yup.object().shape({
  name: yup.string().required().label("Job title"),
  company_name: yup
    .string()
    .trim()
    .max(60, "Company name must be less than 60 characters")
    .test(
      "no-urls",
      "URLs are not allowed",
      (value) => !/(www|http|https)/i.test(value)
    )
    .required()
    .label("Company")
    .nullable(),
  start_date: yup.string().required().label("Start date").nullable(),
  end_date: yup.string().label("End date").nullable(),
  description: yup.string().required().label("Description"),
  is_current: yup.bool(),
});

const JobModal = ({
  user,
  job,
  companies,
  handleSearch,
  showEditModal,
  setShowEditModal,
  loading,
}) => {
  const isEditting = !_.isEmpty(job);
  const { refreshUser } = useUser();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const debouncedFetchCompanies = useCallback(
    debounce((searchKeyword) => {
      if (searchKeyword) {
        handleSearch(searchKeyword);
      }
    }, 300), // Adjust debounce delay as needed
    []
  );

  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<JobInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: job?.name || "",
      company_name: job?.company?.name || "",
      description: job?.description || "",
      start_date: job?.start_date && new Date(job?.start_date),
      end_date: job?.end_date && new Date(job?.end_date),
      is_current: job?.is_current || false,
    },
  });
  const watchIsCurrent = watch("is_current");
  const wattchStartDate = watch("start_date");

  const onSubmit = (data) => {
    setIsSubmitting(true);
    if (!isEditting) data.user_id = user.id;

    FutureLabApi({
      method: isEditting ? "patch" : "post",
      path: isEditting ? `/jobs/${job.id}` : `/jobs`,
      options: data,
      success: ({ data }) => {
        setIsSubmitting(false);
        refreshUser();
        setShowEditModal(false);
        toaster.push(
          <Message showIcon type="success">
            Successfully saved
          </Message>
        );
      },
      error: (err) => {
        const { error, messages } = err.response.data;
        setIsSubmitting(false);
        toaster.push(
          <Message showIcon type="error">
            {error}
          </Message>
        );
      },
    });
  };

  const deleteJob = () => {
    FutureLabApi({
      method: "delete",
      path: `/jobs/${job.id}`,
      success: () => {
        refreshUser();
        setShowEditModal(false);
        toaster.push(
          <Message showIcon type="success">
            Successfully deleted
          </Message>
        );
      },
    });
  };

  return (
    <Modal
      open={showEditModal}
      onClose={() => setShowEditModal(false)}
      className="tw-mt-16 sm:tw-mt-2"
      size="sm"
    >
      <Modal.Header>
        <Modal.Title className="tw-text-xl tw-font-medium">
          {isEditting ? "Edit" : "Add"} Work Experience
        </Modal.Title>
        <Divider className="tw-absolute tw-w-full tw-left-0 tw-mt-4 tw-bg-gray-300" />
      </Modal.Header>
      <Modal.Body>
        <Form fluid className="tw-pt-7">
          <Form.Group>
            <Form.ControlLabel className="tw-text-base">
              Job Title
              <span className="tw-text-red-500">*</span>
            </Form.ControlLabel>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Input placeholder="Job Title" size="lg" {...field} />
              )}
            />
            <ErrorMessage errors={errors} name="name" as={FieldError} />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel className="tw-text-base">
              Company / Organization
              <span className="tw-text-red-500">*</span>
            </Form.ControlLabel>
            <Controller
              name="company_name"
              control={control}
              render={({ field }) => (
                <InputPicker
                  {...field}
                  block
                  creatable
                  data={companies}
                  placeholder="Search"
                  valueKey="name"
                  onSearch={debouncedFetchCompanies}
                  searchBy={() => true}
                  renderMenu={(menu) => {
                    if (loading) {
                      return (
                        <p
                          style={{
                            color: "#999",
                            textAlign: "center",
                          }}
                        >
                          <SpinnerIcon spin /> Loading...
                        </p>
                      );
                    }
                    return menu;
                  }}
                />
              )}
            />
            <ErrorMessage errors={errors} name="company_name" as={FieldError} />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel className="tw-text-base">
              Description
              <span className="tw-text-red-500">*</span>
            </Form.ControlLabel>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <Input rows={4} as="textarea" size="lg" {...field} />
              )}
            />
            <ErrorMessage errors={errors} name="description" as={FieldError} />
          </Form.Group>
          <div className="tw-flex tw-gap-2">
            <Form.Group className="tw-flex-1">
              <Form.ControlLabel className="tw-text-base">
                Start Date <span className="tw-text-red-500">*</span>
              </Form.ControlLabel>
              <Controller
                name="start_date"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    block
                    oneTap
                    format="MMM yyyy"
                    onChange={(value) => setValue(field.name, value)}
                    size="lg"
                    {...field}
                  />
                )}
              />
              <ErrorMessage errors={errors} name="start_date" as={FieldError} />
            </Form.Group>
            <Form.Group className="tw-flex-1">
              <Form.ControlLabel
                className={`tw-text-base ${
                  watchIsCurrent && "tw-text-gray-400"
                }`}
              >
                End Date
              </Form.ControlLabel>
              <Controller
                name="end_date"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    disabledDate={(date) =>
                      DateTime.fromJSDate(date) <
                      DateTime.fromJSDate(wattchStartDate)
                    }
                    block
                    oneTap
                    format="MMM yyyy"
                    onChange={(value) => setValue(field.name, value)}
                    size="lg"
                    disabled={watchIsCurrent}
                    {...field}
                  />
                )}
              />
              <ErrorMessage errors={errors} name="end_date" as={FieldError} />
            </Form.Group>
          </div>
          <Form.Group>
            <Controller
              name="is_current"
              control={control}
              render={({ field }) => (
                <Checkbox
                  name="is_current"
                  defaultChecked={field.value}
                  onChange={(value, checked) => {
                    setValue(field.name, checked);
                  }}
                >
                  <span className="tw-text-base">
                    I'm currently working here
                  </span>
                </Checkbox>
              )}
            />
            <ErrorMessage errors={errors} name="is_current" as={FieldError} />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Divider className="tw-absolute tw-w-full tw-left-0 tw-m-0 tw-bg-gray-300" />
      <Modal.Footer className="tw-pt-5">
        {isEditting && (
          <Button appearance="ghost" onClick={() => setShowDeleteConfirm(true)}>
            Remove
          </Button>
        )}
        <Button
          appearance="primary"
          onClick={handleSubmit(onSubmit)}
          loading={isSubmitting}
        >
          Save
        </Button>
      </Modal.Footer>
      <RemoveJobConfirmationModal
        showDeleteConfirm={showDeleteConfirm}
        setShowDeleteConfirm={setShowDeleteConfirm}
        deleteJob={deleteJob}
      />
    </Modal>
  );
};

const RemoveJobConfirmationModal = ({
  showDeleteConfirm,
  setShowDeleteConfirm,
  deleteJob,
}) => {
  return (
    <Modal
      backdrop="static"
      open={showDeleteConfirm}
      onClose={() => setShowDeleteConfirm(false)}
      size="xs"
    >
      <Modal.Body className="tw-text-center">
        <FaExclamationTriangle className="tw-mr-4" />
        Are you sure you want to delete this experience?
      </Modal.Body>
      <Modal.Footer className="text-center">
        <Button onClick={deleteJob} appearance="primary" color="red">
          Confirm
        </Button>
        <Button
          onClick={() => setShowDeleteConfirm(false)}
          appearance="default"
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default JobModal;
