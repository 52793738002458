import React, { useMemo, useState } from "react";
import GenericMsgLoader from "../../../shared/GenericMsgLoader";
import { Divider } from "rsuite";

const MobileProgramTable = ({ tableData, loading }) => {
  const data = useMemo(() => tableData, [tableData]);

  const handleEnterProgram = (id) => {
    window.location.href = `/enter-program/${id}`;
  };

  if (loading)
    return (
      <div className="tw-flex tw-h-80 tw-justify-center tw-items-center tw-w-full">
        <GenericMsgLoader vertical />
      </div>
    );

  return (
    <>
      <div className="tw-w-full tw-flex tw-flex-col">
        {data.map((program) => (
          <div
            key={program.id}
            className="tw-w-full tw-flex tw-flex-col tw-items-center tw-p-2 tw-border-solid tw-border tw-border-gray-300 tw-rounded-lg tw-gap-2 tw-mb-2"
            onClick={() => handleEnterProgram(program.id)}
          >
            <div className="tw-flex tw-flex-col tw-gap-2 tw-w-full">
              <div
                className="tw-flex tw-pb-2"
                style={{ borderBottom: "1px solid #E5E5E5" }}
              >
                <img
                  src={program.logo}
                  className="tw-h-[48px] tw-w-[48px] tw-rounded-md tw-object-cover tw-mr-4"
                  alt="program_logo"
                />
                <div className="tw-flex tw-break-word">
                  <p className="tw-p-0 tw-font-bold tw-m-0">{program.name}</p>
                </div>
              </div>
              <div className="tw-flex tw-flex-row tw-w-full tw-items-space-between">
                <div className="tw-w-2/3">
                  <p className="tw-text-sm tw-p-0 tw-m-0">Total Users</p>
                </div>
                <div className="tw-w-1/3">
                  <p className="tw-text-sm tw-p-0 tw-m-0">
                    {program.total_users}
                  </p>
                </div>
              </div>
              <div className="tw-flex tw-flex-row tw-w-full tw-items-space-between">
                <div className="tw-w-2/3">
                  <p className="tw-text-sm tw-p-0 tw-m-0">Status</p>
                </div>
                <div className="tw-w-1/3">
                  <p className="tw-text-sm tw-p-0 tw-m-0">{program.status}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default MobileProgramTable;
