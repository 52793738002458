// React
import React from "react";

// FutureLab
import { usePortal } from "../../../contexts/portal";
import NavItemNotificationButton from "../component/NavItemNotificationButton";
import { useUser } from "../../../contexts/user";
import { useNavbarContext } from "../../../contexts/navbarContext";
import ProgramSwitcher from "../common/ProgramSwitcher";
import usePrograms from "../../../hooks/usePrograms";
import { NavItemButton } from "../common";

// UI
import { Avatar, Dropdown, IconButton, Nav, Navbar } from "rsuite";
import {
  MdOutlineAdminPanelSettings,
  MdOutlineLogout,
  MdOutlineManageAccounts,
  MdOutlineSwitchAccessShortcut,
} from "react-icons/md";
import { AiOutlineUserSwitch } from "react-icons/ai";
import { FaUserTie } from "react-icons/fa6";

const PortalAdminAvatar = (props, ref) => {
  const { user } = useUser();

  return (
    <button ref={ref} {...props}>
      <Avatar
        circle
        src={user?.profile_photo_url}
        alt={user?.firstname.charAt(0) || ""}
        className="tw-cursor-pointer"
        style={{ border: "2px solid #E5E5E5" }}
      />
    </button>
  );
};

const PortalAdminSwitcher = (props, ref) => {
  return <NavItemButton ref={ref} {...props} icon={<AiOutlineUserSwitch />} />;
};

const getEnterpriseUrl = () => {
  const baseUrls = {
    production: "https://enterprise.futurelab.my",
    staging: "https://enterprise.futurelab.global",
    development: "http://enterprise.lvh.me:3000",
  };

  const baseUrl = baseUrls[process.env.RAILS_ENV];
  return baseUrl
};

// TODO: Abstract components to Micro components
const PortalAdminTopNav = () => {
  const { program, enterprise } = usePortal();
  const { baseUrl } = useNavbarContext();
  const { user } = useUser();
  const [switchOn, setSwitchOn] = React.useState(false);
  const { activeProgram } = usePrograms();

  const primaryColour =
    program?.white_label?.dashboard_primary_colour || "#428ac9";
  const secondaryColour =
    program?.white_label?.dashboard_secondary_colour || "#696969";

  const toggleSwitcher = () => {
    setSwitchOn(!switchOn);
  };

  const handleSelect = (eventKey) => {
    const toUrl = baseUrl[eventKey];

    if (eventKey == "logout") {
      //window location href with DELETE method
      return fetch(toUrl, {
        method: "DELETE",
        headers: {
          "X-CSRF-Token": document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
        },
      }).then(() => {
        window.location.href = "/";
      });
    }
    window.location.href = toUrl;
  };
  const hasOrgAdminAccess = user?.enterprise_admin_access.includes(
    enterprise?.id
  );
  const handleSwitchToOrgAdmin = () => {
    window.location.href = getEnterpriseUrl()
  };

  return (
    <Navbar
      style={{
        height: "56px",
        backgroundColor: `${primaryColour}`,
        color: `${secondaryColour}`,
        borderBottom: "1px solid #E5E5E5",
      }}
    >
      <Nav pullRight className="tw-h-full">
        <div className="tw-flex tw-h-full tw-items-center tw-gap-3 tw-px-6">
          <NavItemNotificationButton />

          <Dropdown renderToggle={PortalAdminSwitcher} placement="bottomEnd">
            <Dropdown.Item
              onClick={() =>
                (window.location.href = `/app/${enterprise?.slug}/${program?.slug}/overviews`)
              }
            >
              <FaUserTie color="#696969" />
              Participants View
            </Dropdown.Item>
          </Dropdown>

          <Dropdown
            renderToggle={PortalAdminAvatar}
            placement="bottomEnd"
            onSelect={handleSelect}
          >
            <Dropdown.Item
              panel
              style={{ padding: 10, color: "#696969" }}
              className="tw-flex tw-flex-row tw-gap-3 tw-px-4"
            >
              <div>
                <Avatar
                  circle
                  src={user?.profile_photo_url}
                  alt={user?.firstname?.charAt(0) || ""}
                />
              </div>
              <div className="tw-flex tw-flex-col">
                <span className="tw-text-sm">{user?.full_name}</span>
                <span className="tw-text-xs tw-font-medium">{user?.email}</span>
              </div>
            </Dropdown.Item>
            <Dropdown.Separator />
            <Dropdown.Item
              panel
              style={{ padding: 10, color: "#696969" }}
              className="tw-flex tw-flex-col tw-gap-3"
            >
              <span className="tw-text-xs">Active Program</span>
              <div className="tw-flex tw-items-center tw-gap-4">
                <Avatar
                  src={activeProgram?.photo_url}
                  alt={activeProgram?.name.charAt(0) || ""}
                  className="tw-shrink-0"
                />

                <span className="tw-text-sm tw-font-bold">
                  {activeProgram?.name}
                </span>
              </div>
              <IconButton
                appearance="ghost"
                block
                onClick={toggleSwitcher}
                className="tw-flex tw-items-center tw-gap-2"
              >
                Switch Program <MdOutlineSwitchAccessShortcut />
              </IconButton>

              <ProgramSwitcher
                open={switchOn}
                onClose={() => setSwitchOn(false)}
              />
            </Dropdown.Item>
            <Dropdown.Separator />
            <Dropdown.Item
              icon={<MdOutlineManageAccounts />}
              eventKey={"editProfile"}
              onSelect={handleSelect}
            >
              Edit Profile
            </Dropdown.Item>
            {hasOrgAdminAccess && (
              <Dropdown.Item
                icon={<MdOutlineAdminPanelSettings />}
                onSelect={handleSwitchToOrgAdmin}
              >
                Return to Organization Admin
              </Dropdown.Item>
            )}

            {baseUrl.admin && (
              <Dropdown.Item
                icon={<MdOutlineAdminPanelSettings />}
                eventKey={"admin"}
                onSelect={handleSelect}
              >
                Revert to Admin
              </Dropdown.Item>
            )}

            <Dropdown.Separator />
            <Dropdown.Item
              icon={<MdOutlineLogout />}
              eventKey={"logout"}
              onSelect={handleSelect}
            >
              Logout
            </Dropdown.Item>
          </Dropdown>
        </div>
      </Nav>
    </Navbar>
  );
};

export default PortalAdminTopNav;
