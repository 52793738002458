import React from "react";
import AppContainer from "./AppContainer";
import EventShow from "./event/EventShow";

import { UserProvider } from "../contexts/user";
import { VariablesProvider } from "../contexts/variables";

const Event = ({ variables, eventId }) => {
  return (
    <AppContainer>
      <VariablesProvider variables={variables}>
        <UserProvider>
          <EventShow eventId={eventId} />
        </UserProvider>
      </VariablesProvider>
    </AppContainer>
  );
};

export default Event;
