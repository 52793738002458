//@ts-nocheck
import React from "react";
import { usePortal } from "../../../../contexts/portal";
import GenericMsgLoader from "../../../shared/GenericMsgLoader";

const LegacyLoader = ({
  id = "settings",
  group = "admins",
  sub_id = "",
  src = null,
}) => {
  const { program, enterprise } = usePortal();
  const ref = React.useRef(null);

  const url = src
    ? `/app/${enterprise?.slug}/${program?.slug}/${src}`
    : `/app/${enterprise?.slug}/${program?.slug}/${group}/${id}/${sub_id}`;

  if (!program || !enterprise) return null;

  return (
    <turbo-frame
      id={"portal_main"}
      src={url}
      ref={ref}
      data-turbo-action="advance-params"
      data-turbo-progress-bar={true}
    >
      <GenericMsgLoader center content="loading" />
    </turbo-frame>
  );
};

export default LegacyLoader;
