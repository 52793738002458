import { useMediaQuery } from '@mantine/hooks';

// TODO: Cleanup other useMediaQuery calls
// getWindowSize.ts
// useDesktoporMobile.ts

const useScreenSize = () => {
  const isDesktop = useMediaQuery('(min-width: 768px)');
  return {
    isDesktop,
    isMobile: !isDesktop,
  };
};

export default useScreenSize;