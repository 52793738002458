// React
import React, { useState } from "react";

// UI
import { Divider, IconButton } from "rsuite";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

export default function Badges({ user }) {
  const { badges } = user;
  const [showMoreBadges, setShowMoreBadges] = useState(false);

  return (
    <div className="tw-border tw-flex tw-flex-col tw-border-solid tw-border-gray-200 tw-rounded-lg tw-pb-2 tw-pt-3 tw-mb-2">
      <p className="tw-text-center tw-font-bold montserrat">
        Most awarded badges
      </p>

      <div className="tw-flex tw-flex-wrap tw-px-2">
        {badges?.map((badge, idx) => (
          <div
            key={idx}
            className={`tw-p-2 tw-w-1/3 tw-flex tw-flex-col tw-px-2 ${
              idx > 2 && showMoreBadges === false ? "tw-hidden" : ""
            }`}
          >
            <img
              src={badge.badge.image_tag}
              alt={`badge-${idx}`}
              className="tw-text-center tw-self-center tw-mb-1 "
              style={{ width: "40px", height: "40px" }}
            />
            <p className="montserrat tw-text-center tw-font-medium tw-text-base mb-1">
              {badge.count}
            </p>
            <p className="montserrat tw-text-center tw-text-xs">
              {badge.badge.name}
            </p>
          </div>
        ))}
      </div>
      <Divider className="tw-my-2 " />
      <IconButton
        icon={
          showMoreBadges === false ? (
            <MdKeyboardArrowDown />
          ) : (
            <MdKeyboardArrowUp />
          )
        }
        onClick={() => setShowMoreBadges(!showMoreBadges)}
        size="lg"
        style={{ background: "none" }}
      />
    </div>
  );
}
