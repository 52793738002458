import React from 'react';
import { Form, Stack } from 'rsuite';
import { useUser } from '../../contexts/user';
import { FutureLabApi } from '../../util/api';
import toSentenceCase from '../../util/toSentenceCase';
import translateText from '../../util/translateText';
import UserLanguageModal from './UserLanguageModal';

const MentorLanguages = ({convertText}) => {
  const [languages, setLanguages] = React.useState([]);
  const { user } = useUser();

  React.useEffect(() => {
    FutureLabApi({
      method: 'get',
      path: '/languages',
      success: ({ data }) => {
        setLanguages(data);
      },
    });
  }, []);

  return (
    <Form.Group>
      <Stack justifyContent="space-between" className="tw-mt-6">
        <Form.ControlLabel className="tw-text-base">
          {translateText(convertText, "Languages I can mentor")}{" "}
          <span className="tw-text-red-500">*</span>
        </Form.ControlLabel>
        <UserLanguageModal
          languages={languages}
          convertText={convertText}
        />
      </Stack>
      {user.user_languages.map((user_language) => (
        <div
          key={user_language.id}
          className="tw-w-full tw-py-2 tw-px-4 tw-border tw-border-solid tw-border-gray-300 tw-mb-2"
        >
          <Stack justifyContent="space-between">
            <div>
              {user_language.language.name}
              {user_language.proficiency !== "unspecified" &&
                ` - ${toSentenceCase(user_language.proficiency)}`}
            </div>
            <UserLanguageModal
              userLanguage={user_language}
              languages={languages}
              convertText={convertText}
            />
          </Stack>
        </div>
      ))}
    </Form.Group>
  );
};

export default MentorLanguages;
