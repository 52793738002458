import React from "react";
import { VisibilityContext } from "react-horizontal-scrolling-menu";

export const RightArrow = () => {
  const { isFirstItemVisible, scrollNext, visibleElements, initComplete } =
    React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );
  React.useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleElements.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleElements]);

  return (
    <button disabled={disabled} onClick={() => scrollNext()}>
      <i className="fas fa-chevron-right tw-cursor-pointer" />
    </button>
  );
};

export const LeftArrow = () => {
  const { isFirstItemVisible, scrollPrev, visibleElements, initComplete } =
    React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );
  React.useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleElements.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleElements]);

  return (
    <button disabled={disabled} onClick={() => scrollPrev()}>
      <i className="fas fa-chevron-left tw-cursor-pointer" />
    </button>
  );
};
