import React from "react";

import { withContainer, FormWrapper, FormInput } from "../components";
import ResetPasswordForm from "../components/forms/ResetPasswordForm";

export const ResetPassword = withContainer(() => {
  return (
    <div className="tw-flex tw-items-center tw-h-screen tw-w-1/2 sm:tw-w-full lg:tw-w-2/3">
      <FormWrapper
        title="Reset your password"
        footerText=""
        footerLink={{
          title: "Return to login",
          to: "/login",
        }}
      >
        <ResetPasswordForm />
      </FormWrapper>
    </div>
  );
});
