import React from "react";
import { Col, Form, SelectPicker } from "rsuite";
import { useUser } from "../../contexts/user";
import FieldError from "../ui/FieldError";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { map } from "lodash";
import toSentenceCase from "../../util/toSentenceCase";
import translateText from "../../util/translateText";

const HiringFrequency = ({ convertText, control, func, errors }) => {
  const { user } = useUser();
  const setValue = func;

  return (
    <Form fluid className="tw-mt-6">
      <Form.Group className="tw-mb-6">
        <Form.ControlLabel className="tw-text-base">
          {translateText(convertText, "How frequently do you hire?")}
          <span className="tw-text-red-500">*</span>
        </Form.ControlLabel>
        <Controller
          name="info.hiring_frequency"
          control={control}
          render={({ field }) => (
            <SelectPicker
              data={map(user.info.hiring_frequency_list, (value, key) => ({
                label: toSentenceCase(key),
                value,
              }))}
              style={{ width: "100%" }}
              onChange={(value) => {
                setValue(field.name, value);
              }}
              placeholder={translateText(convertText, "Please select")}
              size="lg"
              {...field}
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name="info.hiring_frequency"
          as={FieldError}
        />
      </Form.Group>
    </Form>
  );
};

export default HiringFrequency;
