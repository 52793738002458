import React from "react";
import { MdInfoOutline } from "react-icons/md";
import { Button, ButtonToolbar, IconButton, Message } from "rsuite";
import { useUser } from "../../contexts/user";
import TooltipHelper from "../ui/TooltipHelper";
import translateText from "../../util/translateText";
import PlusIcon from "@rsuite/icons/Plus";

const DashboardCredits = ({ convertText = [], iconOnly = false }) => {
  const { user } = useUser();
  const { balance: creditBalance, upcoming_expiring_credits: creditStatus } =
    user;

  const expiringCredits = creditStatus === true;

  if (iconOnly) {
    return (
      <IconButton
        className="credit_button_intro"
        appearance="ghost"
        href="/subscriptions"
        icon={<PlusIcon />}
        placement="right"
      >
        {creditBalance / 10} {translateText(convertText, "Credits")}
      </IconButton>
    );
  }

  return (
    <>
      <div className="dash-credit-wrapper">
        <div className="credit-wrapper">
          <div className="credit-content">
            <span className="tw-pr-3">
              <TooltipHelper
                tip={
                  "You need credits to schedule mentoring sessions. This is your current credit balance"
                }
              />
            </span>
            <IconButton
              className="credit_button_intro"
              appearance="ghost"
              href="/subscriptions"
              icon={<PlusIcon />}
              placement="right"
            >
              {creditBalance / 10} {translateText(convertText, "Credits")}
            </IconButton>
          </div>
        </div>
        {/* {expiringCredits && (
          <div style={{ backgroundColor: "#fff2f2", padding: "5px 8px" }}>
            <MdInfoOutline
              className="font-weight-medium tw-mr-2"
              style={{ color: "#DB6057" }}
            />
            <a
              href="/main/my_account"
              className="font-size-4 tw-p-0"
              style={{ color: "#DB6057" }}
            >
              {translateText(convertText, "You have credits expiring soon!")}
            </a>
          </div>
        )} */}
      </div>
    </>
  );
};

export default DashboardCredits;
