import React from "react";
import { MdOutlineStar } from "react-icons/md";
import { Button, Panel } from "rsuite";

const LearningExperiencePanel = ({
  avgMenteeRating,
  avgMentorRating,
}) => {
  return (
    <>
      <Panel bordered className="tw-mt-3">
        <p className="tw-text-lg tw-text-center montserrat tw-font-bold">
          Learning Experience
        </p>
        <div className="tw-flex tw-justify-items-center">
          <div className="tw-flex tw-w-1/2 tw-flex-col tw-text-center">
            <span className="tw-text-3xl tw-font-medium tw-flex tw-items-center tw-justify-center	">
              <MdOutlineStar className="tw-mr-1" style={{ color: "#428AC9" }} />
              {avgMenteeRating || 0}
            </span>
            <span className="tw-text-sm">Avg. Mentee Rating</span>
          </div>
          <div className="tw-flex tw-w-1/2 tw-flex-col tw-text-center">
            <span className="tw-text-3xl tw-font-medium tw-flex tw-items-center tw-justify-center	">
              <MdOutlineStar className="tw-mr-1" style={{ color: "#428AC9" }} />
              {avgMentorRating || 0}
            </span>
            <span className="tw-text-sm">Avg. Mentor Rating</span>
          </div>
        </div>
        <div className="tw-text-center tw-mt-4">
          <Button
            appearance="primary"
            href={`pairing-health`}
            target="_self"
          >
            View details
          </Button>
        </div>
      </Panel>
    </>
  );
};

export default LearningExperiencePanel;
