import React from "react";
import { Button, Form, Input, Tooltip, Whisper } from "rsuite";
import { FormInputText } from "../../form/default/FormInputs";

import { MdOutlineDelete } from "react-icons/md";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

interface AdditionalFields {
  idx: string;
  id: string | number | undefined;
  question: number;
  active: boolean;
  // Define other properties as needed
}

const EventAdditionalDataForm = () => {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const {
    fields: dataFields,
    append: appendData,
    remove: removeData,
  } = useFieldArray({
    control,
    name: "extra_fields",
    keyName: "idx",
  });

  return (
    <>
      <h6>Attendee Data</h6>
      If you wish to obtain any extra information from your attendee, you can
      add it here. Your attendee will fill up any additional data you require
      when they sign up for the event.
      <div className="tw-my-6">
        <h6 className="tw-text-sm">Default question fields</h6>
        <ul>
          <li>Name</li>
          <li>Email</li>
          <li>Phone number</li>
        </ul>
      </div>
      <div>
        <h6 className="tw-text-sm">Additional question fields</h6>
        <div className="tw-flex tw-flex-col">
          {dataFields.length == 0
            ? "No additional questions."
            : dataFields.map((field: AdditionalFields, dataIndex) => (
                <div
                  key={dataIndex}
                  className={`tw-w-full tw-flex tw-items-center tw-flex-1 tw-my-2 ${
                    watch(`extra_fields.${dataIndex}.active`) === false
                      ? "tw-hidden"
                      : ""
                  }`}
                >
                  {/* Question ID */}
                  <Controller
                    name={`extra_fields.${dataIndex}.id`}
                    control={control}
                    defaultValue={field.id || undefined}
                    render={({ field }) => (
                      <Input {...field} className="tw-hidden" />
                    )}
                  />
                  <FormInputText
                    name={`extra_fields.${dataIndex}.title`}
                    placeholder={"What is your question?"}
                    control={control}
                    errors={errors}
                    className="tw-w-full"
                  />
                  <div className="tw-mx-2">
                    <Whisper
                      placement="top"
                      trigger="hover"
                      speaker={<Tooltip>Delete Question</Tooltip>}
                    >
                      <a
                        onClick={() => {
                          if (field.id !== undefined) {
                            setValue(`extra_fields.${dataIndex}.active`, false);
                          } else {
                            removeData(dataIndex);
                          }
                        }}
                      >
                        <MdOutlineDelete
                          style={{ fontSize: "18px", color: "red" }}
                        />
                      </a>
                    </Whisper>
                  </div>
                </div>
              ))}
        </div>
        <Button
          appearance="link"
          className="tw-my-6 tw-p-0"
          onClick={() => {
            appendData({ question: "" });
          }}
        >
          Add an extra question
        </Button>
      </div>
      <Form.Group></Form.Group>
    </>
  );
};

export default EventAdditionalDataForm;
