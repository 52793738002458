import React, { useEffect, useState } from "react";
import { Nav } from "rsuite";
import { Col, FlexboxGrid, Progress, Sidenav } from "rsuite";
import PortalDesignForm from "./form/PortalDesignForm";
import PortalMentorshipSettingForm from "./form/PortalMentorshipSettingForm";
import PortalImpactTrackingForm from "./form/PortalImpactTrackingForm";
import PortalStructureForm from "./form/PortalStructureForm";
import { useSetupVariable } from "../../../contexts/portal/setup/setupVariables";
import AlertOnRefresh from "../../shared/AlertOnRefresh";

const setupComponent = (setupStep) => {
  const component = {
    design: {
      form: <PortalDesignForm />,
      percent: 25,
      step: 1,
    },
    structure: {
      form: <PortalStructureForm />,
      percent: 50,
      step: 2,
    },
    mentorship_setting: {
      form: <PortalMentorshipSettingForm />,
      percent: 75,
      step: 3,
    },
    impact_tracking: {
      form: <PortalImpactTrackingForm setupStep={setupStep} />,
      percent: 100,
      step: 4,
    },
  };
  return component[setupStep];
};

const PortalSetupContent = () => {
  const { formStep, setFormStep, isEditing, isSetupComplete } =
    useSetupVariable();

  const { form, percent, step } = setupComponent(formStep);

  const setupComplete = isSetupComplete === true;

  return (
    <>
      {/* {formStep !== "impact_tracking" && <AlertOnRefresh />} */}

      <FlexboxGrid justify="center" className="tw-mt-10 tw-relative">
        <FlexboxGrid.Item as={Col} colspan={24} lg={18}>
          <FlexboxGrid.Item as={Col} colspan={24} lg={6}>
            {/* Side Navigation */}
            <h5 className="tw-font-bold">
              {isEditing && setupComplete ? "Edit" : "Create"} your program
            </h5>

            <p className="montserrat">
              Design impactful mentorship programs with ease.
            </p>

            {/* KIV - FUT-1506: Removed Temp. To be confirmed */}
            {/* <Sidenav appearance="subtle">
              <Sidenav.Body>
                <Nav activeKey={formStep} onSelect={setFormStep}>
                  <Nav.Item eventKey="design">Programme Design</Nav.Item>
                  <Nav.Item eventKey="structure">Programme Structure</Nav.Item>
                  <Nav.Item eventKey="mentorship_setting">
                    Mentorship Setting
                  </Nav.Item>
                  <Nav.Item eventKey="impact_tracking">
                    Impact Tracking
                  </Nav.Item>
                </Nav>
              </Sidenav.Body>
            </Sidenav> */}
          </FlexboxGrid.Item>

          {/* Main Content */}
          <FlexboxGrid.Item
            as={Col}
            colspan={24}
            lg={14}
            className="tw-relative tw-px-10"
          >
            {/* KIV - FUT-1506: Removed Temp. To be confirmed */}
            {/* Progress Bar */}
            {/* <div className="tw-w-full tw-flex">
              <Progress.Line
                percent={percent}
                strokeColor="#428AC9"
                showInfo={false}
                className="tw-p-0 tw-inline-flex tw-mr-2"
              />
              <span className="tw-inline-flex tw-text-sm tw-whitespace-nowrap">
                {step} of 4
              </span>
            </div> */}
            {/* Form Content */}
            {/* <div>{form || ""}</div> */}
            <PortalDesignForm />
          </FlexboxGrid.Item>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default PortalSetupContent;
