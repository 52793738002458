// React
import React, { useState } from "react";

// FutureLab
import useDrag from "../../../util/useDrag";
import BookingSessionDetails from "./BookingSessionDetails";
import avatarPlaceholder from "../../../images/avatar_placeholder.png";
import truncateString from "../../../util/truncateString";
import { BookingCardsButton } from "./component/BookingCardsButton";

// UI
import { Modal, Button, Stack, toaster, Message, Divider } from "rsuite";

// 3rd Party
import axios from "axios";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";

const BookingCards = ({ bookings, category, func, currentUser }) => {
  const updateState = func;
  const [open, setOpen] = useState(false);
  const [openSessionDetails, setOpenSessionDetails] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState();

  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAccept = (booking_id) => {
    setLoading(true);
    axios
      .get(`/main/bookings/${booking_id}/accept_booking`)
      .then(() => {
        setLoading(false);
        updateState(booking_id);
        handleClose();
        toaster.push(<Message type="success">Booking accepted.</Message>);
      })
      .catch((error) => {
        toaster.push(<Message type="error">{error}</Message>);
      });
  };

  const { dragStart, dragStop, dragMove } = useDrag();
  const handleDrag =
    ({ scrollContainer }) =>
    (ev: React.MouseEvent) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  return (
    <>
      <div onMouseLeave={dragStop}>
        <ScrollMenu
          // LeftArrow={LeftArrow}
          // RightArrow={RightArrow}
          onMouseDown={() => dragStart}
          onMouseUp={() => dragStop}
          onMouseMove={handleDrag}
        >
          <Stack
            className="tw-overflow-x-auto no-scrollbar"
            alignItems="flex-start"
          >
            {bookings?.map((booking) => (
              <div key={booking.id} className="dash-booking-card tw-mb-5">
                <div
                  className="tw-mr-3"
                  style={{
                    height: 180,
                    width: 180,
                    backgroundImage: `url(${booking.photo}), url(${avatarPlaceholder})`,
                    borderRadius: 6,
                    backgroundPosition: "center center",
                    backgroundSize: "cover",
                  }}
                />
                <div className="tw-flex tw-flex-col tw-w-full">
                  <div>
                    <h6>{truncateString(`${booking.name}`, 20)}</h6>
                    <p className="montserrat tw-mb-0">
                      {booking.date}
                    </p>
                    <p className="montserrat tw-mb-0">
                      {booking.time} - {booking.ending_time}
                    </p>
                    <Button
                      onClick={() => {
                        setOpenSessionDetails(true);
                        setSelectedBooking(booking);
                      }}
                      appearance="link"
                      className="tw-pl-0"
                    >
                      Session Details
                    </Button>
                  </div>
                  <div
                    className="tw-w-full d-flex"
                    style={{ marginTop: "auto" }}
                  >
                    <BookingCardsButton
                      bookings={booking}
                      category={category}
                      handleOpen={handleOpen}
                    />
                  </div>
                </div>
                <Modal size={"xs"} open={open} onClose={handleClose}>
                  <Modal.Header>
                    <Modal.Title className="tw-text-xl tw-font-medium">
                      Accept the session
                    </Modal.Title>
                  </Modal.Header>
                  <Divider className="tw-absolute tw-w-full tw-left-0 tw-mt-4 tw-bg-gray-300" />
                  <Modal.Body className="tw-text-center tw-py-5">
                    Are you sure you want to accept this request?
                  </Modal.Body>
                  <Divider className="tw-absolute tw-w-full tw-left-0 tw-m-0 tw-bg-gray-300" />
                  <Modal.Footer className="tw-pt-5">
                    <Button
                      className="tw-px-6"
                      onClick={handleClose}
                      appearance="ghost"
                    >
                      No
                    </Button>
                    <Button
                      className="tw-px-6"
                      onClick={() => handleAccept(booking.id)}
                      appearance="primary"
                      loading={loading}
                    >
                      Yes
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            ))}
          </Stack>
        </ScrollMenu>
      </div>
      {openSessionDetails && (
        <BookingSessionDetails
          booking={selectedBooking}
          category={category}
          handleAccept={handleAccept}
          openSessionDetails={openSessionDetails}
          setOpenSessionDetails={setOpenSessionDetails}
        />
      )}
    </>
  );
};

export default BookingCards;
