import React, { useEffect, useState } from "react";
import { FutureLabApi } from "../../util/api";

import { Button, Nav } from "rsuite";
import TooltipHelper from "../ui/TooltipHelper";
import UserCards from "./users/UserCards";
import translateText from "../../util/translateText";

const DashboardMentees = ({ convertText, currentUser }) => {
  const [myMentees, setMymentees] = useState([]);

  useEffect(() => {
    FutureLabApi({
      method: "get",
      path: `/dashboard/users/mentees`,
      success: ({ data }) => {
        setMymentees(data);
      },
    });
  }, []);

  return (
    <>
      <div className="tw-w-full sm:tw-block">
        <div className="sm:tw-w-full sm:tw-text-center sm:tw-mb-5 tw-mb-5 tw-flex tw-justify-between">
          <div className="tw-inline-flex tw-items-center">
            <p className="section-header montserrat">
              {translateText(convertText, "Mentees")}
            </p>
            <TooltipHelper
              tip={"These are the mentees that you have connected with"}
            />
          </div>
          <Button appearance="ghost" href="/main/mentees">
            {translateText(convertText, "See all")}
          </Button>
        </div>
        <Nav appearance="subtle" activeKey={"1"} className="tw-mb-5">
          <Nav.Item eventKey="1" className="tw-text-center">
            {translateText(convertText, "My Mentees")} ({myMentees?.length || 0}
            )
          </Nav.Item>
        </Nav>
      </div>
      {myMentees.length > 0 ? (
        <UserCards user={myMentees} forMentee currentUser={currentUser} />
      ) : (
        <>
          <div className="tw-w-full tw-text-center sm:tw-w-full sm:tw-text-center sm:tw-mb-4">
            <p className="montserrat tw-text-center tw-mt-10">
              {translateText(
                convertText,
                "Oh no! You are not currently connected to any mentees."
              )}
            </p>
            {/* <Button appearance="primary">Book a session now</Button> */}
          </div>
        </>
      )}
    </>
  );
};

export default DashboardMentees;
