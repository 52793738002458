import React from "react";

// FL
import { useEnterprise } from "../../../../contexts/enterpriseContext";

// UI
import { Nav, Navbar } from "rsuite";
import Toaster from "../../../shared/Toaster";

const EnterpriseTopNav = () => {
  const { logoutAdmin, state } = useEnterprise();

  const handleLogout = async () => {
    try {
      await logoutAdmin();
      Toaster({ type: "success", msg: "Logout successful" });
      window.location.href = "/logout";
    } catch (error) {
      console.log(error);
      Toaster({ type: "error", msg: "Oops, something went wrong!" });
    }
  };

  return (
    <Navbar
      className="tw-px-20 sm:tw-px-2 h-[56px]"
      style={{ borderBottom: "1px solid #E5E5E5" }}
    >
      <Nav className="" pullRight>
        <Nav.Menu
          className="tw-w-full"
          title={state?.enterprises?.[0]?.name || "Enterprise"}
        >
          <Nav.Item onClick={handleLogout}>Logout</Nav.Item>
        </Nav.Menu>
      </Nav>
    </Navbar>
  );
};

export default EnterpriseTopNav;
