// React
import React from "react";

// FutureLab
import { useUser } from "../../contexts/user";
import greeting from "../../util/greeting";
import avatarPlaceholder from "../../images/avatar_placeholder.png";
import DashboardCredits from "./DashboardCredits";
import translateText from "../../util/translateText";
import DashboardImpact from "./DashboardImpact";

// UI
import { Col, Divider, FlexboxGrid, Message, toaster } from "rsuite";
import { FaRegIdBadge, FaStar } from "react-icons/fa";
import { MdOutlineShare } from "react-icons/md";

// 3rd Party
import { CopyToClipboard } from "react-copy-to-clipboard";
import * as humanizeDuration from "humanize-duration";
import { DateTime, Interval } from "luxon";

const DashboardProfile = ({ convertText }) => {
  const { user } = useUser();
  const {
    user_type: userType,
    application_pending: pendingApproval,
    application,
  } = user;

  const isMentee = userType === "mentee";
  const isMentor = userType === "mentor" && pendingApproval === false;

  const mentorView = (
    <>
      <p className="montserrat tw-text-sm tw-mb-3">
        {translateText(convertText, "Mentor since")}{" "}
        {DateTime.fromISO(application?.approved_on).year} (
        {humanizeDuration(
          Interval.fromDateTimes(
            DateTime.fromISO(application?.approved_on),
            DateTime.local()
          )
            .toDuration()
            .valueOf(),
          { largest: 2, round: true }
        )}
        )
      </p>
      <span className="profile-links">
        <CopyToClipboard
          text={`${window.location.origin}/mentor_profile/${user.slug}`}
          onCopy={() =>
            toaster.push(
              <Message showIcon type="success">
                {translateText(convertText, "Copied to clipboard")}
              </Message>
            )
          }
        >
          <a>
            <MdOutlineShare className="tw-mr-2" />
            Share
            <span className="sm:tw-hidden">
              {" "}
              {translateText(convertText, "my profile")}
            </span>
          </a>
        </CopyToClipboard>
      </span>
      <Divider vertical />
      <span className="profile-links">
        <a href={`/mentor_profile/${user.slug}`} target="_blank">
          <FaRegIdBadge className="tw-mr-2" />
          <span className="sm:tw-hidden">
            {translateText(convertText, "My profile")}
          </span>
          <span className="sm:tw-inline tw-hidden">
            {translateText(convertText, "Profile")}
          </span>
        </a>
      </span>
    </>
  );

  const menteeView = (
    <>
      <p className="montserrat tw-text-sm tw-mb-3">
        {translateText(convertText, "Mentee since")}{" "}
        {DateTime.fromISO(user.created_at).year} (
        {humanizeDuration(
          Interval.fromDateTimes(
            DateTime.fromISO(user.created_at),
            DateTime.local()
          )
            .toDuration()
            .valueOf(),
          { largest: 2, round: true }
        )}
        )
      </p>
      <span className="profile-links">
        <a href={`/main/reviews`} target="_blank">
          <FaStar className="tw-mr-2" />
          <span className="sm:tw-hidden">My reviews</span>
          <span className="sm:tw-inline tw-hidden">Reviews</span>
        </a>
      </span>
      <Divider vertical />
      <span className="profile-links">
        <a href={`/mentee_profile/${user.slug}`} target="_blank">
          <FaRegIdBadge className="tw-mr-2 " />
          <span className="sm:tw-hidden">
            {translateText(convertText, "My profile")}
          </span>
          <span className="sm:tw-inline tw-hidden">
            {translateText(convertText, "Profile")}
          </span>
        </a>
      </span>
    </>
  );

  const mentorApplicationView = (
    <p className="montserrat tw-text-sm tw-mb-0">
      {translateText(convertText, "Application submitted on")}{" "}
      {application?.application_date}.
    </p>
  );

  return (
    <FlexboxGrid>
      <FlexboxGrid.Item
        as={Col}
        colspan={14}
        xs={24}
        md={14}
        lg={14}
        className="xs:tw-pb-2 tw-pb-0 tw-px-0"
      >
        <FlexboxGrid.Item
          as={Col}
          xs={8}
          md={8}
          lg={6}
          xl={5}
          className="tw-p-0"
        >
          <div>
            <img
              src={user.profile_photo_url || avatarPlaceholder}
              className="tw-w-36 tw-rounded-xl"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = avatarPlaceholder;
              }}
            />
          </div>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          as={Col}
          xs={16}
          md={16}
          lg={18}
          xl={19}
          className="tw-pl-4 tw-pr-2"
        >
          <h6 className="profile-greet">{greeting(convertText)},</h6>
          <h2 className="profile-header">{user.full_name}</h2>
          {isMentee
            ? menteeView
            : isMentor
            ? mentorView
            : mentorApplicationView}
        </FlexboxGrid.Item>
      </FlexboxGrid.Item>
      {isMentor && (
        <FlexboxGrid.Item
          as={Col}
          colspan={10}
          xs={24}
          md={10}
          lg={10}
          className="tw-px-0"
        >
          <DashboardImpact convertText={convertText} />
        </FlexboxGrid.Item>
      )}
    </FlexboxGrid>
  );
};

export default DashboardProfile;
