// React
import React from "react";

// FutureLab
import translateText from "../../../util/translateText";
import { UserType } from "../../../contexts/mentor_profile";

// UI
import { Button } from "rsuite";

export default function PortalBookingButtons({
  handleClick,
  convertText,
  user,
}) {
  const handleMessage = () => {
    window.location.href = user?.chat_link;
  };

  return (
    <>
      <Button onClick={handleMessage} appearance="ghost" block>
        {translateText(convertText, "Message")}
      </Button>
      {user.user_type === UserType.Mentor && (
        <Button
          appearance="primary"
          block
          data-target="#new_bookings_modal"
          data-mentor-id={user?.id}
          className="trigger-bookings-modal modal-trigger"
          onClick={handleClick}
        >
          {translateText(convertText, "Schedule a Session")}
        </Button>
      )}
    </>
  );
}
