// React
import React, { useMemo, useState } from "react";

// FutureLab
import RemoveUserModal from "./component/RemoveUserModal";
import UserRoleTable from "./component/UserRoleTable";
import HorizontalScroller from "../../ui/HorizontalScroller";

// UI
import { Button, Input, InputGroup, Nav } from "rsuite";
import SearchIcon from "@rsuite/icons/Search";

export default function UserManagement({
  program,
  rolesData,
  userProfilePath,
  removeUserPath,
  portalUrl,
  token,
}) {
  const [activeKey, setActiveKey] = useState(0);
  const memoizedRolesData = useMemo(() => rolesData || [], [rolesData]);
  const [userRolesData, setUserRolesData] = useState(memoizedRolesData);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [page, setPage] = useState(1);

  const handleRemoveUser = (rowData) => {
    setOpenModal(true);
    setSelectedRowData(rowData);
  };

  return (
    <div className="user_management_height">
      <div className="tw-flex tw-justify-between tw-mb-4">
        <h1 className="tw-text-xl">Participants</h1>
        <Button
          appearance="primary"
          href='user-invites'
          target="_self"
          className="tw-font-medium"
        >
          User Types and Invites
        </Button>
      </div>
      <div className="tw-flex tw-justify-between">
        <div className="tw-w-3/4">
          <HorizontalScroller>
            <Nav
              appearance="subtle"
              activeKey={activeKey}
              onSelect={setActiveKey}
              className="tw-flex tw-overflow-x-auto no-scrollbar"
            >
              {userRolesData.map((role, index) => (
                <Nav.Item
                  key={role.id}
                  eventKey={index}
                  onClick={() => setPage(1)}
                  className="tw-font-medium tw-text-[#222222] tw-px-4"
                >
                  {`${role.name} (${role.users.length})`}
                </Nav.Item>
              ))}
            </Nav>
          </HorizontalScroller>
        </div>
        <InputGroup className="tw-ml-2 tw-w-72">
          <Input
            placeholder="Search"
            onChange={(value) => setSearchKeyword(value)}
            value={searchKeyword}
          />
          <InputGroup.Button>
            <SearchIcon />
          </InputGroup.Button>
        </InputGroup>
      </div>
      <UserRoleTable
        data={userRolesData[activeKey]?.users}
        searchKeyword={searchKeyword}
        program={program}
        userProfilePath={userProfilePath}
        portalUrl={portalUrl}
        handleRemoveUser={handleRemoveUser}
        setPage={setPage}
        page={page}
      />
      {openModal && (
        <RemoveUserModal
          open={openModal}
          close={() => setOpenModal(false)}
          selectedUser={selectedRowData}
          token={token}
          setUserRolesData={setUserRolesData}
          removeUserPath={removeUserPath}
        />
      )}
    </div>
  );
}
