export default async function copyLink(link: string): Promise<boolean> {
  if (!navigator.clipboard) {
    const textArea = document.createElement("textarea");
    textArea.value = link;
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
      document.body.removeChild(textArea);
      return true;
    } catch (err) {
      console.error("Unable to copy", err);
      document.body.removeChild(textArea);
      return false;
    }
  }
  try {
    await navigator.clipboard.writeText(link);
    return true;
  } catch (err) {
    console.error("Unable to copy", err);
    return false;
  }
}
