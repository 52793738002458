// Keeping it here so that the array can be parsed properly before being passed by BE to FE
// interface ConvertedText {
//   detected: string;
//   from: string;
//   model: string;
//   origin: string;
//   text: string;
//   to: string;
// }

const translateText = (translatedText: string[], target: string): string => {
  return target;
};

export default translateText;
