// React
import React, { useEffect, useState } from "react";

// FutureLab
import { PortalApi } from "../../../util/portal_api";
import { FutureLabApi } from "../../../util/api";
import { OneOnOneRatingModal } from "./modal/OneOnOneRatingModal";
import { GroupRatingModal } from "./modal/GroupRatingModal";

const RatingDetailsModal = ({
  openModal,
  setOpenModal,
  setOpenDrawer,
  bookingId,
  mentee,
  mentor,
  type,
}) => {
  const [loading, setLoading] = useState(false);
  const [menteeReview, setmenteeReview] = useState([]);
  const [mentorReview, setmentorReview] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [activeKey, setActiveKey] = useState("1");

  useEffect(() => {
    setLoading(true);

    if (bookingId) {
      PortalApi({
        method: "get",
        path: "/mentorship_ratings/booking_reviews",
        options: { booking_id: bookingId },
        success: ({ data }) => {
          setmenteeReview(data.mentee);
          setmentorReview(data.mentor);
          setLoading(false);
        },
      });
      return () => {
        setActiveKey("1");
      };
    }
  }, [bookingId]);

  useEffect(() => {
    setLoading(true);

    if (bookingId) {
      FutureLabApi({
        method: "get",
        path: `/bookings/${bookingId}`,
        success: ({ data }) => {
          setBookingData(data);
        },
      });
      return () => {
        setActiveKey("1");
      };
    }
  }, [bookingId]);

  return (
    <>
      {type === "Pair" && (
        <OneOnOneRatingModal
          setActiveKey={setActiveKey}
          activeKey={activeKey}
          menteeReview={menteeReview}
          mentorReview={mentorReview}
          bookingData={bookingData}
          mentee={mentee}
          mentor={mentor}
          setOpenDrawer={setOpenDrawer}
          setOpenModal={setOpenModal}
          openModal={openModal}
        />
      )}

      {type === "Group" && (
        <GroupRatingModal
          setActiveKey={setActiveKey}
          activeKey={activeKey}
          menteeReview={menteeReview}
          mentorReview={mentorReview}
          bookingData={bookingData}
          mentees={mentee}
          mentors={mentor}
          setOpenDrawer={setOpenDrawer}
          setOpenModal={setOpenModal}
          openModal={openModal}
        />
      )}
    </>
  );
};

export default RatingDetailsModal;
