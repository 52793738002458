import React, { createContext, useState, useContext, useEffect } from "react";
import { Loader } from "rsuite";
import { FutureLabApi } from "../util/api";

const UserContext = createContext(undefined);

// TODO: Streamline data to minimize attributes needed
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState();

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    FutureLabApi({
      method: "get",
      path: "/users/current",
      success: ({ data }) => {
        setUser(data);
      },
    });
  };

  const refreshUser = () => {
    getUser();
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        refreshUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);

export function withUser(WrappedComponent) {
  const Component = (props) => {
    const { user, setUser, refreshUser } = useUser();

    if (!user) return <Loader center content="loading" />;

    return (
      <WrappedComponent
        user={user}
        setUser={setUser}
        refreshUser={refreshUser}
        {...props}
      />
    );
  };

  return Component;
}
