// React
import React from "react";

// FutureLab
import translateText from "../../../util/translateText";
import { UserType } from "../../../contexts/mentor_profile";

// UI
import { Button } from "rsuite";

export default function MobileFooterButton({
  convertText,
  setOpenBookingDrawer,
  user,
}) {
  const text =
    user?.user_type === UserType.Mentor
      ? "Connect with Mentor"
      : "Connect with Mentee";
  
  return (
    <div
      style={{
        zIndex: 99999,
      }}
      className="tw-fixed tw-bottom-0 tw-left-0 tw-hidden sm:tw-block tw-w-full tw-py-4 tw-px-8 tw-text-center tw-bg-[#fefefe] tw-border tw-border-solid tw-border-gray-300 tw-rounded-t-md"
    >
      {/* <Button appearance="ghost" className="tw-w-full tw-mb-2">
      {translateText(convertText, "Check my availability")}
    </Button> */}
      <Button
        onClick={() => setOpenBookingDrawer(true)}
        appearance="primary"
        block
        className="sm:tw-block tw-appearance-none tw-z-30 tw-bg-[#428ac9]"
      >
        {translateText(convertText, text)}
      </Button>
    </div>
  );
}
