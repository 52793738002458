// React
import React from "react";

// FutureLab
import { OneOnOneFeedbackDisplay } from "../components/FeedbackDisplay";
import { SessionDetails } from "../components/SessionDetails";

// UI
import { Divider, Loader, Modal, Nav, Rate } from "rsuite";

export function OneOnOneRatingModal(props) {
  const {
    setActiveKey,
    activeKey,
    menteeReview,
    mentorReview,
    bookingData,
    mentee,
    mentor,
    setOpenModal,
    openModal,
    setOpenDrawer,
  } = props;

  const handeClose = () => {
    setOpenDrawer(true);
    setOpenModal(false);
  };

  const display = (activeKey) => {
    const obj = {
      1: { user: "mentee", data: menteeReview },
      2: { user: "mentor", data: mentorReview },
    };

    return obj[activeKey];
  };

  const showDisplay = display(activeKey);

  return (
    <Modal size="md" backdrop={"static"} open={openModal} onClose={handeClose}>
      <Modal.Header>
        <Modal.Title className="tw-text-xl tw-font-medium">
          Rating Details
        </Modal.Title>
      </Modal.Header>
      <Divider className="tw-absolute tw-w-full tw-left-0 tw-mt-4 tw-bg-gray-300" />
      <Modal.Body className="tw-py-5 tw-my-5">
        <Nav
          appearance="subtle"
          activeKey={activeKey}
          onSelect={setActiveKey}
          justified
        >
          <Nav.Item className="tw-text-center" eventKey="1">
            {mentee?.full_name}
          </Nav.Item>
          <Nav.Item className="tw-text-center" eventKey="2">
            {mentor?.full_name}
          </Nav.Item>
          <Nav.Item className="tw-text-center" eventKey="3">
            Session Details
          </Nav.Item>
        </Nav>
        {activeKey !== "3" && (
          <OneOnOneFeedbackDisplay data={showDisplay.data}/>
        )}
        {activeKey === "3" && (
          <SessionDetails
            bookingData={bookingData}
            mentor={mentor}
            mentee={mentee}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}
