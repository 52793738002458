import React from "react";
import { Badge, IconButton } from "rsuite";

export const NavItemButton = ({
  icon,
  badge = false,
  badgeContent = null,
  ...restProps
}) => (
  // For future implementation. Consider badgeContent to include string msgs.
  <IconButton
    appearance="subtle"
    size="lg"
    icon={
      <Badge content={badgeContent || badge}>
        <span style={{ fontSize: 24, color: "#ffffff" }}>{icon}</span>
      </Badge>
    }
    {...restProps}
  />
);