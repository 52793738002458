import React from 'react';
import { UserProvider } from '../contexts/user';
import AppContainer from './AppContainer';
import EditMentorModal from './edit_mentor_profile_popup/EditMentorModal';

const EditMentorProfilePopup = (props) => {
  console.log('Props in Popup:', props)
  return (
    <UserProvider>
      <AppContainer>
        <EditMentorModal convertText={props.convertText} />
      </AppContainer>
    </UserProvider>
  );
};

export default EditMentorProfilePopup;
