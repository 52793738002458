// React
import React from "react";

// FutureLab
import { filterData } from "../callbacks/filterDataCallback";
import { sortData } from "../callbacks/sortColumnsCallback";
import { returnHealthGraph } from "../components/RatingGraph";
import { UserInfo } from "../../PairingTable";
import HeaderTooltip, { headerTooltipMsg } from "../components/HeaderTooltip";
import { GroupInfo } from "../components/GroupPairingInfo";

// UI
import { Button, Table } from "rsuite";
import { FaStar } from "react-icons/fa";

const { Column, HeaderCell, Cell } = Table;

export function GroupPairing(props: any) {
  const {
    data,
    searchKeyword,
    healthFilter,
    sortColumn,
    sortType,
    program,
    loading,
    handleDrawer,
    handleSortColumn,
    enterpriseSlug,
    programSlug,
  } = props;

  return (
    <Table
      data={sortData(
        filterData(data.groupPairing, searchKeyword, healthFilter),
        sortColumn,
        sortType
      )}
      autoHeight
      rowHeight={66}
      headerHeight={60}
      loading={loading}
      wordWrap="break-word"
      className="tw-w-full tw-mt-6"
      sortColumn={sortColumn}
      sortType={sortType}
      onSortColumn={handleSortColumn}
      affixHorizontalScrollbar
    >
      <Column verticalAlign="middle" align="center" flexGrow={2} minWidth={200}>
        <HeaderCell>Group Name</HeaderCell>
        <Cell>
          {(rowData) => (rowData.name ? rowData.name : `Group #${rowData.id}`)}
        </Cell>
      </Column>

      <Column verticalAlign="middle" align="center" flexGrow={2} minWidth={200}>
        <HeaderCell>Mentee</HeaderCell>
        <Cell dataKey="mentee_participants">
          {(rowData) => (
            <GroupInfo
              users={rowData.mentee_participants}
              enterpriseSlug={enterpriseSlug}
              programSlug={programSlug}
            />
          )}
        </Cell>
      </Column>

      <Column verticalAlign="middle" flexGrow={2} minWidth={250}>
        <HeaderCell align="center">Mentor</HeaderCell>
        <Cell>
          {(rowData) =>
            rowData.mentor_participant?.length > 1 ? (
              <GroupInfo
                users={rowData.mentee_participants}
                enterpriseSlug={enterpriseSlug}
                programSlug={programSlug}
              />
            ) : (
              <UserInfo user={rowData.mentor_participants} />
            )
          }
        </Cell>
      </Column>

      <Column verticalAlign="middle" align="center" sortable>
        <HeaderCell>
          <HeaderTooltip
            tooltipMessage={headerTooltipMsg(program?.pairing_method_type)}
            headerText="Pairing Health"
          />
        </HeaderCell>
        <Cell dataKey="pairing_health_score">
          {(rowData) => returnHealthGraph(rowData.pairing_health_score)}
        </Cell>
      </Column>

      <Column verticalAlign="middle" minWidth={300} align="center" sortable>
        <HeaderCell>Confirmed Sessions</HeaderCell>
        <Cell dataKey="confirmed_bookings_count" />
      </Column>

      <Column verticalAlign="middle" minWidth={300} align="center" sortable>
        <HeaderCell>Completed Sessions</HeaderCell>
        <Cell dataKey="completed_bookings_count" />
      </Column>

      <Column verticalAlign="middle" minWidth={300} align="center">
        <HeaderCell>% Mentee Feedback</HeaderCell>
        <Cell>
          {(rowData) =>
            rowData.completed_bookings_count > 0
              ? rowData.mentee_rated_count
              : "-"
          }
        </Cell>
      </Column>

      <Column verticalAlign="middle" minWidth={300} align="center">
        <HeaderCell>% Mentor Feedback</HeaderCell>
        <Cell>
          {(rowData) =>
            rowData.completed_bookings_count > 0
              ? rowData.mentor_rated_count
              : "-"
          }
        </Cell>
      </Column>

      {/* <Column verticalAlign="middle" minWidth={300} align="center">
          <HeaderCell>Messages Sent</HeaderCell>
          <Cell dataKey="messages_sent_count" />
        </Column> */}

      <Column verticalAlign="middle" minWidth={300} align="center">
        <HeaderCell>
          <HeaderTooltip
            tooltipMessage={headerTooltipMsg("avg_score")}
            headerText="Avg. Rating"
          />
        </HeaderCell>
        <Cell>
          {(rowData) =>
            rowData.average_ratings_score > 0 ? (
              <div className="tw-flex tw-items-center tw-justify-center	">
                <span className="tw-mr-1">{rowData.average_ratings_score}</span>
                <FaStar color="428ac9" size={20} className="tw-mb-1" />
              </div>
            ) : (
              "-"
            )
          }
        </Cell>
      </Column>

      <Column
        verticalAlign="middle"
        flexGrow={1}
        minWidth={150}
        align="center"
        fixed="right"
      >
        <HeaderCell>Action</HeaderCell>
        <Cell>
          {(rowData) => (
            <Button
              appearance="link"
              onClick={() =>
                handleDrawer(
                  rowData.id,
                  rowData.mentee_participants,
                  rowData.mentor_participants,
                  rowData.type
                )
              }
            >
              Rating Details
            </Button>
          )}
        </Cell>
      </Column>
    </Table>
  );
}
