import React from "react";
import { Button, Divider, Panel } from "rsuite";

const TotalSessionsPanel = ({
  totalSessions,
  avgSessionsConducted,
  avgRatingsConducted,
}) => {
  return (
    <>
      <Panel
        bordered
        className="tw-w-1/3 tw-mx-4 tw-flex tw-flex-col tw-justify-center tw-items-center"
      >
        <div className="tw-grow tw-w-full">
          <div className="tw-flex tw-flex-col tw-text-center">
            <span className="tw-text-3xl tw-font-medium">
              {totalSessions || 0}
            </span>
            <span className="tw-text-sm">Total Session Booked</span>
          </div>
          <Divider />
          <div className="tw-flex tw-flex-row tw-justify-center tw-w-full">
            <div className="tw-flex tw-w-1/2 tw-flex-col tw-text-center">
              <span className="tw-text-3xl tw-font-medium">
                {avgSessionsConducted || 0}%
              </span>
              <span className="tw-text-sm">Sessions Conducted</span>
            </div>
            <div className="tw-flex tw-w-1/2 tw-flex-col tw-text-center">
              <span className="tw-text-3xl tw-font-medium">
                {avgRatingsConducted || 0}%
              </span>
              <span className="tw-text-sm">Reflections Submitted</span>
            </div>
          </div>
          <div className="tw-text-center tw-mt-4">
            <Button
              appearance="primary"
              href={`bookings/bookings_list`}
              target="_self"
            >
              View details
            </Button>
          </div>
        </div>
      </Panel>
    </>
  );
};

export default TotalSessionsPanel;
