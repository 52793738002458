export const findNavByEventKey = (eventKey, navItems) => {
  let result;

  navItems.forEach((item) => {
    if (item.eventKey === eventKey) {
      result = item;
    } else if (item.children) {
      item.children.forEach((child) => {
        if (child.eventKey === eventKey) {
          result = child;
        }
      });
    }
  });

  return result;
};

export const findNavByActiveRoutePath = (activePath, navItems) => {
  let result;

  navItems.forEach((item) => {
    if (item.path === activePath) {
      result = item;
    } else if (item.children) {
      item.children.forEach((child) => {
        if (child.path === activePath) {
          result = child;
        }
      });
    }
  });

  return result;
};
