// React
import React from "react";

// UI
import { Button } from "rsuite";

export function BookingCardsButton(props) {
  const { bookings, category, handleOpen } = props;
  const openInbox = (chat_link) =>
    chat_link && window.open(chat_link, '_self');

  switch (category) {
    case "upcoming":
      return (
        <>
          <Button
            appearance="ghost"
            className="tw-mr-2 tw-w-full"
            onClick={() => openInbox(bookings.chat_link)}
            /* href={bookings.inbox?.toString()} */
          >
            Chat
          </Button>
          <Button
            appearance="primary"
            className="tw-w-full"
            href={bookings.contact_info?.toString()}
          >
            Join
          </Button>
        </>
      );
    case "completed":
      return (
        <>
          <Button
            appearance="ghost"
            className="tw-mr-2 tw-w-full"
            onClick={() => openInbox(bookings.chat_link)}
            /* href={bookings.inbox?.toString()} */
          >
            Chat
          </Button>
          <Button
            appearance="primary"
            className="tw-w-full"
            href={bookings.rate_now.toString()}
          >
            Rate
          </Button>
        </>
      );
    case "pending":
      return bookings.status === "Pending your approval" ? (
        <>
          <Button
            appearance="ghost"
            className="tw-mr-2 tw-w-full"
            href={bookings.inbox.toString()}
          >
            Chat
          </Button>
          <Button
            appearance="primary"
            className="tw-w-full"
            onClick={handleOpen}
          >
            Accept
          </Button>
        </>
      ) : (
        <>
          <Button
            appearance="ghost"
            className="tw-w-full"
            href={bookings.inbox.toString()}
          >
            Chat
          </Button>
        </>
      );
    default:
      return (
        <Button
          appearance="ghost"
          className="tw-w-full"
          onClick={() => openInbox(bookings.chat_link)}
          /* href={bookings.inbox?.toString()} */
        >
          Chat
        </Button>
      );
  }
}
