import React, { useEffect, useState } from "react";
import { Button, Divider, Form, Input, Modal, Toggle } from "rsuite";
import {
  FormInputPicker,
  FormInputText,
} from "../../../form/default/FormInputs";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  id: yup.mixed().nullable(),
  name: yup.string().required(),
  description: yup.string().required(),
  category: yup.string().required(),
  link_name: yup.string(),
  link_url: yup.string(),
  _destroy: yup.boolean(),
});

const PortalTaskFormModal = ({
  appendTask,
  task,
  taskIndex,
  updateTask,
  openModal,
  handleCloseModalForm,
}) => {
  const defaultformValues = {
    id: "",
    name: "",
    description: "",
    category: "",
    link_name: "",
    link_url: "",
    _destroy: false,
  };

  const {
    control,
    formState: { errors },
    reset,
    setValue,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultformValues,
  });

  useEffect(() => {
    if (task) {
      reset(task);
    }
    if (task === undefined) {
      reset(defaultformValues);
    }
  }, [task, taskIndex]);

  const handleAddTask = (taskData) => {
    if (task) {
      updateTask(taskIndex, taskData);
    } else {
      appendTask(taskData);
    }
    handleCloseModalForm();
    reset();
  };

  return (
    <>
      <Modal size="sm" open={openModal} onClose={() => handleCloseModalForm()}>
        <Modal.Header>
          <Modal.Title className="tw-text-lg tw-font-medium">
            {task ? "Edit" : "Add"} task
          </Modal.Title>
        </Modal.Header>
        <Divider className="tw-absolute tw-w-full tw-left-0 tw-mt-4 tw-bg-gray-300" />
        <Modal.Body className="tw-py-5">
          {/* Input Field for ID */}
          <Form>
            <Controller
              name={`id`}
              control={control}
              render={({ field }) => <Input {...field} className="tw-hidden" />}
            />

            <Controller
              name={"_destroy"}
              control={control}
              render={({ field: { name, value } }) => (
                <Toggle
                  defaultChecked={value}
                  onChange={(value) => setValue(name, value)}
                  className="tw-hidden"
                />
              )}
            />

            <Form.Group>
              <FormInputText
                name={`name`}
                control={control}
                errors={errors}
                label={"Task name"}
                placeholder={`Task name`}
              />
            </Form.Group>
            <Form.Group>
              <FormInputText
                name={`description`}
                control={control}
                errors={errors}
                rows={4}
                as="textarea"
                label={"Task description"}
                placeholder={`Task description`}
              />
            </Form.Group>
            <Form.Group>
              <FormInputPicker
                name={`category`}
                control={control}
                errors={errors}
                label={"Category"}
                data={[
                  { value: "session", label: "Mentoring Session" },
                  { value: "assignment", label: "Assignment" },
                  { value: "mentor_class", label: "Mentor Class" },
                  { value: "survey", label: "Survey" },
                ]}
                placeholder={`Category`}
              />
            </Form.Group>
            <Divider />
            <Form.Group>
              <FormInputText
                name={`link_name`}
                control={control}
                errors={errors}
                label={"Link name"}
                placeholder={`Link name`}
              />
            </Form.Group>
            <Form.Group>
              <FormInputText
                name={`link_url`}
                control={control}
                errors={errors}
                label={"URL"}
                placeholder={`URL`}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Divider className="tw-absolute tw-w-full tw-left-0 tw-m-0 tw-bg-gray-300" />
        <Modal.Footer className="tw-pt-5">
          <Button
            className="tw-px-6"
            onClick={() => handleCloseModalForm()}
            appearance="ghost"
          >
            Close
          </Button>
          <Button
            className="tw-px-6"
            appearance="primary"
            onClick={handleSubmit(handleAddTask)}
          >
            {task ? "Update" : "Add"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PortalTaskFormModal;
