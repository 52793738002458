import React from 'react';

const CustomTag = (props) => {
  const { text, className, onClick } = props;
  return (
    <span
      className={`tw-border tw-text-sm tw-border-solid tw-rounded-md tw-px-2 tw-py-1 tw-inline-block ${className}`}
      style={{ color: '#1675E0', borderColor: '#1675E0' }}
      onClick={onClick}
    >
      {text}
    </span>
  );
};

export default CustomTag;
