const Text = {
  sign_up: {
    header: 'Rate our platform experience',
    title: "Please rate your sign up experience",
    scaleDescription: ["Not Likely", "Very Likely"],
    detractors: {
      description: "Let us know what happened and we will work on it",
      inputPlaceHolder:
        "Please share with us your feedback here. We will address it immediately",
    },
    passives: {
      description: "Thank you for your feedback",
      inputPlaceHolder: "How can we improve your experience",
    },
    promoters: {
      description: `We’re happy that you’re happy!`,
      inputPlaceHolder:
        "Please share with us what you like most about the sign up process",
    },
  },
  first_booking: {
    header: 'Rate our platform experience',
    title: `How likely are you to recommend our mentoring platform to a friend or colleague?`,
    scaleDescription: ["Not Likely", "Very Likely"],
    detractors: {
      description: "Let us know what happened and we will work on it",
      inputQuestion:
        "What challenges have you faced that you find frustating or difficult to use?",
      inputPlaceHolder:
        "Please share with us your feedback here. We will address it immediately",
    },
    passives: {
      description: "Thank you for your feedback",
      inputQuestion:
        "What can we do to improve your experience on the platform?",
      inputPlaceHolder: "How can we improve your experience",
    },
    promoters: {
      description: `We’re happy that you’re happy!`,
      inputQuestion: "What did you liked most about the platform?",
      inputPlaceHolder:
        "Please share with us what you found valuable about our platform",
    },
  },
  program_application: {
    header: 'Rate our application process',
    title: "How was your experience applying?",
    scaleDescription: ["Very Difficult", "Very Easy"],
    detractors: {
      description: "Let us know what happened and we will work on it",
      inputPlaceHolder:
        "Please share with us your feedback. We'll do our best to make it better",
    },
    passives: {
      description: "Thank you for your feedback.",
      inputPlaceHolder: "How can we improve your experience",
    },
    promoters: {
      description: `We’re happy that you’re happy!`,
      inputPlaceHolder: "Please share with us what you liked most about the application process",
    },
  },
};

export function getNpsText(nps_text_type: string, npsScore: number) {
  const surveyType = nps_text_type;

  const scoreRanges = {
    detractors: { min: 1, max: 5 },
    passives: { min: 6, max: 8 },
    promoters: { min: 9, max: 10 },
  };

  const selectedTextType = Object.keys(scoreRanges).find(
    (type) =>
      npsScore >= scoreRanges[type].min && npsScore <= scoreRanges[type].max
  );

  const textInfo = Text[surveyType][selectedTextType];

  return {
    header: Text[surveyType]?.header,
    title: Text[surveyType]?.title,
    scaleDescription: Text[surveyType]?.scaleDescription,
    description: textInfo?.description,
    inputQuestion: textInfo?.inputQuestion,
    inputPlaceHolder: textInfo?.inputPlaceHolder,
  };
}
