// React
import React from "react";

// Futurelab
import mentorshipImage from "../../../images/mentor-navbar-banner-image.png";

// UI
import { Button } from "rsuite";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

export default function MentorNavbarBanner() {
  return (
    <>
      <div
        onClick={() => window.open("https://wa.link/aigdh7", "_blank")}
        className="xs:tw-flex xs:tw-flex-col tw-items-center tw-justify-center tw-bg-[#8DBFEB] tw-font-bold tw-text-xs tw-h-[60px] tw-hidden"
      >
        <span className="tw-bg-[#0769A1] tw-text-[#FFDF5F] tw-rounded tw-py-1 tw-px-1.5 tw-mt-1">
          Looking to bring mentorship to your workplace?
        </span>
        <div className="tw-mt-1.5 tw-flex tw-items-center">
          <span className="tw-text-black ">We can help!</span>
          <Button
            endIcon={<MdOutlineKeyboardArrowRight size={16} />}
            className="tw-ml-3 tw-px-1.5 tw-py-0 tw-font-semibold tw-text-black tw-text-xs"
          >
            Contact us today
          </Button>
        </div>
      </div>
      <div className="tw-flex tw-items-center tw-justify-center tw-bg-[#8DBFEB] tw-font-semibold tw-text-xl sm:tw-text-base tw-h-[60px] tw-overflow-hidden xs:tw-hidden">
        <div className="tw-mt-1.5">
          <span className="tw-bg-[#0769A1] tw-text-[#FFDF5F] tw-rounded tw-py-1 tw-px-2 tw-mr-3">
            Looking to bring mentorship to your workplace?
          </span>
          <span className="tw-text-black">We can help!</span>
          <img
            src={mentorshipImage}
            className="tw-w-28 tw-relative tw-top-3.5 tw-ml-2 tw-overflow-hidden sm:tw-hidden"
          />
        </div>
        <Button
          href="https://wa.link/aigdh7"
          target="_blank"
          endIcon={<MdOutlineKeyboardArrowRight size={24} />}
          className="tw-ml-5 sm:tw-ml-3 tw-font-semibold tw-text-black"
        >
          Contact us today
        </Button>
      </div>
    </>
  );
}
