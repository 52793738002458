// React
import React from "react";

// FutureLab
import instagramIcon from "../../../images/instagram_icon.png";
import linkedinIcon from "../../../images/linkedin_icon.png";
import translateText from "../../../util/translateText";

// Ui
import { Panel } from "rsuite";

export default function SocialMedia({ convertText, user }) {
  return (
    <Panel bordered className="xs:tw-p-0 tw-mt-2">
      <p className="tw-font-bold tw-mb-1 sm:tw-hidden montserrat">
        {translateText(convertText, "More about me")}
      </p>
      <div className="tw-flex tw-gap-2 sm:tw-hidden">
        {user?.info?.linkedin_url && (
          <a href={user?.info?.linkedin_url} target="_blank">
            <img src={linkedinIcon} alt="" />
          </a>
        )}
        {user?.info?.instagram_url && (
          <a href={user?.info?.instagram_url} target="_blank">
            <img src={instagramIcon} alt="" />
          </a>
        )}
      </div>
    </Panel>
  );
}
