import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Form, Input, InputGroup, InputNumber } from "rsuite";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useVariables } from "../../../contexts/variables";
import Cookies from "js-cookie";
import { ErrorMessage } from "@hookform/error-message";
import FieldError from "../../ui/FieldError";
import { useUser } from "../../../contexts/user";
import mixpanel from "mixpanel-browser";

const schema = yup.object().shape({
  tickets: yup.array().of(
    yup.object().shape({
      id: yup.number().required(),
      amount: yup.number().required().label("Amount"),
    })
  ),
});

const EventTicketForm = ({
  tickets,
  handleOpenModal,
  setPurchasedTickets,
  event,
  setOpen = null,
}) => {
  const { variables } = useVariables();
  const { user } = useUser();

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const displayTicketPrice = (ticket) => {
    if (!ticket.available) {
      return "Sold out";
    }

    if (ticket.price > 0) {
      return `RM ${ticket.price.toFixed(2)}*`;
    }

    return "FREE";
  };

  const ticketFormData = watch();

  const ticketValidChecker = () => {
    if (tickets.length == 1) {
      return true;
    }

    const isFormValid = ticketFormData.tickets?.some(
      (ticket) => ticket.amount > 0
    );

    return isFormValid;
  };

  const decrementValue = (field) => {
    const newValue = Math.max(field.value - 1, 0);
    field.onChange(newValue);
  };

  const maxValue = (ticket) => {
    const { price, tickets_left, max_per_user } = ticket;

    if (price > 0 && tickets_left < 10) {
      return tickets_left;
    }
    return max_per_user > 0 ? max_per_user : 10;
  };

  const incrementValue = (field, ticket) => {
    const { ticket_type } = ticket;

    const newValue = Math.min(
      field.value + 1,
      ticket_type === "free" ? 1 : maxValue(ticket)
    );
    field.onChange(newValue);
  };

  const onSubmit = (data) => {
    mixpanel.track("Event - Reserved Spot", {
      Name: user.full_name,
      "User Type": user.user_type,
      "Event Name": event.title,
    });
    if (setOpen) {
      setOpen(false);
    }

    handleOpenModal();
    const filteredTickets = data.tickets?.filter((ticket) => ticket.amount > 0);

    setPurchasedTickets(filteredTickets);
  };

  return (
    <Form>
      {tickets.map((ticket, index) => (
        <div key={ticket.id} className="tw-flex tw-mb-4 tw-justify-between">
          <div>
            <p className="montserrat tw-m-0">{ticket.name}</p>
            <p className="tw-text-sm tw-font-bold tw-m-0 montserrat">
              {displayTicketPrice(ticket)}
            </p>
          </div>
          {user && ticket.available == true && (
            <div>
              <Controller
                control={control}
                name={`tickets.${index}.id`}
                defaultValue={ticket.id}
                render={({ field }) => (
                  <Input {...field} className="tw-hidden" />
                )}
              />
              <Controller
                control={control}
                name={`tickets.${index}.amount`}
                defaultValue={tickets.length > 1 ? 0 : 1}
                render={({ field }) => (
                  <>
                    <InputGroup style={{ width: 120 }}>
                      <InputGroup.Button onClick={() => decrementValue(field)}>
                        -
                      </InputGroup.Button>
                      <InputNumber
                        id={`tickets.${index}.amount`}
                        className="custom-input-number tw-text-center"
                        readOnly
                        {...field}
                      />

                      <InputGroup.Button
                        onClick={() => incrementValue(field, ticket)}
                        disabled={
                          field.value >=
                          (ticket.max_per_user > 0
                            ? ticket.max_per_user
                            : ticket.ticket_type === "free"
                            ? 1
                            : maxValue(ticket))
                        }
                      >
                        +
                      </InputGroup.Button>
                    </InputGroup>
                    <ErrorMessage
                      errors={errors}
                      name={`tickets.${index}.amount`}
                      as={FieldError}
                    />
                  </>
                )}
              />
              {ticket.tickets_left && ticket.tickets_left <= 10 && (
                <p className="tw-text-sm tw-my-2 tw-m-0 montserrat tw-float-right">
                  <span className="tw-font-medium">{ticket.tickets_left}</span>{" "}
                  left
                </p>
              )}
            </div>
          )}
        </div>
      ))}
      {user ? (
        <Button
          appearance="primary"
          block
          disabled={!ticketValidChecker()}
          onClick={handleSubmit(onSubmit)}
        >
          Reserve my spot
        </Button>
      ) : (
        <Button
          appearance="primary"
          block
          href={`${variables?.PWA_URL}/users/sign_in`}
          onClick={() =>
            Cookies.set("url_return_to", window.location.href, {
              domain: `.${variables?.domain}`,
            })
          }
        >
          Reserve my spot
        </Button>
      )}
    </Form>
  );
};

export default EventTicketForm;
