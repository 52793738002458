import React from "react";
import { Button, Divider, Modal } from "rsuite";

const SendFeedbackModal = ({ openModal, setOpenModal, handleClick }) => {
  return (
    <>
      <Modal
        size={"xs"}
        backdrop={"static"}
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <Modal.Header>
          <Modal.Title className="tw-font-medium">
            Send Feedback Reminder
          </Modal.Title>
        </Modal.Header>

        <Divider className="tw-absolute tw-w-full tw-left-0 tw-mt-4 tw-bg-gray-300" />

        <Modal.Body className="tw-py-5 tw-my-5">
          <p className="montserrat">
            Please note that you have already triggered this notification within
            the last 24 hours.
          </p>

          <p className="montserrat">
            Would you like to send another reminder notification regardless?
          </p>
        </Modal.Body>

        <Modal.Footer className="tw-pt-5">
          <Button appearance="ghost" onClick={() => setOpenModal(false)}>
            Cancel
          </Button>
          <Button appearance="primary" onClick={handleClick}>
            Send Reminder
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SendFeedbackModal;