// FutureLab
import { set } from "js-cookie";
import { FutureLabApi } from "./api";
import { handleFollowToggle } from "./handleFollowToggle";

type handleUserFollowProps = {
  mentor: { id };
  setIsFollowing?: React.Dispatch<React.SetStateAction<any>>;
  setUsers?: React.Dispatch<React.SetStateAction<any>>;
  onSuccess?: (data?) => void;
  onFailure?: () => void;
};

export const handleUserFollow = async ({
  mentor,
  setIsFollowing,
  setUsers,
  onSuccess,
  onFailure,
}: handleUserFollowProps) => {
  try {
    await FutureLabApi({
      method: "post",
      path: "/users/current/set_follow",
      options: { mentor_id: mentor.id },
      success: ({ data }) => {
        onSuccess(data); // Execute the success callback if provided
        setIsFollowing(data); // Set the new follow status
        if (setUsers) {
          handleFollowToggle({
            userId: mentor.id,
            isFollowing: data,
            setUsers,
          });
        }
      },
    });
  } catch (error) {
    console.error("Error in following this user:", error);
    onFailure(); // Execute the failure callback if provided
    // Handle error if needed
  }
};
