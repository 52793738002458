// React
import React from "react";

// Futurelab
import applicationSubmitted from "../assets/application-submitted.png";

// UI
import { FlexboxGrid, Col } from "rsuite";

const ApplicationSubmitted = (props) => {
  const { applicationStatusURL, currentUser, program } =
    props;

  return (
    <FlexboxGrid className="tw-pt-12" justify="center">
      <FlexboxGrid.Item colspan={24} className="tw-flex tw-justify-center">
        <img
          className="tw-w-60 tw-h-60 tw-mb-2 tw-rounded-md sm:tw-w-28 sm:tw-h-28"
          src={program?.photo_url}
        />
      </FlexboxGrid.Item>

      <FlexboxGrid.Item colspan={24} className="tw-flex tw-justify-center mt-5">
        <img
          className="tw-w-28 tw-h-28 tw-mb-5 sm:tw-w-16 sm:tw-h-16"
          src={applicationSubmitted}
        />
      </FlexboxGrid.Item>

      <FlexboxGrid justify="center">
        <FlexboxGrid.Item
          colspan={24}
          as={Col}
          xs={21}
          md={12}
          lg={10}
          xl={8}
          className="tw-text-center tw-text-gray-600 tw-pb-6"
        >
          <div className="tw-font-bold tw-text-lg tw-text-center tw-text-gray-900 tw-pb-3">
            {`Thank you, ${currentUser?.firstname} for submitting your application`}
          </div>
          <div>
            We will review your application and will get back to you soon. You
            can track your application process in our website{" "}
            <a
              href={applicationStatusURL}
              className="tw-font-medium hover:tw-underline"
            >
              here
            </a>{" "}
            or check your email inbox.
          </div>
        </FlexboxGrid.Item>
        <div className="tw-fixed tw-bottom-0 tw-text-center">
          {program?.support_email && (
            <p className="tw-text-sm tw-text-center">
              Need further assistance?
              <br />
              Contact{" "}
              <a href={`mailto:${program.support_email}`}>
                {program.support_email}
              </a>
            </p>
          )}
          <p className=" tw-text-sm tw-italic tw-text-center">
            Powered by FutureLab
          </p>
        </div>
      </FlexboxGrid>
    </FlexboxGrid>
  );
};

export default ApplicationSubmitted;
