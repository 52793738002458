import React, { useState, useContext } from "react";

type baseUrlType = {
  fl: string;
  editProfile: string;
  subscriptions: string;
  support: string;
  logout: string;
  chat: string;
  admin: string | null;
};

type onPortalType = {
  programSlug: string;
  enterpriseSlug: string;
};
interface NavbarContextProps {
  onPortal: onPortalType | null;
  portalId: number;
  baseUrl: baseUrlType;
  showFooter: boolean;
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  toggleNavbar: () => void;
}

const NavbarContext: React.Context<NavbarContextProps> = React.createContext({
  onPortal: null,
  portalId: null,
  baseUrl: null,
  showFooter: null,
  isExpanded: false,
  setIsExpanded: () => {},
  toggleNavbar: () => {},
});

export const NavbarProvider = ({
  onPortal = null,
  portalId = null,
  baseUrl = null,
  showFooter = null,
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(Boolean(portalId));

  // TODO: Set activeKey state
  // Get params from URL and set ActiveKey

  const toggleNavbar = () => {
    setIsExpanded((prevState) => !prevState);
  };

  return (
    <NavbarContext.Provider
      value={{
        onPortal,
        portalId,
        isExpanded,
        setIsExpanded,
        toggleNavbar,
        baseUrl,
        showFooter,
      }}
    >
      {children}
    </NavbarContext.Provider>
  );
};

export const useNavbarContext = () => useContext(NavbarContext);
