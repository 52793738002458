// React
import React from "react";

// FutureLab
import NpsForm from "./component/Form";
import { UserNps } from "../NetPromoterScore";
import { checkLocalStorageKeys } from "../../util/localStoragePopUpChecker";
import { getNpsText } from "./utils/getText";

// UI
import { Button, Modal } from "rsuite";

// 3rd Party
import Cookies from "js-cookie";

interface NpsProps {
  portalId: string | null;
  userNpsDetails: UserNps;
  setOpenNpsModel: Function;
  openNpsModel: boolean;
  onSubmit: Function;
  handleSubmit: Function;
  control: any;
  errors: any;
  onPortal: { enterpriseSlug: string | null; programSlug: string | null };
  npsScore: number;
}

export default function NetPromoterScoreContent(npsProps: NpsProps) {
  const {
    portalId,
    userNpsDetails,
    setOpenNpsModel,
    openNpsModel,
    onSubmit,
    handleSubmit,
    control,
    errors,
    npsScore,
  } = npsProps;
  const isMobile = window.innerWidth < 1024;

  function handleClose() {
    if (userNpsDetails?.text_type !== "program_application") {
      Cookies.remove("modal_to_show");
      Cookies.set(`${userNpsDetails?.type}`, "true", { expires: 7 });
    }
    setOpenNpsModel(false);
  }

  return (
    <>
      {userNpsDetails?.type && (
        <Modal
          backdrop="static"
          open={openNpsModel}
          onClose={handleClose}
          size="sm"
          className="xs:tw-mt-5 xs:tw-w-full tw-w-[500px]"
        >
          <Modal.Header>
            <Modal.Title className="tw-font-semibold">
              {getNpsText(userNpsDetails?.text_type, npsScore).header}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <NpsForm
              userNpsDetails={userNpsDetails}
              npsScore={npsScore}
              control={control}
              errors={errors}
              portalId={portalId}
              isMobile={isMobile}
            />
          </Modal.Body>
          <Modal.Footer className="tw-flex tw-justify-end">
            <Button appearance="ghost" onClick={handleClose}>
              Skip for now
            </Button>
            <Button
              appearance="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={!npsScore}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
