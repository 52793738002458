// React
import React, { forwardRef, ReactNode, useEffect, useState } from "react";
import { MemoryRouter, Routes, Route, Outlet, Link } from "react-router-dom";

// FutureLab
import GroupList from "./GroupList";
import UserList from "./UserList";
import ChatProvider from "../../contexts/chatAuth";
import CreateGroup from "./CreateGroup";
import useGroupChat from "./useGroupChat";
import useCreateForm from "./useCreateForm";
import EditGroup from "./EditGroup";

interface NavProps {
  children: ReactNode;
  href: string;
}

const ChatManagement = (props) => {
  return (
    <MemoryRouter>
      <ChatProvider>
        <Routes>
          <Route element={<Wrapper {...props} />}>
            <Route path="/" element={<GroupList />} />
            <Route path="/user-list" element={<UserList />} />
            <Route path="/add-group" element={<CreateGroup />} />
            <Route path="/edit-group" element={<EditGroup />} />
          </Route>
        </Routes>
      </ChatProvider>
    </MemoryRouter>
  );
};

export const NavLink = forwardRef((send, ref: any) => {
  const { href, children, ...props } = send as NavProps;
  return (
    <Link ref={ref} preventScrollReset to={href} {...props}>
      {children}
    </Link>
  );
});

const Wrapper = (props) => {
  const [cb, setCb] = useState(() => {});
  const { groupChats, deleteUsers, addUsers, api, getGroupChats } =
    useGroupChat({
      programId: props.program.id,
      baseUrl: props.baseUrl,
      programName: props.program.name,
    });
  const instance = useCreateForm(props.program, api, getGroupChats);
  const [selectedGroup, setSelectedGroup] = useState(null);

  useEffect(() => {
    if (selectedGroup && groupChats.length > 0) {
      const group = groupChats.find((group) => group.id === selectedGroup.id);
      setSelectedGroup(group);
    }
  }, [groupChats]);

  return (
    <Outlet
      context={{
        groupChats,
        selectedGroup,
        setSelectedGroup,
        cb,
        setCb,
        deleteUsers,
        addUsers,
        instance,
        ...props,
      }}
    />
  );
};

export default ChatManagement;
