// React
import React, { useState } from "react";

// FutureLab
import { SessionDetails } from "../components/SessionDetails";
import { GroupFeedbackDisplay } from "../components/FeedbackDisplay";
import { GroupModalParticipantList } from "../components/GroupModalParticipantList";
import { GroupModalOption } from "../components/GroupModalOption";

// UI
import { Col, Divider, FlexboxGrid, Modal, Nav, Stack } from "rsuite";

// 3rd Party
import _ from "lodash";

export function GroupRatingModal(props) {
  const {
    setActiveKey,
    activeKey,
    menteeReview,
    mentorReview,
    bookingData,
    mentees,
    mentors,
    setOpenDrawer,
    setOpenModal,
    openModal,
  } = props;

  const [openRating, setOpenRating] = useState(false);
  const [menteeKey, setMenteeKey] = useState("0");
  const [mentorKey, setMentorKey] = useState("0");
  const [menteeFeedbackKey, setMenteeFeedbackKey] = useState("0");
  const [mentorFeedbackKey, setMentorFeedbackKey] = useState("0");

  const handeClose = () => {
    setOpenDrawer(true);
    setOpenModal(false);
    setMenteeKey("0");
    setMentorKey("0");
    setMenteeFeedbackKey("0");
    setMentorFeedbackKey("0");
    setActiveKey("1");
    setOpenRating(false);
  };

  // The mentorKey is not utilize since a group can only assign a single
  // mentor hence the initial state is already satifying that requirement
  const display = (
    activeKey,
    menteeKey,
    menteeFeedbackKey,
    mentorKey,
    mentorFeedbackKey
  ) => {
    const obj = {
      1: {
        user: [mentors].slice(
          mentorFeedbackKey,
          _.toInteger(mentorFeedbackKey) + 1
        ),
        data: menteeReview.slice(menteeKey, _.toInteger(menteeKey) + 1),
      },
      2: {
        user: mentees.slice(
          menteeFeedbackKey,
          _.toInteger(menteeFeedbackKey) + 1
        ),
        data: mentorReview.slice(
          menteeFeedbackKey,
          _.toInteger(menteeFeedbackKey) + 1
        ),
      },
    };

    return obj[activeKey];
  };

  const showDisplay = display(
    activeKey,
    menteeKey,
    menteeFeedbackKey,
    mentorKey,
    mentorFeedbackKey
  );

  const renderMenteeOption = () => {
    return GroupModalOption(
      mentees,
      setOpenRating,
      openRating,
      showDisplay.data
    );
  };

  const renderMentorOption = () => {
    return GroupModalOption(
      [mentors],
      setOpenRating,
      openRating,
      showDisplay.data
    );
  };

  const renderMenteeList = () => {
    return GroupModalParticipantList(mentees, menteeReview);
  };

  const renderMentorList = () => {
    return GroupModalParticipantList([mentors], mentorReview);
  };

  return (
    <Modal size="lg" backdrop={"static"} open={openModal} onClose={handeClose}>
      <Modal.Header>
        <Modal.Title className="tw-text-xl tw-font-medium">
          Rating Details
        </Modal.Title>
      </Modal.Header>

      <Divider className="tw-absolute tw-w-full tw-left-0 tw-mt-4 tw-bg-gray-300" />

      <Modal.Body className="tw-py-5 tw-my-5">
        <Nav
          appearance="subtle"
          activeKey={activeKey}
          onSelect={setActiveKey}
          justified
        >
          <Nav.Item className="tw-text-center" eventKey="1">
            Mentee Feedbacks
          </Nav.Item>
          <Nav.Item className="tw-text-center" eventKey="2">
            Mentor Feedback
          </Nav.Item>
          <Nav.Item className="tw-text-center" eventKey="3">
            Session Details
          </Nav.Item>
        </Nav>

        {activeKey !== "3" && (
          <Stack direction="column" alignItems="flex-start" className="tw-my-4">
            <Stack.Item className="tw-w-full">
              <FlexboxGrid.Item as={Col} colspan={9}>
                <Nav
                  activeKey={activeKey === "1" ? menteeKey : mentorKey}
                  onSelect={activeKey === "1" ? setMenteeKey : setMentorKey}
                  appearance="subtle"
                  vertical
                >
                  {activeKey === "1" ? renderMenteeList() : renderMentorList()}
                </Nav>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item as={Col} colspan={15}>
                {!openRating ? (
                  <Nav
                    activeKey={
                      activeKey !== "1" ? menteeFeedbackKey : mentorFeedbackKey
                    }
                    onSelect={
                      activeKey !== "1"
                        ? setMenteeFeedbackKey
                        : setMentorFeedbackKey
                    }
                    appearance="subtle"
                    vertical
                  >
                    {activeKey !== "1"
                      ? renderMenteeOption()
                      : renderMentorOption()}
                  </Nav>
                ) : (
                  <GroupFeedbackDisplay
                    data={showDisplay.data}
                    user={showDisplay.user}
                    setOpenRating={setOpenRating}
                  />
                )}
              </FlexboxGrid.Item>
            </Stack.Item>
          </Stack>
        )}
        {activeKey === "3" && (
          <SessionDetails
            bookingData={bookingData}
            mentor={mentors}
            mentee={mentees}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}
