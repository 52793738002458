// React
import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";

// FutureLab
import SmartText from "../../../../ui/SmartText";
import MentorActionCard from "./MentorActionCard";
import MentorImpact from "./MentorImpact";
import Languages from "../../../shared/Languages";
import { PlatformType } from "../../../../../contexts/mentor_profile";
import Skills from "./Skills";
import translateText from "../../../../../util/translateText";
import Reviews from "../../../shared/Reviews";
import Badges from "../../../shared/Badges";
import Goals from "../../../shared/Goals";
import SocialMedia from "../../../shared/SocialMedia";
// UI
import { Affix, Col, Divider, FlexboxGrid, Nav } from "rsuite";

const MentorProfileBody = ({ convertText, currentUser }) => {
  const { mentor, type } = useOutletContext<any>();
  const [activeKey, setActiveKey] = useState("1");

  return (
    <div>
      <FlexboxGrid justify="center" className="tw-my-7">
        <FlexboxGrid.Item
          as={Col}
          colspan={22}
          xs={22}
          md={14}
          lg={12}
          className="tw-h-full tw-pr-16 sm:tw-pr-0"
        >
          <Nav
            appearance="subtle"
            activeKey={activeKey}
            onSelect={setActiveKey}
            justified
            className="tw-mb-5"
          >
            <Nav.Item eventKey="1" className="tw-justify-center">
              {translateText(convertText, "Overview")}
            </Nav.Item>
            {(mentor.reviews?.length || mentor.thank_you?.length) > 0 && (
              <Nav.Item
                eventKey="2"
                className="tw-justify-center tw-font-medium"
              >
                Reviews
              </Nav.Item>
            )}
          </Nav>
          {activeKey === "1" && (
            <div>
              {mentor.info.impact_to_create && (
                <>
                  <p className="tw-font-bold tw-text-xl tw-mb-2 montserrat">
                    {translateText(convertText, "The impact I want to make")}
                  </p>
                  <SmartText
                    text={mentor.info.impact_to_create}
                    length={300}
                    convertText={convertText}
                  />
                </>
              )}
              {mentor.mentees_helped_count > 0 && (
                <>
                  {mentor.info.impact_to_create && <Divider />}
                  <MentorImpact convertText={convertText} />
                </>
              )}
              <>
                <Divider />
                <Skills convertText={convertText} />
              </>
              {mentor.goals.length > 0 && (
                <>
                  <Divider />
                  <Goals convertText={convertText} user={mentor} />
                </>
              )}
              {mentor.languages.length > 0 && (
                <>
                  <Divider />
                  <Languages user={mentor} convertText={convertText} />
                </>
              )}
            </div>
          )}
          {activeKey === "2" && (
            <div>{mentor.reviews.length > 0 && <Reviews user={mentor} />}</div>
          )}
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          as={Col}
          colspan={22}
          xs={22}
          md={6}
          lg={6}
          className="tw-h-full sm:tw-hidden"
        >
          {mentor.badges?.length > 0 && type === PlatformType.FutureLab && (
            <Badges user={mentor} />
          )}
          <Affix top={70}>
            {currentUser?.id !== mentor?.id && (
              <MentorActionCard convertText={convertText} />
            )}
            {(mentor?.info?.linkedin_url || mentor?.info?.instagram_url) && (
              <SocialMedia convertText={convertText} user={mentor} />
            )}
          </Affix>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </div>
  );
};

export default MentorProfileBody;
