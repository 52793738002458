// React
import React from "react";

// FutureLab
import NotificationContent from "./notification/NotificationContent";

// 3rd Party
import Cookies from "js-cookie";

interface NotificationProps {
  portalId: string | null;
  currentUser: any;
  token: string;
  onPortal: { enterpriseSlug: string | null; programSlug: string | null };
  pwaUrl: string;
}

export default function Notification({
  portalId,
  token,
  currentUser,
  onPortal,
  pwaUrl,
}: NotificationProps) {
  const rawModalInfo = Cookies.get(`modal_to_show`);

  let modalInfo;

  if (rawModalInfo) {
    modalInfo = JSON.parse(rawModalInfo);
  }

  return (
    <>
      {modalInfo && (
        <NotificationContent
          portalId={portalId}
          token={token}
          currentUser={currentUser}
          onPortal={onPortal}
          pwaUrl={pwaUrl}
          modalInfo={modalInfo}
        />
      )}
    </>
  );
}
